import React, { useContext, useMemo, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// import { toast } from "react-toastify";

import useInvoice from "../../../hooks/useInvoice";
import { useGetBillingInfoQuery } from "../../../services/paymentService";
// import { useLazyGetVoucherValidityQuery } from "../../../services/orderService";
import { OrderContext } from "../../../providers/OrderProvider";
// import parseError from "../../../utils/errorUtils";

import Collapsible from "../../../components/collapsible/Collapsible";
import GoBack from "../../../components/GoBack";
import PrescriptionSummary from "../../../components/prescriptionSummary/PrescriptionSummary";
import { PrescriptionCheckoutForm } from "./orderDetails/PrescriptionCheckoutForm";
import Price from "../../../components/price/Price";
// import { Form, SubmitButton, TextField } from "../../../components/forms";

const fonts = [
    {
        cssSrc: "https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;600&display=swap",
        family: "Titillium Web",
    },
];

function OrderPaymentsPage() {
    const { order, prescription, onPayPrescription, deliveryType } =
        useContext(OrderContext);

    // const [discount, setDiscount] = useState(null);
    const [initialValues, setInitialValues] = useState({
        name: "",
        terms_and_conditions: false,
        consent: false,
    });

    const medicationTotal = useInvoice("PRESCRIPTION_DRUG", "PRESCRIPTION");

    const { data: billingInfo } = useGetBillingInfoQuery();
    // const [getVoucherValidity] = useLazyGetVoucherValidityQuery();

    // const handleCheckVoucherValidity = async (values) => {
    //     try {
    //         const res = await getVoucherValidity({ voucherId: values.voucher });

    //         if (res.error) {
    //             // handle error
    //             toast.error(
    //                 parseError(res.error, "couldn't submit voucher code")
    //             );
    //         } else {
    //             // handle success
    //             if (res.data.valid) {
    //                 const finalValue =
    //                     medicationTotal?.price * res.data.percentage;

    //                 setDiscount(finalValue);
    //             }
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    // delivery fee
    const deliveryFee = deliveryType === "EXPRESS" ? 15 : 0;

    useState(() => {
        if (order) {
            setInitialValues({
                ...initialValues,
                name: order.patient.displayName,
            });
        }
    }, [order]);

    const stripePromise = useMemo(async () => {
        if (billingInfo) {
            return await loadStripe(billingInfo.publicKey);
        }

        return null;
    }, [billingInfo]);

    const medicationPrice = useMemo(() => {
        // if (discount > 0) {
        //     return medicationTotal?.price - discount;
        // }

        return medicationTotal?.price;
    }, [
        medicationTotal,
        // discount
    ]);

    return (
        <Elements stripe={stripePromise} options={{ fonts }}>
            <div className="row mx-0 h-100">
                <div className="col-md-6 pt-5 position-relative bg-light-gray">
                    <div className="row mx-0 mb-0 mb-md-8">
                        <div className="col-12 col-lg-11 col-xl-9 col-xxl-8 mx-auto pb-5 pb-md-0 mb-0 mb-md-8">
                            <GoBack />
                            <h3 className="text-center fw-bold text-brown-black mt-4">
                                Payment details
                            </h3>
                            <p className="mt-4 text-medium-gray fs-6 text-center">
                                Please provide your card details to book your
                                appointment.
                            </p>
                            <div className="mt-3 text-center">
                                <img
                                    src="/images/vectors/visa.svg"
                                    alt="visa"
                                />
                                <img
                                    className="ms-2"
                                    src="/images/vectors/mastercard.svg"
                                    alt="mastercard"
                                />
                            </div>
                            <div className="d-flex justify-content-center mt-5">
                                <PrescriptionCheckoutForm
                                    initialValues={initialValues}
                                    onCheckout={onPayPrescription}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 pt-10 px-2 px-lg-6 mb-5 bg-white">
                    <div className="row mx-0 mb-8 mb-md-0 pb-8 pb-md-0">
                        <div className="col-12 col-lg-11 col-xl-9 col-xxl-8 mx-auto pb-5 pb-md-0 mb-0 mb-md-8">
                            <Collapsible title="Order Summary">
                                <PrescriptionSummary
                                    prescription={prescription}
                                />
                            </Collapsible>
                            <div className="mt-5">
                                <Collapsible title="Payment Summary" expanded>
                                    <div>
                                        <div className="d-flex mb-3 align-items-center justify-content-between text-medium-gray fs-6">
                                            <div>Medication total</div>
                                            <div>
                                                <Price
                                                    amount={medicationPrice}
                                                    currency="GBP"
                                                />
                                            </div>
                                        </div>
                                        {/* 
                                            <div className="d-flex mb-3 align-items-center justify-content-between text-medium-gray fs-6">
                                                <div>Processing fee</div>
                                                <div>
                                                    <Price
                                                    amount={processingAmount?.price}
                                                    currency="GBP"
                                                    />
                                                </div>
                                            </div>
                                        */}
                                        <div className="d-flex mb-3 align-items-center justify-content-between text-medium-gray fs-6">
                                            <div>
                                                {deliveryType === "EXPRESS"
                                                    ? "Express delivery"
                                                    : "Shipping"}
                                            </div>
                                            <div>
                                                <Price
                                                    amount={Number(deliveryFee)}
                                                    currency="GBP"
                                                />
                                            </div>
                                        </div>

                                        <hr />
                                        <div className="d-flex mb-3 align-items-center fw-bold justify-content-between text-primary fs-5">
                                            <div>Total</div>
                                            <div>
                                                <Price
                                                    amount={
                                                        Number(
                                                            medicationPrice || 0
                                                        ) +
                                                        Number(deliveryFee || 0)
                                                    }
                                                    currency="GBP"
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="d-flex mb-4 align-items-center fw-bold justify-content-between text-primary fs-6">
                                            <div>Discount code</div>
                                        </div>
                                        <div className="text-medium-gray mb-4">
                                            If you have a discount code please
                                            enter it here
                                        </div>
                                        <Form
                                            initialValues={{ voucher: "" }}
                                            onSubmit={
                                                handleCheckVoucherValidity
                                            }
                                        >
                                            <div className="input-group flex-nowrap">
                                                <TextField
                                                    name="voucher"
                                                    placeholder="Discount code"
                                                    className="rounded-0 rounded-start"
                                                />
                                                <SubmitButton
                                                    variant="primary fw-bold py-2 rounded-0 rounded-end"
                                                    disabledLoading
                                                    fullWidth={false}
                                                >
                                                    APPLY
                                                </SubmitButton>
                                            </div>
                                        </Form> */}
                                    </div>
                                </Collapsible>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Elements>
    );
}

export default OrderPaymentsPage;
