import { ACCESS_TOKEN_NAME } from "../constants";

async function uploadFile(data = {}) {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);

    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: data,
        redirect: "follow",
    };

    let res = {};

    await fetch(
        `${process.env.REACT_APP_API_BASE}/api/patient/file-upload`,
        requestOptions
    )
        .then((response) => {
            if (response.status === 401) {
                res = {
                    error: {
                        status: response.status,
                    },
                };
                window.location = "/signout";
            } else {
                if (response.ok) {
                    res = response;
                } else {
                    res = {
                        error: {
                            status: response.status,
                        },
                    };
                }
            }
        })
        .catch((error) => {
            res = {
                error: {
                    status: error.status,
                },
            };
            console.error(error);
        });

    return res;
}

async function uploadOrderFile(orderId, values = {}) {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);

    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    let requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: values,
        redirect: "follow",
    };

    let res = {};

    await fetch(
        `${process.env.REACT_APP_API_BASE}/api/patient/upload-order-files/${orderId}`,
        requestOptions
    )
        .then((response) => {
            if (response.status === 401) {
                res = {
                    error: {
                        status: response.status,
                    },
                };
                window.location = "/signout";
            } else {
                if (response.ok) {
                    res = response;
                } else {
                    res = {
                        error: {
                            status: response.status,
                        },
                    };
                }
            }
        })
        .catch((error) => {
            res = {
                error: {
                    status: error.status,
                },
            };
            console.error(error);
        });

    return res;
}

export { uploadFile, uploadOrderFile };
