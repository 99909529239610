import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";

import "./user-layout.scss";

function UserLayoutPage() {
    return (
        <div className="user-layout px-0">
            <div className="d-flex flex-wrap">
                <div className="sidebar-container position-fixed h-100 d-none d-md-block">
                    <Sidebar />
                </div>

                <main className="main px-3 px-md-8 px-lg-12 pt-4 pt-lg-8 pb-11 pb-sm-8 bg-light-gray position-relative">
                    <Outlet />
                </main>
            </div>
        </div>
    );
}

export default UserLayoutPage;
