import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import { useAuth } from "../../utils/authUtils";

import "./bottom-navigation.scss";

export default function BottomNavigation() {
    const location = useLocation();
    const auth = useAuth();

    const getActiveClass = (path) => {
        if (location.pathname.toLowerCase().includes(path)) {
            return "active";
        }
        return "";
    };

    return (
        <nav className="bottom-navigation d-block d-md-none bg-white py-4 px-5 px-sm-8 position-fixed bottom-0 w-100">
            <div className="d-flex justify-content-between align-items-center">
                {auth.isLogged && (
                    <>
                        <NavLink
                            className="nav-link text-primary d-flex justify-content-center align-items-center flex-column"
                            to="/user/dashboard"
                        >
                            <img
                                src={
                                    getActiveClass("dashboard") === ""
                                        ? "/images/sidebar/home.svg"
                                        : "/images/sidebar/home-active.svg"
                                }
                                alt=""
                            />
                            <span className="mt-1 small">Dashboard</span>
                        </NavLink>
                        <NavLink
                            className="nav-link text-primary d-flex justify-content-center align-items-center flex-column"
                            to="/user/order"
                        >
                            <img
                                src={
                                    getActiveClass("/order") === ""
                                        ? "/images/sidebar/order.svg"
                                        : "/images/sidebar/order-active.svg"
                                }
                                alt=""
                            />
                            <span className="mt-1 small">Treatments</span>
                        </NavLink>
                        <NavLink
                            className="nav-link text-primary d-flex justify-content-center align-items-center flex-column"
                            to="/user/my-cannabis-card"
                        >
                            <img src={getActiveClass("my-cannabis-card") === "" ? "/images/sidebar/qr.svg" : "/images/sidebar/qr-active.svg"} alt=""/>
                            <span className="mt-1 small">My Cannabis Card</span>
                        </NavLink>
                        <NavLink
                            className="nav-link text-primary d-flex justify-content-center align-items-center flex-column"
                            to="/user/support"
                        >
                            <img
                                src={
                                    getActiveClass("/support") === ""
                                        ? "/images/sidebar/support.svg"
                                        : "/images/sidebar/support-active.svg"
                                }
                                alt=""
                            />
                            <span className="mt-1 small">Support</span>
                        </NavLink>
                        <NavLink
                            className="nav-link text-primary d-flex justify-content-center align-items-center flex-column"
                            to="/user/account"
                        >
                            <img
                                src={
                                    getActiveClass("/account") === ""
                                        ? "/images/sidebar/account.svg"
                                        : "/images/sidebar/account-active.svg"
                                }
                                alt=""
                            />
                            <span className="mt-1 small">Account</span>
                        </NavLink>
                    </>
                )}
            </div>
        </nav>
    );
}
