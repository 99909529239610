import { useState } from "react";
import { toast } from "react-toastify";

import { uploadFile } from "../../../services/fileService";
import { useGetMyProfileQuery } from "../../../services/userService";
import parseError from "../../../utils/errorUtils";

import { Form } from "../../../components/forms";
import GoBack from "../../../components/GoBack";
import UploadDocumentForm from "../../../components/uploadDocumentForm/UploadDocumentForm";
import CollapsibleDocumentViewer from "../../../components/collapsibleDocumentViewer/CollapsibleDocumentViewer";

export default function UpdateKYCDocs() {
    const [initialValues] = useState({ id: [] });

    const { data: profileData, refetch, isLoading } = useGetMyProfileQuery();

    const handleSubmit = async (values, { resetForm }) => {
        try {
            let res;

            var formData = new FormData();

            formData.append("action", "PATIENT_KYC_DOCUMENTS");

            for (const fileData of values.id) {
                formData.append(
                    "files",
                    fileData.file,
                    `${fileData.type}.${fileData.file.name.split(".")[1]}`
                );
                formData.append("category", fileData.type);

                res = await uploadFile(formData);
                formData.delete("files");
                formData.delete("category");
            }

            if (res.error) {
                // handle error
                toast.error(parseError(res.error, "Couldn't upload file"));
            } else {
                // request succeeded
                resetForm({ id: [] });
                refetch();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const files =
        profileData?.files?.filter(
            ({ action }) => action === "PATIENT_KYC_DOCUMENTS"
        ) || [];

    return (
        <div className="account-layout pt-6 pt-lg-0 mb-4 mb-sm-6 mb-lg-0 px-xl-6 px-xxl-12">
            <GoBack text="Go back to account" />
            <div className="description-section bg-white rounded mt-5 px-5 py-7">
                <h3 className="text-primary text-center fw-bold">
                    Identifying Documents
                </h3>

                <div className="uploaded-docs d-flex flex-column justify-content-center mt-8 mb-4 px-2 px-md-4 px-lg-8">
                    <CollapsibleDocumentViewer
                        isLoading={isLoading}
                        files={files}
                        emptyMessage="No Identifying Documents available"
                    />
                </div>

                <div className="px-2 px-md-8">
                    <Form
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        <UploadDocumentForm
                            name="id"
                            options={[
                                {
                                    label: "Driving license",
                                    value: "driving_license",
                                },
                                {
                                    label: "Identification of Residence (ID)",
                                    value: "identification_of_residence",
                                },
                                {
                                    label: "Passport",
                                    value: "passport",
                                },
                            ]}
                            multiple
                            submitText="Submit for review"
                        />
                    </Form>
                </div>
            </div>
        </div>
    );
}
