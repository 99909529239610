import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";

import { useChangePasswordMutation } from "../../../services/userService";
import parseError from "../../../utils/errorUtils";

import { Form, SubmitButton, TextField } from "../../../components/forms";
import GoBack from "../../../components/GoBack";
import PasswordConditions from "../../../components/passwordConditions/PasswordConditions";
import TimerWindow from "../../../components/timerWindow/TimerWindow";

import { TIMER_TIMEOUT } from "../../../constants";

const ChangePasswordSchema = yup.object({
    oldPassword: yup.string().required("Please enter current password"),
    newPassword: yup
        .string()
        .required("Please enter a password")
        .min(8, "New password must be at least 8 characters")
        .matches(
            /^(?=.*[a-z])/,
            "Must contain at least one lowercase character"
        )
        .matches(
            /^(?=.*[A-Z])/,
            "Must contain at least one uppercase character"
        )
        .matches(/^(?=.*[0-9])/, "Must contain at least one number")
        .matches(
            /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~€£¥•]/,
            "Must contain at least one special character"
        ),
    confirmPassword: yup
        .string()
        .required("Please re-enter the password")
        .oneOf([yup.ref("newPassword")], "Passwords must match"),
});

export default function ChangePasswordPage() {
    const navigate = useNavigate();

    const [containsUpperCase, setContainsUpperCase] = useState(false);
    const [containsLowercase, setContainsLowerCase] = useState(false);
    const [containsNumber, setContainsNumber] = useState(false);
    const [containsSpecialChars, setContainsSpecialChars] = useState(false);
    const [containsMinChars, setContainsMinChars] = useState(false);
    const [showTimerWindow, setShowTimerWindow] = useState(false);
    const [initialValues] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    });

    const [changePassword] = useChangePasswordMutation();

    const handleSubmit = async (values) => {
        const data = {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
        };

        try {
            const res = await changePassword({ values: data });

            if (res.error) {
                // handle error
                toast.error(parseError(res.error, "Couldn't update password"));
            } else {
                // handle success
                setShowTimerWindow(true);
                setTimeout(() => {
                    navigate("/user/account");
                }, TIMER_TIMEOUT);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleKeyUp = (e) => {
        let value = e.target.value;
        if (/^(?=.*[@#_$!%])/.test(value)) {
            setContainsSpecialChars(true);
        } else {
            setContainsSpecialChars(false);
        }

        if (/^(?=.*[a-z])/.test(value)) {
            setContainsLowerCase(true);
        } else {
            setContainsLowerCase(false);
        }

        if (/^(?=.*[A-Z])/.test(value)) {
            setContainsUpperCase(true);
        } else {
            setContainsUpperCase(false);
        }

        if (/^(?=.*[0-9])/.test(value)) {
            setContainsNumber(true);
        } else {
            setContainsNumber(false);
        }

        if (value.length >= 8) {
            setContainsMinChars(true);
        } else {
            setContainsMinChars(false);
        }
    };

    const handleNavigateToAccount = () => {
        navigate("/user/account");
    };

    return (
        <div className="account-layout pt-6 pt-lg-0 mb-4 mb-sm-6 mb-lg-0 px-xl-6 px-xxl-12">
            <GoBack text="Go back to account" />
            <div className="description-section bg-white rounded mt-5 px-5 py-7">
                {showTimerWindow ? (
                    <TimerWindow
                        success
                        showTimer={false}
                        description="Your password has been successfully changed."
                        action={handleNavigateToAccount}
                        actionBtnText="Okay, got it"
                    />
                ) : (
                    <>
                        <h3 className="text-primary text-center fw-bold">
                            Change password
                        </h3>
                        <p className="body body-small text-dark-5 text-center">
                            Please type in your old password and your new
                            password.
                        </p>
                        <Form
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                            validationSchema={ChangePasswordSchema}
                        >
                            <div className="col-12 col-sm-7 col-lg-5 mb-3 mb-lg-0 mx-auto mt-8">
                                <div className="row mb-4">
                                    <TextField
                                        name="oldPassword"
                                        type="password"
                                        placeholder="Old password"
                                    />
                                </div>
                                <div className="row mb-4">
                                    <TextField
                                        name="newPassword"
                                        type="password"
                                        onKeyUp={handleKeyUp}
                                        placeholder="New password"
                                    />
                                </div>
                                <div className="row mb-4">
                                    <TextField
                                        name="confirmPassword"
                                        type="password"
                                        placeholder="Re-type password"
                                    />
                                </div>
                                <div className="row mb-5">
                                    <PasswordConditions
                                        containsLowercase={containsLowercase}
                                        containsMinChars={containsMinChars}
                                        containsNumber={containsNumber}
                                        containsSpecialChars={
                                            containsSpecialChars
                                        }
                                        containsUpperCase={containsUpperCase}
                                        title="Password Conditions:"
                                    />
                                </div>

                                <SubmitButton variant="primary text-uppercase px-2">
                                    Change Password
                                </SubmitButton>
                            </div>
                        </Form>
                    </>
                )}
            </div>
        </div>
    );
}
