import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/authUtils";

export default function SignoutPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    // set auth context to false
    auth.logOut();
    // clear redux store
    dispatch({ type: "auth/logout" });
    // clear browser storages
    localStorage.clear();
    sessionStorage.clear();
    //go to signin page
    navigate("/auth/signin");
  }, [auth, dispatch, navigate]);

  return null;
}
