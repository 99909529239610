import React from "react";

import "./dialog-box.scss";

/**
 * DialogBox component
 * @param {{
 * children: JSX.Element | string,
 * sent: boolean,
 * }} props
 * @returns {React.FunctionComponent}
 */

export default function DialogBox({ children = "", sent = true, sameSender }) {
    return (
        <div
            className={`dialog-box ${sent && "sent"} ${
                sent && sameSender && "me-6 me-md-7"
            } ${!sent && sameSender && "ms-6 ms-md-7"} fw-light text-break`}
        >
            {children}
        </div>
    );
}
