import React from "react";

export default function BusinessEstablishmentPage() {
	return (
		<div className="fs-18">
			<p>
				Important Legal Notice for Businesses: The Significance of
				Proper Accommodation for Medicinal Cannabis Users.
			</p>
			<p>
				<b>Unacceptable Practices:</b>
			</p>
			<ul>
				<li>
					<b>Service or Access Denial:</b> It's impermissible to
					refuse service or entry to anyone based on their use or
					possession of medicinal cannabis.
				</li>
				<li>
					<b>Invasion of Privacy:</b> Should a guest or visitor reveal
					their use of medicinal cannabis or show a Treat-It medicinal
					cannabis card, their privacy must be respected. Discussing
					or disclosing their status without permission is strictly
					forbidden.
				</li>
				<li>
					<b>Implementation of Biased Policies:</b> Ensure any policy
					concerning medicinal cannabis is factual, impartial, and
					consistently applied across all guests or visitors.
				</li>
				<li>
					<b>Discriminatory Behavior:</b> Engaging in discrimination,
					mocking, or demeaning guests or visitors for their medicinal
					cannabis usage or status is strictly prohibited.
				</li>
			</ul>

			<p>Additional Details:</p>

			<p>
				<b>
					Security Staff Instructions: Engaging Properly with
					Medicinal Cannabis Patients
				</b>
			</p>
			<p>
				Welcome to the security team. The rise in medicinal cannabis use
				necessitates a well-informed and understanding approach to
				interactions with patients.
			</p>

			<p>
				<b>Identifying Users of Medicinal Cannabis:</b>
			</p>
			<ul>
				<li>
					<b>Verification Process:</b> Confirm a patient's legitimate
					status through a prescription, the Treat-It Patient Card, or
					a notification from the Treat-It app.
				</li>
				<li>
					<b>Quick Identification:</b> Learn to recognize the common
					tools for identifying medicinal cannabis users efficiently
					and accurately.
				</li>
			</ul>

			<p>
				<b>Principal Responsibilities & Recommended Practices:</b>
			</p>
			<ul>
				<li>
					<b>Elimination of Discrimination:</b> Adopt an unbiased
					approach towards everyone, especially medicinal cannabis
					patients, to comply with the Equality Act 2010.
				</li>
				<li>
					<b>Acknowledgment of Medical Needs:</b> Understand that many
					rely on cannabis as an essential part of their health care
					and treat this need with respect.
				</li>
				<li>
					<b>Promotion of Empathy & Privacy:</b> Be considerate of
					patients who may be coping with conditions like anxiety or
					chronic pain, and handle these situations with care and
					confidentiality.
				</li>
			</ul>

			<p>
				<b>Expected Situations & Responses:</b>
			</p>
			<ul>
				<li>
					<b>Entry Screenings:</b> When a guest discloses their
					medicinal cannabis use during entry checks, validate their
					status through the Treat-It app or a valid prescription
					before granting entry.
				</li>
				<li>
					<b>Public Use:</b> If venue rules limit public consumption,
					discreetly guide the user to a private or designated area
					for their needs.
				</li>
				<li>
					<b>Medicine Storage & Limitations:</b> Keep informed about
					the allowable quantities for medicinal cannabis and use the
					Treat-It app for any necessary clarifications.
				</li>
			</ul>

			<p>
				<b>Documentation & Reporting Incidents:</b>
			</p>
			<ul>
				<li>
					It's crucial to document all interactions with medicinal
					cannabis, noting the patient's condition and ensuring their
					rights are upheld. In complex situations, seek advice from
					management or rely on the Treat-It app's verification
					features.
				</li>
			</ul>

			<p>
				<b>Consequences of Rights Infringements:</b>
			</p>
			<ul>
				<li>
					Violating a patient's rights can lead them to report the
					incident through the Treat-It app, potentially resulting in
					a formal complaint against the individual and the
					establishment, leading to significant penalties, including
					fines and criminal charges.
				</li>
			</ul>
		</div>
	);
}
