import React, { useMemo } from "react";

import { getFile } from "../../utils/dataUtils";

export default function DocumentViewer({ fileData, fullHeight = false }) {
    const displayingFile = useMemo(() => {
        if (!fileData?.url) {
            const file = getFile(fileData.base64value, "file");

            return { url: fileData.base64value, contentType: file.type };
        }

        return fileData;
    }, [fileData]);

    const handleViewPdf = (url) => {
        let pdfWindow = window.open("");

        pdfWindow.document.write(
            `<iframe width="100%" height="100%" src="${encodeURI(
                url
            )}"></iframe>`
        );
    };

    const handleViewImage = (url) => {
        let imgWindow = window.open("");

        imgWindow.document.write(
            `<img width="auto" height="auto" src="${encodeURI(url)}"></img>`
        );
    };

    return (
        <>
            {displayingFile?.contentType?.includes("image") ? (
                <span onClick={() => handleViewImage(displayingFile.url)}>
                    <img
                        src={displayingFile.url}
                        alt=""
                        className={`img-fluid w-100 ${
                            fullHeight && "h-100"
                        } cursor-pointer`}
                    />
                </span>
            ) : (
                <span
                    onClick={() => handleViewPdf(displayingFile.url)}
                    className="d-flex border rounded py-2 px-3 w-fit cursor-pointer"
                >
                    View document in a new tab
                </span>
            )}
        </>
    );
}
