import React from "react";
import { Link } from "react-router-dom";

import { GPS_URL } from "../../../constants";
import GoBack from "../../../components/GoBack";

export default function EligibilityMentalHealthExitPage() {
    return (
        <div className="age-exit-page px-3 m-auto mt-1">
            <div className="d-flex flex-column">
                <Link to={-1}>
                    <GoBack text="Back"/>
                </Link>
                <h2 className="text-primary fw-bold mt-5 mb-6 text-center">
                    We are fully booked at the moment
                </h2>
                <p className="fs-5 subtitle text-primary-200 text-center">
                    Our mental health clinic is fully booked right now.
                </p>
                <p className="fs-5 subtitle text-primary-200 text-center mb-10">
                    Please call <a href="tel:02477450738">024 7745 0738</a> to join the wait list 
                    for July appointments.
                </p>
            </div>
        </div>
    );
}