import React from "react";
import { useDropzone } from "react-dropzone";
import { useField, useFormikContext } from "formik";
import { toast } from "react-toastify";

import "./file-field.scss";

const acceptedFileTypesArray = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/doc",
    "application/docx",
    "application/ppt",
    "application/pptx",
    "application/xls",
    "application/xlsx",
    "application/msword",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    "application/vnd.ms-word.document.macroEnabled.12",
    "application/vnd.ms-word.template.macroEnabled.12",
    "application/vnd.ms-excel",
    "application/vnd.ms-excel",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
    "application/vnd.ms-excel.sheet.macroEnabled.12",
    "application/vnd.ms-excel.template.macroEnabled.12",
    "application/vnd.ms-excel.addin.macroEnabled.12",
    "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
    "application/vnd.ms-powerpoint",
    "application/vnd.ms-powerpoint",
    "application/vnd.ms-powerpoint",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.openxmlformats-officedocument.presentationml.template",
    "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
    "application/vnd.ms-powerpoint.addin.macroEnabled.12",
    "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
    "application/vnd.ms-powerpoint.template.macroEnabled.12",
    "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
];

export default function FileField(props) {
    const {
        name,
        multiple = false,
        disabled = false,
        files,
        droppable = true,
        fileLimit = 100,
    } = props;
    const [, meta] = useField(props);
    const { values, setFieldValue } = useFormikContext();

    const onDrop = (acceptedFiles) => {
        let fileList = [...values[name]];

        for (const file of acceptedFiles) {
            if (acceptedFileTypesArray.includes(file.type)) {
                fileList.push(file);
            } else {
                toast.error(
                    <>
                        <b>{file.type}</b> file type is not allowed
                    </>
                );
            }
        }

        if (values[name]?.length >= fileLimit) {
            toast.error(
                `You have reached the maximum upload limit of ${fileLimit} documents. You can upload more documents later, in your account dashboard.`
            );

            return;
        }

        if (acceptedFiles?.length > fileLimit) {
            toast.error(
                `You can only upload ${fileLimit} file${
                    fileLimit > 1 ? "s" : ""
                }`
            );

            return;
        }

        fileList.forEach((fileObj, idx) => {
            if(idx <= (fileLimit-1)){
                setFieldValue(`${name}[${idx}].file`, fileObj?.file || fileObj);
            }
            else{
                toast.error(
                    `You have reached the maximum upload limit of ${fileLimit} documents. You can upload more documents later, in your account dashboard.`
                );
            }
        });
    };

    const { getRootProps, getInputProps } = useDropzone({
        multiple,
        disabled,
        onDropAccepted: onDrop,
    });

    return (
        <>
            {droppable && (
                <div
                    {...getRootProps({
                        className: "file-field py-7 mb-7",
                    })}
                >
                    <input name={name} {...getInputProps()} />
                    <p className="mb-0 d-flex justify-content-center">
                        <img
                            src="/images/vectors/backup.svg"
                            alt=""
                            className="my-auto"
                            width={25}
                        />
                        <span className="ms-1 ms-sm-3 my-auto fs-6 fw-semibold text-primary text-decoration-underline text-center">
                            Upload document{fileLimit > 1 ? "s" : ""} from
                            device
                        </span>
                    </p>
                    <p className="fs-6 mb-0 mt-5 text-primary text-center">
                        or drag & drop
                    </p>
                </div>
            )}

            {files}

            {meta.touched && meta.error ? (
                <div
                    className="invalid-feedback mb-0"
                    style={{ display: "block" }}
                >
                    {meta.error}
                </div>
            ) : null}
        </>
    );
}
