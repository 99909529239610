import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { AuthProvider } from "./utils/authUtils";
import RepeatOrderProvider from "./providers/RepeatOrderProvider";
import AppointmentProvider from "./providers/AppointmentProvider";
import { useLoggerUtils } from "./utils/loggerUtils";

import RequireAuth from "./components/RequireAuth";

import AppLayout from "./components/AppLayout";
import FullScreenLayout from "./components/FullScreenLayout";
import ModalContainer from "./components/modals/ModalContainer";

import { AppointmentPage } from "./pages/appointment";
import {
    ConsultationCompletePage,
    ConsultationPage,
} from "./pages/consultation";

import SignInPage from "./pages/auth/SignInPage/SignInPage";

import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage";
import ResetPasswordPage from "./pages/auth/ResetPasswordPage";
import SignoutPage from "./pages/auth/SignoutPage";
import UserLayoutPage from "./pages/user/UserLayoutPage";
import AccountPage from "./pages/user/account/AccountPage";
import SupportPage from "./pages/user/SupportPage";
import MyCannabisCardPage from "./pages/user/my-cannabis-card/MyCannabisCardPage";

import DashboardPage from "./pages/user/dashboard/DashboardPage";
import OrderDetailsPage from "./pages/user/order/OrderDetailsPage";
import OrdersPage from "./pages/user/order/OrdersPage";
import OrderConfirmPage from "./pages/user/order/orderConfirmation/OrderConfirmPage";
import OrderPaymentsPage from "./pages/user/order/OrderPaymentsPage";
import OrderSuccessPage from "./pages/user/order/OrderSuccessPage";
import UserOrderLayoutPage from "./pages/user/UserOrderLayoutPage";
import ConsultationUploadsPage from "./pages/appointment/ConsultationUploadsPage";
import AppointmentLayoutPage from "./pages/appointment/AppointmentLayoutPage";
import AppointmentPaymentsPage from "./pages/appointment/AppointmentPaymentsPage";
import ChangeEmailPage from "./pages/user/account/ChangeEmailPage";
import ChangePasswordPage from "./pages/user/account/ChangePasswordPage";
import ChangeBasicInfoPage from "./pages/user/account/ChangeBasicInfoPage";
import ReOrderConfirmPage from "./pages/user/reorder/ReOrderConfirmPage";
import ReOrderQuestionnairePage from "./pages/user/reorder/ReOrderQuestionnaire/ReOrderQuestionnairePage";
import ChangePrescriptionPage from "./pages/user/reorder/ChangePrescription/ChangePrescriptionPage";
import FeedbackContactPage from "./pages/user/reorder/FeedbackContactPage";
import ReOrderFeedBackPage from "./pages/user/reorder/ReOrderFeedBackPage";
// import PromptToConsultationPage from "./pages/user/reorder/PromptToConsultationPage";
import UpdateProfilePicture from "./pages/user/account/UpdateProfilePicture";
import UpdateKYCDocsOnBoarding from "./pages/user/account/UpdateKYCDocsOnBoarding";
import OrderInvoicePage from "./pages/user/order/OrderInvoicePage";
import UpdateKYCDocs from "./pages/user/account/UpdateKYCDocs";
import UpdateSCRDocs from "./pages/user/account/UpdateSCRDocs";

import PrescriptionInvoicePage from "./pages/user/order/PrescriptionInvoicePage";
import ConsultationInvoicePage from "./pages/user/order/ConsultationInvoicePage";
import CookiesPolicyPage from "./pages/policy/CookiesPolicyPage";
import PrivacyPolicyPage from "./pages/policy/PrivacyPolicyPage";
import TermsConditionPage from "./pages/policy/TermsConditionPage";
import NotFoundPage from "./pages/NotFoundPage";

// Onboarding
import OnboardingLayout from "./components/OnboardingLayout";

// Onboarding - Eligibility
import LandingPage from "./pages/onboarding/eligibility/LandingPage";
import NameConfirmationPage from "./pages/onboarding/eligibility/NameConfirmationPage";
import EligibilityQuestionPage from "./pages/onboarding/eligibility/EligibilityQuestionPage";
import EligibilityAgeExitPage from "./pages/onboarding/eligibility/EligibilityAgeExitPage";
import EligibilityConditionExitPage from "./pages/onboarding/eligibility/EligibilityConditionExitPage";
import EligibilityDiagnosisExitPage from "./pages/onboarding/eligibility/EligibilityDiagnosisExitPage";
import EligibilityDisordersQuestionPage from "./pages/onboarding/eligibility/EligibilityDisordersQuestionPage";
import EligibilityPregnantExitPage from "./pages/onboarding/eligibility/EligibilityPregnantExitPage";
import EligibilityLocationExitPage from "./pages/onboarding/eligibility/EligibilityLocationExitPage";
import EligibilityMentalHealthExitPage from "./pages/onboarding/eligibility/EligibilityMentalHealthExitPage";
import EligibilitySuccessPage from "./pages/onboarding/eligibility/EligibilitySuccessPage";
import CannabisCardPage from "./pages/public/CannabisCardPage";
import CannabisCardFormPage from "./pages/public/CannabisCardFormPage";

// Onboarding - Auth
import RegisterPage from "./pages/onboarding/account/CreateAccountPage";
import CreateAccountSuccessPage from "./pages/onboarding/account/CreateAccountSuccessPage";

// Onboarding - Appointment
import UserDetailsPage from "./pages/onboarding/appointment/UserDetailsPage";
import SelectSlotPage from "./pages/onboarding/appointment/SelectSlotPage";
import ConfirmDetailsPage from "./pages/onboarding/appointment/ConfirmDetailsPage";
import CheckoutPage from "./pages/onboarding/appointment/CheckoutPage";
import UploadDocumentsPage from "./pages/onboarding/appointment/UploadDocumentsPage";
import AdditionalNotesPage from "./pages/onboarding/appointment/AdditionalNotesPage";
import ReferredByPage from "./pages/onboarding/appointment/ReferredByPage";
import AppointmentSuccessPage from "./pages/onboarding/appointment/AppointmentSuccessPage";

import { TOAST_CONFIG } from "./constants";

import "./custom.scss";
import "react-toastify/dist/ReactToastify.css";
import ConsultationDetailsPage from "./pages/onboarding/appointment/ConsultationDetailsPage";

// TODO: move routes a config object

function App() {
    const { info } = useLoggerUtils();

    datadogLogs.init({
        clientToken: process.env.REACT_APP_CLIENT_TOKEN,
        site: process.env.REACT_APP_SITE,
        service: process.env.REACT_APP_SERVICE,
        env: process.env.REACT_APP_ENV,
        forwardErrorsToLogs: true,
        sampleRate: 100,
    });

    datadogRum.init({
        applicationId: process.env.REACT_APP_DATADOG_APP_ID,
        clientToken: process.env.REACT_APP_CLIENT_TOKEN,
        site: process.env.REACT_APP_SITE,
        service: process.env.REACT_APP_SERVICE,
        env: process.env.REACT_APP_ENV,
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "allow",
    });

    datadogRum.startSessionReplayRecording();
    info("DataDog RUM initiated!");

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        >
            <GoogleOAuthProvider
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            >
                <AuthProvider>
                    <AppointmentProvider>
                        <RepeatOrderProvider>
                            <ToastContainer {...TOAST_CONFIG} />
                            <ModalContainer />
                            <Routes>
                                <Route path="/" element={<AppLayout />}>
                                    <Route
                                        path="/*"
                                        element={
                                            <RequireAuth>
                                                <Routes>
                                                    <Route
                                                        path="consultation/:id/*"
                                                        element={
                                                            <Routes>
                                                                <Route
                                                                    path="/"
                                                                    element={
                                                                        <ConsultationPage />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="complete"
                                                                    element={
                                                                        <ConsultationCompletePage />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="*"
                                                                    element={
                                                                        <NotFoundPage />
                                                                    }
                                                                />
                                                            </Routes>
                                                        }
                                                    />
                                                    <Route
                                                        path="user"
                                                        element={
                                                            <UserLayoutPage />
                                                        }
                                                    >
                                                        <Route
                                                            path="dashboard"
                                                            element={
                                                                <DashboardPage />
                                                            }
                                                        />
                                                        <Route
                                                            path="order"
                                                            element={
                                                                <OrdersPage />
                                                            }
                                                        />
                                                        <Route
                                                            path="order/:id"
                                                            element={
                                                                <OrderDetailsPage />
                                                            }
                                                        />
                                                        <Route
                                                            path="order/:id/consultation-invoice"
                                                            element={
                                                                <OrderInvoicePage />
                                                            }
                                                        />
                                                        <Route
                                                            path="order/:id/consultation-invoice/view"
                                                            element={
                                                                <ConsultationInvoicePage />
                                                            }
                                                        />
                                                        <Route
                                                            path="order/:id/prescription-invoice/view"
                                                            element={
                                                                <PrescriptionInvoicePage />
                                                            }
                                                        />
                                                        <Route
                                                            path="support"
                                                            element={
                                                                <SupportPage />
                                                            }
                                                        />
                                                        <Route
                                                            path="my-cannabis-card"
                                                            element={
                                                                <MyCannabisCardPage />
                                                            }
                                                        />
                                                        <Route
                                                            path="account/*"
                                                            element={
                                                                <Routes>
                                                                    <Route
                                                                        path="/"
                                                                        element={
                                                                            <AccountPage />
                                                                        }
                                                                    />
                                                                    <Route
                                                                        path="change-email"
                                                                        element={
                                                                            <ChangeEmailPage />
                                                                        }
                                                                    />
                                                                    <Route
                                                                        path="change-password"
                                                                        element={
                                                                            <ChangePasswordPage />
                                                                        }
                                                                    />
                                                                    <Route
                                                                        path="basic-info"
                                                                        element={
                                                                            <ChangeBasicInfoPage />
                                                                        }
                                                                    />
                                                                    <Route
                                                                        path="change-kyc"
                                                                        element={
                                                                            <UpdateKYCDocs />
                                                                        }
                                                                    />
                                                                    <Route
                                                                        path="change-scr"
                                                                        element={
                                                                            <UpdateSCRDocs />
                                                                        }
                                                                    />
                                                                    <Route
                                                                        path="*"
                                                                        element={
                                                                            <NotFoundPage />
                                                                        }
                                                                    />
                                                                </Routes>
                                                            }
                                                        />
                                                        <Route
                                                            path="*"
                                                            element={
                                                                <NotFoundPage />
                                                            }
                                                        />
                                                    </Route>
                                                    <Route
                                                        path="order/:id"
                                                        element={
                                                            <UserOrderLayoutPage />
                                                        }
                                                    >
                                                        <Route
                                                            path="payment"
                                                            element={
                                                                <OrderPaymentsPage />
                                                            }
                                                        />
                                                        <Route
                                                            path="confirm"
                                                            element={
                                                                <OrderConfirmPage />
                                                            }
                                                        />
                                                        <Route
                                                            path="success"
                                                            element={
                                                                <OrderSuccessPage />
                                                            }
                                                        />
                                                        <Route
                                                            path="*"
                                                            element={
                                                                <NotFoundPage />
                                                            }
                                                        />
                                                    </Route>

                                                    <Route
                                                        path="appointment"
                                                        element={
                                                            <AppointmentLayoutPage />
                                                        }
                                                    >
                                                        <Route
                                                            index
                                                            element={
                                                                <AppointmentPage />
                                                            }
                                                        />
                                                        <Route
                                                            path="questionnaire"
                                                            element={
                                                                <ReOrderQuestionnairePage />
                                                            }
                                                        />
                                                        <Route
                                                            path="change-prescription"
                                                            element={
                                                                <ChangePrescriptionPage />
                                                            }
                                                        />
                                                        <Route
                                                            path="feedback"
                                                            element={
                                                                <ReOrderFeedBackPage />
                                                            }
                                                        />
                                                        <Route
                                                            path=":id/uploads"
                                                            element={
                                                                <ConsultationUploadsPage />
                                                            }
                                                        />
                                                        <Route
                                                            path=":id/payment"
                                                            element={
                                                                <AppointmentPaymentsPage />
                                                            }
                                                        />
                                                        <Route
                                                            path="*"
                                                            element={
                                                                <NotFoundPage />
                                                            }
                                                        />
                                                    </Route>
                                                    <Route
                                                        path="*"
                                                        element={
                                                            <NotFoundPage />
                                                        }
                                                    />
                                                </Routes>
                                            </RequireAuth>
                                        }
                                    />
                                    <Route
                                        path="cookies-policy"
                                        element={<CookiesPolicyPage />}
                                    />
                                    <Route
                                        path="privacy-policy"
                                        element={<PrivacyPolicyPage />}
                                    />
                                    <Route
                                        path="terms-condition"
                                        element={<TermsConditionPage />}
                                    />
                                    <Route
                                        path="/cannabis-card/:token"
                                        element={<CannabisCardFormPage />}
                                    />
                                    <Route
                                        path="/cannabis-card"
                                        element={<CannabisCardPage />}
                                    />
                                </Route>
                                <Route
                                    path="reorder"
                                    element={<FullScreenLayout />}
                                >
                                    <Route
                                        path="/reorder/*"
                                        element={
                                            <RequireAuth>
                                                <Routes>
                                                    <Route
                                                        path="confirm"
                                                        element={
                                                            <ReOrderConfirmPage />
                                                        }
                                                    />
                                                    <Route
                                                        path="contact-support"
                                                        element={
                                                            <FeedbackContactPage />
                                                        }
                                                    />
                                                    <Route
                                                        path="*"
                                                        element={
                                                            <NotFoundPage />
                                                        }
                                                    />
                                                </Routes>
                                            </RequireAuth>
                                        }
                                    />
                                </Route>
                                <Route
                                    path="auth"
                                    element={
                                        <FullScreenLayout className="onboarding-theme" />
                                    }
                                >
                                    <Route
                                        path="signin"
                                        element={<SignInPage />}
                                    />
                                    <Route
                                        path="forgot-password"
                                        element={<ForgotPasswordPage />}
                                    />
                                    <Route
                                        path="reset-password"
                                        element={<ResetPasswordPage />}
                                    />
                                    <Route
                                        path="*"
                                        element={<NotFoundPage />}
                                    />
                                </Route>
                                <Route
                                    path="onboarding"
                                    element={<OnboardingLayout />}
                                >
                                    <Route
                                        path="/onboarding/*"
                                        element={
                                            <Routes>
                                                <Route
                                                    path="eligibility/name"
                                                    element={<LandingPage />}
                                                />
                                                <Route
                                                    path="eligibility/name-confirm"
                                                    element={
                                                        <NameConfirmationPage />
                                                    }
                                                />
                                                <Route
                                                    path="eligibility/age"
                                                    element={
                                                        <EligibilityQuestionPage questionId="q1" />
                                                    }
                                                />
                                                <Route
                                                    path="eligibility/age/exit"
                                                    element={
                                                        <EligibilityAgeExitPage />
                                                    }
                                                />
                                                <Route
                                                    path="eligibility/condition"
                                                    element={
                                                        <EligibilityQuestionPage questionId="q2" />
                                                    }
                                                />
                                                <Route
                                                    path="eligibility/condition/exit"
                                                    element={
                                                        <EligibilityConditionExitPage />
                                                    }
                                                />
                                                <Route
                                                    path="eligibility/mental-health/exit"
                                                    element={
                                                        <EligibilityMentalHealthExitPage />
                                                    }
                                                />
                                                <Route
                                                    path="eligibility/symptoms"
                                                    element={
                                                        <EligibilityQuestionPage questionId="q3" />
                                                    }
                                                />
                                                <Route
                                                    path="eligibility/diagnosis"
                                                    element={
                                                        <EligibilityQuestionPage questionId="q4" />
                                                    }
                                                />
                                                <Route
                                                    path="eligibility/diagnosis/exit"
                                                    element={
                                                        <EligibilityDiagnosisExitPage />
                                                    }
                                                />
                                                <Route
                                                    path="eligibility/disorders"
                                                    element={
                                                        <EligibilityQuestionPage questionId="q5" />
                                                    }
                                                />
                                                <Route
                                                    path="eligibility/disorders/exit"
                                                    element={
                                                        <EligibilityDisordersQuestionPage />
                                                    }
                                                />
                                                <Route
                                                    path="eligibility/pregnant"
                                                    element={
                                                        <EligibilityQuestionPage questionId="q6" />
                                                    }
                                                />
                                                <Route
                                                    path="eligibility/pregnant/exit"
                                                    element={
                                                        <EligibilityPregnantExitPage />
                                                    }
                                                />
                                                <Route
                                                    path="eligibility/location"
                                                    element={
                                                        <EligibilityQuestionPage questionId="q7" />
                                                    }
                                                />
                                                <Route
                                                    path="eligibility/location/exit"
                                                    element={
                                                        <EligibilityLocationExitPage />
                                                    }
                                                />
                                                <Route
                                                    path="eligibility/success"
                                                    element={
                                                        <EligibilitySuccessPage />
                                                    }
                                                />
                                                <Route
                                                    path="auth/register"
                                                    element={<RegisterPage />}
                                                />
                                                <Route
                                                    path="auth/success"
                                                    element={
                                                        <CreateAccountSuccessPage />
                                                    }
                                                />
                                                <Route
                                                    path="appointment/user-details"
                                                    element={
                                                        <UserDetailsPage />
                                                    }
                                                />
                                                <Route
                                                    path="appointment/select-slot"
                                                    element={<SelectSlotPage />}
                                                />
                                                <Route
                                                    path="appointment/confirm-details"
                                                    element={
                                                        <ConfirmDetailsPage />
                                                    }
                                                />
                                                <Route
                                                    path="appointment/checkout"
                                                    element={<CheckoutPage />}
                                                />
                                                <Route
                                                    path="appointment/:id/documents"
                                                    element={
                                                        <UploadDocumentsPage />
                                                    }
                                                />
                                                <Route
                                                    path="appointment/:id/notes"
                                                    element={
                                                        <AdditionalNotesPage />
                                                    }
                                                />
                                                <Route
                                                    path="appointment/referred-by"
                                                    element={<ReferredByPage />}
                                                />
                                                <Route
                                                    path="appointment/success"
                                                    element={
                                                        <AppointmentSuccessPage />
                                                    }
                                                />
                                                <Route
                                                    path="consultation/confirm-details"
                                                    element={
                                                        <ConsultationDetailsPage />
                                                    }
                                                />
                                                <Route
                                                    path="update-profile-picture"
                                                    element={
                                                        <UpdateProfilePicture />
                                                    }
                                                />
                                                <Route
                                                    path="kyc-document"
                                                    element={
                                                        <UpdateKYCDocsOnBoarding />
                                                    }
                                                />
                                                <Route
                                                    path="*"
                                                    element={<NotFoundPage />}
                                                />
                                            </Routes>
                                        }
                                    />
                                </Route>
                                <Route
                                    path="/signout"
                                    element={<SignoutPage />}
                                />
                                <Route path="*" element={<NotFoundPage />} />
                            </Routes>
                        </RepeatOrderProvider>
                    </AppointmentProvider>
                </AuthProvider>
            </GoogleOAuthProvider>
        </GoogleReCaptchaProvider>
    );
}

export default App;
