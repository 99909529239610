import React from "react";

function DeliveryTypeCard({ active, onChangeDeliveryType, delType, estTime }) {
    return (
        <div
            onClick={onChangeDeliveryType}
            className={`w-100 bg-white p-3 shadow-sm border cursor-pointer ${
                active ? "border-primary-1" : "border-white"
            } border-2 rounded`}
        >
            <h4
                className={`${
                    active ? "text-primary" : "text-brown-black"
                } fw-bold mb-0`}
            >
                {delType}
            </h4>
            {estTime && (
                <div
                    className={`pt-3 ${
                        active ? "text-primary" : "text-medium-gray"
                    } fw-bold`}
                >
                    {estTime}
                </div>
            )}
        </div>
    );
}

export default DeliveryTypeCard;
