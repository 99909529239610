import React from "react";

import { TREAT_IT_URL } from "../../constants";

export default function LogoNavbar() {
    const handleRouteToWebsite = () => {
        window.location.href = TREAT_IT_URL;
    };

    return (
        <nav className="logo-nav d-flex justify-content-between align-items-center pt-4 px-3 px-lg-5 position-fixed top-0 w-100">
            <div className="me-auto">
                <button
                    className="bg-transparent p-0 border-0"
                    onClick={handleRouteToWebsite}
                >
                    <img
                        className="logo p-4 pb-5"
                        src="/images/logo.png"
                        alt=""
                    />
                </button>
            </div>
        </nav>
    );
}
