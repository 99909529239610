import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { uploadOrderFile } from "../../../services/fileService";
import parseError from "../../../utils/errorUtils";

import { Form } from "../../../components/forms";
import UploadDocumentsForm from "./UploadDocumentsForm";

export default function UploadDocumentsPage() {
    const navigate = useNavigate();
    const { id: orderId } = useParams();

    const handleSubmit = async (values) => {
        try {
            let res;

            var formData = new FormData();

            for (const fileData of values.documents) {
                formData.append(
                    "files",
                    fileData.file,
                    `${fileData.type}.${fileData.file.name.split(".")[1]}`
                );
                formData.append("type", "Other supporting document");

                res = await uploadOrderFile(orderId, formData);
                formData.delete("files");
                formData.delete("type");
            }

            if (res.error) {
                // handle error
                toast.error(parseError(res.error, "Couldn't upload document"));
            } else {
                //handle success
                navigate(`/onboarding/appointment/${orderId}/notes`, {
                    replace: true,
                });
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleSkip = () => {
        navigate(`/onboarding/appointment/${orderId}/notes`);
    };

    return (
        <div className="upload-documents-page px-3 m-auto pb-12 w-100">
            <div className="d-flex flex-column">
                <h2 className="text-primary fw-bold mb-6 text-center">
                    Additional documents for the consultant
                </h2>
                <p className="fs-5 subtitle text-primary-200 text-center mb-8 mx-2 mx-lg-8">
                    Thanks for securing your appointment with us! Please share
                    supporting documents with us as this often accelerates the
                    approval process! This could be things like recent test
                    results, medical history summaries, or a list of medications
                    or treatments you have tried.
                </p>
            </div>
            <Form initialValues={{ documents: [] }} onSubmit={handleSubmit}>
                <UploadDocumentsForm name="documents" onSkip={handleSkip} />
            </Form>
        </div>
    );
}
