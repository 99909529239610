import React, { useState } from "react";
import useCollapse from "react-collapsed";

function PastQuestions({ questionData }) {
    const [isExpanded, setExpanded] = useState(false);

    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

    return (
        <>
            <div className="d-flex flex-column flex-sm-row align-items-center justify-content-between bg-light-gray border border-gray rounded py-4 px-3 px-sm-5">
                <div className="d-flex align-items-center">
                    <img
                        src="/images/vectors/specialist-question.svg"
                        alt="question"
                    />
                    <div className="fw-bold ms-3 pe-2 fs-6">
                        {/*{`Questions from the specialist (${questionData[0]?.doctor?.displayName})`}*/}
                        {`Message History (${questionData[0]?.doctor?.displayName})`}
                    </div>
                </div>
                <div
                    className="fw-bold fs-7 text-primary text-decoration-underline cursor-pointer"
                    {...getToggleProps({
                        onClick: () =>
                            setExpanded((prevExpanded) => !prevExpanded),
                    })}
                >
                    {isExpanded ? "Hide responses" : "Show all responses"}
                </div>
            </div>
            <div {...getCollapseProps()}>
                <div className="basic-table-section table-responsive border border-gray rounded mt-5">
                    <table className="table table-responsive mb-0">
                        <thead className="bg-light-gray">
                            <tr>
                                <th className="ps-5" scope="col">
                                    Message
                                </th>
                                <th className="pe-5" scope="col">
                                    Your answer
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {questionData?.map((note, index) => {
                                let displayingQuestionAnswer = {};
                                try {
                                    displayingQuestionAnswer = JSON.parse(
                                        note?.questionAnswer || "null"
                                    );
                                } catch (error) {
                                    console.error(error);
                                }

                                return (
                                    <tr key={note.id}>
                                        <td
                                            className={`py-5 ps-5 ${
                                                index ===
                                                    questionData.length - 1 &&
                                                "border-0"
                                            }`}
                                        >
                                            <span className="me-2 text-medium-gray fw-bold">
                                                {`${index + 1 < 10 ? "0" : ""}${
                                                    index + 1
                                                }.`}
                                            </span>
                                            <span>
                                                {displayingQuestionAnswer?.question ||
                                                    "No question preview available"}
                                            </span>
                                        </td>
                                        <td
                                            className={`py-5 pe-5 ${
                                                index ===
                                                    questionData.length - 1 &&
                                                "border-0"
                                            }`}
                                        >
                                            {displayingQuestionAnswer?.answer ||
                                                "You have not answered this question yet"}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default PastQuestions;
