import React from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

import {
    CONSULTATION_WAITING,
    REJECTED_STATUS,
} from "../../../../constants/orderStatus";

import "./order-details.scss";

dayjs.extend(advancedFormat);

export default function ConsultationCard({
    orderId,
    doctorData,
    appointmentDateTime,
    small,
    status,
    pharmacyConsultation = false,
    pharmacyData,
}) {
    const navigate = useNavigate();

    const handleViewConsultation = () => {
        navigate(`/user/order/${orderId}/consultation-invoice`);
    };

    const handleViewConsultationInvoice = () => {
        navigate(`/user/order/${orderId}/consultation-invoice/view`);
    };

    const rejected = REJECTED_STATUS.includes(status);
    const waiting = CONSULTATION_WAITING.includes(status);

    const avatar =
        (pharmacyConsultation
            // TODO: add correct attr. here
            ? pharmacyData?.settings?.LOGO
            : doctorData?.settings?.DOCTOR_AVATAR) ||
        "/images/samples/doctor-thumb.png";
    const name = pharmacyConsultation
        ? pharmacyData?.displayName
        : `${doctorData?.firstName} ${doctorData?.lastName}`;
    const registration = pharmacyConsultation
        ? `Registration Number: ${pharmacyData?.gphc}`
        : `Registration Number: ${doctorData?.gmc}`;
    const dateTime = `${dayjs(appointmentDateTime).format(
        "MMMM Do, YYYY"
    )} at ${dayjs(appointmentDateTime).format("hh:mm a")}`;

    return (
        <div
            className={`consultation-card d-flex justify-content-between flex-column ${
                small ? "align-items-start" : "flex-md-row align-items-center"
            } bg-light-gray border border-gray rounded p-4`}
        >
            <div className="d-flex justify-content-start align-items-center w-100">
                <div className="image-section me-3 mb-auto">
                    <img
                        src={avatar}
                        alt=""
                        style={{ width: 64, height: 64, borderRadius: "50%" }}
                    />
                </div>
                <div className="description-section text-brown-black d-flex flex-column">
                    <div className="title fw-bold mb-auto">{name}</div>
                    <div className="mt-1">{registration}</div>
                    <div className="date mt-1">{dateTime}</div>
                </div>
            </div>
            {(!waiting || rejected) && (
                <div
                    className={`track-section d-flex justify-content-end text-primary text-decoration-underline fw-bold mt-3 ${
                        small
                            ? "mt-md-6 ms-10 ps-2 pb-md-1"
                            : "mt-md-0 ms-6 ms-sm-0 long"
                    }`}
                >
                    <div
                        className="link"
                        type="button"
                        onClick={
                            rejected
                                ? handleViewConsultationInvoice
                                : handleViewConsultation
                        }
                    >
                        {rejected
                            ? "View invoice"
                            : "View consultation details"}
                    </div>
                </div>
            )}
        </div>
    );
}
