/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

export function useOutsideAlerter(ref, onClickOutside) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, onClickOutside]);
}

export function useIsInViewport(ref) {
    const [isIntersecting, setIntersecting] = useState({});

    const observer = new IntersectionObserver(([entry]) => {
        var out = {};
        out.top = entry.boundingClientRect.top < 0;
        out.left = entry.boundingClientRect.left < 0;
        out.bottom = entry.boundingClientRect.bottom > entry.rootBounds?.height;
        out.right = entry.boundingClientRect.right > entry.rootBounds?.width;

        setIntersecting(out);
    });

    const observe = () => {
        if (ref?.current) {
            observer.observe(ref.current);
        } else {
            observer.disconnect();
        }
    };

    useEffect(() => {
        return () => {
            observer.disconnect();
        };
    }, []);

    return { isIntersecting, observe, disconnect: () => observer.disconnect() };
}
