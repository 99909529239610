import { useEffect, useState } from "react";
import { useFormikContext } from "formik";

import { FileField, SubmitButton } from "../forms";
import Document from "./Document";

import "./upload-document-form.scss";

function UploadDocumentForm({
    name,
    multiple,
    fileLimit,
    options,
    submitText,
    hideSubmit = false,
}) {
    const { values, setFieldValue } = useFormikContext();

    const [isFileTypeAvailable, setIsFileTypeAvailable] = useState(false);

    const handleRemoveDocument = (id) => {
        let valuesCopy = [...values[name]];

        valuesCopy.splice(id, 1);
        setFieldValue(name, valuesCopy);
    };

    useEffect(() => {
        if (!hideSubmit) {
            if (values[name].length > 0) {
                let fileTypeUnavailability = 0;

                values[name].forEach((fileData) => {
                    if (!fileData.type || fileData.type === "") {
                        fileTypeUnavailability++;
                    }
                });

                if (fileTypeUnavailability === 0) {
                    setIsFileTypeAvailable(true);
                } else {
                    setIsFileTypeAvailable(false);
                }
            }
        }
    }, [name, values, hideSubmit]);

    return (
        <>
            <FileField
                name={name}
                multiple={multiple}
                fileLimit={fileLimit}
                files={
                    <div className="selected-documents my-4">
                        {values[name]?.map((value, idx) => {
                            return (
                                <div
                                    className={
                                        values[name].length - 1 === idx
                                            ? ""
                                            : "mb-3"
                                    }
                                    key={idx}
                                >
                                    <Document
                                        idx={idx}
                                        fieldName={name}
                                        fileName={value?.file?.name}
                                        fileSize={value?.file?.size}
                                        onRemoveDocument={handleRemoveDocument}
                                        options={options}
                                    />
                                </div>
                            );
                        })}
                    </div>
                }
            />

            {!hideSubmit && (
                <div className="mt-5 mb-8 text-center">
                    <SubmitButton
                        variant="primary px-8"
                        fullWidth={false}
                        disabled={
                            !values[name] ||
                            values[name].length === 0 ||
                            !isFileTypeAvailable
                        }
                    >
                        {submitText}
                    </SubmitButton>
                </div>
            )}
        </>
    );
}

export default UploadDocumentForm;
