import React, { useState } from "react";
import { toast } from "react-toastify";

import { useSubscribeToMailMutation } from "../../../services/dataService";
import { useModal } from "../../../hooks/modalsHooks";
import parseError from "../../../utils/errorUtils";

import { Form, SubmitButton, TextField } from "../../forms";
import ModalLayout from "../ModalLayout";
import ContactCard from "../../contactCard/ContactCard";
import ServiceCard from "../../serviceCard/ServiceCard";

import { GPS_URL, KANABO_STORE_URL } from "../../../constants";
import {
    SUPPORT_EMAIL,
    SUPPORT_TELEPHONE,
    SUPPORT_WHATSAPP,
} from "../../../constants/appProperties";

export default function JoinMailingListModal() {
    const { hideModal } = useModal();
    const isMailSent = JSON.parse(localStorage.getItem("SubscribedToMail"));
    const [subscriptionSuccess, setSubscriptionSuccess] = useState(isMailSent);

    const [subscribeToMail] = useSubscribeToMailMutation();

    const handleSubmit = async (values) => {
        try {
            const eligibilityForm = JSON.parse(
                localStorage.getItem("EligibilityForm") || "{}"
            );

            const data = { email: values.email, body: eligibilityForm.name };

            const res = await subscribeToMail(data);

            if (res.error) {
                // handle error
                toast.error(parseError(res.error, "Couldn't subscribe"));
            } else {
                // request succeeded
                localStorage.setItem("SubscribedToMail", true);
                setSubscriptionSuccess(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <ModalLayout className="bg-secondary-100" width={"43rem"}>
            <ModalLayout.Header
                className="bg-secondary-100"
                closeModal={hideModal}
            />
            <ModalLayout.Body className="pt-0 px-5 pb-10 h-max bg-secondary-100">
                {subscriptionSuccess ? (
                    <SubscriptionSuccess />
                ) : (
                    <JoinMailListForm onSubmit={handleSubmit} />
                )}
            </ModalLayout.Body>
        </ModalLayout>
    );
}

const JoinMailListForm = ({ onSubmit }) => {
    return (
        <>
            <div className="w-100">
                <div className="text-center">
                    <img
                        src="/images/vectors/send-mail.svg"
                        alt=""
                        width={60}
                    />
                    <h4 className="fs-4 text-primary fw-semibold mt-4 mb-5">
                        Join Our Mailing List
                    </h4>
                </div>
            </div>
            <div className="text-center m-auto fs-6 text-primary-200">
                <p>
                    We understand you need help for a mental health condition.
                    Although we currently only prescribe medical cannabis for
                    pain, we will soon expand our services to support mental
                    health needs, too.
                </p>
                <p>
                    You deserve access to effective treatment options that
                    improve your wellbeing. We are working to launch new mental
                    health services soon. Join our mailing list to be the first
                    to know when we begin prescribing cannabis to manage mental
                    health conditions.
                </p>
                <p className="m-0">
                    By signing up, you'll get exclusive updates and
                    offers. Please join our list so we can contact you when we
                    open enrollment.
                </p>
                <div className="px-0 px-sm-8 px-md-10 d-flex flex-column mx-auto contact-item-container mt-6">
                    <Form initialValues={{ email: "" }} onSubmit={onSubmit}>
                        <div className="mb-6">
                            <TextField
                                name="email"
                                className="form-control-sm fs-6 px-5 py-4 bg-white"
                                placeholder="Email"
                            />
                        </div>
                        <SubmitButton>
                            <img
                                className="me-2 my-auto"
                                src="/images/vectors/arrow-right.svg"
                                alt=""
                            />
                            Join the waitlist
                        </SubmitButton>
                    </Form>
                </div>
            </div>
        </>
    );
};

const SubscriptionSuccess = () => {
    return (
        <>
            <div className="w-100">
                <div className="text-center">
                    <img
                        src="/images/vectors/success-primary.svg"
                        alt=""
                        width={60}
                    />
                    <h4 className="fs-4 text-primary fw-semibold mt-4 mb-5">
                        Thank you for your interest in our upcoming mental
                        health cannabis services.
                    </h4>
                </div>
            </div>
            <div className="text-center m-auto fs-6 text-primary-200">
                <p className="m-0">
                    While you wait, please explore the other benefits we offer:
                </p>
                <div className="row px-0 mt-6">
                    <div className="col-12 col-md-6 mb-5">
                        <ServiceCard
                            title="Online GP Service"
                            body="Access medical care and advice from home through our virtual doctor platform."
                            logo="gpservice-logo"
                            buttonText="Go to The GP Service"
                            link={GPS_URL}
                        />
                    </div>
                    <div className="col-12 col-md-6 mb-5">
                        <ServiceCard
                            title="Kanabo Store CBD"
                            body="Shop our selection of non-prescription high concentrate CBD wellness products."
                            logo="kanabo-logo"
                            buttonText="Visit Kanabo Store"
                            link={KANABO_STORE_URL}
                        />
                    </div>
                </div>
            </div>
            <div className="text-center bg-white bg-opacity-50 shadow-sm p-4 rounded-1">
                <span className="fs-5 fw-semibold text-primary">
                    Reach our Team:
                </span>
                <div className="px-0 mx-auto mt-6">
                    <div className="mb-4 text-center">
                        <ContactCard
                            icon="phone-primary"
                            text="Call us:"
                            type="tel"
                            contact={SUPPORT_TELEPHONE}
                        />
                    </div>
                    <div className="mb-4 text-center">
                        <ContactCard
                            icon="mail"
                            text="Email us:"
                            type="mailto"
                            contact={SUPPORT_EMAIL}
                        />
                    </div>
                    <div className="mb-4 text-center">
                        <ContactCard
                            icon="whatsapp"
                            text="Chat with us through:"
                            contact={SUPPORT_WHATSAPP}
                            contactText="WhatsApp"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
