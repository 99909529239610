import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import React, { useState, useEffect } from 'react';

import { useModal } from "../../hooks/modalsHooks";
import { useAddOrderNotesMutation } from "../../services/orderService";
import useVideoStream from "../../hooks/videoStreamHooks";
import parseError from "../../utils/errorUtils";

import Button from "../button/Button";
import ChatThread from "../chat/ChatThread";

import "./video-stream-with-chat.scss";

const messageEnums = {
    PATIENT_DETAIL_CONFIRMATION: "Patient details confirmation form",
    PEG_FORM: "PEG form",
    AUDIT_FORM: "AUDIT form",
    GAD_FORM: "GAD form",
};

export default function VideoStreamWithChat({
    sessionId,
    sessionToken,
    orderData,
}) {
    const navigate = useNavigate();
    const { id } = useParams();
    const { showModal, hideModal } = useModal();
    const [ browserPermission, setBrowserPermission ] = useState(true);

    const [addOrderNote] = useAddOrderNotesMutation();

    const handleSubmitOrderNotes = async (values) => {
        try {
            const res = await addOrderNote({ orderId: orderData.id, values });

            if (res.error) {
                // handle error
                toast.error(parseError(res.error, "Couldn't add order note"));
            } else {
                toast.success("Successfully added order note");

                const message = `You submitted the ${
                    messageEnums[values.type]
                }.`;
                handleSendMessage(message, {
                    type: values.type,
                });
                hideModal();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleConsultationEnd = () => {
        handleDisconnectSession();
        setIsCallFinished(true);
        navigate(`/consultation/${id}/complete`, { replace: true });
    };

    const handleShowQuestionnaireModal = (type) => {
        switch (type) {
            case "PATIENT_DETAIL_CONFIRMATION":
                showModal({
                    modalType: "ConsultationConsentModal",
                    modalProps: {
                        onSubmit: handleSubmitOrderNotes,
                    },
                });
                break;
            case "PEG_FORM":
                showModal({
                    modalType: "PEGModal",
                    modalProps: {
                        onSubmit: handleSubmitOrderNotes,
                    },
                });
                break;
            case "AUDIT_FORM":
                showModal({
                    modalType: "AUDITModal",
                    modalProps: {
                        onSubmit: handleSubmitOrderNotes,
                    },
                });
                break;
            case "GAD_FORM":
                showModal({
                    modalType: "GADModal",
                    modalProps: {
                        onSubmit: handleSubmitOrderNotes,
                    },
                });
                break;
            default:
                break;
        }
    };

    const handleSignalAccept = (event) => {
        const parsedData = JSON.parse(event.data || "null");
        if (!parsedData) {
            handleConsultationEnd();
        }
    };

    const handleSignalComplete = (event) => {
        if (event.data.completed) {
            handleConsultationEnd();
        }
    };

    const handleSignalRequestQuestionnaire = (event) => {
        handleShowQuestionnaireModal(event.data.type);
    };

    const requestPermission = () => {
        showModal({ modalType: "CameraModal" })
    };

    const requestCameraAndMicPermissions = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            if(stream){
                setBrowserPermission(true);
            }
        } catch (error) {
            let errorString = error.toString().split(": ");
            if(errorString.length > 0 && errorString[1] === "Permission denied"){
                setBrowserPermission(false);
            }
        }
    };

    useEffect(() => {
        // Call the requestCameraAndMicPermissions function when the component is mounted
        requestCameraAndMicPermissions();
    }, []);

    const {
        handleConnectToSession,
        handleDisconnectSession,
        isAudioPublished,
        isAudioSubscribed,
        isCallFinished,
        isConnected,
        isPublished,
        isVideoPublished,
        messages,
        handleSendMessage,
        setIsCallFinished,
        handleUpdatePublisher,
        handleUpdateSubscriber,
    } = useVideoStream({
        sessionId,
        sessionToken,
        onSignalAccept: handleSignalAccept,
        onSignalComplete: handleSignalComplete,
        onSignalRequestQuestionnaire: handleSignalRequestQuestionnaire,
    });

    return (
        <>
            <div className="col-12 col-lg-8 position-relative video-container ps-0">
                <div className="video-stream-container">
                    <div
                        id="publisher"
                        className={`video-stream publisher position-absolute ${
                            !isPublished && "show-overlay"
                        }`}
                    >
                        {!isCallFinished && !isPublished && browserPermission && (
                            <div className="overlay-text">
                                <h2 className="mb-3 fw-light text-center">
                                    The call has not started
                                </h2>
                                <p className="mb-0 fw-light small text-center">
                                    The consultant is waiting for you to join the
                                    call. In order to join the call please press
                                    the button below
                                </p>
                                <div className="d-flex mt-4">
                                    <Button
                                        variant="primary btn-sm px-3 py-2 mx-auto"
                                        fullWidth={false}
                                        onClick={handleConnectToSession}
                                        disabled={!isConnected}
                                    >
                                        Join call
                                    </Button>
                                </div>
                            </div>
                        )}

                        {!isCallFinished && !isPublished && !browserPermission && (
                            <div className="overlay-text">
                                <h2 className="mb-3 fw-light text-center browser-permission">
                                    Your device's current settings block microphone and camera usage for video consultation.
                                </h2>
                                <div className="d-flex mt-4">
                                    <Button
                                        variant="primary btn-sm px-3 py-2 mx-auto"
                                        fullWidth={false}
                                        onClick={requestPermission}
                                        disabled={!isConnected}
                                    >
                                        Allow microphone and camera
                                    </Button>
                                </div>
                            </div>
                        )}

                        {isCallFinished && (
                            <div className="overlay-text">
                                <h2 className="mb-3 fw-light text-center">
                                    Consultation ended
                                </h2>
                                <p className="mb-0 fw-light small text-center">
                                    The consultation was ended by the Doctor.
                                </p>
                            </div>
                        )}
                    </div>
                    <div
                        id="subscriber"
                        className="video-stream subscriber position-absolute"
                    >
                        <div className="d-flex">
                            <div className="mute-btn-container mt-4 ms-4 me-auto">
                                <Button
                                    variant="dark-transparent btn-sm px-3 py-2 mx-auto d-flex"
                                    fullWidth={false}
                                    onClick={() =>
                                        handleUpdateSubscriber(
                                            !isAudioSubscribed
                                        )
                                    }
                                >
                                    <img
                                        src={`/images/vectors/speaker${
                                            isAudioSubscribed ? "" : "-muted"
                                        }.svg`}
                                        alt=""
                                        className="my-auto mx-auto me-sm-2"
                                    />
                                    <span className="d-none d-sm-block my-auto fw-light">
                                        {orderData?.doctor.displayName}
                                    </span>
                                </Button>
                            </div>
                        </div>
                        <div className="d-flex video-controls-container position-absolute d-flex justify-content-center">
                            <div className="me-1 me-sm-2">
                                <Button
                                    variant="primary border-0 p-2 p-sm-3"
                                    fullWidth={false}
                                    onClick={() => {
                                        handleUpdatePublisher(
                                            "camera",
                                            !isVideoPublished
                                        );
                                    }}
                                    disabled={!isConnected || isCallFinished || !browserPermission}
                                >
                                    <img
                                        src={`/images/vectors/video${
                                            isVideoPublished ? "" : "-hidden"
                                        }.svg`}
                                        alt=""
                                    />
                                </Button>
                            </div>
                            <div className="mx-1 mx-sm-2">
                                <Button
                                    variant="primary border-0 p-2 p-sm-3"
                                    fullWidth={false}
                                    onClick={() => {
                                        handleUpdatePublisher(
                                            "mic",
                                            !isAudioPublished
                                        );
                                    }}
                                    disabled={!isConnected || isCallFinished || !browserPermission}
                                >
                                    <img
                                        src={`/images/vectors/mic${
                                            isAudioPublished ? "" : "-muted"
                                        }.svg`}
                                        alt=""
                                    />
                                </Button>
                            </div>
                            {/* NOTE: removed for the moment */}
                            {/*
                                <div className="ms-1 ms-sm-2">
                                    <Button variant="white border-0 border-0 p-2 p-sm-3" fullWidth={false}>
                                        <img src="/images/vectors/dots.svg" alt="" />
                                    </Button>
                                </div>
                            */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="chat-thread-container col-12 col-lg-4 mt-5 mt-lg-0 px-0 mb-8 mb-lg-0">
                <ChatThread
                    onSend={handleSendMessage}
                    messages={messages}
                    orderData={orderData}
                    disabled={isCallFinished}
                    isCallFinished={isCallFinished}
                    isPublished={isPublished}
                    handleDisconnectSession={handleDisconnectSession}
                />
            </div>
        </>
    );
}
