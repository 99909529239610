/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

import { useIsInViewport } from "../../utils/domUtils";

import "./tooltip.scss";

function Tooltip({
    message,
    style,
    messageStyles,
    position,
    onClick,
    children,
    hidden,
}) {
    const [showTooltip, setShowTooltip] = useState(false);
    const [dynamicPosition, setDynamicPosition] = useState(null);

    const tooltipRef = useRef(null);
    const { isIntersecting: isVisible, observe } = useIsInViewport(tooltipRef);

    useEffect(() => {
        if (isVisible?.top) {
            setDynamicPosition("bottom");
        } else if (isVisible?.bottom) {
            setDynamicPosition("top");
        } else if (isVisible?.left) {
            setDynamicPosition("left");
        } else if (isVisible?.right) {
            setDynamicPosition("right");
        }
    }, [isVisible?.top, isVisible?.bottom, isVisible?.left, isVisible?.right]);

    useEffect(() => {
        observe();
    }, [showTooltip]);

    if (hidden) {
        return children;
    }

    return (
        <span
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            onClick={onClick}
            className="position-relative"
        >
            <span
                className={`tool-tip ${
                    showTooltip && message && isVisible ? "d-block" : "d-none"
                }`}
            >
                <div
                    className={`tool-tip-bubble text-center tool-tip-${
                        !isVisible?.top &&
                        !isVisible?.bottom &&
                        !isVisible?.left &&
                        !isVisible?.right
                            ? position
                            : dynamicPosition
                    }`}
                    style={style}
                    ref={tooltipRef}
                >
                    <div
                        className="tool-tip-message p-1 text-white bg-dark-5 small rounded"
                        style={messageStyles}
                    >
                        {message}
                    </div>
                </div>
            </span>
            {children}
        </span>
    );
}

export default Tooltip;
