export default function LoadingSpinner({
    spinnerImage,
    hideLoadingText,
    loadingText,
}) {
    return (
        <>
            {spinnerImage ? (
                <img className="spinner" src={spinnerImage} alt="" />
            ) : (
                <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                ></span>
            )}
            {!hideLoadingText && (
                <span className="ms-2">{loadingText || "Loading..."}</span>
            )}
        </>
    );
}
