import { getStatus } from "../../../../utils/orderStatusUtils";

import Pill from "../../../../components/pill/Pill";
import Avatar from "../../../../components/avatar/Avatar";

import "./order-description.scss";

export default function OrderDescription({
    orderName = "",
    orderNumber = "",
    orderDate = "",
    prescriptionNumber = "",
    registration,
    doctorAvatar,
    status,
}) {
    return (
        <div className="order-description-section">
            <div className="d-flex flex-column px-5 px-md-8 px-xxl-10">
                <div className="d-flex flex-column flex-sm-row justify-content-between w-100 mb-5">
                    <div className="header-section">
                        <div className="d-flex">
                            {doctorAvatar && (
                                <div className="avatar-container me-5">
                                    <Avatar src={doctorAvatar} />
                                </div>
                            )}
                            <div className="primary-title h3 fw-bold my-auto">
                                {orderName}
                            </div>
                        </div>
                    </div>
                    <div className="status-section d-flex align-items-center mt-4 my-sm-auto">
                        <Pill variant={`${getStatus(status).variant} fw-bold`}>
                            {getStatus(status).statusText}
                        </Pill>
                    </div>
                </div>
                <div className="d-flex flex-column flex-sm-row justify-content-between w-100">
                    {orderDate && (
                        <div className="d-flex flex-column mb-3">
                            <p className="body body-small fw-bold mb-0">
                                Date & Time:
                            </p>
                            <small>{orderDate}</small>
                        </div>
                    )}
                    <div className="d-flex flex-column mb-3">
                        <p className="body body-small fw-bold mb-0">
                            Order number:
                        </p>
                        <small>{orderNumber}</small>
                    </div>
                    {prescriptionNumber && (
                        <div className="d-flex flex-column mb-3">
                            <p className="body body-small fw-bold mb-0">
                                Prescription number:
                            </p>
                            <small>{prescriptionNumber}</small>
                        </div>
                    )}
                    {registration?.number && (
                        <div className="d-flex flex-column mb-3">
                            <p className="body body-small fw-bold mb-0">
                                Registration number:
                            </p>
                            <small>{registration?.number}</small>
                        </div>
                    )}
                </div>
            </div>
            <hr className="mt-3 mb-5" />
        </div>
    );
}
