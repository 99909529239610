import React from "react";

import "./cookies-policy.scss";
import {
    SUPPORT_EMAIL,
    SUPPORT_TELEPHONE,
} from "../../constants/appProperties";

function TermsConditionPage() {
    return (
        <div className="policy-container container my-6 pb-6">
            <h1 className="fw-bold fs-3 mb-6">Terms & Conditions</h1>
            <p>
                Treat It (“The Clinic”, “we,” or “us”) is part of The GP Service
                and brand name trademarked under Kanabo Group PLC, a company
                incorporated in England and Wales under company number
                338440891.
            </p>
            <p>
                Treat It provides an online service that brings together
                patients, doctors and pharmacies. It provides this service and
                related services (the “Services”) through its website at{" "}
                <a
                    href="https://treat-it.clinic/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    https://treat-it.clinic
                </a>{" "}
                , its mobile and tablet applications and certain software
                (together, the “Treat It System”).
            </p>
            <p>
                Before using our website/applications please read these terms
                and conditions carefully. By using the Treat It System you agree
                to be legally bound by these terms which will take effect
                immediately on your first use of the Treat It System. If you do
                not agree to be legally bound by these terms and conditions then
                please do not access or use the Treat It System. We may change
                these terms and conditions at any time and will post changes
                online. Please review these terms regularly to ensure you are
                aware of any changes made by us. Continued use of the Treat It
                System after these changes are posted means you agree to be
                legally bound by these changes as updated. These Terms form a
                legal agreement (the “Agreement”) between you and The Clinic
            </p>

            <h6 className="fs-5 my-5">IMPORTANT:</h6>
            <p>
                Our services are not suitable for any emergency medical
                situation or for any diagnosis or treatment that may require a
                physical examination.
            </p>
            <p>
                In an emergency, please call 999 or go to your nearest accident
                and emergency department.
            </p>

            <h6 className="mt-5">1.0 Definitions</h6>
            <p>In these Terms the following definitions shall apply:</p>
            <ul>
                <li>
                    “Advice” means any advice or information provided (whether
                    through voice communication, video or electronically) by the
                    Treat It System or a Doctor via the Treat It System;
                </li>
                <li>
                    “Doctor” means any doctor or medical practitioner to whom
                    the Treat It Clinic provides access to via the Treat It
                    System;
                </li>
                <li>
                    “Nominated Pharmacy” means the pharmacy selected by you or
                    your doctor at the time you place your order via the Treat
                    It System, or if that pharmacy fails to or declines to
                    accept your prescription for dispensing, the pharmacy
                    nominated by us and notified to you by email; and
                </li>
                <li>
                    “Product” means any physical product that you may order
                    through the Treat It System for supply by Treat It Clinic or
                    by a pharmacy.
                </li>
            </ul>

            <h6 className="mt-7 fs-6 fw-bold">About Treat It</h6>
            <h6 className="mt-5 fs-6">2.0 Treat It provides a service that:</h6>
            <ul>
                <li>
                    Allows users to consult with Doctors via online assessment
                    questionnaires or through secure video chat, on a paid for
                    and private basis;
                </li>
            </ul>

            <h6 className="mt-5 fs-6">
                2.1 Treat It (part of the GP Service) is regulated by the Care
                Quality Commission (the “CQC”) under number ‘1-2804999282’. The
                CQC monitors, inspects and regulates health and social care
                services.
            </h6>

            <h6 className="mt-5 fs-6">
                2.2 All Doctors are registered with the General Medical Council
                in the UK, and their registration details and status can be
                found at www.gmc-org.uk. We do not guarantee the availability of
                any particular Doctor. All Doctors are independent contractors
                and any Advice provided or offered to you by a Doctor is the
                sole responsibility of that Doctor.
            </h6>

            <h6 className="mt-5 fs-6">
                2.3 All of the pharmacies that are listed on the Treat It System
                are registered with the General Pharmaceutical Council (GPhC)
                and information about each of them can be found at{" "}
                <a
                    href="www.pharmacyregulation.org/registers/pharmacy"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    www.pharmacyregulation.org/registers/pharmacy
                </a>{" "}
                . If you require further information about a pharmacy, you can
                contact the pharmacy directly on the number listed for that
                pharmacy. All prescription medication dispensed by a pharmacy,
                and all other products and services supplied by a pharmacy, are
                dispensed and supplied under that pharmacy’s terms and
                conditions, a copy of which can be obtained directly by
                contacting the relevant pharmacy. Treat It is not responsible
                for the dispensing, supply and/or delivery of any medication,
                treatment, product or service prescribed to you or ordered by
                you via the Treat It System.
            </h6>

            <h6 className="mt-5 fs-6">
                2.4 The Services and any Advice are provided for non-urgent
                circumstances only. If you require emergency medical attention,
                any other treatment that is urgent, a physical face to face
                examination, or if your situation is not suited to remote
                communication, we strongly advise that you contact your NHS
                General Practitioner (“GP”), other medical practitioner or the
                emergency services. The Services are in no way designed or
                intended to replace the services of the NHS or services provided
                by your GP.
            </h6>

            <h6 className="mt-5 fs-6">
                2.5 We will share your information automatically with your GP
                and will access your summary care record. This is a compliance
                requirement of the General Medical Council.
            </h6>

            <h6 className="mt-5 fs-6">
                2.6 If you need any information or have a complaint about the
                Website or any of our Services, please contact us by one of the
                following methods: By Email: {SUPPORT_EMAIL} If you require
                assistance for a video consultation booking or require support
                for an appointment you can speak to us by calling{" "}
                {SUPPORT_TELEPHONE} between the hours of 9.00am and 5.00pm
                Monday to Thursday.
            </h6>

            <h6 className="mt-7 fs-6 fw-bold">
                3.0 Registration and Eligibility
            </h6>

            <h6 className="mt-5 fs-6">
                3.1 In order to access the Services, you must register with us
                and create an account (“Account”). You agree for us to be able
                to undertake an ID check with an external agency to confirm your
                identity. It is therefore important that you provide us with
                correct and accurate details.
            </h6>

            <h6 className="mt-5 fs-6">
                3.2 The Treat It System and the Services are only intended for
                use by people resident in the United Kingdom. We do not accept
                orders for Services or Products from individuals outside these
                countries. All Doctors practise in accordance with UK healthcare
                regulations and guidelines.
            </h6>

            <h6 className="mt-5 fs-6">
                3.3 The Services are not intended for users under 18 years of
                age. We check your date of birth during the registration process
                and if you are under 18, you are not permitted to have an
                account or to receive the Services. You are not permitted to use
                our services on behalf of a person under 18 years of age.
            </h6>

            <h6 className="mt-7 fs-6 fw-bold">4.0 Your Account</h6>

            <h6 className="mt-5 fs-6">
                4.1 You agree, at all times, to provide us with accurate and
                complete information in your registration and Account, and to
                update such information as appropriate.
            </h6>

            <h6 className="mt-5 fs-6">
                4.2 For security purposes, you must also set a password for your
                Account. You are responsible for maintaining the confidentiality
                of your Account and password and for restricting access to your
                computer and devices, and to the extent permitted by applicable
                law you agree to accept responsibility for all activities that
                occur under your Account or password. You should take all
                necessary steps to ensure that the password is kept confidential
                and secure and should inform us immediately if you have any
                reason to believe that your password has become known to anyone
                else, or if the password is being, or is likely to be used in an
                unauthorised manner. You can reset your password at any time
                from within your Account.
            </h6>

            <h6 className="mt-5 fs-6">
                4.3 If you want to disable your Account at any time, please
                contact us at {SUPPORT_EMAIL}. Please also see our Privacy
                Policy for further information.
            </h6>

            <h6 className="mt-7 fs-6 fw-bold">5.0 Consultations</h6>

            <p>
                You must have an available photo proof of identity (for example
                a Passport or Driver’s Licence) to show the Doctor at the start
                of your consultation. By using this service, you also consent to
                us making an ID check with an external agency to confirm the
                details you have provided us. If the Doctor cannot verify your
                identity or your ID check has failed they may choose to cancel
                the consultation or be unable to issue a prescription. Please
                therefore ensure that you provide correct and accurate
                information.
            </p>

            <h6 className="mt-5 fs-6">
                5.1 Treat It provides a service that allows users to consult
                with a Doctor through secure video chat via our mobile or tablet
                applications ( “Live Consultation”).
            </h6>

            <h6 className="mt-5 fs-6">
                5.2 Repeat prescriptions are completed and submitted via the
                Treat It System. Doctors can only make a decision as to whether
                it is in your best interests or the best interests of the
                patient to receive medication if and when the Doctor has been
                provided with all the relevant information. In the
                circumstances, on occasion and after submission by you of your
                completed forms, the Doctor may ask you for additional
                information or, if the Doctor deems it necessary or appropriate,
                refer you to your GP.
            </h6>

            <h6 className="mt-5 fs-6">
                5.3 The Doctor allocated to you and with whom you have a Live
                Consultation will record the results of the consultation as
                required by law and regulatory requirements. You agree to review
                and inform us of anything that you believe to be incorrect or
                inaccurate.
            </h6>

            <h6 className="mt-7 fs-6 fw-bold">6.0 Prescriptions</h6>

            <h6 className="mt-5 fs-6">
                6.1 When you have your medication delivered, you may be required
                to present a photo proof of identity (for example a Passport or
                Driver’s Licence) to verify your identity to the delivery
                driver. If you cannot verify your identity, delivery of the
                medication may be returned to the Pharmacy. You will be required
                to pay for the cost of re-delivery.
            </h6>

            <h6 className="mt-5 fs-6">
                6.2 If the Doctor, following a Live Consultation, and based on
                his/her medical assessment, decides that you are suitable for
                treatment, he/she may issue a private prescription that shall be
                dispensed by the Nominated Pharmacy.
            </h6>

            <h6 className="mt-5 fs-6">
                6.3 You understand and accept that there is no guarantee that
                you will be approved to receive a prescription following a Live
                Consultation. Prescriptions are issued at the sole discretion of
                the Doctor. Certain types and classes of medications such as
                controlled drugs (or restricted drugs and substances as
                classified by The Misuse of Drugs Regulations 2001), specialist
                drugs, medications that require closer monitoring,
            </h6>

            <h6 className="mt-5 fs-6">
                6.4 If you are approved to receive a prescription following a
                Live Consultation, we will send you an email attaching a patient
                information leaflet about the medication that has been
                prescribed (where available). These patient information leaflets
                are regularly revised and updated, and we therefore cannot
                guarantee that we will send you the most up to date version. We
                recommend that you obtain the latest version of the information
                leaflet that is relevant to you from the electronic Medicines
                Compendium (eMC) website at{" "}
                <a
                    href=" https://www.medicines.org.uk/emc/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    https://www.medicines.org.uk/emc/
                </a>
                . A copy of the leaflet is also normally provided with the
                medication when dispensed by a pharmacy. You agree to contact
                your GP or other qualified health professional or pharmacist if
                you do not fully understand anything about the medication you
                have been prescribed or if you do not fully understand why you
                have been prescribed that medication or do not fully understand
                the instructions.
            </h6>

            <h6 className="mt-5 fs-6">
                6.5 You agree and consent to Treat It sharing basic
                identification data with the Pharmacy, for the purposes of
                enabling the pharmacy to verify your identification on
                collection of your prescription. The pharmacy may contact you
                directly to process the prescription, including to confirm your
                details and to arrange delivery.
            </h6>

            <h6 className="mt-5 fs-6">
                6.6 All prescriptions that are issued by a Doctor are only valid
                at approved UK pharmacies.
            </h6>

            <h6 className="mt-7 fs-6 fw-bold">7.0 Fees</h6>

            <h6 className="mt-5 fs-6">7.1 Video Consultations:</h6>

            <p>
                The fee for a Live Consultation will vary depending on the
                duration of the session spent with the Doctor and will be shown
                on the website and when booking. The initial fee that is charged
                for an online Live Doctor Consultation and is for a thirty (30)
                minute session.
            </p>

            <p>
                The fee referred to above is for the Live Consultation. In
                addition to the initial consultation fee advised at the time of
                booking for the live consultation, if the Doctor offers you any
                Prescription, you will be charged (subject to your acceptance),
                a Prescription fee which shall be shown on our website. You will
                not be entitled to a refund for any prescriptions once issued to
                you as part of the Consultation.
            </p>

            <h6 className="mt-5 fs-6">7.2 Delivery charges for medication:</h6>

            <p>
                If a Doctor issues you with a prescription during a Live
                Consultation, your medication will be delivered to you from your
                nominated pharmacy. Additional charges will apply depending on
                the nominated pharmacy selected, and you will be advised of
                these at the time you place your order.
            </p>

            <h6 className="mt-7 fs-6 fw-bold">8.0 Orders</h6>

            <h6 className="mt-5 fs-6">8.1 Orders for Live Consultations:</h6>

            <p>
                For a Live Consultation, you must make a booking online and
                choose a time slot. All appointment times are by reference to
                local UK time.
            </p>

            <h6 className="mt-5 fs-6">
                8.2 Following the completion of your order for a Live
                Consultation, you will receive an email confirming the order has
                been received. Your request for a Live Consultation will then be
                sent to a Doctor. Once a Doctor has accepted your request for a
                Live Consultation, we will send you an email confirming the
                start time of your appointment and containing a link to the Live
                Consultation which will take place by secure video link. You
                should receive this email within one (1) hour of making your
                booking. If you do not receive this email, please first check
                your email junk folder, and if you still haven’t received the
                email, please contact us on {SUPPORT_EMAIL}.
            </h6>

            <h6 className="mt-5 fs-6">
                8.3 All confirmed appointment times are a guide only, and we
                cannot guarantee that the Live Consultation will commence at the
                stated appointment time or within a specified time parameter. At
                times, due to medical emergencies and other reasons beyond our
                reasonable control, we may also need to change your appointment
                time. We reserve the right to do so at any time up to the
                confirmed start time of your appointment. We will, in such
                circumstances, write to you to agree on an alternative
                appointment time.
            </h6>

            <h6 className="mt-5 fs-6">
                8.4 On the date and time of your allocated slot, you must click
                on the link provided to you to commence your Live Consultation.
                You will also find this link on your Account with us, which you
                can access by logging in to your Account on the Website or via
                our mobile or table applications. The link will become active
                ten (10) minutes prior to the start of your Live Consultation.
                The Doctor allocated to you shall be entitled to move on to
                his/her next appointment if you are more than five (5) minutes
                late to your appointment, and you will be considered to have
                missed your appointment. You will be charged in full for all
                missed appointments.
            </h6>

            <h6 className="mt-5 fs-6">
                8.5 Following the end of the Live Consultation, the Doctor will,
                if required, issue a prescription based on the information you
                provided to the Doctor during the Live Consultation, following
                which you will be sent an invoice for the Live Consultation.
            </h6>

            <h6 className="mt-5 fs-6">
                8.6 If the Doctor issues a prescription, this will be sent
                electronically to the pharmacy. All medication prescribed to you
                during a Live Consultation must be paid for before being
                dispensed.
            </h6>

            <h6 className="mt-5 fs-6">8.7 Non-acceptance of an order:</h6>

            <p>Your order may be declined if:</p>

            <ul>
                <li>
                    The medication that you have requested is out of stock or
                    unavailable from your Nominated Pharmacy;
                </li>
                <li>
                    We are unable to obtain authorisation for payment from the
                    card details you have provided to us;
                </li>
                <li>
                    The request for a prescription is declined by the Doctor
                    (you will be advised of this by email). The Doctor may also
                    request additional information from you by phone or email
                    which must be provided within 24 hours of the request. If
                    you do not provide the additional information within this
                    timeframe, you may still be charged for the Service without
                    a prescription being provided;
                </li>
            </ul>

            <h6 className="mt-7 fs-6 fw-bold">9.0 Payment</h6>

            <h6 className="mt-5 fs-6">
                9.1 In order to pay for the Services, you will need to provide
                payment card details. You must have appropriate authority to use
                the payment card which you use for payment.
            </h6>

            <h6 className="mt-5 fs-6">
                9.2 If you book a Live Consultation, you will need to provide
                payment card details at the time of booking. Your card will be
                pre-authorised at the time of booking, but will not be charged
                until the Live Consultation has taken place, following which you
                will be able to access your invoice on your online account for
                the charges you have incurred.
            </h6>

            <h6 className="mt-5 fs-6">
                9.3 You agree and hereby authorise us to either pre-authorise or
                charge your debit or credit card with any charges for Services
                or Products that you have purchased from us via the Treat It
                System or from a Doctor during a Live Consultation. Any failure
                of pre-authorisation or authorisation processes will result in
                the Services or Products not being provided and any booked
                consultations will be cancelled. Any unpaid balance shall become
                payable within seven (7) days of your purchase or completion of
                a consultation, and if it remains, will incur a late payment
                interest charge equal to 4% above the Barclays Bank plc base
                lending rate as published from time to time.
            </h6>

            <h6 className="mt-5 fs-6">
                9.4. Payments cannot be made by your employer or insurance
                company on your behalf unless this has been previously agreed
                with us.
            </h6>

            <h6 className="mt-5 fs-6">
                9.5 Payment processing services are provided by Stripe. The
                details you provide to us via the Treat It System are passed
                directly to Stripe and will be subject to its terms of use and
                privacy policy{" "}
                <a
                    href="https://stripe.com/gb/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    https://stripe.com/gb/privacy
                </a>
                . By inputting payment card details, you are consenting to use
                of the payment card details by Stripe for the purpose of paying
                for your order. If you have any questions relating to these
                services, please contact Stripe at privacy@stripe.com.
            </h6>

            <h6 className="mt-5 fs-6">
                9.6 Treat It does not store any of your payment card details on
                its systems.
            </h6>

            <h6 className="mt-7 fs-6 fw-bold">
                10.0 Your Rights To Cancel an Order
            </h6>

            <h6 className="mt-5 fs-6"> 10.1 Order for Live Consultations:</h6>

            <p>
                You may cancel an appointment for a Live Consultation at any
                time up to 24 hours before the confirmed start time of your
                appointment without incurring any charges. If your appointment
                is within 24 hours, you will be charged the full consultation
                fee. Once a Live Consultation has started, you will be charged
                in full for the Live Consultation.
            </p>

            <h6 className="mt-5 fs-6">10.2 Cancelling an order:</h6>

            <p>
                To meet the cancellation deadline, it is sufficient for you to
                send your communication concerning your exercise of the right to
                cancel before the cancellation period has expired.
            </p>

            <h6 className="mt-7 fs-6 fw-bold">
                11.0 Problems with the processing of prescriptions, the
                dispensing of medication and / or the administration of
                vaccinations, by pharmacies.
            </h6>

            <h6 className="mt-5 fs-6">
                11.1. Treat It has no control over or responsibility for any
                individual pharmacy, or its policies in relation to the
                acceptance or processing of prescriptions, the dispensing of
                medication, or delivery or opening times.
            </h6>

            <h6 className="mt-5 fs-6">
                11.2. If an order is processed or administered correctly by the
                Pharmacy, we recommend that you contact the Pharmacy
                immediately. You may, in these circumstances, be entitled to a
                refund. You will not, however, be entitled to a refund, either
                from the Treat It or Pharmacy, for the fees you have paid for a
                Live Consultation (see paragraph 7.2 above) itself or any
                prescriptions admin fee issued by a Doctor for a Live
                Consultation.
            </h6>

            <h6 className="mt-7 fs-6 fw-bold">
                12.0 Accessing the Treat It System
            </h6>

            <h6 className="mt-5 fs-6">
                12.1 Access to the Services relies on you being able to receive
                data over the Internet via Wi-Fi or a mobile data connection,
                using a PC, mobile device or tablet. You are responsible for
                making all arrangements and payments necessary for you to access
                the Services, including internet and network connections, an
                appropriate mobile device, and any additional software or
                hardware required.
            </h6>

            <h6 className="mt-5 fs-6">
                12.2 In order to participate in a Live Consultation via the
                Treat It System, you will need to ensure that the operating
                system and browser you are using meet the following minimum
                specification:
            </h6>

            <div className="policy-table-container overflow-auto mt-6">
                <table className="table table-responsive table-bordered fs-6">
                    <thead>
                        <tr className="table-primary">
                            <th className="px-3 py-4" scope="col">
                                Operating System requirements
                            </th>
                            <th className="px-3 py-4" scope="col">
                                Browser requirements
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="table-light">
                            <td>
                                <div className="py-3 px-2">Windows 10</div>
                                <div className="py-3 px-2">
                                    Mac OS X 10.11.6 and newer
                                </div>
                                <div className="py-3 px-2">
                                    Apple iOS App on the appstore : The GP
                                    Service
                                </div>
                                <div className="py-3 px-2">
                                    Android App on Google Playstore: The GP
                                    Service
                                </div>
                            </td>
                            <td>
                                <div className="py-3 px-2">
                                    Google Chrome 40 or greater
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <p className="mt-3">
                We recommend that you check this prior to the confirmed start
                time of your Live Consultation, as we will not be able to refund
                your fee if you are unable to connect to a Live Consultation as
                a result of the operating system and / or browser that you are
                using, or if you have a poor internet connection.
            </p>

            <p>
                For the Internet connection, we recommend a minimum of 1MB/s of
                available bandwidth, and at least 3MB/s of available bandwidth
                for the best connection for a Live Consultation.
            </p>

            <h6 className="mt-5 fs-6">
                12.3 In the event of a lost connection, the Doctor will
                endeavour to contact you to re-initiate the consultation or
                contact you by telephone.
            </h6>

            <h6 className="mt-5 fs-6">
                12.4 You agree and accept that we are not responsible for:
            </h6>

            <ul>
                <li>
                    (i) The availability of the internet or any communications
                    network; or
                </li>
                <li>
                    (ii) For any errors caused by transmission of information,
                    data or communication over telecommunication carriers or
                    poor Internet connections.
                </li>
            </ul>

            <h6 className="mt-7 fs-6 fw-bold">
                13.0. Unavailability of the Services
            </h6>

            <h6 className="mt-5 fs-6">
                13.1 We will do our utmost to ensure that availability of the
                Services will be uninterrupted and that transmissions will be
                error-free. However, due to the nature of the Internet, this
                cannot be guaranteed. Also, your access to the Services may also
                be occasionally suspended or restricted to allow for repairs,
                maintenance, or the introduction of new facilities or services.
                We will attempt to limit the frequency and duration of any such
                suspension or restriction.
            </h6>

            <h6 className="mt-5 fs-6">
                13.2 In the unlikely event of a problem with the provision, or
                the unavailability, of the Services:
            </h6>

            <ul>
                <li>
                    (i) We will use our reasonable efforts to restore the
                    Services and rectify any defects;
                </li>
                <li>
                    {" "}
                    (ii) You will not be required to pay or (if applicable) you
                    shall be entitled to a refund for any Service or Product
                    purchased where we have caused such defect and we were
                    unable to provide you with the Service or Product following
                    your purchase.
                </li>
            </ul>

            <h6 className="mt-7 fs-6 fw-bold">
                14.0 Your use of the Treat It System and the Services
            </h6>

            <h6 className="mt-5 fs-6">
                14.1 You must not use the Treat It System (or any part of it):
            </h6>

            <ul>
                <li>
                    (i) in any way that causes, or is likely to cause, the Treat
                    It System (or any part of it), or any access to it, to be
                    interrupted, damaged or impaired in any way, or
                </li>
                <li>
                    (ii) for fraudulent purposes, or in connection with a
                    criminal offence or other unlawful activity, or
                </li>
                <li> (iii) to cause annoyance, inconvenience or anxiety.</li>
            </ul>

            <h6 className="mt-5 fs-6">
                14.2 We reserve the right to withdraw the Services, terminate
                your Account, remove or edit any associated content and/or
                terminate or suspend your access to the Services at any time if
                you are in breach or we reasonably suspect that you are in
                breach of any applicable laws, these Terms or any other
                applicable terms and conditions, guidelines or policies, or if
                we cease to offer (or change the way in which we offer) the
                Treat It System or the Services. You will not be entitled to any
                compensation for such withdrawal, removal, termination or
                suspension. Following termination or suspension of your access
                to the Services for any reason, we may retain your Account
                details and other records relating to you for record keeping.
            </h6>

            <h6 className="mt-5 fs-6">
                14.3 By proceeding to use the Treat It System, you agree to the
                following:
            </h6>

            <ul>
                <li>
                    (i) The services provided to you are conditional upon you
                    providing information by completing the consultation
                    questionnaires on our website truthfully and honestly. You
                    must disclose all relevant information truthfully to the
                    best of your knowledge. This applies to both completed
                    questionnaires as well as any other communication with a
                    Doctor.
                </li>
                <li>
                    (ii) The Services and Advice provided to you when you use
                    the Treat It System is based on the information you have
                    supplied. It is your responsibility to ensure this
                    information is accurate and complete and you accept that
                    failure to do so (whether intentionally or not) will affect
                    the Advice given to you and the medicines prescribed to you
                    and, as such, may have consequences for which neither we or
                    any Doctor can be responsible for.
                </li>
                <li>
                    (iii) We cannot accept liability and are not liable for any
                    loss, damage or distress that you may suffer as a result of
                    your failure to follow Advice provided to you when you use
                    the Treat It System or from your failure to pass on
                    information to your regular healthcare provider. You accept
                    that the Advice given to you by any Doctor does not replace
                    the advice you receive from your regular healthcare
                    provider. You must tell your regular healthcare provider
                    about any treatment and/or medication that you receive
                    through use of the Treat It System.
                </li>

                <li>
                    {" "}
                    (iv) You agree that you are solely responsible for ensuring
                    that you understand the questions in the questionnaires you
                    complete when using the Treat It System. If you are unsure
                    about any questions or you do not fully understand the
                    Advice given to you, please do not continue the process any
                    further and instead contact your regular healthcare
                    provider.
                </li>
                <li>
                    (v) If medication is prescribed and dispensed to you, you
                    are responsible for checking that the medication dispensed
                    by the Pharmacy is the correct medication and that such
                    medicine is not damaged in any way. If in doubt, you must
                    contact the Pharmacy to seek advice. You must carefully read
                    all product packaging and labels prior to taking any
                    medication or commencing treatment.
                </li>
                <li>
                    (vi) Where you are being provided with Services that involve
                    a Doctor who you know, are connected to or who works at a
                    practice where you are registered under the National Health
                    Service (NHS), you must immediately notify that Doctor, who
                    will then arrange for another appointment with an
                    alternative Doctor. The Services cannot be used to replace a
                    service provided by the NHS.
                </li>
            </ul>

            <h6 className="mt-5 fs-6">
                14.4 By registering with us, and placing an order through the
                Treat It System, you warrant and represent to us that:
            </h6>

            <ul>
                <li>
                    {" "}
                    (i) you are legally capable of entering into binding
                    contracts;
                </li>
                <li>(ii) you are resident in the UK;</li>
                <li> (iii) you are at least 18 years old;</li>
                <li>
                    {" "}
                    you are accessing the Treat It System from within the UK;
                </li>
                <li>
                    you have not registered with us more than once and only for
                    yourself;
                </li>
                <li>
                    (vi) you have provided your legal name and permanent
                    address;
                </li>
                <li>
                    (vii) you have answered all questions truthfully and fully
                    and you have not omitted anything of importance; and
                </li>
                <li>
                    (viii) you have not completed the questionnaires on behalf
                    of anyone other than yourself.
                </li>
            </ul>

            <h6 className="mt-5 fs-6">14.5 You further undertake that:</h6>

            <ul>
                <li>
                    (i) you will not take part in a Live Consultation or use the
                    Services on behalf of anyone other than yourself;
                </li>
                <li>
                    (ii) you will comply with any instructions given in relation
                    to any prescription provided to you including in particular
                    route of administration, timing and dosages, and you will
                    promptly report any side effects of any prescription to a
                    Doctor;
                </li>
                <li>
                    (iii) you will not use any medication prescribed to you
                    after its expiry date;
                </li>
                <li>
                    (iv) you will immediately notify us or the Doctor with whom
                    you have a Live Consultation if any information held on the
                    Treat It System about you is inaccurate or incomplete;
                </li>
                <li>
                    {" "}
                    (v) in the interests of maintaining privacy and security of
                    information, you will not make any private copies of any
                    recordings of consultations or allow anyone other than
                    yourself to access information from your Account;
                </li>
                <li>
                    {" "}
                    (vi) if you have any concerns or doubts about any Advice you
                    receive or fail to receive as a result of using the
                    Services, you will seek immediate medical help from the
                    emergency services or your GP or other registered health
                    professional; and and
                </li>
                <li>
                    {" "}
                    (vii) you will not permit any unauthorised person to access
                    your prescription or use medication prescribed to you.
                </li>
            </ul>

            <h6 className="mt-7 fs-6 fw-bold">15.0. Limitation of Liability</h6>

            <h6 className="mt-5 fs-6">
                15.1 Nothing in these Terms excludes or limits our liability for
                death or personal injury caused by our negligence or for fraud
                or fraudulent misrepresentation or for any other liability which
                cannot be excluded or limited under applicable law. Nothing in
                these Terms shall affect your statutory rights.
            </h6>

            <h6 className="mt-5 fs-6">
                15.2 Except as may be expressly set out in these Terms, all
                conditions, representations and warranties relating to the Treat
                It System are excluded to the maximum extent permitted by law.
            </h6>

            <h6 className="mt-5 fs-6">
                15.3 We shall not be liable for any defects or errors in the
                Treat It System or the Services, nor interruptions in or
                unavailability of the Services, nor any breach of any
                obligations due to a cause beyond our reasonable control.
            </h6>

            <h6 className="mt-5 fs-6">
                15.4 Subject to paragraph 15.1 above, and to the extent
                permitted by law, neither the Treat It, any Doctor or any third
                parties involved in or connected with the provision of the
                Services shall be liable for:
            </h6>

            <ul>
                <li>
                    {" "}
                    (i) any loss, damage or distress arising from reliance on
                    information or reliance on availability of the Services; or
                </li>
                <li>
                    {" "}
                    (ii) any indirect, consequential or economic loss, damage or
                    distress (including any such loss, damage or distress
                    arising from unauthorised use of or damage to your data or
                    content), incurred by you or any user of the Services as a
                    result of using the Treat It System.
                </li>
            </ul>

            <h6 className="mt-5 fs-6">
                15.5 Subject to paragraph 15.1 above, and to the extent
                permitted by law, neither the Treat It, any Doctor or any third
                parties involved in or connected with the provision of the
                Services shall be liable for any loss, damage or distress or
                damages arising in circumstances where the Services, Advice or
                Products are used for any treatment that is urgent, is an
                emergency or where a physical face to face examination is
                required or where the issue is suited to non-remote
                communication. We advise that, in any of these circumstances,
                you contact your GP, other medical practitioner or the emergency
                services.
            </h6>

            <h6 className="mt-5 fs-6">
                15.6 Treat It is a distinct and separate entity to:
            </h6>

            <h6 className="mt-4 ms-4 fs-6">
                15.6.1 the pharmacies listed on the Website;
            </h6>

            <h6 className="mt-4 ms-4 fs-6">
                15.6.2 any other third party referred to in these Terms as a
                supplier of Products (including, without limitation, TDL).
            </h6>

            <h6 className="mt-5 fs-6">
                15.7 In the circumstances, and to the extent permitted by law,
                Treat It disclaims any liability for any acts or omissions of
                these pharmacies. Treat It has no control over or responsibility
                for any individual pharmacy, or its policies in relation to the
                acceptance or dispensing of prescriptions, the supply of other
                products and services, or delivery or opening times.
            </h6>
            <h6 className="mt-5 fs-6">
                15.8 All Doctors are also independent contractors and any Advice
                provided or offered to you by a Doctor is the sole
                responsibility of that Doctor. In the circumstances, and to the
                extent permitted by law, the Treat It does not warrant or make
                any representation as to the Advice provided by any Doctor, and
                disclaims any liability for the acts or omissions of any Doctor.
            </h6>
            <h6 className="mt-5 fs-6">
                15.9 Without prejudice to the exclusions and other limitations
                on our liability, our total liability to you in connection with
                the Services whether under contract, tort, statute, common law
                or otherwise (and including for negligence or wilful default)
                shall not in any circumstances exceed £1,000.
            </h6>

            <h6 className="mt-7 fs-6 fw-bold">
                16.0 Content on the Treat It System
            </h6>

            <h6 className="mt-5 fs-6">
                16.1 Treat It does not warrant or make any representation as to
                the accuracy of any information or advice or any material or
                links to any articles or publications on the Website or any part
                of the Treat It System. Treat It provides the service of
                connecting users to a Doctor and pharmacies via the Treat It
                System, and does not directly dispense medication. Any
                information provided on the Website or any part of the Treat It
                System is for educational and informational purposes only, and
                none of it should be construed or relied upon as medical advice,
                unless it is directly provided to you by a Doctor.
            </h6>

            <h6 className="mt-5 fs-6">
                16.2 Treat It does not accept any responsibility for any loss
                which may arise from reliance on any information contained on
                the Website or any part of the Treat It System.
            </h6>

            <h6 className="mt-7 fs-6 fw-bold">
                17.0 Intellectual Property Rights
            </h6>
            <h6 className="mt-5 fs-6">
                17.1 You acknowledge that the Treat It System, and all content
                published and / or stored on it, are protected by copyright,
                database rights, trademarks and other intellectual property
                rights of us and our licensors, as applicable, and all rights
                not expressly granted to you are reserved to us and our
                licensors.
            </h6>

            <h6 className="mt-5 fs-6">
                17.2 We hereby grant you a non-exclusive and non-transferable
                licence to:
            </h6>

            <ul>
                <li> (i) use the Treat It System;</li>
                <li>
                    (ii) to access the information and data stored on the Treat
                    It System; and
                </li>
                <li>
                    (iii) install and use a copy of our mobile and tablet
                    application on your mobile device or tablet, for the purpose
                    of accessing and using the Services, in accordance with
                    these Terms.
                </li>
            </ul>

            <h6 className="mt-5 fs-6">
                17.3 You may not use the Treat It System or the Services in any
                way incompatible with their intended purpose nor in any unlawful
                or unauthorised manner and, in particular, you may not:
            </h6>

            <ul>
                <li>
                    {" "}
                    (i) make any copy of our mobile or tablet application (other
                    than the permitted copy on your mobile device and/or tablet
                    and a single back-up copy to the extent necessary to
                    exercise your rights under this Agreement); nor
                </li>
                <li>
                    (ii) modify, adapt, reverse engineer, decompile or
                    disassemble, create derivative works of, publish, distribute
                    or commercially exploit the Treat It System (or any part of
                    it) or any content of the Services; nor
                </li>
                <li>
                    (iii) remove any copyright or proprietary notices on the
                    Treat It System or the content of the Services; nor
                </li>
                <li>
                    {" "}
                    (iv) use, distribute or disclose confidential, personal or
                    sensitive information within the content of the Treat It
                    System or the Services without appropriate authority; nor
                </li>
                <li>
                    (v) make any unlawful or unauthorised use of our (or our
                    service providers’) equipment, networks, systems or software
                    (including attempting to gain unauthorised access,
                    introducing any computer virus or malware, or inhibiting
                    their operation).
                </li>
            </ul>

            <h6 className="mt-7 fs-6 fw-bold">18.0. Links from the Website</h6>

            <h6 className="mt-5 fs-6">
                18.1. Where the Website contains links to other websites and
                resources provided by third parties, these links are provided
                for your information only. We have no control over the contents
                of those websites or resources, and accept no responsibility for
                them or for any loss or damage that may arise from your use of
                them.
            </h6>

            <h6 className="mt-7 fs-6 fw-bold">
                19.0 Communication – Written/Email
            </h6>

            <h6 className="mt-5 fs-6">
                19.1 You accept that communication with us will be mainly
                electronic and where such information would normally under
                applicable laws be required to be sent to you in writing you
                waive this right and agree to receive any such information from
                us electronically. We will contact you by e-mail, sms and phone
                or provide you with information by posting notices on the
                Website. For contractual purposes, you agree to this electronic
                means of communication and you acknowledge that all contracts,
                notices, information and other communications that we provide to
                you electronically comply with any legal requirement that such
                communications be in writing. This condition does not affect
                your statutory rights.
            </h6>
            <h6 className="mt-2 fs-6">
                Treat It can be contacted by writing to The GP Service (UK) Ltd,
                Coventry University Technology Park, The TechnoCentre, Puma Way,
                Coventry CV1 2TT or by emailing us at {SUPPORT_EMAIL}.
            </h6>

            <h6 className="mt-7 fs-6 fw-bold">
                20.0 Use of your Personal Information
            </h6>

            <h6 className="mt-5 fs-6">
                20.1 You agree to our use of your personal information as set
                out in our Privacy Policy{" "}
                <a
                    href="https://treat-it.clinic/privacy-policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    https://treat-it.clinic/privacy-policy/
                </a>
            </h6>
            <h6 className="mt-5 fs-6">
                20.2 In particular, any personal information relating to you
                that you provide to us or that is generated as a result of your
                use of the Services (e.g. the results of any consultation with a
                Doctor) may be used to:
            </h6>

            <ul>
                <li>
                    {" "}
                    (i) provide the Services, Advice and/or Products purchased;
                </li>
                <li>
                    (ii) provide information to other healthcare providers and
                    professionals;
                </li>
                <li>
                    (iii) take payment for the Services and/or Products
                    purchased;
                </li>
                <li>
                    {" "}
                    (iv) provide relevant information to Doctors and Nominated
                    Pharmacies involved in conducting consultations with you and
                    / or dispensing prescriptions;
                </li>
                <li>(v) detect or prevent fraudulent activity; and</li>
                <li> (vi) comply with requests by any regulatory authority.</li>
            </ul>

            <h6 className="mt-5 fs-6">
                20.3 If you have any concerns in relation to the way we are
                using your personal data, please contact us at
                {SUPPORT_EMAIL}
            </h6>

            <h6 className="mt-7 fs-6 fw-bold">21.0 Feedback / Complaints</h6>

            <h6 className="mt-5 fs-6">
                21.1 If you wish to provide us with any feedback, please email
                us at {SUPPORT_EMAIL}.
            </h6>
            <h6 className="mt-5 fs-6">
                21.2 In the event that you wish to make a complaint, you may do
                so by emailing us at the email address noted above or by writing
                to us at The GP Service (UK) Limited, Coventry University
                Technology Park, The TechnoCentre, Puma Way, Coventry CV1 2TT.
                We request that all complaints are made in writing and as soon
                as possible after the event so that we may establish what
                occurred in a timely manner.
            </h6>

            <h6 className="mt-5 fs-6">
                21.3 We will allocate a senior member of our team to deal with
                the issue raised in your complaint. The senior member of our
                team allocated to your complaint will investigate every aspect
                of the complaint and discuss it with all those concerned or
                involved.
            </h6>

            <h6 className="mt-5 fs-6">
                21.4 We endeavour to resolve all complaints quickly. We will
                acknowledge your complaint within three (3) working days and
                will aim to have it fully investigated within twenty (20)
                working days from the date we receive it. We will write to you
                to let you know of any delays to this timescale.
            </h6>

            <h6 className="mt-5 fs-6">
                21.5 Upon completing our investigation, you will receive a
                letter setting out the outcome of our investigation into the
                complaint. We will ensure that you receive an apology if
                appropriate, and we will take any and all actions necessary and
                reasonable to ensure that the problem is not repeated.
            </h6>

            <h6 className="mt-7 fs-6 fw-bold">22.0 General</h6>

            <h6 className="mt-5 fs-6">
                22.1 All provisions of this Agreement which by their nature are
                intended to continue shall survive termination, including terms
                relating to exclusions and limitations of liability,
                intellectual property restrictions and on-going use of your
                data.
            </h6>

            <h6 className="mt-5 fs-6">
                22.2 Termination of this Agreement or the Services shall not
                affect accrued rights and liabilities of you or us up to the
                date of termination.
            </h6>

            <h6 className="mt-5 fs-6">
                22.3 No provision of this Agreement is intended to be
                enforceable by any person other than you and us.
            </h6>

            <h6 className="mt-5 fs-6">
                22.4 We may, without your consent, sub-contract provision of the
                App, the Web Ordering Tool and the Services or sub-license our
                rights under this Agreement or our third party service
                providers.
            </h6>

            <h6 className="mt-5 fs-6">
                22.5 We may, without your consent, assign or transfer any or all
                of our rights and obligations under this Agreement to any
                successor in title of all or part of the App, the Web Ordering
                Tool, the Services, or intellectual property or other rights or
                obligations subsisting in relation to the same.
            </h6>

            <h6 className="mt-5 fs-6">
                22.6 No failure or delay by a party to exercise any right or
                remedy provided under this agreement or by law shall constitute
                a waiver of that or any other right or remedy, nor shall it
                prevent or restrict the further exercise of that or any other
                right or remedy. No single or partial exercise of such right or
                remedy shall prevent or restrict the further exercise of that or
                any other right or remedy.
            </h6>

            <h6 className="mt-5 fs-6">
                22.7 This agreement constitutes the entire agreement between the
                parties and supersedes and extinguishes all previous agreements,
                promises, assurances, warranties, representations and
                understandings between them, whether written or oral, relating
                to its subject matter.
            </h6>

            <h6 className="mt-5 fs-6">
                22.8 Each party agrees that it shall have no remedies in respect
                of any statement, representation, assurance or warranty (whether
                made innocently or negligently) that is not set out in this
                agreement. Each party agrees that it shall have no claim for
                innocent or negligent misrepresentation or negligent
                misstatement based on any statement in this agreement. Nothing
                in this paragraph clause shall limit or exclude any liability
                for fraud.
            </h6>

            <h6 className="mt-5 fs-6">
                22.9 If any provision of this Agreement is found by any court or
                legal authority to be invalid, unenforceable or illegal, the
                other provisions shall remain in force and, to the extent
                possible, the provision shall be modified to ensure it is valid,
                enforceable and legal, whilst maintaining or giving effect to
                its commercial intention.
            </h6>

            <h6 className="mt-5 fs-6">
                23.0 We will not be held responsible for any delay or failure to
                comply with our obligations under these Terms if the delay or
                failure arises from any cause which is beyond our reasonable
                control. This does not affect your legal right to have goods
                sent or services provided within a reasonable time or to receive
                a refund if goods or services ordered cannot be supplied within
                a reasonable time owing to a cause beyond our reasonable
                control.
            </h6>

            <h6 className="mt-5 fs-6">
                24.0 This Agreement is governed by the laws of England and
                Wales, and the courts of England and Wales shall have
                jurisdiction to hear any disputes arising in connection with it.
            </h6>

            <h6 className="mt-5 fs-6">Cancellation Form</h6>
            <h6 className="mt-3 fs-6">
                (Complete and return this form only if you wish to withdraw from
                the contract)
            </h6>
            <h6 className="mt-3 fs-6">By post to:</h6>
            <p className="mb-1">The GP Service (UK) Limited,</p>
            <p className="mb-1">Coventry University Technology Park,</p>
            <p className="mb-1">The TechnoCentre, Puma Way,</p>
            <p className="mb-1">Coventry,</p>

            <h6 className="mt-3 fs-6">Or by email to:</h6>
            <p className="mb-1">{SUPPORT_EMAIL}.</p>
            <p className="mb-1">
                I/We [*] hereby give notice that I/We [*] cancel my/our [*]
                contract of sale of the following goods [*]/for the supply of
                the following service [*],
            </p>
            <p className="mb-1">Ordered on [*]/received on [*],</p>
            <p className="mb-1">Name of consumer(s),</p>
            <p className="mb-1">Address of consumer(s),</p>
            <p className="mb-1">
                Signature of consumer(s) (only if this form is notified on
                paper),
            </p>
            <p className="mb-1">Date</p>
            <p className="mb-1">[*] Delete as appropriate</p>
        </div>
    );
}

export default TermsConditionPage;
