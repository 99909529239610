import { useNavigate } from "react-router-dom";

import ContactSupportForm from "../../../components/contactSupportForm/ContactSupportForm";

export default function FeedbackContactPage() {
    const navigate = useNavigate();

    const handleClickLater = () => {
        navigate("/user/dashboard");
    };

    const handleContinue = () => {
        navigate(`/reorder/request-change-prescription`);
    };

    return (
        <div className="prompt-consultation">
            <div className="row mx-0">
                <div className="col-12 col-lg-11 col-xl-9 col-xxl-8 mx-auto pb-5 pb-md-0 mb-0 mb-md-8">
                    <ContactSupportForm
                        title="Thanks for your feedback! One of our team members will help you with your order."
                        description="Please contact us if you want to know more about the status of your order."
                        onClickLater={handleClickLater}
                        onClickAction={handleContinue}
                        actionButtonText="CONTINUE"
                    />
                </div>
            </div>
        </div>
    );
}
