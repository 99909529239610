import { useContext } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import { useAddOrderNotesMutation } from "../../../../services/orderService";
import { getStatus } from "../../../../utils/orderStatusUtils";
import parseError from "../../../../utils/errorUtils";

import Button from "../../../../components/button/Button";
import Tooltip from "../../../../components/tooltip/Tooltip";

import {
    ORDER_STATUS,
    PATIENT_APPROVAL_WAITING,
} from "../../../../constants/orderStatus";

import "./order-details.scss";
import { useModal } from "../../../../hooks/modalsHooks";
import { RepeatOrderContext } from "../../../../providers/RepeatOrderProvider";

export default function OrderActionCard({ order }) {
    const navigate = useNavigate();
    const { showModal, hideModal } = useModal();
    const { hasSixMonthsPassed, canOrder } = useContext(RepeatOrderContext);

    const [addOrderNote] = useAddOrderNotesMutation();

    const confirmOrderWithNote = async (type, approved, description = "") => {
        try {
            const res = await addOrderNote({
                orderId: order.id,
                values: {
                    type,
                    approved,
                    description,
                },
            });

            if (res.error) {
                // handle error
                toast.error(parseError(res.error, "Couldn't update decision"));
            } else {
                // handle success
                toast.success("Successfully submitted decision");
            }
        } catch (error) {}
    };

    const getDisplayData = (val) => {
        const formattedStatus = getStatus(val).status;

        switch (formattedStatus) {
            case "COMPLETED":
                if (isBefore) {
                    if (diff === 0) {
                        return {
                            description: `You will be able to re-order in ${diffInHours} hour${
                                diffInHours > 1 ? "s" : ""
                            }`,
                            buttonTitle: "RE-ORDER",
                        };
                    }
                    return {
                        description: `You will be able to re-order in ${diff} day${
                            diff > 1 ? "s" : ""
                        }`,
                        buttonTitle: "RE-ORDER",
                    };
                }

                return {
                    description: "Do you want to re-order this prescription?",
                    buttonTitle: "RE-ORDER",
                };
            case "PENDING":
                if (PATIENT_APPROVAL_WAITING.includes(val)) {
                    if (
                        val ===
                        ORDER_STATUS.AWAITING_PATIENT_PRESCRIPTION_PAYMENT
                    ) {
                        return {
                            description:
                                "Please make a payment to complete the order",
                            buttonTitle: "MAKE PAYMENT",
                        };
                    }

                    if (
                        val ===
                        ORDER_STATUS.AWAITING_PATIENT_PHARMACY_PRESCRIPTION_CONFIRMATION
                    ) {
                        return {
                            description:
                                "Please review your order prior to payment",
                            buttonTitle: "CONFIRM",
                        };
                    }

                    return {
                        description: "Please confirm your order",
                        buttonTitle: "CONFIRM",
                    };
                }

                return {
                    description: "Your order is pending",
                    buttonTitle: "CONFIRM",
                };
            default:
                return {
                    description: "Do you want to re-order this prescription?",
                    buttonTitle: "RE-ORDER",
                };
        }
    };

    const handleApproveOrder = (status) => {
        switch (status) {
            case "AWAITING_PATIENT_PRESCRIPTION_PAYMENT":
                navigate(`/order/${order.id}/confirm`);
                break;
            case "AWAITING_PATIENT_PHARMACY_PRESCRIPTION_CONFIRMATION":
                confirmOrderWithNote(
                    "PATIENT_PHARMACY_DECISION",
                    true,
                    "Patient does not require drugs"
                );
                break;
            case "AWAITING_PATIENT_SPECIALIST_PRESCRIPTION_CONFIRMATION":
                confirmOrderWithNote(
                    "PATIENT_SPECIALIST_RECOMMENDATION_DECISION",
                    true,
                    ""
                );
                break;
            case "DELIVERED":
                navigate(`/reorder/confirm/?type=REPEAT`);
                break;
            default:
            // navigate(`/order/${order.id}/confirm`);
        }
    };

    const handleReject = async (status) => {
        try {
            let noteType =
                status === "AWAITING_PATIENT_PHARMACY_PRESCRIPTION_CONFIRMATION"
                    ? "PATIENT_PHARMACY_DECISION"
                    : "PATIENT_SPECIALIST_RECOMMENDATION_DECISION";

            const res = await addOrderNote({
                orderId: order.id,
                values: {
                    type: noteType,
                    approved: false,
                },
            });

            if (res.error) {
                // handle error
                toast.error(parseError(res.error, "Couldn't update decision"));
            } else {
                // handle success
                toast.success("Successfully rejected");
                hideModal();
                navigate(`/user/order/${order?.id}`, { replace: true });
            }
        } catch (error) {}
    };

    const handleRejectOrder = (status) => {
        showModal({
            modalType: "RejectWarningModal",
            modalProps: {
                onAccept: () => handleReject(status),
                onDecline: () => hideModal(),
            },
        });
    };

    const isDelivered = ORDER_STATUS.DELIVERED === order?.status;
    const isApprovalWaiting = PATIENT_APPROVAL_WAITING.includes(order?.status);

    const deliveryDatePlus14 = dayjs(order?.updateDate).add(14, "day");
    const currentDate = dayjs();
    const diff = deliveryDatePlus14.diff(currentDate, "day");
    const diffInHours = deliveryDatePlus14.diff(currentDate, "hours") + 1;
    const isBefore = currentDate.isBefore(deliveryDatePlus14);
    const hasActiveRepeatOrder = order.hasActiveRepeatOrder;

    return (
        <div className="order-action-section d-flex justify-content-between flex-column flex-sm-row align-items-sm-center bg-primary-light border-primary rounded p-4">
            <p className="body fw-bold mb-0 pe-2">
                {getDisplayData(order?.status).description}
            </p>
            <div className="action-section d-flex flex-wrap flex-sm-nowrap justify-content-sm-end">
                {isApprovalWaiting && (
                    <>
                        {order?.status !==
                            ORDER_STATUS.AWAITING_PATIENT_PRESCRIPTION_PAYMENT && (
                            <Button
                                variant="outline-primary outline-button text-uppercase fw-bold me-0 me-2 mt-3 mt-sm-0"
                                size="sm"
                                fullWidth={false}
                                onClick={() => handleRejectOrder(order?.status)}
                            >
                                Reject
                            </Button>
                        )}
                        <Button
                            variant="primary text-uppercase fw-bold mt-3 mt-sm-0"
                            size="sm"
                            fullWidth={false}
                            onClick={() => handleApproveOrder(order?.status)}
                        >
                            {getDisplayData(order?.status).buttonTitle}
                        </Button>
                    </>
                )}

                {isDelivered && (
                    <Tooltip
                        message={
                            hasSixMonthsPassed
                                ? "Over 6 months since last video consultation. Please book a repeat appointment"
                                : "Active repeat order exists for this order"
                        }
                        position="top"
                        hidden={
                            hasSixMonthsPassed
                                ? !hasSixMonthsPassed
                                : !hasActiveRepeatOrder
                        }
                    >
                        <Button
                            variant="primary text-uppercase fw-bold mt-3 mt-sm-0"
                            size="sm"
                            fullWidth={false}
                            onClick={() => handleApproveOrder(order?.status)}
                            disabled={
                                isBefore ||
                                hasSixMonthsPassed ||
                                hasActiveRepeatOrder ||
                                !canOrder
                            }
                        >
                            {getDisplayData(order?.status).buttonTitle}
                        </Button>
                    </Tooltip>
                )}
            </div>
        </div>
    );
}
