import React from "react";

export default function ContactCard({
    text,
    type,
    contactText,
    contact,
    icon,
}) {
    return (
        <div
            className={`card shadow-sm rounded-1 border-0 px-2 px-sm-5 py-4 d-flex flex-row justify-content-center`}
        >
            <img
                src={`/images/vectors/${icon}.svg`}
                alt=""
                width={25}
                className="my-auto"
            />
            <span className="ms-3 text-primary fs-6 my-auto">
                {text}
                <a
                    className="fw-semibold ms-1 my-auto"
                    href={`${type ? `${type}:` : ""}${contact}`}
                    target={`${type ? "" : "_blank"}`}
                    rel="noreferrer"
                >
                    {contactText || contact}
                </a>
            </span>
        </div>
    );
}
