import React from "react";
import { useField, useFormikContext } from "formik";

export default function Radio(props) {
    const {
        name,
        id,
        label,
        onChange,
        disabled,
        labelFw = "light",
        labelClass,
    } = props;
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);

    const handleChange = (name, id) => {
        setFieldValue(name, id);
        if (onChange) {
            onChange(name, id);
        }
    };

    return (
        <div className="custom-control custom-radio mr-3">
            <input
                className="custom-control-input"
                name={name}
                checked={field.value === id || field?.value?.choice === id}
                onChange={() => {
                    handleChange(name, id);
                }}
                id={`${name}-${id}`}
                type="radio"
                disabled={disabled}
            />
            <label
                className={`custom-control-label fw-${labelFw} ${labelClass}`}
                htmlFor={`${name}-${id}`}
                title={label}
            >
                {label}
            </label>
        </div>
    );
}
