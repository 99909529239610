/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { usePagination, useSortBy, useTable } from "react-table";

import Price from "../price/Price";
import OrderContent from "./OrderContent";

import "./basic-table.scss";

/**
 * Table component wrapped around `react-table`.
 * @param {{
 * data: any[],
 * columns: any[],
 * isSortable?: boolean,
 * isPaginated?: boolean,
 * pageSize?: boolean,
 * pageIndex?: number,
 * }} props
 * @returns {React.FunctionComponent}
 */
export default function BasicTable({
    data,
    columns,
    variant,
    isSortable = false,
    isPaginated = false,
    pageSize = 10,
    currentPage = 0,
    orderCalculation = {
        active: false,
        medicationAmount: false,
        deliveryAmount: false,
        colCount: 0,
    },
    alternateMedication = { active: false, info: [] },
    treatmentMedication = { active: false, info: [] },
    contentBackgroundWhite = false,
}) {
    const tableColumns = useMemo(
        () =>
            columns.map((col) => ({
                ...col,
                Header: col.header,
            })),
        []
    );

    const pluginList = useMemo(() => {
        let list = [];

        if (isSortable) {
            list.push(useSortBy);
        }

        if (isPaginated) {
            list.push(usePagination);
        }

        return list;
    });

    const tableInstance = useTable(
        {
            columns: tableColumns,
            data: data,
            initialState: {
                pageSize: pageSize,
                pageIndex: currentPage,
            },
        },
        ...pluginList
    );

    const getHeaderProps = (tableInstance, column) => {
        if (isSortable) {
            return column.getHeaderProps(column.getSortByToggleProps());
        }

        return column.getHeaderProps();
    };

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        tableInstance;

    let out_of_stock;
    let alternatives;
    switch (true) {
        case alternateMedication.active:
            out_of_stock = {
                key: "Out of Stock Medication",
                background: "bg-white",
                color: "text-danger",
            };
            alternatives = {
                key: "Suggested Alternative",
                background: "bg-alternative-column",
                color: "text-success",
            };
            break;
        default:
            out_of_stock = "";
            alternatives = "";
    }

    return (
        <div
            className={`basic-table-section table-responsive ${variant} ${
                (alternateMedication.active ||
                    treatmentMedication.active ||
                    contentBackgroundWhite) &&
                "rounded border border-1 border-gray"
            }`}
        >
            <table
                className="table table-sm bg-light-gray rounded m-0"
                {...getTableProps()}
            >
                <thead>
                    {headerGroups.map((headerGroup, index) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                            {headerGroup.headers.map((column, idx) => (
                                <th
                                    {...getHeaderProps(tableInstance, column)}
                                    className={`${
                                        column.header === out_of_stock?.key &&
                                        out_of_stock?.color
                                    } ${
                                        column.header === alternatives?.key &&
                                        alternatives?.color
                                    } ${
                                        column.header === "Price" && "price-col"
                                    } border-0 py-3 px-3 px-md-5`}
                                    key={idx}
                                >
                                    {column.render("Header")}
                                    {isSortable && (
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? " 🔽"
                                                    : " 🔼"
                                                : ""}
                                        </span>
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {(isPaginated ? tableInstance.page : rows).map(
                        (row, rowIdx) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} key={rowIdx}>
                                    {row.cells.map((cell, index) => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                className={`${
                                                    cell.column.Header ===
                                                        out_of_stock?.key &&
                                                    out_of_stock?.background
                                                } ${
                                                    cell.column.Header ===
                                                        alternatives?.key &&
                                                    alternatives?.background
                                                } ${
                                                    (treatmentMedication.active ||
                                                        contentBackgroundWhite) &&
                                                    "bg-white"
                                                } text-brown-black py-3 px-3 px-md-5`}
                                                key={index}
                                            >
                                                {cell.render("Cell")}
                                                {alternateMedication.active &&
                                                    alternateMedication?.info?.map(
                                                        (data, idx) =>
                                                            data.medication ===
                                                                cell.value && (
                                                                <ul
                                                                    className="medication-points m-0 mt-2 ps-5 pe-0"
                                                                    style={{
                                                                        listStyleType:
                                                                            "square",
                                                                    }}
                                                                    key={idx}
                                                                >
                                                                    <li>
                                                                        Strength
                                                                        -{" "}
                                                                        {
                                                                            data.strength
                                                                        }
                                                                    </li>
                                                                    <li>
                                                                        Dosage -{" "}
                                                                        {
                                                                            data.dosage
                                                                        }
                                                                    </li>
                                                                    <li>
                                                                        Quantity
                                                                        -{" "}
                                                                        {
                                                                            data.quantity
                                                                        }
                                                                    </li>
                                                                    <li>
                                                                        Price -{" "}
                                                                        <span className="fw-bold">
                                                                            <Price
                                                                                amount={
                                                                                    data.price
                                                                                }
                                                                                currency="GBP"
                                                                            />
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                            )
                                                    )}
                                                {treatmentMedication.active &&
                                                    treatmentMedication?.info?.map(
                                                        (data, idx) =>
                                                            data.medication ===
                                                                cell.value && (
                                                                <ul
                                                                    className="medication-points m-0 mt-2 ps-5 pe-0"
                                                                    style={{
                                                                        listStyleType:
                                                                            "square",
                                                                    }}
                                                                    key={idx}
                                                                >
                                                                    {data.adminRoute && (
                                                                        <li>
                                                                            Admin
                                                                            route
                                                                            -{" "}
                                                                            {
                                                                                data.adminRoute
                                                                            }
                                                                        </li>
                                                                    )}
                                                                    {data.quantity && (
                                                                        <li>
                                                                            Quantity
                                                                            -{" "}
                                                                            {
                                                                                data.quantity
                                                                            }
                                                                        </li>
                                                                    )}
                                                                    {data.medicineProfile && (
                                                                        <li>
                                                                            Med.
                                                                            profile
                                                                            -{" "}
                                                                            {
                                                                                data.medicineProfile
                                                                            }
                                                                        </li>
                                                                    )}
                                                                    {data.strain && (
                                                                        <li>
                                                                            Strain
                                                                            -{" "}
                                                                            {
                                                                                data.strain
                                                                            }
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            )
                                                    )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        }
                    )}
                    {orderCalculation.active && (
                        <OrderContent
                            medicationAmount={orderCalculation.medicationAmount}
                            deliveryAmount={orderCalculation.deliveryAmount}
                            colCount={orderCalculation.colCount}
                            totalAmount={orderCalculation.totalAmount}
                            deliveryType={orderCalculation.deliveryType}
                            textColor={orderCalculation.textColor}
                            textTotalAmount={orderCalculation.textTotalAmount}
                        />
                    )}
                </tbody>
            </table>

            {isPaginated && (
                <div className="table-pagination">
                    <nav>
                        <ul className="pagination">
                            <li
                                className={`page-item ${
                                    !tableInstance.canPreviousPage
                                        ? "disabled"
                                        : ""
                                }`}
                            >
                                <a
                                    className="page-link"
                                    href="#"
                                    onClick={tableInstance.previousPage}
                                >
                                    Previous
                                </a>
                            </li>
                            {[...Array(tableInstance.pageCount)].map((_, p) => (
                                <li
                                    className={`page-item ${
                                        p === tableInstance.state.pageIndex
                                            ? "active"
                                            : ""
                                    }`}
                                    key={p}
                                >
                                    <a
                                        className="page-link"
                                        href="#"
                                        onClick={() =>
                                            tableInstance.gotoPage(p)
                                        }
                                    >
                                        {p + 1}
                                    </a>
                                </li>
                            ))}
                            <li
                                className={`page-item ${
                                    !tableInstance.canNextPage ? "disabled" : ""
                                }`}
                            >
                                <a
                                    className="page-link"
                                    href="#"
                                    onClick={tableInstance.nextPage}
                                >
                                    Next
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            )}
        </div>
    );
}
