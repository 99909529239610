import React from "react";

import { useModal } from "../../../hooks/modalsHooks";

import Button from "../../button/Button";
import ModalLayout from "../ModalLayout";

export default function SuccessModal({
    title,
    body,
    buttonText,
    onButtonClick,
    width,
}) {
    const { hideModal } = useModal();

    const handleOnClick = () => {
        if (onButtonClick) {
            onButtonClick();
        }
    };

    return (
        <ModalLayout width={width || "43rem"}>
            <ModalLayout.Header closeModal={hideModal}>
                <div className="w-100">
                    <h2 className="position-relative text-center fw-bold">
                        {title}
                        <span className="underline center short" />
                    </h2>
                </div>
            </ModalLayout.Header>
            <ModalLayout.Body className="d-flex d-sm-block p-5">
                <div className="my-auto">
                    <div className="row mx-0">
                        <img
                            src="/images/vectors/success-lg.svg"
                            alt=""
                            className="mx-auto w-fit"
                        />
                    </div>
                    <p className="mt-4 mb-12 mb-sm-0 fw-light text-center">{body}</p>
                </div>
            </ModalLayout.Body>
            <ModalLayout.Footer>
                <Button variant="primary" onClick={handleOnClick}>
                    {buttonText}
                </Button>
            </ModalLayout.Footer>
        </ModalLayout>
    );
}
