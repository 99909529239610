/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useMemo, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { useGetBillingInfoQuery } from "../../../services/paymentService";
import { useGetDoctorQuery } from "../../../services/dataService";
import {
    useAddOrderMutation,
    useLazyGetVoucherValidityQuery,
} from "../../../services/orderService";
import { AppointmentContext } from "../../../providers/AppointmentProvider";
import parseError from "../../../utils/errorUtils";

import { AppointmentCheckoutFormFooter } from "../../appointment/AppointmentCheckoutForm";
import Collapsible from "../../../components/collapsible/Collapsible";
import Avatar from "../../../components/avatar/Avatar";
import Price from "../../../components/price/Price";
import { Form, TextField } from "../../../components/forms";
import CheckoutForm from "./CheckoutForm";
import {useModal} from "../../../hooks/modalsHooks";

const fonts = [
    {
        cssSrc: "https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;600&display=swap",
        family: "Titillium Web",
    },
];

export default function CheckoutPage() {
    const { appointmentData } = useContext(AppointmentContext);
    const { showModal, hideModal } = useModal();


    const navigate = useNavigate();

    const [couponId, setCouponId] = useState("");
    const [discount, setDiscount] = useState(0);

    const { data: doctorData } = useGetDoctorQuery(
        { doctorId: appointmentData.doctorId },
        { skip: !appointmentData?.doctorId }
    );
    const [getVoucherValidity] = useLazyGetVoucherValidityQuery();
    const [addOrder] = useAddOrderMutation();
    const { data: billingInfo } = useGetBillingInfoQuery();

    const handleCheckVoucherValidity = async (values) => {
        try {
            const res = await getVoucherValidity({ voucherId: values.voucher });

            if (res.error) {
                // handle error
                toast.error(parseError(res.error, "Couldn't submit voucher"));
                setCouponId(null);
            } else {
                // handle success
                if (res.data.valid) {
                    setCouponId(values.voucher);
                    const tempAppointmentPrice = billingInfo?.appointmentPrice;

                    const finalValue =
                        tempAppointmentPrice * res.data.percentage;

                    setDiscount(finalValue);
                    toast.success("Voucher added successfully");
                } else {
                    setCouponId(null);
                    toast.error(parseError(res.error, "The discount code provided is not applicable or valid for use."));
                }
            }
        } catch (error) {
            setCouponId(null);
            console.error(error);
        }
    };

    const handleAddOrder = async () => {
        const orderData = {
            orderNotes: [],
            deliveryType: "STANDARD",
            paymentType: "VOUCHER",
            deviceType: "OTHER",
            info: {
                PRESENTING_COMPLAINT: appointmentData?.notes || "",
                COUPON: couponId,
            },
            name: appointmentData.name,
            type: appointmentData.type,
            appointment: {
                id: Number(appointmentData?.id),
            },
            patient: appointmentData.patient,
            isRepeatOrder: false,
        };

        try {
            let payload = orderData;
            if (orderData?.patient?.preferences?.TEMPORARY_APPOINTMENT) {
                payload = { ...orderData, source: 'CALL_BOOKING' };
            }
            const res = await addOrder({ type: "BOOKING", values: payload });

            if (res.error) {
                // handle error
                toast.error(parseError(res.error, "Couldn't place order"));
            } else {
                //handle success
                if (appointmentData.patient.preferences?.TEMPORARY_APPOINTMENT) {
                    showModal({
                        modalType: "PaymentSuccessInfoModal",
                        modalProps: {
                            title: "Payment Successful",
                            message: res.data.message,
                            isPaymentSuccess: true
                        },
                    });
                } else {
                    navigate(
                        `/onboarding/appointment/${res.data.message}/documents`,
                        {
                            replace: true,
                        }
                    );
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const stripePromise = useMemo(async () => {
        if (billingInfo) {
            return await loadStripe(billingInfo.publicKey);
        }

        return null;
    }, [billingInfo]);

    const appointmentPrice = useMemo(() => {
        const tempAppointmentPrice = billingInfo?.appointmentPrice;

        if (discount > 0) {
            return tempAppointmentPrice - discount;
        }

        return tempAppointmentPrice;
    }, [billingInfo, discount]);

    return (
        <Elements stripe={stripePromise} options={{ fonts }}>
            <div className="checkout-page row mx-0">
                <div className="container-left pt-5 bg-transparent">
                    <div className="row mx-0 mb-0 mb-md-5">
                        <div className="col-12 col-lg-9 col-xl-7 col-xxl-6 m-auto pb-5 pb-md-6 pb-xxl-10">
                            <h2 className="text-start fw-bold text-primary mt-7">
                                Payment details
                            </h2>
                            <p className="mt-5  text-primary-200 fs-6 text-start">
                                Please provide your card details to book your
                                appointment.
                            </p>
                            <div className="mt-3 text-start">
                                <img
                                    src="/images/vectors/visa.svg"
                                    alt="visa"
                                />
                                <img
                                    className="ms-2"
                                    src="/images/vectors/mastercard.svg"
                                    alt="mastercard"
                                />
                            </div>
                            <div className="d-flex justify-content-center mt-5">
                                <CheckoutForm
                                    initialValues={{
                                        name: appointmentData?.patient
                                            ?.displayName,
                                        terms_and_conditions: false,
                                    }}
                                    couponId={couponId}
                                    fullVoucher={appointmentPrice === 0}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-right pt-10 px-2 px-lg-6 bg-white">
                    <div className="row mx-0 mb-0 mb-md-5">
                        <div className="col-12 col-lg-11 col-xl-9 col-xxl-8 m-auto pb-5 pb-md-6 pb-xxl-10">
                            <div className="mb-5">
                                <Collapsible
                                    title="Consultation Summary"
                                    className="py-6 px-5 custom-shadow"
                                >
                                    <div className="text-primary fs-6 fw-bold">
                                        Consultation details
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mt-4">
                                        <div className="d-flex align-items-sm-center">
                                            <div
                                                className="d-none d-sm-block"
                                                style={{
                                                    width: 56,
                                                    height: 56,
                                                }}
                                            >
                                                <Avatar
                                                    src={
                                                        doctorData?.settings
                                                            ?.DOCTOR_AVATAR ||
                                                        "/images/samples/doctor-thumb.png"
                                                    }
                                                />
                                            </div>

                                            <div className="ms-0 ms-sm-4">
                                                <div className="primary-title fw-bold fs-5">
                                                    {doctorData?.displayName}
                                                </div>
                                                {appointmentData?.datetime && (
                                                    <div className="secondary-title text-dark-3 fw-light">
                                                        {dayjs(
                                                            appointmentData.datetime
                                                        ).format("DD/MM/YYYY")}
                                                        &nbsp; at&nbsp;
                                                        {dayjs(
                                                            appointmentData.datetime
                                                        ).format("hh:mma")}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {appointmentData?.notes && (
                                        <>
                                            <hr />
                                            <div className="text-primary fs-6 fw-bold">
                                                Consultation notes
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mt-2">
                                                <div className="text-dark-4 pe-2">
                                                    {appointmentData.notes}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </Collapsible>
                            </div>
                            <Collapsible
                                title="Payment Summary"
                                expanded
                                className="py-6 px-5 custom-shadow"
                            >
                                <div>
                                    <div className="d-flex mb-3 align-items-center justify-content-between text-medium-gray fs-6">
                                        <div>Video Consultation</div>
                                        <div>
                                            <Price
                                                amount={appointmentPrice}
                                                currency="GBP"
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="d-flex mb-5 align-items-center fw-bold justify-content-between text-primary fs-5">
                                        <div>Total</div>
                                        <div>
                                            <Price
                                                amount={appointmentPrice}
                                                currency="GBP"
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex mb-2 align-items-center fw-bold justify-content-between text-primary">
                                        <div>Discount code</div>
                                    </div>
                                    <div className="text-medium-gray mb-4">
                                        If you have a discount code please enter
                                        it here
                                    </div>
                                    <div className="mb-3">
                                        <Form
                                            initialValues={{ voucher: "" }}
                                            onSubmit={
                                                handleCheckVoucherValidity
                                            }
                                        >
                                            <div className="input-group flex-nowrap">
                                                <TextField
                                                    name="voucher"
                                                    placeholder="Discount code"
                                                    className="rounded-0 rounded-start form-control-sm fs-6 px-5 py-3 bg-white border"
                                                    disabled={
                                                        appointmentPrice === 0
                                                    }
                                                />
                                                <button
                                                    className="bg-primary text-white fs-5 fw-semibold border-0 btn-primary py-2 px-4 rounded-0 rounded-end"
                                                    disabled={
                                                        appointmentPrice === 0
                                                    }
                                                    type="submit"
                                                >
                                                    Apply
                                                </button>
                                            </div>
                                        </Form>
                                        {appointmentPrice === 0 && (
                                            <Form
                                                initialValues={{
                                                    terms_and_conditions: "",
                                                }}
                                                onSubmit={handleAddOrder}
                                            >
                                                <AppointmentCheckoutFormFooter />
                                            </Form>
                                        )}
                                    </div>
                                </div>
                            </Collapsible>
                        </div>
                    </div>
                </div>
            </div>
        </Elements>
    );
}
