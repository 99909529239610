import React from "react";

import { useModal } from "../../../hooks/modalsHooks";

import { Form } from "../../forms";
import { GADForm } from "../../orderNoteForms";
import ModalLayout from "../ModalLayout";

export default function GADModal({ onSubmit }) {
  const { hideModal } = useModal();

  const handleSubmit = (values) => {
    const score = values.feeling + values.worrying;

    const data = {
      type: "GAD_FORM",
      questionAnswer: JSON.stringify({ ...values, score }),
    };

    onSubmit(data);
  };

  return (
    <ModalLayout width="44rem">
      <ModalLayout.Header closeModal={hideModal}>
        <div className="w-100">
          <h2 className="position-relative text-center fw-bold">
            GAD form
            <span className="underline center short" />
          </h2>
        </div>
      </ModalLayout.Header>
      <Form
        initialValues={{
          feeling: null,
          worrying: null,
        }}
        onSubmit={handleSubmit}
        className="h-100"
      >
        <GADForm />
      </Form>
    </ModalLayout>
  );
}
