import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import "./side-bar.scss";

function Sidebar() {
    const location = useLocation();

    const getActiveClass = (path) => {
        if (location.pathname.toLowerCase().includes(path)) {
            return "active";
        }
        return "";
    };

    return (
        <nav className="sidebar d-flex flex-column text-center justify-content-start pt-12 pb-4 px-2 px-lg-3">
            <ul className="mb-0 list-unstyled d-flex flex-column">
                <li
                    className={`nav-item mx-auto w-100 py-4 px-2 px-lg-3 px-xl-4 mb-1 ${getActiveClass(
                        "dashboard"
                    )}`}
                >
                    <NavLink
                        className="nav-link text-primary d-flex justify-content-center justify-content-md-start"
                        to="/user/dashboard"
                    >
                        <img
                            src={
                                getActiveClass("dashboard") === ""
                                    ? "/images/sidebar/home.svg"
                                    : "/images/sidebar/home-active.svg"
                            }
                            alt=""
                        />
                        <span className="ms-3 fw-bold fs-6">Dashboard</span>
                    </NavLink>
                </li>
                <li
                    className={`nav-item mx-auto w-100 py-4 px-2 px-lg-3 px-xl-4 mb-1 ${getActiveClass(
                        "order"
                    )}`}
                >
                    <NavLink
                        className="nav-link text-primary d-flex justify-content-center justify-content-md-start"
                        to="/user/order"
                    >
                        <img
                            src={
                                getActiveClass("order") === ""
                                    ? "/images/sidebar/order.svg"
                                    : "/images/sidebar/order-active.svg"
                            }
                            alt=""
                        />
                        <span className="ms-3 fw-bold fs-6">Treatments</span>
                    </NavLink>
                </li>
                <li
                    className={`nav-item mx-auto w-100 py-4 px-2 px-lg-3 px-xl-4 mb-1 ${getActiveClass("my-cannabis-card")}`}
                >
                    <NavLink
                        className="nav-link text-primary d-flex justify-content-center justify-content-md-start"
                        to="/user/my-cannabis-card"
                    >
                        <img src={getActiveClass("qr") === "" ? "/images/sidebar/qr.svg" : "/images/sidebar/qr-active.svg"} alt=""/>
                        <span className="ms-3 fw-bold fs-6">My Cannabis Card</span>
                    </NavLink>
                </li>
                <li
                    className={`nav-item mx-auto w-100 py-4 px-2 px-lg-3 px-xl-4 mb-1 ${getActiveClass(
                        "support"
                    )}`}
                >
                    <NavLink
                        className="nav-link text-primary d-flex justify-content-center justify-content-md-start"
                        to="/user/support"
                    >
                        <img
                            src={
                                getActiveClass("support") === ""
                                    ? "/images/sidebar/support.svg"
                                    : "/images/sidebar/support-active.svg"
                            }
                            alt=""
                        />
                        <span className="ms-3 fw-bold fs-6">Support</span>
                    </NavLink>
                </li>
                <li
                    className={`nav-item mx-auto w-100 py-4 px-2 px-lg-3 px-xl-4 mb-1 ${getActiveClass(
                        "account"
                    )}`}
                >
                    <NavLink
                        className="nav-link text-primary d-flex justify-content-center justify-content-md-start"
                        to="/user/account"
                    >
                        <img
                            src={
                                getActiveClass("account") === ""
                                    ? "/images/sidebar/account.svg"
                                    : "/images/sidebar/account-active.svg"
                            }
                            alt=""
                        />
                        <span className="ms-3 fw-bold fs-6">Account</span>
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
}

export default Sidebar;
