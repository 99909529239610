import React from "react";

export default function Price({ amount = 0, currency = "GBP" }) {
    const getPrice = () => {
        if (typeof amount === "number") {
            return amount.toLocaleString("en-US", {
                style: "currency",
                currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        } else {
            return Number(amount);
        }
    };

    return <>{getPrice()}</>;
}
