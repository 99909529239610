import {
    APPROVED_STATUS,
    DOCTOR_REJECTED,
    MDT_REJECTED,
    PATIENT_APPROVAL_WAITING,
    PATIENT_REJECTED,
    FAILED_STATUS,
    PENDING_STATUS,
    PHARMACY_REJECTED,
    REJECTED_AFTER_28_DAYS,
    REJECTED_STATUS,
    SPECIALIST_REJECTED,
    PATIENT_ANSWER_WAITING,
    ADMIN_REJECTED,
} from "../constants/orderStatus";

export function getStatus(status) {
    if (APPROVED_STATUS.includes(status)) {
        return {
            status: "COMPLETED",
            variant: "completed",
            borderClass: "border border-0",
            btnText: "View order",
            statusText: "Completed",
        };
    } else if (PENDING_STATUS.includes(status)) {
        return getPendingStatus(status);
    } else if (REJECTED_STATUS.includes(status)) {
        return getRejectedStatus(status);
    } else if (FAILED_STATUS.includes(status)) {
        return getFailedStatus(status);
    }

    return {
        status: "DEFAULT",
        variant: "default",
        borderClass: "border border-dark",
        btnText: "View Order",
        statusText: getOrderStatusText(status),
    };
}

function getPendingStatus(status) {
    let tempStatus = {
        status: "PENDING",
        variant: "pending",
        borderClass: "border border-primary",
        btnText: "View Order",
        statusText: "Review Order",
    };

    tempStatus["statusText"] = getOrderStatusText(status);

    if (PATIENT_APPROVAL_WAITING.includes(status)) {
        tempStatus["btnText"] = "Review Order";
    }

    if (PATIENT_ANSWER_WAITING.includes(status)) {
        tempStatus["btnText"] = "Answer";
    }

    return tempStatus;
}

function getRejectedStatus(status) {
    let tempStatus = {
        status: "REJECTED",
        variant: "rejected",
        borderClass: "border border-danger",
        btnText: "View Order",
        statusText: "Rejected order",
        rejectedReason: "Not provided",
    };

    tempStatus["statusText"] = getOrderStatusText(status);

    if (PATIENT_REJECTED.includes(status)) {
        tempStatus["rejectedReason"] = [
            "PATIENT_SPECIALIST_RECOMMENDATION_DECISION",
            "PATIENT_PHARMACY_DECISION",
        ];
    } else if (MDT_REJECTED.includes(status)) {
        tempStatus["rejectedReason"] = ["MDT_DECISION"];
    } else if (SPECIALIST_REJECTED.includes(status)) {
        tempStatus["rejectedReason"] = ["SPECIALIST_DECISION"];
    } else if (PHARMACY_REJECTED.includes(status)) {
        tempStatus["rejectedReason"] = ["PHARMACY_DECISION"];
    } else if (DOCTOR_REJECTED.includes(status)) {
        tempStatus["rejectedReason"] = ["GP_APPROVAL_DECISION", "DOCTOR_ORDER_CANCELLATION_NOTE"];
    } else if (ADMIN_REJECTED.includes(status)) {
        tempStatus["rejectedReason"] = ["ADMIN_DECISION", "ADMIN_ORDER_CANCELLATION_NOTE"];
    } else if (REJECTED_AFTER_28_DAYS.includes(status)) {
        tempStatus["rejectedReason"] = "Expired";
    }

    return tempStatus;
}

function getFailedStatus(status) {
    let tempStatus = {
        status: "FAILED",
        variant: "rejected",
        borderClass: "border border-danger",
        btnText: "View Order",
        statusText: getOrderStatusText(status),
    };

    return tempStatus;
}

function getOrderStatusText(status) {
    switch (status) {
        case "WAITING_FOR_PHARMACY_APPROVAL":
            return "Sent to Pharmacy";
        case "REJECTED_BY_PHARMACY":
            return "Rejected by Pharmacy";
        case "PAYMENT_FAILED":
            return "Payment Failed";
        case "DELIVERED":
            return "Complete";
        case "BOOKED":
            return "Booked";
        case "IN_MDT_SESSION":
            return "In MDT Session";
        case "AWAITING_MDT_SESSION_COMPLETION":
            return "In MDT Session";
        case "WAITING_FOR_TRACKING_CODE":
            return "Awaiting Tracking Code";
        case "AWAITING_PHARMACY_DISPENSE":
            return "Awaiting Pharmacy Dispense";
        case "AWAITING_SPECIALIST_CALL_PATIENT":
            return "Awaiting Specialist Review";
        case "REJECTED_BY_PHARMACY_AWAITING_SPECIALIST_ALTERATION":
            return "Awaiting Specialist Alteration";
        case "AWAITING_SPECIALIST_PRESCRIPTION_CREATION":
            return "Awaiting Prescription Creation";
        case "AWAITING_PATIENT_SPECIALIST_PRESCRIPTION_CONFIRMATION":
            return "Awaiting Your Approval";
        case "AWAITING_PATIENT_PHARMACY_PRESCRIPTION_CONFIRMATION":
            return "Awaiting Your Approval";
        case "AWAITING_PATIENT_SPECIALIST_ALERT_REPLY":
            return "Awaiting Your Answer";
        case "WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_PATIENT_APPROVAL":
            return "Awaiting Pink Prescription Confirmation";
        case "WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_NO_CHANGES":
            return "Awaiting Pink Prescription Confirmation";
        case "AWAITING_PATIENT_PRESCRIPTION_PAYMENT":
            return "Awaiting Your Payment";
        case "AWAITING_PHARMACY_DISPATCH":
            return "Awaiting Pharmacy Dispatch";
        case "AWAITING_PHARMACY_DELIVER":
            return "Awaiting Pharmacy Deliver";
        case "AWAITING_MDT_SESSION_INITIATION":
            return "Awaiting MDT Session";
        case "REJECTED_BY_SPECIALIST":
            return "Rejected by Specialist";
        case "REJECTED_BY_MDT":
            return "Rejected by MDT";
        case "REJECTED_BY_DOCTOR":
            return "Rejected by Doctor";
        case "REJECTED_BY_PATIENT":
            return "Rejected by You";
        case "CANCELED_BY_DOCTOR":
            return "Cancelled by Doctor";
        case "CANCELED_BY_ADMIN":
            return "Cancelled by Admin";
        case "REJECTED_AFTER_28_DAYS":
            return "Rejected After 28 Days";
        default:
            return status;
    }
}
