import { useFormikContext } from "formik";

import ModalLayout from "../modals/ModalLayout";
import { Radio, SubmitButton } from "../forms";

import { AUDIT_FORM } from "../../constants/orderNotesQuestions";

export default function AUDITForm({ viewOnly = false }) {
    const { values } = useFormikContext();

    const score =
        values.question1 +
        values.question2 +
        values.question3 +
        values.question4 +
        values.question5 +
        values.question6 +
        values.question7 +
        values.question8 +
        values.question9 +
        values.question10;
    const isNull =
        values.question1 === null ||
        values.question2 === null ||
        values.question3 === null ||
        values.question4 === null ||
        values.question5 === null ||
        values.question6 === null ||
        values.question7 === null ||
        values.question8 === null ||
        values.question9 === null ||
        values.question10 === null;

    return (
        <>
            <ModalLayout.Body className={`d-flex d-sm-block p-5 ${viewOnly ? "p-0" : ""}`}>
                <div className="my-auto">
                    <div
                        className={`${
                            viewOnly ? "" : "audit-question-container"
                        } fw-normal mb-12 mb-sm-0`}
                    >
                        {AUDIT_FORM.keys.map((key) => (
                            <div className="row mx-0 mb-5" key={key}>
                                <label className="form-label">
                                    {AUDIT_FORM.data[key].label}
                                </label>
                                <div className="d-flex flex-column flex-lg-row">
                                    {AUDIT_FORM.data[key].values.map(
                                        (data, idx) => (
                                            <div
                                                className="me-0 me-lg-3 mb-2 mb-lg-0"
                                                key={idx}
                                            >
                                                <Radio
                                                    name={key}
                                                    id={idx}
                                                    label={data}
                                                    disabled={viewOnly}
                                                />
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    {!isNull && !isNaN(score) && (
                        <div className="row mx-0 mt-3">
                            <h6>Score: {score.toFixed(2)}</h6>
                        </div>
                    )}
                </div>
            </ModalLayout.Body>
            {viewOnly ? (
                <></>
            ) : (
                <ModalLayout.Footer>
                    <div className="d-flex flex-column flex-lg-row w-100">
                        <SubmitButton variant="primary" disabled={isNull}>
                            Submit
                        </SubmitButton>
                    </div>
                </ModalLayout.Footer>
            )}
        </>
    );
}
