import React from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

import Avatar from "../avatar/Avatar";
import Button from "../button/Button";
import { ORDER_STATUS } from "../../constants/orderStatus";

function ConsultantCard({ consultData }) {
    const navigate = useNavigate();

    const onJoinSession = () => {
        navigate(`/consultation/${consultData?.id}`);
    };

    const onViewConsultDetails = (id) => {
        navigate(`/user/order/${id}`);
    };

    const availableToJoin =
        dayjs(consultData?.appointment.datetime).diff(new Date()) < 600000 &&
        dayjs(consultData?.appointment.datetime).diff(new Date()) > -3600000 &&
        consultData?.status === ORDER_STATUS.BOOKED;

    return (
        <div className="bg-white d-flex justify-content-between align-items-start flex-column flex-md-row rounded p-4">
            <div className="d-flex align-items-sm-center">
                <div style={{ width: 56, height: 56 }}>
                    <Avatar
                        src={
                            consultData?.doctor?.settings?.DOCTOR_AVATAR ||
                            "/images/samples/doctor-thumb.png"
                        }
                    />
                </div>

                <div className="ms-2 ms-sm-4">
                    <div className="primary-title fw-bold fs-5">
                        {consultData?.doctor.displayName}
                    </div>
                    <div className="secondary-title text-dark-5 fw-light">{`${dayjs(
                        consultData?.appointment.datetime
                    ).format("DD/MM/YYYY")} at ${dayjs(
                        consultData?.appointment.datetime
                    ).format("hh:mm a")}`}</div>
                </div>
            </div>
            <div className="d-flex mt-4 my-md-auto">
                {availableToJoin ? (
                    <Button
                        variant="primary text-uppercase"
                        size="sm"
                        onClick={onJoinSession}
                    >
                        Join Session
                    </Button>
                ) : (
                    <Button
                        variant=" text-decoration-underline border-0 fw-bold mt-3 mt-sm-0 p-0"
                        onClick={() => onViewConsultDetails(consultData?.id)}
                    >
                        View details
                    </Button>
                )}
            </div>
        </div>
    );
}

export default ConsultantCard;
