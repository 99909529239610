import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useInvoice from "../../../hooks/useInvoice";
import { OrderContext } from "../../../providers/OrderProvider";

import Price from "../../../components/price/Price";

function OrderSuccessPage() {
    const navigate = useNavigate();

    const { id } = useParams();
    const { deliveryType } = useContext(OrderContext);

    const medicationTotal = useInvoice("PRESCRIPTION_DRUG", "PRESCRIPTION");

    // delivery fee
    const deliveryFee = deliveryType === "EXPRESS" ? 15 : 0;

    const onViewSummary = () => {
        navigate(`/user/order/${id}`, { replace: true });
    };

    return (
        <div
            className="row mx-0 align-items-center justify-content-center bg-light-gray"
            style={{
                minHeight: `calc(100vh - 80px)`,
            }}
        >
            <div className="col-12 col-md-10 col-xl-4 col-xxl-3 mx-auto pb-12">
                <img
                    src="/images/vectors/success.svg"
                    className="img-fluid mx-auto d-block"
                    alt=""
                />
                <div className="fw-bold fs-4 text-brown-black text-center mt-3">
                    Your order has been placed successfully.
                </div>

                <div className="mt-3 text-dark-4 fs-6 text-center px-xl-2">
                    We'll get your prescription sorted. You can check the status
                    of your order through the{" "}
                    <span
                        className="text-decoration-underline ms-1 cursor-pointer"
                        onClick={onViewSummary}
                    >
                        Orders Summary
                    </span>
                </div>

                <div className="bg-white p-4 border mt-6">
                    <h3 className="text-center mb-5 fw-bold text-brown-black">
                        Payment Summary
                    </h3>

                    <div className="d-flex mb-3 align-items-center justify-content-between text-medium-gray fs-6">
                        <div>Medication total</div>
                        <div>
                            <Price
                                amount={medicationTotal?.price}
                                currency="GBP"
                            />
                        </div>
                    </div>
                    {/*
                        <div className="d-flex mb-3 align-items-center justify-content-between text-medium-gray fs-6">
                            <div>Processing fee</div>
                            <div>
                                <Price amount={processingAmount?.price} currency="GBP" />
                            </div>
                        </div>
                    */}
                    <div className="d-flex mb-3 align-items-center justify-content-between text-medium-gray fs-6">
                        <div>
                            {deliveryType === "EXPRESS"
                                ? "Express delivery"
                                : "Shipping"}
                        </div>
                        <div>
                            <Price
                                amount={Number(deliveryFee)}
                                currency="GBP"
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="d-flex mb-3 align-items-center fw-bold justify-content-between text-primary fs-5">
                        <div>Total</div>
                        <div>
                            <Price
                                amount={
                                    Number(medicationTotal?.price) +
                                    Number(deliveryFee)
                                }
                                currency="GBP"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderSuccessPage;
