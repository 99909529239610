import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AppointmentContext } from "../../../../providers/AppointmentProvider";
import { RepeatOrderContext } from "../../../../providers/RepeatOrderProvider";

import GoBack from "../../../../components/GoBack";
import Button from "../../../../components/button/Button";
import PrevPrescription from "../../../../components/prevPrescription/PrevPrescription";

export default function ChangePrescriptionPage() {
    const navigate = useNavigate();

    const { appointmentData, setAppointmentData } =
        useContext(AppointmentContext);
    const { parentOrderId } = useContext(RepeatOrderContext);

    const handleAccept = () => {
        navigate(`/appointment/feedback`);
    };

    const handleReject = () => {
        let tempAppointmentData = { ...appointmentData };
        if (tempAppointmentData.prescriptionRejectReason) {
            delete tempAppointmentData?.prescriptionRejectReason;
        }

        if (tempAppointmentData.pictures) {
            delete tempAppointmentData?.pictures;
        }

        setAppointmentData(tempAppointmentData);

        navigate(`/appointment/${parentOrderId}/payment?type=REPEAT`);
    };

    return (
        <div className="row mx-0 pt-5 mb-5">
            <div className="col-12 col-lg-8 col-xl-6 mx-auto mb-12">
                <GoBack center="justify-content-center" />
                <h2 className="text-center mt-5 fw-semibold">
                    REORDER YOUR LAST PRESCRIPTION
                </h2>
                <div className="my-8 mx-auto col-12">
                    <PrevPrescription orderId={parentOrderId} />
                </div>
                <h3 className="text-center text-primary">
                    Do you wish to change your prescription?
                </h3>
                <div className="row mx-0 mt-8 mb-12 justify-content-center">
                    <Button
                        variant="white border-0 w-fit px-8 px-sm-11 mb-2 mx-1 me-sm-2"
                        fullWidth={false}
                        onClick={handleAccept}
                    >
                        Yes
                    </Button>
                    <Button
                        variant="white border-0 w-fit px-8 px-sm-11 mb-2 mx-1 ms-sm-2"
                        fullWidth={false}
                        onClick={handleReject}
                    >
                        No
                    </Button>
                </div>
            </div>
        </div>
    );
}
