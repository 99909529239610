import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

import Price from "../../../../components/price/Price";
import BasicTable from "../../../../components/tables/BasicTable";
import Button from "../../../../components/button/Button";

export default function ConsultationInvoice({
    amount,
    topic,
    dateTime,
    orderId,
    orderDescription,
    registration,
}) {
    const navigate = useNavigate();

    const date = dayjs(dateTime).format("MMMM Do, YYYY");
    const time = dayjs(dateTime).format("hh:mm a");

    const viewInvoice = () => {
        navigate(`/user/order/${orderId}/consultation-invoice/view`);
    };

    return (
        <div className="consultation-invoice-section">
            <div className="d-flex">
                <div className="text-primary p1 fw-bold mt-5">
                    Consultation Invoice
                </div>
                <Button
                    variant="link fw-semibold mt-5 ms-auto p-0"
                    fullWidth={false}
                    onClick={viewInvoice}
                >
                    View invoice
                </Button>
            </div>
            <div className="mt-4">
                <BasicTable
                    data={[
                        {
                            description: orderDescription,
                            quantity: "1",
                            price: amount,
                        },
                    ]}
                    columns={[
                        {
                            header: "Description",
                            accessor: "description",
                            Cell: () => (
                                <div>
                                    <div className="mb-2">
                                        {orderDescription}
                                    </div>
                                    <ul>
                                        <li>Topic - {topic}</li>
                                        <li>
                                            Date & Time - {date} at {time}
                                        </li>
                                        <li>
                                            Registration Number -{" "}
                                            {registration?.number}
                                        </li>
                                    </ul>
                                </div>
                            ),
                        },
                        {
                            header: "Quantity",
                            accessor: "quantity",
                        },
                        {
                            header: "Price",
                            accessor: "price",
                            Cell: ({ cell: { value } }) => (
                                <Price amount={value} currency="GBP" />
                            ),
                        },
                    ]}
                    contentBackgroundWhite={true}
                    isSortable={true}
                    isPaginated={false}
                    pageSize={1}
                    currentPage={0}
                    orderCalculation={{
                        active: true,
                        deliveryAmount: amount,
                        totalAmount: amount,
                        colCount: 2,
                        textColor: "brown-black",
                        textTotalAmount: "for the consultation",
                    }}
                />
            </div>
        </div>
    );
}
