/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import LoadingSpinner from "../../../components/loadingSpinner/LoadingSpinner";
import Price from "../../../components/price/Price";

import { TIMER_TIMEOUT } from "../../../constants";

const appointmentPrice = 79.99;

export default function CreateAccountSuccessPage() {
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate("/onboarding/appointment/user-details");
        }, TIMER_TIMEOUT);
    }, []);

    return (
        <div className="create-account-success-page px-3 m-auto pb-md-12">
            <div className="d-flex flex-column mb-md-12">
                <img
                    src="/images/vectors/success-primary.svg"
                    alt=""
                    className="mx-auto mb-5"
                />
                <h2 className="text-primary fw-bold mb-5 text-center">
                    Account created successfully!
                </h2>
                <p className="fs-5 subtitle text-primary-200 text-center mb-8 mx-2 mx-lg-10 px-lg-10">
                    You created a Treat it account successfully. Please proceed
                    to book your appointment.
                </p>
                {/* TODO: Question - no idea what the use of this? */}
                <p className="fs-5 text-primary text-center mb-8 mx-auto px-2 px-lg-6">
                    Online video appointment with a pain specialist for&nbsp;
                    <b>
                        <Price amount={appointmentPrice} />
                    </b>
                </p>

                <div className="spinner-container mx-auto">
                    <LoadingSpinner
                        spinnerImage="/images/vectors/spinner-primary-100.svg"
                        hideLoadingText
                    />
                </div>
            </div>
        </div>
    );
}
