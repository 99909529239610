import React from "react";

export default function DoormenInfoPage() {
	return (
		<div className="fs-18">
			<p>
				Critical Legal Advisory for Security Teams: The Importance of
				Correct Conduct with Medicinal Cannabis Consumers.
			</p>
			<p>
				<b>Prohibited Actions:</b>
			</p>
			<ul>
				<li>
					<b>Denying Access to Medication:</b> Security must not
					obstruct a patient's access to their medically prescribed
					cannabis, especially when it pertains to a disability.
				</li>
				<li>
					<b>Entry Denial Due to Medicinal Cannabis:</b> Entrance
					should not be refused solely because an individual possesses
					medicinal cannabis.
				</li>
				<li>
					<b>Concealing Establishment Regulations:</b> Security is
					required to immediately disclose any specific rules
					regarding medicinal cannabis upon inquiry.
				</li>
				<li>
					<b>Discriminatory or Unfair Treatment:</b> It's unacceptable
					to discriminate against, mock, or belittle anyone for their
					medicinal cannabis use or status.
				</li>
			</ul>

			<p>Additional Insights:</p>
			
			<p>
				<b>
					Guidelines for Security: Engaging with Patients Using
					Medicinal Cannabis
				</b>
			</p>
			<p>
				Welcome, security personnel. With the increasing prevalence of
				medicinal cannabis, it's crucial to equip ourselves with the
				knowledge and understanding needed to navigate interactions with
				users effectively.
			</p>

			<p>
				<b>Identifying Medicinal Cannabis Users:</b>
			</p>
			<ul>
				<li>
					<b>Verification Process:</b> Confirm a patient's status by
					checking a valid prescription, the Treat-it Patient Card, or
					a notification through the Treat-it app.
				</li>
				<li>
					<b>Efficient Identification:</b> Familiarize yourself with
					common identification methods to quickly and accurately
					recognize users of medicinal cannabis.
				</li>
			</ul>

			<p>
				<b>Fundamental Duties & Best Practices:</b>
			</p>
			<ul>
				<li>
					<b>Eliminate Prejudice:</b> Maintain an impartial approach
					towards all, including those using medicinal cannabis.
					Prejudice based on medicinal usage violates the Equality Act
					2010 principles.
				</li>
				<li>
					<b>Acknowledge Medical Needs:</b> Recognize the essential
					role of cannabis in some individuals' health regimens and
					respect this necessity.
				</li>
				<li>
					<b>Offer Empathy & Privacy:</b> Be mindful of the potential
					for patients to experience anxiety or chronic pain, and
					handle these situations with sensitivity and privacy.
				</li>
			</ul>

			<p>
				<b>Handling Various Situations & Actions to Take:</b>
			</p>
			<ul>
				<li>
					<b>During Entry Screening:</b> Should a visitor reveal their
					medicinal cannabis use during entry checks, verify their
					status via the Treat-it app or a valid prescription and
					allow entry once confirmed.
				</li>
				<li>
					<b>Regarding Public Use:</b> Politely direct patients to a
					private or designated area if the venue has restrictions on
					public consumption.
				</li>
				<li>
					<b>Concerning Medicine Storage & Limits:</b> Keep informed
					about the standard limits for medicinal cannabis possession
					and consult the Treat-it app for any clarifications needed.
				</li>
			</ul>

			<p>
				<b>Documentation & Incident Reporting:</b>
			</p>
			<ul>
				<li>
					Always record any interactions related to medicinal
					cannabis, detailing the patient's condition and ensuring
					their rights are protected. For complex situations or
					doubts, it is wise to seek advice from venue management or
					use the Treat-it app's verification features.
				</li>
			</ul>

			<p>
				<b>Consequences for Infringing on Patients' Rights:</b>
			</p>
			<ul>
				<li>
					Be cognizant that violating a patient's rights can lead to
					them reporting the incident through the Treat-it app,
					potentially resulting in an official complaint against both
					the individual and their organization, with serious outcomes
					including unlimited fines and criminal charges.
				</li>
			</ul>
		</div>
	);
}
