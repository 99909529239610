import React from "react";
import * as Yup from "yup";

import { Form, SubmitButton } from "../../../components/forms";
import ReferredByForm from "./ReferredByForm";
import {
    useGetMyProfileQuery,
    useUpdateProfileMutation,
} from "../../../services/userService";
import { toast } from "react-toastify";
import parseError from "../../../utils/errorUtils";
import { useNavigate } from "react-router-dom";

const referralOptions = [
    { id: "facebook", label: "Facebook" },
    { id: "instagram", label: "Instagram" },
    { id: "tiktok", label: "TikTok" },
    { id: "search", label: "Search" },
    { id: "google_ads", label: "Google Ads/Online Ads" },
    { id: "email", label: "Email" },
    { id: "referral", label: "Referral" },
    { id: "website", label: "Website" },
    { id: "instore_ads", label: "InStore Ad" },
    { id: "flyer", label: "Flyer" },
    { id: "online_forum", label: "Online forum" },
    { id: "seminar-webinar", label: "Seminar-webinar" },
    { id: "sales_call", label: "Sales call" },
    { id: "other", label: "Other" },
];

export default function ReferredByPage() {
    const navigate = useNavigate();

    const { data: profileData } = useGetMyProfileQuery();
    const [updateProfile] = useUpdateProfileMutation();

    const validationSchema = Yup.object().shape({
        referredBy: Yup.string().required("Please select an option"),
        other: Yup.string().when("referredBy", {
            is: "other",
            then: Yup.string().required("Please specify your answer"),
            otherwise: Yup.string().notRequired()
        })
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        let data = {
            ...profileData,
            preferences: {
                ...profileData.preferences,
                referredBy: values.referredBy === "other" ? `other: ${values.other}` : values.referredBy,
            },
        };

        try {
            await validationSchema.validate(values, { abortEarly: false });

            const res = await updateProfile({ values: data });

            if (res.error) {
                toast.error(parseError(res.error, "Couldn't complete request"));
            } else {
                navigate(`/onboarding/appointment/success`, {
                    replace: true,
                });
            }
        } catch (e) {
            // Display validation error messages
            if (e.name === "ValidationError") {
                e.inner.forEach(error => {
                    toast.error(error.message);
                });
            } else {
                console.error(e);
            }
        } finally {
            // Always set submitting to false, even if an error occurs
            setSubmitting(false);
        }
    };

    return (
        <div className="referred-by-page px-3 m-auto pb-12 w-100">
            <div className="d-flex flex-column">
                <h2 className="text-primary fw-bold mb-6 text-center">
                    How did you hear about us?*
                </h2>
                <p className="fs-5 subtitle text-primary-200 text-center mb-8 mx-2 mx-lg-8">
                    How did you find us?! Your input helps guide our service.
                </p>
            </div>
            <Form
                initialValues={{ referredBy: "", other: "" }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                <div className="px-1 px-md-12">
                    <ReferredByForm options={referralOptions} />
                </div>
                <div className="d-flex justify-content-center mt-7">
                    <SubmitButton
                        fullWidth={false}
                        variant="primary fs-5 px-12"
                        type="submit"
                    >
                        <div className="d-flex justify-content-center px-sm-10">
                            <img
                                className="me-2 my-auto"
                                src="/images/vectors/arrow-right.svg"
                                alt=""
                            />
                            <span>Next</span>
                        </div>
                    </SubmitButton>
                </div>
            </Form>
        </div>
    );
}
