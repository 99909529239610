import React, { useEffect, useRef } from "react";

import { useGetMyProfileQuery } from "../../services/userService";

import Avatar from "../avatar/Avatar";
import DialogBox from "./DialogBox";
import { Form, TextField } from "../forms";
import Button from "../button/Button";

import "./chat-thread.scss";

export default function ChatThread({
    onSend,
    messages = [],
    orderData,
    disabled,
    isCallFinished,
    isPublished,
    handleDisconnectSession,
}) {
    const lastMessage = useRef(null);

    const { data: profileData } = useGetMyProfileQuery();

    const handleSubmitMessage = async ({ message }, { resetForm }) => {
        if (message) {
            await onSend(message);
            resetForm();
        }
    };

    const checkFileIsValid = (fileType) => {
        if (["PNG", "JPG", "JPEG", "IMG"].includes(fileType)) {
            return { valid: true, type: "image" };
        } else if (["PDF"].includes(fileType)) {
            return { valid: true, type: "document" };
        } else {
            console.error(`Invalid file type: ${fileType}`);
            return { valid: false, type: fileType };
        }
    };

    useEffect(() => {
        if (lastMessage.current) {
            lastMessage.current.scrollIntoView();
        }
    }, [messages]);

    return (
        <div className="chat-thread">
            <div className="chat-title d-flex justify-content-between align-items-sm-center flex-row border-bottom py-3">
                <div className="start-container d-flex">
                    <div className="avatar-container w-fit m-2 me-3">
                        <Avatar
                            src={
                                orderData?.doctor?.settings.DOCTOR_AVATAR ||
                                "/images/samples/doctor-thumb.png"
                            }
                        />
                    </div>
                    <div className="details-container my-auto">
                        <div>you're chatting with</div>
                        <p className="p1 fw-bold mb-0">
                            {orderData?.doctor?.displayName}
                        </p>
                    </div>
                </div>
                <div className="action-container d-flex align-items-center">
                    <Button
                        variant="dark-red d-flex text-white border-0 p-2 p-sm-3 me-5"
                        fullWidth={false}
                        onClick={handleDisconnectSession}
                        disabled={!isPublished || isCallFinished}
                    >
                        <img
                            src="/images/vectors/logout.svg"
                            alt=""
                            className="my-auto me-0 me-xl-3"
                        />
                        <span className="fw-light my-auto d-none d-xl-block">
                            End Session
                        </span>
                    </Button>
                </div>
            </div>
            <div className="thread d-flex flex-column px-1 px-sm-2 px-md-3 pb-3">
                <div className="position-relative d-flex justify-content-center start-text-container">
                    <span className="text-center headliner mt-4 start-text">
                        Start of conversation
                    </span>
                </div>
                {messages.map((message, idx) => {
                    const sameSender =
                        (messages[idx - 1]?.sent && messages[idx - 1]?.sent) ===
                        message.sent;

                    const isFile = message.data.isFileMsg;
                    const fileData =
                        isFile && checkFileIsValid(message.data.fileType);
                    const file = message.data.fileUrl;

                    return (
                        <div
                            className={`${
                                message.sent ? "ms-auto" : ""
                            } d-flex ${sameSender ? "" : "mt-5"}`}
                            key={idx}
                            ref={
                                idx === messages.length - 1 ? lastMessage : null
                            }
                        >
                            {sameSender ? (
                                <></>
                            ) : (
                                <>
                                    {message.sent ? (
                                        <></>
                                    ) : (
                                        <div className="mb-auto me-2 me-md-3 user-avatar">
                                            <Avatar
                                                src={
                                                    orderData?.doctor?.settings
                                                        .DOCTOR_AVATAR ||
                                                    "/images/samples/doctor-thumb.png"
                                                }
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                            <DialogBox
                                sent={message.sent}
                                sameSender={sameSender}
                            >
                                {isFile && fileData.valid ? (
                                    <>
                                        <p className="mb-0">
                                            You sent
                                            {fileData.type === "image"
                                                ? " an image"
                                                : " a document"}
                                        </p>
                                        {fileData.type === "image" ? (
                                            <a
                                                href={file}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img
                                                    src={file}
                                                    alt=""
                                                    className="img-fluid"
                                                />
                                            </a>
                                        ) : (
                                            <a
                                                href={file}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="btn btn-link p-0 text-white text-start text-decoration-underline btn-sm mt-2"
                                            >
                                                Click to open in a new tab
                                            </a>
                                        )}
                                    </>
                                ) : (
                                    <span>{message.data.text}</span>
                                )}
                            </DialogBox>
                            {sameSender ? (
                                <></>
                            ) : (
                                <>
                                    {message.sent ? (
                                        <div className="mb-auto ms-2 ms-md-3 user-avatar">
                                            <Avatar
                                                src={profileData?.avatar_url}
                                            />
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            )}
                        </div>
                    );
                })}
            </div>
            <div className="chat-footer">
                <Form
                    initialValues={{ message: "" }}
                    onSubmit={handleSubmitMessage}
                    enableReinitialize
                    className="d-flex"
                >
                    <TextField
                        name="message"
                        placeholder="Type a message"
                        autoComplete="no"
                        disabled={disabled}
                        className="p-3 bg-white rounded-0 rounded-start"
                    />
                    <Button
                        variant="primary py-2 px-3 border-0 rounded-0 rounded-end"
                        fullWidth={false}
                        type="submit"
                        disabled={disabled}
                    >
                        <img src="/images/vectors/message-icon.svg" alt="" />
                    </Button>
                </Form>
            </div>
        </div>
    );
}
