import React from "react";

import ServiceCard from "../../../components/serviceCard/ServiceCard";
import ContactCard from "../../../components/contactCard/ContactCard";

import {
    SUPPORT_EMAIL,
    SUPPORT_TELEPHONE,
    SUPPORT_WHATSAPP,
} from "../../../constants/appProperties";

import { GPS_URL, KANABO_STORE_URL } from "../../../constants";

export default function EligibilityExitPageLayout({ title, children }) {
    return (
        <div className="eligibility-exit-page-layout px-3 m-auto">
            <div className="d-flex flex-column">
                <h2 className="text-primary fw-bold mb-5 text-center">
                    {title}
                </h2>
                {children}
                <div className="row px-0 mt-6">
                    <div className="col-12 col-sm-6 mb-5">
                        <ServiceCard
                            title="Online GP Service"
                            body="Access medical care and advice from home through our virtual doctor platform."
                            logo="gpservice-logo"
                            buttonText="Go to The GP Service"
                            link={GPS_URL}
                        />
                    </div>
                    <div className="col-12 col-sm-6 mb-5">
                        <ServiceCard
                            title="Kanabo Store CBD"
                            body="Shop our selection of non-prescription high concentrate CBD wellness products."
                            logo="kanabo-logo"
                            buttonText="Visit Kanabo Store"
                            link={KANABO_STORE_URL}
                        />
                    </div>
                </div>
                <div className="d-flex flex-column shadow-sm justify-content-center mx-auto mb-10 px-3 px-sm-5 pt-6 pb-3 card border-0 rounded-1 w-100 bg-white bg-opacity-50">
                    <h6 className="text-primary text-center fw-bold mb-5">
                        Contact Treat it Support:
                    </h6>
                    <div className="row">
                        <div className="col-12 col-md-6 mb-3">
                            <ContactCard
                                icon="phone-primary"
                                text="Call us:"
                                type="tel"
                                contact={SUPPORT_TELEPHONE}
                            />
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                            <ContactCard
                                icon="whatsapp"
                                text="WhatsApp us:"
                                contact={SUPPORT_WHATSAPP}
                                contactText="0738 582 8801"
                            />
                        </div>
                        <div className="col-12 mb-3">
                            <ContactCard
                                icon="mail"
                                text="Email us:"
                                type="mailto"
                                contact={SUPPORT_EMAIL}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
