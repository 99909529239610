import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import { useSubscribeToMailMutation } from "../../../services/dataService";
import parseError from "../../../utils/errorUtils";
import GoBack from "../../../components/GoBack";

import EligibilityExitPageLayout from "./EligibilityExitPageLayout";
import { Form, SubmitButton, TextField } from "../../../components/forms";

export default function EligibilityLocationExitPage() {
    const [subscribeToMail] = useSubscribeToMailMutation();

    const handleSubmit = async (values, { resetForm }) => {
        try {
            const eligibilityForm = JSON.parse(
                localStorage.getItem("EligibilityForm") || "{}"
            );

            const data = { email: values.email, body: eligibilityForm.name };

            const res = await subscribeToMail(data);

            if (res.error) {
                // handle error
                toast.error(parseError(res.error, "Couldn't subscribe"));
            } else {
                // request succeeded
                resetForm();
                toast.success("Successfully subscribed");
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <div className="mb-5 eligibility-diagnosis-back">
                <Link to={-1}>
                    <GoBack text="Back"/>
                </Link>
            </div>
            <EligibilityExitPageLayout title="We're thrilled to announce that we're expanding our services to serve new regions.">
                <p className="fs-5 subtitle text-primary-200 text-center mb-5 mx-2 mx-lg-10 px-lg-10">
                    While we don't currently provide our specialised care in your
                    area, we cant wait to start serving you. Keep an eye out for
                    updates and announcements as we prepare to launch our services
                    in your region.
                </p>
                <div className="d-flex justify-content-center my-5">
                    <img
                        className="my-auto"
                        src="/images/vectors/send-mail.svg"
                        alt=""
                        width={35}
                    />
                    <h4 className="ms-5 fs-4 text-primary fw-semibold my-auto">
                        Join Our Mailing List
                    </h4>
                </div>
                <div className="text-center m-auto fs-5 text-primary-200">
                    <p>
                        Be among the first to receive information about our
                        expansion. Join our waiting list, and we'll keep you
                        informed about our launch date, special offers, and more.
                    </p>
                    <div className="px-0 px-sm-8 px-md-10 d-flex flex-column mx-auto contact-item-container mt-6 mb-5">
                        <Form initialValues={{ email: "" }} onSubmit={handleSubmit}>
                            <div className="mb-6">
                                <TextField
                                    name="email"
                                    placeholder="Type your email"
                                />
                            </div>
                            <SubmitButton variant="primary px-12" fullWidth={false}>
                                <img
                                    className="me-2 my-auto"
                                    src="/images/vectors/arrow-right.svg"
                                    alt=""
                                />
                                Join the waitlist
                            </SubmitButton>
                        </Form>
                    </div>
                </div>
            </EligibilityExitPageLayout>
        </div>
    );
}
