/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import OT from "@opentok/client";

let opentokSession = null;
let publisher = null;
let subscriber = null;

export default function useVideoStream({
    sessionId,
    sessionToken,
    onSignalAccept,
    onSignalComplete,
    onSignalRequestQuestionnaire,
}) {
    const API_KEY = "46057882"; // process.env.REACT_APP_TOKBOX_API_KEY;
    const SESSION_ID = sessionId;
    const SESSION_TOKEN = sessionToken;

    const [isVideoPublished, setIsVideoPublished] = useState(true);
    const [isAudioPublished, setIsAudioPublished] = useState(true);
    const [isAudioSubscribed, setIsAudioSubscribed] = useState(true);
    const [isConnected, setIsConnected] = useState(false);
    const [isPublished, setIsPublished] = useState(false);
    const [isCallFinished, setIsCallFinished] = useState(false);
    const [messages, setMessages] = useState([]);

    const handleConnectToSession = () => {
        publisher = OT.initPublisher("publisher", {
            insertMode: "append",
            width: "25%",
            height: "25%",
            showControls: false,
            publishAudio: isAudioPublished,
            publishVideo: isVideoPublished,
        });

        opentokSession.publish(publisher, () => {
            setIsPublished(true);
            // startStopTimer();
        });
    };

    const handleUpdatePublisher = (type, action) => {
        if (type === "mic") {
            publisher.publishAudio(action);
            setIsAudioPublished(action);
        } else {
            publisher.publishVideo(action);
            setIsVideoPublished(action);
        }
    };

    const handleUpdateSubscriber = (action) => {
        setIsAudioSubscribed(action);
        subscriber.subscribeToAudio(action);
    };

    const handleSendMessage = (message, submissionData = {}) => {
        opentokSession.signal(
            {
                type: "msg",
                data: { text: message, ...submissionData },
            },
            (error) => {
                if (error) {
                    console.error("Sending message failed", "Send Message");
                }
            }
        );
    };

    const handleDisconnectSession = async () => {
        if (publisher) {
            await opentokSession.unpublish(publisher);
            setIsPublished(false);
        }
    };

    const handleReceiveMessages = (event, messageData) => {
        if (messageData?.userType.toLowerCase() === "patient") {
            const messageData = {
                data: event.data,
                sent: true,
                creationTime: event.from.creationTime,
            };

            setMessages((prevState) => [...prevState, messageData]);
        } else {
            if (
                typeof event.data === "string" ||
                event.data instanceof String
            ) {
                event.data = JSON.parse(event.data || "null");
            }
            const messageData = {
                data: event.data,
                sent: false,
                creationTime: event.from.creationTime,
            };

            setMessages((prevState) => [...prevState, messageData]);
        }
    };

    useEffect(() => {
        opentokSession = OT.initSession(API_KEY, SESSION_ID);
        opentokSession
            .on("connectionCreated", () => {
                setIsConnected(true);
            })
            .on("connectionDestroyed", () => {
                setIsConnected(false);
            })
            .on("streamCreated", (event) => {
                subscriber = opentokSession.subscribe(
                    event.stream,
                    "subscriber",
                    {
                        insertMode: "append",
                        width: "100%",
                        height: "100%",
                        showControls: false,
                    }
                );
            })
            .on("signal:msg", (event) => {
                const data = JSON.parse(event.from.data || "null");
                handleReceiveMessages(event, data);
            })
            .on("signal:accept", (event) => {
                onSignalAccept(event);
            })
            .on("signal:complete", (event) => {
                onSignalComplete(event);
            })
            .on("signal:requestQuestionnaire", (event) => {
                onSignalRequestQuestionnaire(event);
            });

        opentokSession.connect(SESSION_TOKEN, (error) => {
            if (error) {
                console.debug(
                    "There was an error connecting to the session: ",
                    error.code,
                    error.message
                );
            }
        });

        return () => {
            setIsPublished(false);
            handleDisconnectSession();
            OT.sessions.destroy();
        };
    }, []);

    return {
        handleConnectToSession,
        handleDisconnectSession,
        handleUpdatePublisher,
        handleUpdateSubscriber,
        handleSendMessage,
        isConnected,
        isPublished,
        setIsCallFinished,
        isCallFinished,
        messages,
        isVideoPublished,
        isAudioPublished,
        isAudioSubscribed,
    };
}
