/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Outlet, useLocation, matchPath, useNavigate } from "react-router-dom";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

import { useAuth } from "../utils/authUtils";
import { useLoggerUtils } from "../utils/loggerUtils";
import { useGetMyProfileQuery } from "../services/userService";

import { Navbar } from "./navbar";
import { BottomNavigation } from "./bottomNavigation";

import "./app-layout.scss";

const fullWidthRoutes = [
    "/user/*",
    "/order/*",
    "/appointment/*",
    "/consultation/*",
];

export default function AppLayout() {
    const location = useLocation();
    const navigate = useNavigate();
    const auth = useAuth();
    const { info } = useLoggerUtils();

    const { data: profileData } = useGetMyProfileQuery(
        {},
        { skip: !auth.isLogged, refetchOnMountOrArgChange: true }
    );

    if (auth.isLogged && profileData) {
        datadogRum.setUser({
            id: profileData?.id,
            email: profileData?.email,
            name: profileData?.displayName,
        });
        info("User added to RUM!");

        datadogLogs.setUser({
            id: profileData?.id,
            email: profileData?.email,
            name: profileData?.displayName,
        });
        info("User added to logger!");
    }

    if (location.pathname === "/") {
        navigate("/user/dashboard", { replace: true });
    }

    const isMatch = fullWidthRoutes.some((path) =>
        matchPath(path, location.pathname)
    );

    useEffect(() => {
        // if user only completed eligibility flow and just got registered but left before entering basic info
        if (profileData?.regStatus === "BASIC") {
            navigate("/onboarding/appointment/user-details");
        }

    }, [auth.isLogged, profileData]);

    return (
        <>
            <Navbar profileData={profileData} />
            <div className="pt-12">
                <div className="d-flex flex-wrap">
                    <main
                        className={`app-layout w-100 position-relative ${
                            isMatch ? "" : "px-4 px-lg-6 px-xl-10 pb-11 pb-sm-0"
                        } `}
                    >
                        <Outlet />
                    </main>
                </div>
            </div>
            <BottomNavigation />
        </>
    );
}
