import { useContext } from 'react';
import { OrderContext } from '../providers/OrderProvider';

function useInvoice(type, phase) {
    const { invoice } =
    useContext(OrderContext);
  
  const matchingInvoice = invoice?.find(item => item.type === type && item.phase === phase);
  
  return matchingInvoice || null;
}

export default useInvoice;
