import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./baseQueries";

export const paymentAPI = createApi({
  reducerPath: "paymentAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getBillingInfo: build.query({
      query: () => ({
        url: `/api/public/billing/`,
      }),
    }),
    getPaymentIntent: build.query({
      query: () => ({
        url: `/api/patient/order/setup-intent/`,
      }),
    }),
    addMedicationPayment: build.mutation({
      query: ({ values }) => ({
        url: `/api/patient/order-item/prescription/`,
        method: "PUT",
        body: { ...values },
      }),
    }),
  }),
});

export const { 
  useGetBillingInfoQuery, 
  useLazyGetPaymentIntentQuery, 
  useAddMedicationPaymentMutation 
} = paymentAPI;
