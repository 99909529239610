/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import QuestionContainer from "../../../components/questionContainer/QuestionContainer";
import EligibilityForm from "../../../components/eligibilityForm/EligibilityForm";
import EligibilityFormElements from "./EligibilityFormElements";

import { ONBOARDING_ELIGIBILITY_QUESTIONS } from "../../../constants/onboardingEligibilityQuestions";

export default function EligibilityQuestionPage({ questionId }) {
    const navigate = useNavigate();
    const [percentage, setPercentage] = useState(0);

    const currentQuestion = ONBOARDING_ELIGIBILITY_QUESTIONS.data[questionId];
    const savedValues = JSON.parse(
        localStorage.getItem("EligibilityForm") || "{}"
    );
    const prevQuestionAnswer = savedValues[`q${currentQuestion.index - 1}`];

    let currentPage = currentQuestion.index;

    let totalquestion = 9;
    const calculateProgress = () => {
        return (currentPage / (totalquestion - 1)) * 100;
    }

    const handleSubmit = (values) => {
        let formattedData = {
            ...savedValues,
            ...values,
        };
        let nextPage = "";

        if (currentPage < totalquestion) {
            if(currentPage < 7){
                currentPage++;
                localStorage.setItem("activePage", currentPage);
            }
        }
        let currState = calculateProgress();
        setPercentage(currState);

        if (currentQuestion.conditionalPath[values[questionId]]) {
            const path = currentQuestion.conditionalPath[values[questionId]];
            // only paths without `exit` will save the form data without any changes in the local storage
            // if path has `exit` in it, current question answer will be deleted. user comes back to a fresh page
            // in this approach, if user tries to go deliberately to another page ahead in the flow, it will land exactly correct page where user has to be
            // TODO: need to think of a way how to handle the following scenario(this is a known edge-case and highly unlikely to happen):
                    // if the user went through the flow and came back a few steps and went to an `exit`.
                    // the the current answer data is deleted for this question but answers for the questions ahead are still there.
                    // in that case if the user try to go to a step ahead of the flow, user will land on the latest question(as it supposed to) but without answering the previous `exit` page question.
            if (path.includes("exit")) {
                delete formattedData[questionId];
            }

            nextPage = `/onboarding/${path}`;
        } else {
            nextPage = `/onboarding/${currentQuestion.paths.next}`;
        }

        const data = JSON.stringify({ ...formattedData });
        localStorage.setItem("EligibilityForm", data);

        let url = window.location.protocol + "//" +  window.location.host + nextPage;
        window.history.pushState(null, null, url);
        navigate(nextPage, {replace: true});
        window.scrollTo(0,0);
    };

    useEffect(() => {
        let nextPage = "";
        setPercentage(calculateProgress());

        const updatePageWithProgress = (event) => {
            currentPage = localStorage.getItem("activePage") ? localStorage.getItem("activePage") : 1;
            if (currentPage > 1) {
                currentPage--;
                setPercentage(calculateProgress());
                localStorage.setItem("activePage", currentPage);
            }
        }

        // // Attach the event listener when the component mounts
        window.addEventListener('popstate', updatePageWithProgress);
        
        // if name is not filled and user deliberately trying to go to a page ahead of the flow
        if (currentQuestion.index > 0 && !savedValues?.name) {
            nextPage = `/onboarding/eligibility/age`;
            // if prev question is not answered and user deliberately trying to go to a page ahead of the flow
        } else if (currentQuestion.index > 1 && !prevQuestionAnswer) {
            const eligibilityQuestionKeys = ONBOARDING_ELIGIBILITY_QUESTIONS.keys;
            let latestAnsweredQuestionId = [...Object.keys(savedValues)].sort().pop();
            latestAnsweredQuestionId = latestAnsweredQuestionId == 'phone' ? 'name' : latestAnsweredQuestionId;
            const currentQuestionId = eligibilityQuestionKeys[eligibilityQuestionKeys.indexOf(latestAnsweredQuestionId) + 1];
            nextPage = `/onboarding/${ONBOARDING_ELIGIBILITY_QUESTIONS.data[currentQuestionId].paths.current}`;
        }

        if(nextPage !== ""){
            navigate(nextPage);
        }

        // Detach the event listener when the component unmounts
        return () => {
            window.removeEventListener('popstate', updatePageWithProgress);
        };
    }, []);

    return (
        <QuestionContainer
            index={currentQuestion.index}
            title={currentQuestion.title}
            subTitle={currentQuestion.subTitle}
            hint={currentQuestion.hint}
            helpText={currentQuestion.helpText}
            helpModal={currentQuestion.helpModal}
            helpModalBody={currentQuestion.helpModalBody}
            initValues={savedValues || { [currentQuestion.id]: "" }}
            validation={currentQuestion.validation}
            prevQuestionAnswer={prevQuestionAnswer}
            onSubmit={handleSubmit}
            percentage={percentage}
        >
            <EligibilityForm
                condition={
                    currentQuestion.conditionType.includes("info")
                        ? currentQuestion.condition
                        : []
                }
                field={
                    currentQuestion.conditionType.includes("info")
                        ? currentQuestion.id
                        : ""
                }
                infoText={
                    currentQuestion.conditionType.includes("info")
                        ? currentQuestion.infoText
                        : ""
                }
            >
                <EligibilityFormElements
                    answers={currentQuestion.answers}
                    condition={
                        currentQuestion.conditionType.includes("field")
                            ? currentQuestion.condition
                            : []
                    }
                    field={
                        currentQuestion.conditionType.includes("field")
                            ? currentQuestion.id
                            : ""
                    }
                    conditionalField={
                        currentQuestion.conditionType.includes("field")
                            ? currentQuestion.conditionalField
                            : {}
                    }
                    name={currentQuestion.id}
                />
            </EligibilityForm>
        </QuestionContainer>
    );
}
