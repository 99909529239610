import React from "react";
import { Outlet } from "react-router-dom";

import { OnboardingNavbar } from "./navbar";

import "./onboarding-layout.scss";

export default function OnboardingLayout({ className }) {
    return (
        <div className={`onboarding-layout ${className}`} style={{ overflow: 'hidden' }}>
            <OnboardingNavbar />
            <main className="main-container mx-auto px-0 px-md-4 px-lg-6 pt-4 pt-lg-8 pb-11 pb-sm-8 d-flex flex-column position-relative">
                <Outlet />
            </main>
        </div>
    );
}
