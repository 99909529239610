import React from "react";
import { useFormikContext } from "formik";

import { Checkbox, Radio, YesNoRadio } from "../forms";

import {
    DOCTOR_NOTES_QUESTIONS,
    questionStructure,
} from "../../constants/orderNotesQuestions";

export default function DoctorNotesQuestionAnswers({ viewOnly = false }) {
    const { values } = useFormikContext();

    const valuesArray = values ? Object.keys(values) : [];
    const questionsLength = valuesArray.length;

    const questionAnswersToDisplay = DOCTOR_NOTES_QUESTIONS.keys.filter(
        (key) => DOCTOR_NOTES_QUESTIONS.data[key].active
    );

    return (
        <>
            {questionAnswersToDisplay.map((key, index) => {
                if (DOCTOR_NOTES_QUESTIONS.data[key].active) {
                    if (
                        DOCTOR_NOTES_QUESTIONS.data[key].type ===
                        questionStructure.text
                    ) {
                        return (
                            <tr key={key}>
                                <td
                                    className={`py-5 ps-5 ${
                                        index === questionsLength - 1 &&
                                        "border-0"
                                    }`}
                                >
                                    <span className="me-2 text-medium-gray fw-bold">
                                        {`${index + 1 < 10 ? "0" : ""}${
                                            index + 1
                                        }.`}
                                    </span>
                                    <span>
                                        {DOCTOR_NOTES_QUESTIONS.data[key]
                                            .label ||
                                            "No question preview available"}
                                    </span>
                                </td>
                                <td
                                    className={`py-5 pe-5 text-break ${
                                        index === questionsLength - 1 &&
                                        "border-0"
                                    }`}
                                    title={values[key]?.text}
                                >
                                    {values && values[key]
                                        ? values[key]?.text
                                        : "No answer preview available"}
                                </td>
                            </tr>
                        );
                    }

                    if (
                        DOCTOR_NOTES_QUESTIONS.data[key].type ===
                        questionStructure.choice
                    ) {
                        return (
                            <tr key={key}>
                                <td
                                    className={`py-5 ps-5 ${
                                        index === questionsLength - 1 &&
                                        "border-0"
                                    }`}
                                >
                                    <span className="me-2 text-medium-gray fw-bold">
                                        {`${index + 1 < 10 ? "0" : ""}${
                                            index + 1
                                        }.`}
                                    </span>
                                    <span>
                                        {DOCTOR_NOTES_QUESTIONS.data[key]
                                            .label ||
                                            "No question preview available"}
                                    </span>
                                </td>
                                <td
                                    className={`py-5 pe-5 text-break ${
                                        index === questionsLength - 1 &&
                                        "border-0"
                                    }`}
                                >
                                    <YesNoRadio
                                        name={`${key}.choice`}
                                        disabled={viewOnly}
                                    />
                                </td>
                            </tr>
                        );
                    }

                    if (
                        DOCTOR_NOTES_QUESTIONS.data[key].type ===
                        questionStructure.yesNoChoiceAndText
                    ) {
                        return (
                            <tr key={key}>
                                <td
                                    className={`py-5 ps-5 ${
                                        index === questionsLength - 1 &&
                                        "border-0"
                                    }`}
                                >
                                    <span className="me-2 text-medium-gray fw-bold">
                                        {`${index + 1 < 10 ? "0" : ""}${
                                            index + 1
                                        }.`}
                                    </span>
                                    <span>
                                        {DOCTOR_NOTES_QUESTIONS.data[key]
                                            .label ||
                                            "No question preview available"}
                                    </span>
                                </td>
                                <td
                                    className={`py-5 pe-5 text-break ${
                                        index === questionsLength - 1 &&
                                        "border-0"
                                    }`}
                                >
                                    {values[key]?.choice !== undefined && (
                                        <div
                                            className={
                                                values[key]?.choice !==
                                                undefined
                                                    ? "mb-2"
                                                    : ""
                                            }
                                        >
                                            <YesNoRadio
                                                name={`${key}.choice`}
                                                disabled={viewOnly}
                                            />
                                        </div>
                                    )}
                                    <b
                                        title={`Note: ${values[key]?.text}`}
                                        className="text-break"
                                    >
                                        {values[key]?.text
                                            ? `Note: ${values[key]?.text}`
                                            : null}
                                    </b>
                                </td>
                            </tr>
                        );
                    }

                    if (
                        DOCTOR_NOTES_QUESTIONS.data[key].type ===
                        questionStructure.multiChoiceAndText
                    ) {
                        return (
                            <tr key={key}>
                                <td
                                    className={`py-5 ps-5 ${
                                        index === questionsLength - 1 &&
                                        "border-0"
                                    }`}
                                >
                                    <span className="me-2 text-medium-gray fw-bold">
                                        {`${index + 1 < 10 ? "0" : ""}${
                                            index + 1
                                        }.`}
                                    </span>
                                    <span>
                                        {DOCTOR_NOTES_QUESTIONS.data[key]
                                            .label ||
                                            "No question preview available"}
                                    </span>
                                </td>
                                <td
                                    className={`py-5 pe-5 text-break ${
                                        index === questionsLength - 1 &&
                                        "border-0"
                                    }`}
                                >
                                    <div className="mb-2">
                                        {DOCTOR_NOTES_QUESTIONS.data[
                                            key
                                        ].values.map((data, idx) => (
                                            <div
                                                className="me-0 me-lg-3 mb-2 mb-lg-0"
                                                key={idx}
                                            >
                                                <Radio
                                                    name={key}
                                                    id={data.value}
                                                    label={data.label}
                                                    disabled={viewOnly}
                                                    labelClass="text-break"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <b
                                        title={`Note: ${values[key]?.text}`}
                                        className="text-break"
                                    >
                                        {values[key]?.text
                                            ? `Note: ${values[key]?.text}`
                                            : null}
                                    </b>
                                </td>
                            </tr>
                        );
                    }

                    if (
                        DOCTOR_NOTES_QUESTIONS.data[key].type ===
                        questionStructure.checkbox
                    ) {
                        return (
                            <tr key={key}>
                                <td
                                    className={`py-5 ps-5 ${
                                        index === questionsLength - 1 &&
                                        "border-0"
                                    }`}
                                >
                                    <span className="me-2 text-medium-gray fw-bold">
                                        {`${index + 1 < 10 ? "0" : ""}${
                                            index + 1
                                        }.`}
                                    </span>
                                    <span>
                                        {DOCTOR_NOTES_QUESTIONS.data[key]
                                            .label ||
                                            "No question preview available"}
                                    </span>
                                </td>
                                <td
                                    className={`py-5 pe-5 text-break ${
                                        index === questionsLength - 1 &&
                                        "border-0"
                                    }`}
                                >
                                    {DOCTOR_NOTES_QUESTIONS.data[
                                        key
                                    ].values.map((data, idx) => (
                                        <div
                                            className="me-0 me-lg-3 mb-2 mb-lg-0"
                                            key={idx}
                                        >
                                            <Checkbox
                                                name={`${key}.choices[${data.value}]`}
                                                label={data.label}
                                                disabled={viewOnly}
                                                labelClass="text-break"
                                            />
                                        </div>
                                    ))}
                                </td>
                            </tr>
                        );
                    }

                    if (
                        DOCTOR_NOTES_QUESTIONS.data[key].type ===
                        questionStructure.checkboxAndText
                    ) {
                        if (
                            DOCTOR_NOTES_QUESTIONS.data[key].renderCondition
                                ?.decisionValue ===
                            values[
                                DOCTOR_NOTES_QUESTIONS.data[key].renderCondition
                                    ?.parentId
                            ]?.choice
                        ) {
                            return (
                                <tr key={key}>
                                    <td
                                        className={`py-5 ps-5 ${
                                            index === questionsLength - 1 &&
                                            "border-0"
                                        }`}
                                    >
                                        <span className="me-2 text-medium-gray fw-bold">
                                            {`${index + 1 < 10 ? "0" : ""}${
                                                index + 1
                                            }.`}
                                        </span>
                                        <span>
                                            {DOCTOR_NOTES_QUESTIONS.data[key]
                                                .label ||
                                                "No question preview available"}
                                        </span>
                                    </td>
                                    <td
                                        className={`py-5 pe-5 text-break ${
                                            index === questionsLength - 1 &&
                                            "border-0"
                                        }`}
                                    >
                                        {DOCTOR_NOTES_QUESTIONS.data[
                                            key
                                        ].values.map((data, idx) => (
                                            <div key={idx}>
                                                <div
                                                    className="me-0 me-lg-3 mb-2 mb-lg-0"
                                                    key={idx}
                                                >
                                                    <Checkbox
                                                        name={`${key}.choices[${data.value}]`}
                                                        label={data.label}
                                                        disabled={viewOnly}
                                                        labelClass="text-break"
                                                    />
                                                </div>
                                                <b
                                                    title={`Note: ${
                                                        values[key]?.choices[
                                                            data.value
                                                        ]?.text
                                                    }`}
                                                    className="text-break"
                                                >
                                                    {values[key]?.choices[
                                                        data.value
                                                    ]?.text
                                                        ? `Note: ${
                                                              values[key]
                                                                  ?.choices[
                                                                  data.value
                                                              ]?.text
                                                          }`
                                                        : null}
                                                </b>
                                            </div>
                                        ))}
                                    </td>
                                </tr>
                            );
                        } else {
                            return null;
                        }
                    }

                    if (
                        DOCTOR_NOTES_QUESTIONS.data[key].type ===
                        questionStructure.checkboxAndTextWithoutLabel
                    ) {
                        return DOCTOR_NOTES_QUESTIONS.data[key].values.map(
                            (data) => (
                                <tr key={key}>
                                    <td
                                        className={`py-5 ps-5 ${
                                            index === questionsLength - 1 &&
                                            "border-0"
                                        }`}
                                    >
                                        <span className="me-2 text-medium-gray fw-bold">
                                            {`${index + 1 < 10 ? "0" : ""}${
                                                index + 1
                                            }.`}
                                        </span>
                                        <span>
                                            {data.label ||
                                                "No question preview available"}
                                        </span>
                                    </td>
                                    <td
                                        className={`py-5 pe-5 text-break ${
                                            index === questionsLength - 1 &&
                                            "border-0"
                                        }`}
                                    >
                                        <div className="d-flex">
                                            <Checkbox
                                                name={`${key}.choices[${data.value}]`}
                                                disabled={viewOnly}
                                                labelClass="text-break"
                                            />
                                            {values[key]?.choices[data.value]
                                                ? "Advised"
                                                : "Not advised"}
                                        </div>
                                        <b
                                            title={`Note: ${
                                                values[key]?.choices[data.value]
                                                    ?.text
                                            }`}
                                            className="text-break"
                                        >
                                            {values[key]?.choices[data.value]
                                                ?.text
                                                ? `Note: ${
                                                      values[key]?.choices[
                                                          data.value
                                                      ]?.text
                                                  }`
                                                : null}
                                        </b>
                                    </td>
                                </tr>
                            )
                        );
                    }

                    return (
                        <tr key={key}>
                            <td
                                className={`py-5 ps-5 ${
                                    index === questionsLength - 1 && "border-0"
                                }`}
                            >
                                <span className="me-2 text-medium-gray fw-bold">
                                    {`${index + 1 < 10 ? "0" : ""}${
                                        index + 1
                                    }.`}
                                </span>
                                <span>
                                    {DOCTOR_NOTES_QUESTIONS.data[key].label ||
                                        "No question preview available"}
                                </span>
                            </td>
                            <td
                                className={`py-5 pe-5 text-break ${
                                    index === questionsLength - 1 && "border-0"
                                }`}
                                title={
                                    values[key]?.text ||
                                    "No answer preview available"
                                }
                            >
                                {values[key]?.text ||
                                    "No answer preview available"}
                            </td>
                        </tr>
                    );
                }

                return null;
            })}
        </>
    );
}
