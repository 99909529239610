import Price from "../price/Price";

export default function PrescriptionSummary({ prescription }) {
    return (
        <div>
            {prescription?.medication.map((medication, index) => (
                <div>
                    <div className="d-flex mb-3 align-items-center justify-content-between text-medium-gray fs-6">
                        <div>{medication.formulary.productName}</div>
                        <div className="fw-bold">
                            <Price amount={medication.charge} currency="GBP" />
                        </div>
                    </div>
                    <ul className="ps-5 text-medium-gray">
                        {medication.formulary.medicineProfile && (
                            <li className="mb-3">
                                Profile - {medication.formulary.medicineProfile}
                            </li>
                        )}
                        {medication.formulary.category && (
                            <li className="mb-3">
                                Category - {medication.formulary.category}
                            </li>
                        )}
                        {medication.formulary.originalStrain && (
                            <li className="mb-3">
                                Strain - {medication.formulary.originalStrain}
                            </li>
                        )}
                        {medication.formulary.brandName && (
                            <li className="mb-3">
                                Brand - {medication.formulary.brandName}
                            </li>
                        )}
                        {medication.formulary.manufacture && (
                            <li className="mb-3">
                                Manufacturer -{" "}
                                {medication.formulary.manufacture}
                            </li>
                        )}
                        {medication.formulary.medicineProfile && (
                            <li>
                                Profile - {medication.formulary.medicineProfile}
                            </li>
                        )}
                    </ul>
                    {index !== prescription.medication.length - 1 && <hr />}
                </div>
            ))}
        </div>
    );
}
