import React, { useMemo } from "react";
import { useField, useFormikContext } from "formik";

import DropDown from "../dropDown/DropDown";

/**
 * Search enabled dropdown (Autocomplete). Integrated with Formik
 * @param {{
 * name: string,
 * label: string,
 * options: { value: string, label: string }[],
 * placeholder: string,
 * onChange: function,
 * multiSelect: boolean,
 * disabled?: boolean,
 * }} props
 * @returns {React.FunctionComponent}
 *
 */
export default function SearchableSelect(props) {
    const {
        name,
        label,
        options = [],
        placeholder,
        onChange,
        onSelect,
        multiSelect = true,
        fullWidth = false,
        showOnMouseEnter = false,
        disabled,
        ...rest
    } = props;
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();

    const handleAddOption = (value) => {
        setFieldValue(name, value);
        if (onSelect) {
            onSelect(value);
        }
    };

    const handleOnChange = (value) => {
        if (onChange) {
            onChange(value);
        }

        if (!value || value === "") {
            setFieldValue(name, "");
        }
    };

    const displayingOptions = useMemo(() => {
        if (multiSelect && field.value?.length > 0) {
            return options.filter((optn) => !field.value.includes(optn.value));
        }

        return options;
    }, [options, field.value, multiSelect]);

    return (
        <div className="searchable-select w-100 h-100">
            {label && (
                <label className="form-label fs-5 fw-bold" htmlFor={name}>
                    {label}
                </label>
            )}
            <DropDown
                {...rest}
                onBlur={field.onBlur}
                name={field.name}
                selectedValues={field.value}
                onSelect={handleAddOption}
                onChange={handleOnChange}
                options={displayingOptions}
                placeholder={placeholder}
                multiSelect={multiSelect}
                fullWidth={fullWidth}
                showOnMouseEnter={showOnMouseEnter}
                disabled={disabled}
            />

            {meta.touched && meta.error ? (
                <div
                    className="invalid-feedback mb-0 mt-0 w-fit"
                    style={{ display: "block" }}
                >
                    {meta.error}
                </div>
            ) : null}
        </div>
    );
}
