import React from "react";
import { Link } from "react-router-dom";

import { GPS_URL } from "../../../constants";
import GoBack from "../../../components/GoBack";

export default function EligibilityAgeExitPage() {
    return (
        <div className="age-exit-page px-3 m-auto">
            <div className="d-flex flex-column">
                <Link to={-1} className="ageExit">
                    <GoBack text="Back"/>
                </Link>
                <h2 className="text-primary fw-bold mb-6 text-center">
                    Sorry, we are unable to proceed further
                </h2>
                <p className="fs-5 subtitle text-primary-200 text-center mb-10">
                    If you are under 18 and experiencing pain, we understand the
                    importance of seeking medical support. Unfortunately we
                    cannot provide consultations to those under 18.
                </p>
            </div>
        </div>
    );
}
