import React from "react";
import { useNavigate } from "react-router-dom";
import "./GoBack.scss";

function GoBack({ text, center, onClickBack }) {
    const navigate = useNavigate();

    const handleOnBack = () => {
        if (onClickBack) {
            onClickBack();
        } else {
            if(text !== "Back"){
                navigate(-1);
            }
        }
    };

    return (
        <div
            className={`p1 text-uppercase d-flex align-items-center ${center} fw-bold back`}
            type="button"
            onClick={handleOnBack}
        >
            <img
                src="/images/vectors/back-arrow.svg"
                alt="completed"
                className="me-2"
            />
            {text || "Go Back"}
        </div>
    );
}

export default GoBack;
