import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { authAPI } from "./services/authService";
import { userAPI } from "./services/userService";
import { dataAPI } from "./services/dataService";
import { paymentAPI } from "./services/paymentService";
import { orderAPI } from "./services/orderService";

const combinedReducer = combineReducers({
  [authAPI.reducerPath]: authAPI.reducer,
  [userAPI.reducerPath]: userAPI.reducer,
  [dataAPI.reducerPath]: dataAPI.reducer,
  [paymentAPI.reducerPath]: paymentAPI.reducer,
  [orderAPI.reducerPath]: orderAPI.reducer,
  // ... add your reducers here
});

// we structure store like this so that we can easily reset the store on logout.
const rootReducer = (state, action) => {
  if (action.type === "auth/logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  //add rtkq middleware to below aray
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authAPI.middleware)
      .concat(userAPI.middleware)
      .concat(dataAPI.middleware)
      .concat(paymentAPI.middleware)
      .concat(orderAPI.middleware),
  devTools: true,
});
