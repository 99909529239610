import React from "react";
import { useField } from "formik";

/**
 * Checkbox integrated with Formik.
 * @param {{
 * name: string,
 * label:string
 * }} props
 * @returns {React.FunctionComponent}
 */
export default function Checkbox({
    name,
    label,
    tooltip,
    subtitle,
    disabled,
    className,
    labelClass,
    subtitleClass,
    ...rest
}) {
    const [field, meta, helpers] = useField({ name, label, ...rest });

    return (
        <>
            <div className={`form-check ${className}`}>
                <input
                    type="checkbox"
                    className="form-check-input my-auto"
                    id={name}
                    checked={field.value || false}
                    value={field.value || false}
                    onChange={() => {
                        helpers.setTouched(true);
                        helpers.setValue(!field.value);
                    }}
                    disabled={disabled}
                />
                <label
                    className={`form-check-label fw-light my-auto ${labelClass}`}
                    htmlFor={name}
                    title={typeof label === "object" ? (tooltip !== undefined ? tooltip : '') : label}
                >
                    {label}
                </label>
            </div>
            {meta.touched && meta.error && (
                <div className="row mx-0">
                    <div
                        className="invalid-feedback ms-5 mb-0 mt-0 w-fit"
                        style={{ display: "block" }}
                    >
                        {meta.error}
                    </div>
                </div>
            )}
            {subtitle && (
                <div className="row mx-0">
                    <label
                        className={`form-check-label fw-light ${subtitleClass}`}
                        title={subtitle}
                    >
                        {subtitle}
                    </label>
                </div>
            )}
        </>
    );
}
