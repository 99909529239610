import React, { useState} from "react"
import { useParams, useNavigate } from "react-router-dom";

import "./public.scss";
import Button from "../../components/button/Button";

export default function CannabisCardFormPage(props) {

    const navigate = useNavigate();
    const { token: token } = useParams();
    const [errorMsg, setErrorMsg] = useState('');

    function handleSubmit(e) {
        setErrorMsg('');
        e.preventDefault();
        const formData = new FormData(e.target);
        const formJson = Object.fromEntries(formData.entries());

        if (formJson.pin.length !== 4) {
            setErrorMsg('Please enter the 4-digit PIN.');
        } else {
            navigate("/cannabis-card", {state: {pin: formJson.pin, token: token, expire: true}});
        }
    }

    return (
        <div className="row card-outer">
            <div className="col-12">
                <div className="px-0 px-sm-5 mt-10 mb-5 text-center">
                    <h2 className="mt-3 fw-bold">Please enter the PIN</h2>
                </div>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="col-12 text-center mb-5">
                    <input name="pin" className="pin-input text-center" maxLength="4" type="number" />
                </div>

                <div className="col-12 text-center">
                    <Button
                        variant="primary py-2 px-3 border-0"
                        fullWidth={false}
                        type="submit"
                    >
                        Submit
                    </Button>
                </div>
            </form>

            { errorMsg.length !== 0 ? (
                <div className="col-12">
                    <div className="mt-5 text-center">
                        <p>{errorMsg}</p>
                    </div>
                </div>
            ):(null)}
        </div>
    );
}
