import React from "react";

import { useModal } from "../../../hooks/modalsHooks";

import { Form } from "../../forms";
import { AUDITForm } from "../../orderNoteForms";
import ModalLayout from "../ModalLayout";

export default function AUDITModal({ onSubmit }) {
    const { hideModal } = useModal();

    const handleSubmit = (values) => {
        const score =
            values.question1 +
            values.question2 +
            values.question3 +
            values.question4 +
            values.question5 +
            values.question6 +
            values.question7 +
            values.question8 +
            values.question9 +
            values.question10;

        const data = {
            type: "AUDIT_FORM",
            questionAnswer: JSON.stringify({ ...values, score }),
        };

        onSubmit(data);
    };

    return (
        <ModalLayout width="55rem">
            <ModalLayout.Header closeModal={hideModal}>
                <div className="w-100">
                    <h2 className="position-relative text-center fw-bold">
                        AUDIT form
                        <span className="underline center short" />
                    </h2>
                </div>
            </ModalLayout.Header>
            <Form
                initialValues={{
                    question1: null,
                    question2: null,
                    question3: null,
                    question4: null,
                    question5: null,
                    question6: null,
                    question7: null,
                    question8: null,
                    question9: null,
                    question10: null,
                }}
                onSubmit={handleSubmit}
                className="h-100"
            >
                <AUDITForm />
            </Form>
        </ModalLayout>
    );
}
