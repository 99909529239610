import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import { AppointmentContext } from "../../../providers/AppointmentProvider";
import { useGetDoctorQuery } from "../../../services/dataService";

import Button from "../../../components/button/Button";

export default function ConfirmDetailsPage() {
    const navigate = useNavigate();
    const { appointmentData } = useContext(AppointmentContext);

    const { data: doctorData } = useGetDoctorQuery(
        {
            doctorId: appointmentData.doctorId,
        },
        { skip: !appointmentData?.doctorId }
    );

    const handleNavigate = () => {
        navigate("/onboarding/appointment/checkout");
    };

    return (
        <div className="confirm-details-page px-3 m-auto pb-12 w-100">
            <div className="d-flex flex-column">
                <h2 className="text-primary fw-bold mb-6 text-center">
                    Consultation details
                </h2>
                <p className="fs-5 subtitle text-primary-200 text-center mb-8 mx-2 mx-lg-8">
                    Your Tailored Care Awaits.
                    <br />
                    Validate Your Consultation Details and Proceed to Payment.
                </p>
            </div>
            <div className="bg-white bg-opacity-25 py-6 px-4 mx-1 mx-md-2 mb-2 row">
                <div className="col-12 col-md-6 d-flex justify-content-start mb-4 mb-md-0">
                    <img src="/images/vectors/stethoscope.svg" alt="" />
                    <div className="d-flex flex-column justify-content-center ms-3">
                        <label className="mb-1 small">Clinician name</label>
                        <label className="text-start fs-6 fw-bold">
                            {doctorData?.displayName}
                        </label>
                    </div>
                </div>
                <div className="col-12 col-md-6 d-flex justify-content-start">
                    <img src="/images/vectors/calendar-bordered.svg" alt="" />
                    <div className="d-flex flex-column justify-content-center ms-3">
                        <label className="mb-1 small">
                            Consultation date & time
                        </label>
                        <label className="text-start fs-6 fw-bold">
                            {dayjs(appointmentData.datetime).format(
                                "Do MMMM, YYYY"
                            )}
                            &nbsp;|&nbsp;
                            {dayjs(appointmentData.datetime).format("hh:mma")}
                        </label>
                    </div>
                </div>
            </div>

            <div className="d-flex">
                <Button
                    fullWidth={false}
                    variant="primary mt-7 fs-5 mx-auto"
                    onClick={handleNavigate}
                >
                    <img
                        className="me-2 my-auto"
                        src="/images/vectors/arrow-right.svg"
                        alt=""
                    />
                    Confirm and Continue
                </Button>
            </div>
        </div>
    );
}
