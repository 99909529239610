import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import BasicTable from "../../../../components/tables/BasicTable";
import Price from "../../../../components/price/Price";
import DropDown from "../../../../components/dropDown/DropDown";
import Button from "../../../../components/button/Button";

import { ORDER_STATUS } from "../../../../constants/orderStatus";

export default function TreatmentMedications({
    prescription,
    deliveryType,
    orderId,
}) {
    const navigate = useNavigate();

    const viewInvoice = (type) => {
        if (type) {
            navigate(
                `/user/order/${orderId}/${type
                    .split(" ")[0]
                    .toLowerCase()}-invoice/view`
            );
        }
    };

    const medicationAmount = _.sum(
        prescription?.medication.map((med) => med?.charge || 0)
    );

    // delivery fee
    const deliveryFee = deliveryType
        ? deliveryType === "EXPRESS"
            ? 15
            : 0
        : 0;

    const tableData = useMemo(() => {
        if (prescription) {
            if (
                prescription.status ===
                ORDER_STATUS.AWAITING_PATIENT_SPECIALIST_PRESCRIPTION_CONFIRMATION
            ) {
                //  console.log(JSON.parse(prescription.changes || "null"));
            }

            const medication = prescription.medication.map((med) => ({
                ...med,
                formulary: {
                    ...med.formulary,
                    pharmacyName: prescription.pharmacy.displayName,
                },
            }));

            return medication;
        }

        return [];
    }, [prescription]);

    return (
        <div className="treatment-medication-section">
            <div className="d-flex">
                <div className="text-primary p1 fw-bold mt-5">
                    Your Treatment
                </div>
                <DropDown
                    hideIcon
                    dropDownOnly
                    showSearchIcon
                    searchable={false}
                    multiSelect={false}
                    containerClass="invoice-options fw-semibold mt-5 ms-auto p-0"
                    onSelect={viewInvoice}
                    options={[
                        {
                            label: "Consultation invoice",
                            value: "consultation",
                        },
                        {
                            label: "Prescription invoice",
                            value: "prescription",
                        },
                    ]}
                >
                    <Button variant="link fw-semibold p-0" fullWidth={false}>
                        View invoice
                        <img
                            src="/images/vectors/down-primary.svg"
                            alt=""
                            className="ms-auto my-auto"
                        />
                    </Button>
                </DropDown>
            </div>
            <div className="mt-4">
                <BasicTable
                    data={tableData}
                    columns={[
                        {
                            header: "Medication Name",
                            accessor: "formulary.productName",
                        },
                        {
                            header: "Pharmacy",
                            accessor: "formulary.pharmacyName",
                        },
                        {
                            header: "Price",
                            accessor: "charge",
                            Cell: ({ cell: { value } }) => (
                                <Price amount={value} currency="GBP" />
                            ),
                        },
                    ]}
                    isSortable={true}
                    isPaginated={false}
                    pageSize={1}
                    currentPage={0}
                    treatmentMedication={{
                        active: true,
                        info: prescription?.medication.map((data) => ({
                            medication: data.formulary.productName,
                            adminRoute: data.formulary.administrationRoute,
                            quantity: data.quantity,
                            medicineProfile: data.formulary.medicineProfile,
                            strain: data.formulary.strain,
                        })),
                    }}
                    orderCalculation={{
                        active: medicationAmount > 0,
                        medicationAmount,
                        deliveryAmount: deliveryFee,
                        deliveryType,
                        totalAmount: medicationAmount + Number(deliveryFee),
                        colCount: 2,
                    }}
                />
            </div>
        </div>
    );
}
