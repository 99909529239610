export const ACCESS_TOKEN_NAME = "AccessToken";
export const REFRESH_TOKEN_NAME = "RefreshToken";

export const TREAT_IT_URL = "https://treat-it.clinic/";
export const GPS_URL = "https://thegpservice.co.uk";
export const KANABO_STORE_URL = "https://kanabo.store/";

export const NHS_SUBSCRIPTION_KEY = "02768fc86b4b478795b5618b2e26ce77";

export const DEBOUNCE_DELAY = 300;

export const TIMER_TIMEOUT = 5000;
export const LOADING_TIMER_TIMEOUT = 6000;

export const APPOINTMENT_CONTEXT_NAME = "AppointmentData";
export const ORDER_CONTEXT_NAME = "OrderData";

export const TOAST_CONFIG = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
};
