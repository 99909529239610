import React from "react";
import Button from "../button/Button";

import "./side-drawer.scss";

const SideDrawer = ({ children, isOpen, setIsOpen, direction = "left" }) => {
    return (
        <div
            className={`side-drawer ${direction} ${isOpen ? "open" : "close"}`}
        >
            <Button
                variant={`secondary float-${
                    direction === "left" ? "end me-4" : "start ms-4"
                } py-2 px-3 mt-4 d-flex`}
                onClick={() => setIsOpen(false)}
                fullWidth={false}
            >
                <img
                    src="/images/vectors/back-arrow.svg"
                    alt=""
                    className="m-auto"
                    style={{
                        ...(direction === "right"
                            ? { transform: "rotate(180deg)" }
                            : {}),
                    }}
                />
            </Button>
            <div className="p-4 w-100 mt-8">{children}</div>
        </div>
    );
};

export default SideDrawer;
