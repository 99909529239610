import React, { useState } from "react";
import { toast } from "react-toastify";

import { useUpdateOrderNoteMutation } from "../../../../services/orderService";
import parseError from "../../../../utils/errorUtils";

import Button from "../../../../components/button/Button";
import { Form, SubmitButton, TextArea } from "../../../../components/forms";

export default function MessageThread({
    orderNoteId,
    question,
    onMessageSuccess,
    show = false,
}) {
    const [showQuestion, setShowQuestion] = useState(show);
    const [isLoading, setIsLoading] = useState(false);

    const [updateOrderNote] = useUpdateOrderNoteMutation();

    const handleSubmit = async (values) => {
        setIsLoading(true);
        const data = {
            type: "SPECIALIST_PATIENT_MESSAGE",
            questionAnswer: JSON.stringify({
                question: displayingQuestion,
                answer: values.answer,
            }),
        };

        try {
            const res = await updateOrderNote({ orderNoteId, values: data });

            if (res.error) {
                // handle error
                toast.error(parseError(res.error, "Couldn't submit answer"));
            } else {
                // handle success
                setShowQuestion(false);
                onMessageSuccess(orderNoteId);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const displayingQuestion = JSON.parse(question || "null")?.question;

    return (
        <div className="border border-primary d-flex justify-content-between flex-column flex-sm-row align-items-sm-center bg-primary-light rounded p-4">
            {showQuestion ? (
                <div className="w-100">
                    <p className="body body-small text-dark-1 mb-0">
                        {/*Your specialist is asking a question*/}
                        You have a new message:
                    </p>

                    <p className="body fw-bold mt-3">{displayingQuestion}</p>
                    <Form
                        initialValues={{ answer: "" }}
                        onSubmit={handleSubmit}
                    >
                        <div className="mb-3">
                            <TextArea
                                name="answer"
                                placeholder="Your answer"
                                rows="2"
                            />
                        </div>
                        <SubmitButton
                            variant="primary text-uppercase fw-bold px-6 px-sm-11 mt-3"
                            fullWidth={false}
                            disabled={isLoading}
                        >
                            SUBMIT ANSWER
                        </SubmitButton>
                    </Form>
                </div>
            ) : (
                <>
                    <div className="d-flex flex-column position-relative">
                        <p className="body fw-bold mb-0">
                            {/*Your specialist is asking a question*/}
                            You have a new message:
                        </p>
                        <p className="body body-small mb-0 text-truncate max-vw-25">
                            {displayingQuestion}
                        </p>
                    </div>

                    <Button
                        variant="primary text-uppercase fw-bold px-5 px-md-9 px-lg-11 mt-3 mt-sm-0"
                        fullWidth={false}
                        onClick={() => setShowQuestion(true)}
                        disabled={isLoading}
                    >
                        Answer
                    </Button>
                </>
            )}
        </div>
    );
}
