import React, { useMemo } from "react";
import dayjs from "dayjs";

import { getStatus } from "../../utils/orderStatusUtils";

import Button from "../button/Button";
import Pill from "../pill/Pill";

import "./order-card.scss";

export default function OrderCard({ onClickAction, orderData, isReorder }) {
    const rejectedReason = useMemo(() => {
        if (getStatus(orderData?.status).status === "REJECTED") {
            if (Array.isArray(getStatus(orderData?.status).rejectedReason)) {
                return (
                    orderData?.orderNotes
                        .filter((note) =>
                            getStatus(
                                orderData?.status
                            ).rejectedReason.includes(note.type)
                        )
                        .map((note) => note.description)[0] || "Not provided"
                );
            } else {
                return (
                    getStatus(orderData?.status).rejectedReason ||
                    "Not provided"
                );
            }
        }
    }, [orderData]);

    const handleClickOrderAction = () => {
        if (onClickAction) {
            onClickAction(orderData?.id, "", isReorder);
        }
    };

    const formatDateTime = (dateTimeString) => {
        let [date, time] = dateTimeString.split(', ');
        let [month, day, year] = date.split('/');
        let [hours, minutes] = time.split(':');

        if (hours == '24') {
            hours = '00';
        }

        let dateObject = new Date(`${year}-${month}-${day}T${hours}:${minutes}`);
        let options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };

        let formattedDateTime = dateObject.toLocaleString('en-GB', options).replace(',', '');
        return formattedDateTime;
    };

    const extractDateTime = (fullString) => {
        const dateTimePart = fullString.split(' - ')[1];
        return dateTimePart.trim();
    };

    return (
        <div className="order-card">
            <div
                className={`d-flex justify-content-between align-items-start flex-column flex-xl-row ${
                    getStatus(orderData?.status).borderClass
                } bg-white rounded p-4`}
            >
                {getStatus(orderData?.status).status === "REJECTED" ? (
                    <div className="description-section-rejected pe-2">
                        <div className="error-title text-danger h3 fw-bold">{`Your order was rejected (Order no.${orderData?.id})`}</div>
                        <div className="error-reason text-dark-1 fw-light">
                            Reason: {rejectedReason}
                        </div>
                    </div>
                ) : (
                    <div className="description-section pe-2">
                        <div className="primary-title h3 fw-bold">
                            { orderData?.consultationDuration === 'MINUTES_00' && `Repeat prescription - Order ${orderData?.id}` }
                            { orderData?.consultationDuration === 'MINUTES_30' && `Consultation (30 minutes) - Order ${orderData.id} ${orderData?.name.slice(orderData?.name.indexOf('('))}` }
                            { orderData?.consultationDuration === 'MINUTES_15' && `Repeat consultation (15 minutes) - Order ${orderData.id} ${orderData?.name.slice(orderData?.name.indexOf('('))}` }
                            { orderData?.consultationDuration === 'IN_PERSON_MINUTE_00' && `Pharmacy Consultation - Order ${orderData.id} (${formatDateTime(extractDateTime (orderData?.name))})` }

                            {/*This is the older way of showing the order details*/}
                            {/*{orderData?.name}*/}
                        </div>
                        <div className="secondary-title text-dark-1 fw-light">
                            {dayjs(orderData?.creationDate).format(
                                "MMMM Do, YYYY"
                            )}
                        </div>
                    </div>
                )}

                {!isReorder &&
                    getStatus(orderData?.status).status !== "REJECTED" && (
                        <div className="status-section my-auto pe-2 pe-lg-1">
                            <Pill
                                variant={`${
                                    getStatus(orderData?.status).variant
                                } fw-bold mt-3 mt-xl-0`}
                            >
                                {getStatus(orderData?.status).statusText}
                            </Pill>
                        </div>
                    )}
                <div className="divide-line d-none d-xl-block my-auto"></div>
                <div className="action-section d-flex flex-wrap align-items-end mt-4 my-xl-auto ps-xl-1">
                    <Button
                        variant="primary text-uppercase fw-bold px-2 py-3"
                        onClick={handleClickOrderAction}
                        size="sm"
                    >
                        {isReorder
                            ? "Re order"
                            : getStatus(orderData?.status).btnText}
                    </Button>
                </div>
            </div>
        </div>
    );
}
