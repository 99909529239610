import React from "react";

export default function PoliceInfoPage() {
	return (
		<div className="fs-18">
			<p>
				Vital Legal Information for Police Officers: The Impact of Your
				Actions on Medicinal Cannabis Users.
			</p>
			<p>
				<b>Restrictions on Police Action:</b>
			</p>
			<ul>
				<li>
					<b>Illegal Search or Confiscation:</b> Seizing or searching
					a patient's medicinal cannabis prescription without valid
					reason is prohibited.
				</li>
				<li>
					<b>Harassment, Arrest, or Detention:</b> Individuals should
					not be harassed, arrested, or detained based solely on their
					use of medicinal cannabis.
				</li>
				<li>
					<b>Overlooking Medicinal Cannabis Evidence:</b> Upon
					presentation of a Treat-It medicinal cannabis card, law
					enforcement must verify the prescription and acknowledge the
					individual's right to their medication.
				</li>
				<li>
					<b>Ignoring Medicinal Cannabis Disclosure:</b> When someone
					reveals their medicinal cannabis use, respect their privacy
					and avoid discrimination.
				</li>
			</ul>

			<p>Further Information:</p>
			
			<p>
				<b>
					Guidance for Security Staff: Interacting with Medicinal
					Cannabis Patients
				</b>
			</p>
			<p>
				Welcome, law enforcement personnel. The rising prevalence of
				medicinal cannabis underscores the importance of being
				well-informed and understanding in interactions with patients.
			</p>

			<p>
				<b>Identifying Medicinal Cannabis Patients:</b>
			</p>
			<ul>
				<li>
					<b>Verification Steps:</b> Confirm a patient's status
					through their prescription, the Treat-It Patient Card, or an
					alert from the Treat-It app.
				</li>
				<li>
					<b>Prompt Identification:</b> Become familiar with the key
					identification tools to recognize medicinal cannabis
					patients quickly and accurately.
				</li>
			</ul>

			<p>
				<b>Essential Responsibilities & Recommended Approaches:</b>
			</p>
			<ul>
				<li>
					<b>Prevent Discrimination:</b> Maintain an unbiased stance
					towards all individuals, including those using medicinal
					cannabis, as prejudice based on medicinal status is contrary
					to the principles of the Equality Act 2010.
				</li>
				<li>
					<b>Recognize Medical Requirements:</b> Understand that many
					people rely on cannabis as a crucial aspect of their
					healthcare regimen and treat this need with the respect it
					deserves.
				</li>
				<li>
					<b>Foster Empathy & Privacy:</b> Be aware that patients may
					be experiencing conditions such as anxiety or chronic pain,
					and handle these situations with sensitivity and
					confidentiality.
				</li>
			</ul>

			<p>
				<b>Situations to Anticipate & Appropriate Actions:</b>
			</p>
			<ul>
				<li>
					<b>At Entry Points:</b> Verify the authenticity of a patient
					disclosing their medicinal cannabis use through the Treat-It
					app or a valid prescription before granting access.
				</li>
				<li>
					<b>Concerning Public Use:</b> If venue rules limit public
					consumption, discreetly direct the patient to a private or
					specified area.
				</li>
				<li>
					<b>Regarding Medicine Storage & Limits:</b> Stay informed
					about the standard possession limits for medicinal cannabis
					and consult the Treat-It app for guidance as needed.
				</li>
			</ul>

			<p>
				<b>Documentation & Incident Reporting:</b>
			</p>
			<ul>
				<li>
					Document all interactions related to medicinal cannabis,
					taking note of the patient's condition and protecting their
					legal rights. In complex cases or uncertainties, seek advice
					from higher-ups or utilize the Treat-It app's verification
					functionalities.
				</li>
			</ul>

			<p>
				<b>Consequences for Violating Patients' Rights:</b>
			</p>
			<ul>
				<li>
					Be aware that infringing on a patient's rights can lead to
					them reporting the incident through the Treat-It app,
					potentially resulting in a formal complaint against the
					officer and their department, with serious outcomes
					including hefty fines and criminal charges.
				</li>
			</ul>
		</div>
	);
}
