export const AUDIT_FORM = {
    keys: [
        "question1",
        "question2",
        "question3",
        "question4",
        "question5",
        "question6",
        "question7",
        "question8",
        "question9",
        "question10",
    ],
    data: {
        question1: {
            label: "How often do you have a drink containing alcohol?",
            values: [
                "Never",
                "Monthly or less",
                "2 to 4 times per month",
                "2 to 3 times per week",
                "4 times or more per week",
            ],
        },
        question2: {
            label: "How many units of alcohol do you drink on a typical day when you are drinking?",
            values: ["0 to 2", "3 to 4", "5 to 6", "7 to 9", "10 or more"],
        },
        question3: {
            label: "How often have you had 6 or more units if female, or 8 or more if male, on a single occasion in the last year?",
            values: [
                "Never",
                "Less than monthly",
                "Monthly",
                "Weekly",
                "Daily or almost daily",
            ],
        },
        question4: {
            label: "How often during the last year have you found that you were not able to stop drinking once you had started?",
            values: [
                "Never",
                "Less than monthly",
                "Monthly",
                "Weekly",
                "Daily or almost daily",
            ],
        },
        question5: {
            label: "How often during the last year have you failed to do what was normally expected from you because of your drinking?",
            values: [
                "Never",
                "Less than monthly",
                "Monthly",
                "Weekly",
                "Daily or almost daily",
            ],
        },
        question6: {
            label: "How often during the last year have you needed an alcoholic drink in the morning to get yourself going after a heavy drinking session?",
            values: [
                "Never",
                "Less than monthly",
                "Monthly",
                "Weekly",
                "Daily or almost daily",
            ],
        },
        question7: {
            label: "How often during the last year have you had a feeling of guilt or remorse after drinking?",
            values: [
                "Never",
                "Less than monthly",
                "Monthly",
                "Weekly",
                "Daily or almost daily",
            ],
        },
        question8: {
            label: "How often during the last year have you been unable to remember what happened last night before because you had been drinking?",
            values: [
                "Never",
                "Less than monthly",
                "Monthly",
                "Weekly",
                "Daily or almost daily",
            ],
        },
        question9: {
            label: "Have you or somebody else been injured as a result of your drinking?",
            values: [
                "No",
                "Yes, but not in the last year",
                "Yes, during the last year",
            ],
        },
        question10: {
            label: "Has a relative or friend, doctor or other health worker been concerned about your drinking or suggested that you cut down?",
            values: [
                "No",
                "Yes, but not in the last year",
                "Yes, during the last year",
            ],
        },
    },
};

export const SIDE_EFFECT_FORM = {
    keys: [
        "sideEffectPain"
    ],
    data: {
        sideEffectPain: 'How would you rate your side effects?',
    },
};

export const PEG_FORM = {
    keys: [
        "painInPastWeek",
        "painInterferenceWithEnjoyment",
        "painInterferenceWithGeneralActivity",
    ],
    data: {
        painInPastWeek: 'What point on the scale best describes your pain on average in the past week?',
        painInterferenceWithEnjoyment: 'What point on the scale best describes how, during the past week, pain has interfered with your enjoyment of life?',
        painInterferenceWithGeneralActivity: 'What point on the scale best describes how, during the past week, pain has interfered with your general activity?'
    },
};

export const GAD_FORM = {
    keys: ["feeling", "worrying"],
    data: {
        feeling: {
            label: "Feeling nervous, anxious or on edge?",
            values: [
                "Not at all",
                "Several days",
                "More than half the days",
                "Nearly every day",
            ],
        },
        worrying: {
            label: "Not being able to stop or control worrying?",
            values: [
                "Not at all",
                "Several days",
                "More than half the days",
                "Nearly every day",
            ],
        },
    },
};

export const CONSENT_FORM = {
    keys: [
        "ageOver25",
        "answeredAssessment",
        "soleUseAcceptance",
        "agreeToReadInfo",
        "understandTnc",
    ],
    data: {
        ageOver25: "I am 18 or over and a UK resident.",
        answeredAssessment:
            "I have answered the online medical assessment truthfully with the knowledge that giving false information to obtain medicines could lead to a medicine being prescribed that is harmful to my health.",
        soleUseAcceptance: "Any medicine prescribed is for my sole use.",
        agreeToReadInfo:
            "I agree to read the patient information leaflet that will accompany the medicine.",
        understandTnc: "I have read and understood the terms and conditions.",
    },
};

export const ELIGIBILITY_QUESTIONS = {
    keys: ["q_1", "q_2", "q_3", "q_4"],
    data: {
        q_1: "Have you tried at least 2 medications prescribed by a healthcare professional to control your condition?",
        q_2: "Are you pregnant, intending to become pregnant or breast feeding?",
        q_3: "Have you ever been diagnosed with Schizophrenia / Schizoaffective disorder, Psychosis or similar?",
        q_4: "Patient agree to controlled drug consent form",
    },
};

export const CONDITION_QUESTIONS = {
    keys: ["q_1", "q_2", "q_3", "q_4"],
    data: {
        q_1: "Have you been diagnosed with any condition by a GP or a specialist?",
        q_2: "What is the condition that you’ve been diagnosed with?",
        q_3: "Where do you feel pain?",
        q_4: "Is there any other specific details you want to mention about your pain or condition",
    },
};

export const questionStructure = {
    text: "text",
    choice: "choice",
    yesNoChoiceAndText: "yesNoChoiceAndText",
    multiChoiceAndText: "multiChoiceAndText",
    checkbox: "checkbox",
    checkboxAndText: "checkboxAndText",
    checkboxAndTextWithoutLabel: "checkboxAndTextWithoutLabel",
    checkboxQuestion: "checkboxQuestion",
    externalForm: "externalForm",
    choiceAndTextOnCondition: "choiceAndTextOnCondition",
};

export const DOCTOR_NOTES_QUESTIONS = {
    keys: [
        "q_1",
        "q_2",
        "q_3",
        "q_4",
        "q_5",
        "q_6",
        "q_7",
        "q_8",
        "q_9",
        "q_10",
        "q_11",
        "q_12",
        "q_13",
        "q_14",
        "q_15",
        "q_16",
        "q_17",
        "q_18",
        "q_19",
        "q_20",
        "q_21",
        "q_22",
        "q_23",
        "q_24",
        "q_25",
        "q_26",
        "q_27",
        "q_28",
        "q_29",
        "q_30",
        "q_31",
        "q_32",
        "q_33",
        "q_34",
        "q_35",
        "q_36",
        "q_37",
        "q_38",
    ],
    data: {
        q_1: {
            active: true,
            label: "For what condition(s) and resultant symptoms are you hoping to be treated for?",
            questionId: "q_1",
            type: questionStructure.text,
        },
        q_2: {
            active: true,
            label: "Are your symptoms stable?",
            questionId: "q_2",
            type: questionStructure.text,
        },
        q_3: {
            active: false,
            label: "What is your occupation?",
            questionId: "q_3",
            type: questionStructure.text,
        },
        q_4: {
            active: false,
            label: "Does your job involved skilled tasks or the operation of machinery?",
            questionId: "q_4",
            type: questionStructure.yesNoChoiceAndText,
        },
        q_5: {
            active: true,
            label: "Have you ever had any medical problems affecting the following?",
            questionId: "q_5",
            type: questionStructure.checkboxAndText,
            values: [
                { label: "Heart", value: "heart" },
                { label: "Lungs", value: "lungs" },
                { label: "GIT", value: "git" },
                { label: "Eyes", value: "eyes" },
                { label: "Skin", value: "skin" },
                { label: "Brain", value: "brain" },
                { label: "Liver", value: "liver" },
                { label: "Kidney", value: "kidney" },
                { label: "Anything else", value: "others" },
                { label: "None of the above", value: "none" },
            ],
        },
        q_6: {
            active: true,
            label: "Has a healthcare professional ever advised that you should not take Cannabis?",
            questionId: "q_6",
            type: questionStructure.yesNoChoiceAndText,
        },
        q_7: {
            active: true,
            label: "Have you previously or are you currently prescribed Medical Cannabis?",
            questionId: "q_7",
            type: questionStructure.yesNoChoiceAndText,
        },
        q_8: {
            active: false,
            label: "Have you used Cannabis within the last year via any of the following methods?",
            questionId: "q_8",
            type: questionStructure.checkboxAndText,
            values: [
                { label: "Oil", value: "oil" },
                { label: "Flower", value: "flower" },
                { label: "Smoke", value: "smoke" },
                { label: "Oral", value: "oral" },
                { label: "Other", value: "other" },
                // { label: 'Have not used cannabis in the last year', value: 'none' },
            ],
        },
        q_9: {
            active: true,
            label: "Have you ever been prescribed the following?",
            questionId: "q_9",
            type: questionStructure.checkboxAndText,
            values: [
                { label: "PreGabalin", value: "preGabalin" },
                { label: "Codeine", value: "codeine" },
                { label: "Morphine", value: "morphine" },
                { label: "Oxycodone", value: "oxycodone" },
                { label: "Oxycontin", value: "oxycontin" },
                { label: "Tapentadol", value: "tepantadol" },
                {
                    label: "Fentanyl or Buprenoprphine Patch",
                    value: "fentanyl",
                },
                { label: "Tramadol", value: "tramadol" },
                { label: "Co-Codamol", value: "codamol" },
                { label: "Gabapentin", value: "gabapentin" },
                { label: "Amitriptyline", value: "amitriptyline" },
                { label: "Duloxetine", value: "duloxetine" },
                // {
                //   label: 'Benzodiazepine such as Diazepam, Temazepam, Clobazam',
                //   value: 'benzodiazepine',
                // },
                // {
                //   label:
                //     'ADHD Medication such as Methylphenidate, Ritalin, Amphetamines, Aderrall',
                //   value: 'adhd',
                // },
                { label: "Other", value: "other" },
                { label: "None of the above", value: "none" },
            ],
        },
        q_10: {
            active: false,
            label: "Are you taking any regular medication?",
            questionId: "q_10",
            type: questionStructure.text,
        },
        q_11: {
            active: true,
            label: "Are you currently under the care of a specialist for the condition you are requesting Medical Cannabis for?",
            questionId: "q_11",
            type: questionStructure.yesNoChoiceAndText,
        },
        q_12: {
            active: true,
            label: "Any preference for formulation",
            questionId: "q_12",
            type: questionStructure.checkbox,
            values: [
                { label: "Vape", value: "vape" },
                { label: "Oil", value: "oil" },
                { label: "Flower", value: "flower" },
                { label: "Oral", value: "oral" },
                { label: "Other", value: "other" },
                { label: "No preference", value: "none" },
            ],
        },
        q_13: {
            active: true,
            label: "Have you ever had an allergic or hypersensitivity reaction to Cannabis or Cannabis related products?",
            questionId: "q_13",
            type: questionStructure.yesNoChoiceAndText,
            condition: true,
        },
        q_14: {
            active: true,
            label: "I understand that should I be prescribed medical cannabis I require an effective means of contraception?",
            questionId: "q_14",
            type: questionStructure.choice,
            hideInstructions: ["FEMALE"],
        },
        q_15: {
            active: true,
            label: "Have you ever been diagnosed with any of the following?",
            questionId: "q_15",
            type: questionStructure.checkboxAndText,
            values: [
                { label: "Personality disorder", value: "personality" },
                {
                    label: "Schizophrenia or Psychosis",
                    value: "schizophrenia",
                },
                { label: "Depression", value: "depression" },
                { label: "PTSD", value: "ptsd" },
                { label: "Bipolar disorder", value: "bipolar" },
                {
                    label: "History of Suicide attempts or Self harm",
                    value: "selfharm",
                },
                { label: "History of addiction", value: "addiction" },
                {
                    label: "A blood relative with a history of Schizophrenia or Psychosis",
                    value: "bloodRelative",
                },
                { label: "Anything else", value: "any" },
                { label: "None of the above", value: "none" },
            ],
        },
        q_16: {
            active: true,
            label: "Do you use tobacco?",
            questionId: "q_16",
            type: questionStructure.multiChoiceAndText,
            values: [
                { label: "Non-smoker", value: "Non-smoker" },
                { label: "Ex-smoker", value: "Ex-smoker" },
                { label: "Current-smoker", value: "Current-smoker" },
            ],
        },
        q_17: {
            active: true,
            label: "Do you drink alcohol?",
            questionId: "q_17",
            type: questionStructure.choice,
        },
        q_18: {
            active: true,
            label: "Do you currently use any recreational drugs?",
            questionId: "q_18",
            type: questionStructure.checkboxAndText,
            values: [
                { label: "Cocaine", value: "cocaine" },
                { label: "Ecstasy", value: "ecstasy" },
                { label: "Heroin", value: "heroin" },
                { label: "Anything else", value: "any" },
                { label: "None of the above", value: "none" },
            ],
        },
        q_19: {
            active: true,
            label: "Do you have any mobility issues/Have you been advised you are at risk of falls?",
            questionId: "q_19",
            type: questionStructure.yesNoChoiceAndText,
        },
        q_20: {
            active: false,
            label: "Do you have children at home with you?",
            questionId: "q_20",
            type: questionStructure.yesNoChoiceAndText,
        },
        q_21: {
            active: true,
            label: "Are you the main carer for anyone you live with?",
            questionId: "q_21",
            type: questionStructure.yesNoChoiceAndText,
        },
        q_22: {
            active: true,
            label: "During the last month, have you often been bothered by feeling down, depressed or hopeless?",
            questionId: "q_22",
            type: questionStructure.yesNoChoiceAndText,
        },
        q_23: {
            active: true,
            label: "During the last month, have you often been bothered by having little interest or pleasure in doing things?",
            questionId: "q_23",
            type: questionStructure.yesNoChoiceAndText,
        },
        q_24: {
            active: true,
            label: "MSE - General comments?",
            questionId: "q_24",
            type: questionStructure.text,
        },
        q_25: {
            active: true,
            label: null,
            questionId: "q_25",
            type: questionStructure.checkboxAndTextWithoutLabel,
            values: [
                {
                    label: "Advice regarding Driving/ operating skilled machinery",
                    value: "operatingAdvice",
                },
            ],
        },
        q_26: {
            active: true,
            label: null,
            questionId: "q_26",
            type: questionStructure.checkboxAndTextWithoutLabel,
            values: [
                {
                    label: "Advice regarding Cannabis and travel",
                    value: "travelAdvice",
                },
            ],
        },
        q_27: {
            active: true,
            label: null,
            questionId: "q_27",
            type: questionStructure.checkboxAndTextWithoutLabel,
            values: [
                {
                    label: "Advice regarding Cannabis and side effects",
                    value: "sideEffectAdvice",
                },
            ],
        },
        q_28: {
            active: true,
            label: "SCR accessed and reviewed?",
            questionId: "q_28",
            type: questionStructure.yesNoChoiceAndText,
        },
        q_29: {
            active: true,
            label: "What is your occupation/Does this involve the performance of skilled tasks, manual or cognitive?",
            questionId: "q_29",
            type: questionStructure.text,
        },
        q_30: {
            active: true,
            label: "What treatments have you tried to date?",
            questionId: "q_30",
            type: questionStructure.text,
        },
        q_31: {
            active: true,
            label: "Investigations diagnostics",
            questionId: "q_31",
            type: questionStructure.text,
        },
        q_32: {
            active: true,
            label: "Past medical history",
            questionId: "q_32",
            type: questionStructure.text,
        },
        q_33: {
            active: true,
            label: "Are you allergic to any other medication or anything else including nuts seeds and oils?",
            questionId: "q_33",
            type: questionStructure.yesNoChoiceAndText,
        },
        q_34: {
            active: true,
            label: "Current medication",
            questionId: "q_34",
            type: questionStructure.text,
        },
        q_35: {
            active: true,
            label: "Have you been diagnosed with any other mental health disorder?",
            questionId: "q_35",
            type: questionStructure.yesNoChoiceAndText,
        },
        q_36: {
            active: true,
            label: "Do you believe this patient meets the criteria to be prescribed and would benefit from being prescribed medical cannabis?",
            questionId: "q_36",
            type: questionStructure.yesNoChoiceAndText,
        },
        q_37: {
            active: true,
            label: "Have you used Cannabis within the last year?",
            questionId: "q_37",
            type: questionStructure.choice,
        },
        q_38: {
            active: true,
            label: "Via what method did you take cannabis?",
            questionId: "q_38",
            type: questionStructure.checkboxAndText,
            values: [
                { label: "Oil", value: "oil" },
                { label: "Flower", value: "flower" },
                { label: "Smoke", value: "smoke" },
                { label: "Vape", value: "vape" },
                { label: "Oral", value: "oral" },
                { label: "Other", value: "other" },
            ],
            renderCondition: {
                parentId: "q_37",
                selectionKey: "choice",
                decisionValue: true,
            },
        },
    },
};

export const PHARMACY_NOTES_QUESTIONS = {
    keys: [
        "step_1|q_1",
        "step_1|q_2",
        "step_1|q_3",
        "step_1|q_4",
        "step_1|q_5",
        "step_1|q_6",
        "step_2|q_8",
        "step_2|q_9",
        "step_3|q_10",
        "step_3|q_11",
        "step_3|q_12",
        "step_3|q_13",
        "step_3|q_14",
        "step_3|q_15",
        "step_3|q_16",
        "step_3|q_17",
        "step_3|q_18",
        "step_3|q_19",
        "step_3|q_20",
        "step_3|q_21",
        "step_4|q_22",
        "step_4|q_23",
        "step_4|q_24",
        "step_4|q_25",
        "step_5|q_27",
        "step_5|q_28",
        "step_5|q_30",
        "step_5|q_31",
        "step_6|q_32",
        "step_6|q_33",
        "step_6|q_34",
        "step_6|q_36",
        "step_7|q_37",
        "step_7|q_38",
        "step_7|q_39",
    ],
    data: {
        "step_1|q_1": {
            label: "What is your occupation?",
            questionId: "q_1",
            renderPharmacistComment: true,
            type: questionStructure.text,
            active: true,
        },
        "step_1|q_2": {
            label: "For what condition(s) and resultant symptoms are you hoping to be treated?",
            questionId: "q_2",
            renderPharmacistComment: true,
            type: questionStructure.text,
            active: true,
        },
        "step_1|q_3": {
            label: "Does your occupation involve the performance of skilled tasks, whether manual or cognitive?",
            questionId: "q_3",
            renderPharmacistComment: true,
            type: questionStructure.text,
            active: true,
        },
        "step_1|q_4": {
            label: "Are your symptoms stable, getting better, or getting worse?",
            questionId: "q_4",
            renderPharmacistComment: true,
            type: questionStructure.text,
            active: true,
        },
        "step_1|q_5": {
            label: "What treatments have you tried to date for managing your pain symptoms?",
            questionId: "q_5",
            renderPharmacistComment: true,
            type: questionStructure.text,
            active: true,
        },
        "step_1|q_6": {
            label: "Please provide details of any investigations diagnostics",
            questionId: "q_6",
            renderPharmacistComment: true,
            type: questionStructure.text,
            active: true,
        },
        "step_2|q_8": {
            active: true,
            label: "Have you ever had medical problems affecting the following (Select all that apply)",
            questionId: "q_8",
            type: questionStructure.checkboxAndText,
            renderPharmacistComment: true,
            renderPatientComment: false,
            choices: [
                { label: "Heart", value: "heart" },
                { label: "Lungs", value: "lungs" },
                { label: "GIT", value: "git" },
                { label: "Eyes", value: "eyes" },
                { label: "Skin", value: "skin" },
                { label: "Brain", value: "brain" },
                { label: "Liver", value: "liver" },
                { label: "Kidney", value: "kidney" },
                { label: "Other", value: "other" },
                { label: "None of the above", value: "none" },
            ],
        },
        "step_2|q_9": {
            active: true,
            label: "Are there any other medical conditions that you have which I don’t know about?",
            questionId: "q_9",
            type: questionStructure.text,
            renderPharmacistComment: true,
        },
        "step_3|q_10": {
            active: true,
            label: "What current medication are you taking?",
            questionId: "q_10",
            type: questionStructure.text,
            renderPharmacistComment: true,
        },
        "step_3|q_11": {
            active: true,
            label: "Have you ever been prescribed any of the following medications?",
            questionId: "q_11",
            type: questionStructure.checkboxAndText,
            renderPharmacistComment: true,
            renderPatientComment: false,
            choices: [
                { label: "PreGabalin", value: "preGabalin" },
                { label: "Codeine", value: "codeine" },
                { label: "Morphine", value: "morphine" },
                { label: "Oxycodone", value: "oxycodone" },
                { label: "Oxycontin", value: "oxycontin" },
                { label: "Tapentadol", value: "tepantadol" },
                {
                    label: "Fentanyl or Buprenoprphine Patch",
                    value: "fentanyl",
                },
                { label: "Tramadol", value: "tramadol" },
                { label: "Co-Codamol", value: "codamol" },
                { label: "Gabapentin", value: "gabapentin" },
                { label: "Amitriptyline", value: "amitriptyline" },
                { label: "Duloxetine", value: "duloxetine" },
                { label: "Other", value: "other" },
                { label: "None of the above", value: "none" },
            ],
        },
        "step_3|q_12": {
            active: true,
            label: "Have you used any over-the-counter (OTC) medications, especially pain killers, regularly in the last year?",
            questionId: "q_12",
            type: questionStructure.choiceAndTextOnCondition,
            renderPharmacistComment: true,
        },
        "step_3|q_13": {
            active: true,
            label: "Are you taking any food supplements or herbal remedies?",
            questionId: "q_13",
            type: questionStructure.text,
            renderPharmacistComment: true,
        },
        "step_3|q_14": {
            active: true,
            label: "Are you currently under the care of a specialist for the condition you are requesting medical cannabis for?",
            questionId: "q_14",
            type: questionStructure.choiceAndTextOnCondition,
        },
        "step_3|q_15": {
            active: true,
            label: "Have you previously or are you currently prescribed medical cannabis?",
            questionId: "q_15",
            type: questionStructure.choiceAndTextOnCondition,
        },
        "step_3|q_16": {
            active: true,
            label: "Have you used cannabis within the last year?",
            questionId: "q_16",
            type: questionStructure.choice,
        },
        "step_3|q_17": {
            active: true,
            label: "Can you please specify the method(s) you used to take cannabis? (Select all that apply)",
            questionId: "q_17",
            type: questionStructure.checkboxAndText,
            renderCondition: {
                parentId: "step_3|q_16",
                decisionValue: true,
            },
            choices: [
                { label: "Oil", value: "oil" },
                { label: "Flower", value: "flower" },
                { label: "Smoke", value: "smoke" },
                { label: "Vape", value: "vape" },
                { label: "Oral", value: "oral" },
                { label: "Other", value: "other" },
            ],
        },
        "step_3|q_18": {
            active: true,
            label: "I understand that should I be prescribed medical cannabis I require an effective means of contraception?",
            questionId: "q_18",
            type: questionStructure.choiceAndTextOnCondition,
        },
        "step_3|q_19": {
            active: true,
            label: "Has a healthcare professional ever advised that you should not take cannabis?",
            questionId: "q_19",
            type: questionStructure.choiceAndTextOnCondition,
        },
        "step_3|q_20": {
            active: true,
            label: "Have you ever had an allergic or hypersensitivity reaction to cannabis or cannabis-related products?",
            questionId: "q_20",
            type: questionStructure.choiceAndTextOnCondition,
        },
        "step_3|q_21": {
            active: true,
            label: "Are you allergic to any other medications or substances, including nuts, seeds, and oils?",
            questionId: "q_21",
            type: questionStructure.choiceAndTextOnCondition,
        },
        "step_4|q_22": {
            active: true,
            label: "Have you ever been diagnosed with any of the following? (Please select all that apply)",
            questionId: "q_22",
            type: questionStructure.checkboxAndText,
            renderPharmacistComment: true,
            renderPatientComment: false,
            choices: [
                { label: "Personality disorder", value: "personality" },
                {
                    label: "Schizophrenia or Psychosis",
                    value: "schizophrenia",
                },
                { label: "Depression", value: "depression" },
                { label: "PTSD", value: "ptsd" },
                { label: "Bipolar disorder", value: "bipolar" },
                {
                    label: "History of Suicide attempts or Self harm",
                    value: "selfharm",
                },
                { label: "History of addiction", value: "addiction" },
                {
                    label: "A blood relative with a history of Schizophrenia or Psychosis",
                    value: "bloodRelative",
                },
                { label: "Other", value: "other" },
                { label: "None of the above", value: "none" },
            ],
        },
        "step_4|q_23": {
            active: true,
            label: "Have you been diagnosed with any other mental health disorders not previously mentioned?",
            questionId: "q_23",
            type: questionStructure.choiceAndTextOnCondition,
        },
        "step_4|q_24": {
            active: true,
            label: "During the last month, have you often been bothered by feeling down, depressed, or hopeless?",
            questionId: "q_24",
            type: questionStructure.choiceAndTextOnCondition,
            renderPharmacistComment: true,
        },
        "step_4|q_25": {
            active: true,
            label: "During the last month, have you often been bothered by having little interest or pleasure in doing things?",
            questionId: "q_25",
            type: questionStructure.choiceAndTextOnCondition,
            renderPharmacistComment: true,
        },
        "step_5|q_27": {
            active: true,
            label: "Could you please share any general comments or observations you have regarding the patient’s mental state examination?",
            questionId: "q_27",
            type: questionStructure.text,
        },
        "step_5|q_28": {
            active: true,
            label: "Do you drink alcohol?",
            questionId: "q_28",
            type: questionStructure.choice,
        },
        "step_5|q_30": {
            active: true,
            label: "Do you use tobacco? (Please select the most appropriate option)",
            questionId: "q_30",
            type: questionStructure.choiceAndTextOnCondition,
            choices: [
                { label: "Non-smoker", value: "none" },
                { label: "Ex-smoker", value: "ex" },
                { label: "Current-smoker", value: "smoker" },
                { label: "Vape", value: "vape" },
            ],
            inputRenderCondition: {
                "Ex-smoker": "ex",
                "Current-smoker": "smoker",
                Vape: "vape",
            },
        },
        "step_5|q_31": {
            active: true,
            label: "Do you currently use any recreational drugs? (Please select all that apply)",
            questionId: "q_31",
            type: questionStructure.checkboxAndText,
            choices: [
                { label: "Cocaine", value: "cocaine" },
                { label: "Ecstasy", value: "ecstasy" },
                { label: "Heroin", value: "heroin" },
                { label: "Other", value: "other" },
                { label: "None of the above", value: "none" },
            ],
        },
        "step_6|q_32": {
            active: true,
            label: "Do you have any mobility issues or have you been advised that you are at risk of falls?",
            questionId: "q_32",
            type: questionStructure.choiceAndTextOnCondition,
        },
        "step_6|q_33": {
            active: true,
            label: "Are you the main carer for anyone you live with?",
            questionId: "q_33",
            type: questionStructure.choiceAndTextOnCondition,
        },
        "step_6|q_34": {
            active: true,
            label: "I would like to provide you with advice on a few important topics.",
            questionId: "q_34",
            type: questionStructure.checkboxQuestion,
            choices: [
                {
                    label: "Driving or operating skilled machinery",
                    value: "driving",
                },
                {
                    label: "Cannabis and travel",
                    value: "travelling",
                },
                {
                    label: "Understanding potential side effects of cannabis",
                    value: "understanding",
                },
            ],
        },
        "step_6|q_36": {
            active: true,
            label: "If prescribed medical cannabis, do you have a preference for how you would like to take it? Here are the options you can choose from (select all that apply)",
            questionId: "q_36",
            type: questionStructure.checkbox,
            choices: [
                { label: "Vape", value: "vape" },
                { label: "Oil", value: "oil" },
                { label: "Flower", value: "flower" },
                { label: "Oral", value: "oral" },
                { label: "No preference", value: "none" },
            ],
        },
        "step_7|q_37": {
            active: true,
            label: "Do you have any questions or concerns about the process, the treatment, or any other related matter?",
            questionId: "q_37",
            type: questionStructure.choiceAndTextOnCondition,
        },
        "step_7|q_38": {
            active: true,
            label: "Do you understand and are you comfortable with the process as explained to you?",
            questionId: "q_38",
            type: questionStructure.choiceAndTextOnCondition,
        },
        "step_7|q_39": {
            active: true,
            label: "Have all of your questions been answered to your satisfaction and do you feel informed to make decisions regarding your treatment if it is approved for you?",
            questionId: "q_39",
            type: questionStructure.choiceAndTextOnCondition,
        },
    },
};

export const MOCK_PHARMACY_ANSWERS = {
    "step_1|q_1": {
        question: "What is your occupation?",
        patientText:
            "hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia",
        pharmacyText:
            "kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid ",
    },
    "step_1|q_2": {
        question:
            "For what condition(s) and resultant symptoms are you hoping to be treated?",
        patientText:
            "hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia",
        pharmacyText:
            "kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid ",
    },
    "step_1|q_3": {
        question:
            "Does your occupation involve the performance of skilled tasks, whether manual or cognitive?",
        patientText:
            "hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia",
        pharmacyText:
            "kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid ",
    },
    "step_1|q_4": {
        question: "Are your symptoms stable, getting better, or getting worse?",
        patientText:
            "hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia",
        pharmacyText:
            "kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid ",
    },
    "step_1|q_5": {
        question:
            "What treatments have you tried to date for managing your pain symptoms?",
        patientText:
            "hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia",
        pharmacyText:
            "kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid ",
    },
    "step_1|q_6": {
        question: "Please provide details of any investigations diagnostics",
        patientText:
            "hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia",
        pharmacyText:
            "kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid ",
    },
    "step_2|q_8": {
        question:
            "Have you ever had medical problems affecting the following (Select all that apply)",
        choices: [
            {
                label: "Heart",
                checked: true,
                patientText: null,
                pharmacyText:
                    "kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid ",
                id: "heart",
            },
            {
                label: "Lungs",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "lungs",
            },
            {
                label: "GIT",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "git",
            },
            {
                label: "Eyes",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "eyes",
            },
            {
                label: "Skin",
                checked: true,
                patientText: null,
                pharmacyText:
                    "kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid ",
                id: "skin",
            },
            {
                label: "Brain",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "brain",
            },
            {
                label: "Liver",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "liver",
            },
            {
                label: "Kidney",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "kidney",
            },
            {
                label: "Other",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "other",
            },
            {
                label: "None of the above",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "none",
            },
        ],
    },
    "step_2|q_9": {
        question:
            "Are there any other medical conditions that you have which I don’t know about?",
        patientText:
            "hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia",
        pharmacyText:
            "kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid ",
    },
    "step_3|q_10": {
        question: "What current medication are you taking?",
        patientText:
            "hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia",
        pharmacyText:
            "kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid ",
    },
    "step_3|q_11": {
        question:
            "Have you ever been prescribed any of the following medications?",
        choices: [
            {
                label: "PreGabalin",
                checked: true,
                patientText: null,
                pharmacyText:
                    "kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid ",
                id: "preGabalin",
            },
            {
                label: "Codeine",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "codeine",
            },
            {
                label: "Morphine",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "morphine",
            },
            {
                label: "Oxycodone",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "oxycodone",
            },
            {
                label: "Oxycontin",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "oxycontin",
            },
            {
                label: "Tapentadol",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "tepantadol",
            },
            {
                label: "Fentanyl or Buprenoprphine Patch",
                checked: true,
                patientText: null,
                pharmacyText:
                    "kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid ",
                id: "fentanyl",
            },
            {
                label: "Tramadol",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "tramadol",
            },
            {
                label: "Co-Codamol",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "codamol",
            },
            {
                label: "Gabapentin",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "gabapentin",
            },
            {
                label: "Amitriptyline",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "amitriptyline",
            },
            {
                label: "Duloxetine",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "duloxetine",
            },
            {
                label: "Other",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "other",
            },
            {
                label: "None of the above",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "none",
            },
        ],
    },
    "step_3|q_12": {
        question:
            "Have you used any over-the-counter (OTC) medications, especially pain killers, regularly in the last year?",
        patientText:
            "hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia",
        pharmacyText:
            "kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid ",
        choice: true,
    },
    "step_3|q_13": {
        question: "Are you taking any food supplements or herbal remedies?",
        patientText:
            "hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia",
        pharmacyText:
            "kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid ",
    },
    "step_3|q_14": {
        question:
            "Are you currently under the care of a specialist for the condition you are requesting medical cannabis for?",
        patientText: null,
        pharmacyText: null,
        choice: false,
    },
    "step_3|q_15": {
        question:
            "Have you previously or are you currently prescribed medical cannabis?",
        patientText:
            "hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia",
        pharmacyText: null,
        choice: true,
    },
    "step_3|q_16": {
        question: "Have you used cannabis within the last year?",
        patientText: null,
        pharmacyText: null,
        choice: false,
    },
    "step_3|q_17": {
        question:
            "Can you please specify the method(s) you used to take cannabis? (Select all that apply)",
        choices: [
            {
                label: "Oil",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "oil",
            },
            {
                label: "Flower",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "flower",
            },
            {
                label: "Smoke",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "smoke",
            },
            {
                label: "Vape",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "vape",
            },
            {
                label: "Oral",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "oral",
            },
            {
                label: "Other",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "other",
            },
        ],
    },
    "step_3|q_18": {
        question:
            "I understand that should I be prescribed medical cannabis I require an effective means of contraception?",
        patientText:
            "hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia",
        pharmacyText: null,
        choice: true,
    },
    "step_3|q_19": {
        question:
            "Has a healthcare professional ever advised that you should not take cannabis?",
        patientText: null,
        pharmacyText: null,
        choice: false,
    },
    "step_3|q_20": {
        question:
            "Have you ever had an allergic or hypersensitivity reaction to cannabis or cannabis-related products?",
        patientText:
            "hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia",
        pharmacyText: null,
        choice: true,
    },
    "step_3|q_21": {
        question:
            "Are you allergic to any other medications or substances, including nuts, seeds, and oils?",
        patientText:
            "hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia",
        pharmacyText: null,
        choice: true,
    },
    "step_4|q_22": {
        question:
            "Have you ever been diagnosed with any of the following? (Please select all that apply)",
        choices: [
            {
                label: "Personality disorder",
                checked: true,
                patientText: null,
                pharmacyText:
                    "kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid ",
                id: "personality",
            },
            {
                label: "Schizophrenia or Psychosis",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "schizophrenia",
            },
            {
                label: "Depression",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "depression",
            },
            {
                label: "PTSD",
                checked: true,
                patientText: null,
                pharmacyText:
                    "kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid ",
                id: "ptsd",
            },
            {
                label: "Bipolar disorder",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "bipolar",
            },
            {
                label: "History of Suicide attempts or Self harm",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "selfharm",
            },
            {
                label: "History of addiction",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "addiction",
            },
            {
                label: "A blood relative with a history of Schizophrenia or Psychosis",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "bloodRelative",
            },
            {
                label: "Other",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "other",
            },
            {
                label: "None of the above",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "none",
            },
        ],
    },
    "step_4|q_23": {
        question:
            "Have you been diagnosed with any other mental health disorders not previously mentioned?",
        patientText: null,
        pharmacyText: null,
        choice: false,
    },
    "step_4|q_24": {
        question:
            "During the last month, have you often been bothered by feeling down, depressed, or hopeless?",
        patientText:
            "hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia",
        pharmacyText:
            "kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid ",
        choice: true,
    },
    "step_4|q_25": {
        question:
            "During the last month, have you often been bothered by having little interest or pleasure in doing things?",
        patientText: null,
        pharmacyText: null,
        choice: false,
    },
    "step_5|q_27": {
        question:
            "Could you please share any general comments or observations you have regarding the patient’s mental state examination?",
        patientText: "asdjha dihashasiduh siu sdoiuaisaisuaisud",
        pharmacyText: null,
    },
    "step_5|q_28": {
        question: "Do you drink alcohol?",
        patientText: null,
        pharmacyText: null,
        choice: false,
    },
    "step_5|q_30": {
        question:
            "Do you use tobacco? (Please select the most appropriate option)",
        patientText:
            "hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia",
        pharmacyText: null,
        choice: "Current-smoker",
    },
    "step_5|q_31": {
        question:
            "Do you currently use any recreational drugs? (Please select all that apply)",
        choices: [
            {
                label: "Cocaine",
                checked: true,
                patientText:
                    "hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia",
                pharmacyText: null,
                id: "cocaine",
            },
            {
                label: "Ecstasy",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "ecstasy",
            },
            {
                label: "Heroin",
                checked: true,
                patientText:
                    "hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia",
                pharmacyText: null,
                id: "heroin",
            },
            {
                label: "Other",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "notherw",
            },
            {
                label: "None of the above",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "none",
            },
        ],
    },
    "step_6|q_32": {
        question:
            "Do you have any mobility issues or have you been advised that you are at risk of falls?",
        patientText: null,
        pharmacyText: null,
        choice: false,
    },
    "step_6|q_33": {
        question: "Are you the main carer for anyone you live with?",
        patientText:
            "hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia",
        pharmacyText: null,
        choice: true,
    },
    "step_6|q_34": {
        question:
            "I would like to provide you with advice on a few important topics.",
        choices: [
            {
                label: "Driving or operating skilled machinery",
                checked: true,
                patientText: null,
                pharmacyText: null,
                id: "driving",
            },
            {
                label: "Cannabis and travel",
                checked: true,
                patientText: null,
                pharmacyText: null,
                id: "travelling",
            },
            {
                label: "Understanding potential side effects of cannabis",
                checked: true,
                patientText: null,
                pharmacyText: null,
                id: "understanding",
            },
        ],
    },
    "step_6|q_36": {
        question:
            "If prescribed medical cannabis, do you have a preference for how you would like to take it? Here are the options you can choose from (select all that apply)",
        choices: [
            {
                label: "Vape",
                checked: true,
                patientText: null,
                pharmacyText: null,
                id: "vape",
            },
            {
                label: "Oil",
                checked: true,
                patientText: null,
                pharmacyText: null,
                id: "oil",
            },
            {
                label: "Flower",
                checked: true,
                patientText: null,
                pharmacyText: null,
                id: "flower",
            },
            {
                label: "Oral",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "oral",
            },
            {
                label: "Other",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "other",
            },
            {
                label: "No preference",
                checked: null,
                patientText: null,
                pharmacyText: null,
                id: "none",
            },
        ],
    },
    "step_7|q_37": {
        question:
            "Do you have any questions or concerns about the process, the treatment, or any other related matter?",
        patientText: null,
        pharmacyText: null,
        choice: false,
    },
    "step_7|q_38": {
        question:
            "Do you understand and are you comfortable with the process as explained to you?",
        patientText:
            "hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia",
        pharmacyText: null,
        choice: true,
    },
    "step_7|q_39": {
        question:
            "Have all of your questions been answered to your satisfaction and do you feel informed to make decisions regarding your treatment if it is approved for you?",
        patientText:
            "hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia",
        pharmacyText: null,
        choice: true,
    },
};
