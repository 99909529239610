import {Form} from "../../../components/forms";
import {useGetMyProfileQuery} from "../../../services/userService";
import React, {useEffect, useState} from "react";
import {uploadFile} from "../../../services/fileService";
import {toast} from "react-toastify";
import parseError from "../../../utils/errorUtils";
import {useNavigate} from "react-router-dom";
import {UploadProfileForm} from "./UploadProfileForm";


export default function UpdateProfilePicture () {
    const [isHovered, setIsHovered] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [initialValues] = useState({ id: [] });
    const navigate = useNavigate();
    const { data: profileData, refetch, isLoading } = useGetMyProfileQuery();


    useEffect(() => {
        const userAgent = navigator.userAgent;
        setIsMobile(/iPhone|iPad|iPod|Android/i.test(userAgent));
    }, []);

    const handleSubmit = async (values, { resetForm }) => {
        try {
            values.id.forEach(value => {
                if (!value.type) {
                    value.type = 'PATIENT_PROFILE_PICTURE'
                }
            });
            let res;

            var formData = new FormData();

            formData.append("action", "PATIENT_KYC_DOCUMENTS");

            for (const fileData of values.id) {
                formData.append(
                    "files",
                    fileData.file,
                    `${fileData.type}.${fileData.file.name.split(".")[1]}`
                );
                formData.append("category", fileData.type);

                res = await uploadFile(formData);
                formData.delete("files");
                formData.delete("category");
            }

            if (res.error) {
                // handle error
                toast.error(parseError(res.error, "Couldn't upload file"));
            } else {
                // request succeeded
                // Redirect to the next page
                // navigate(`/onboarding/appointment/referred-by`);
                navigate(`/onboarding/kyc-document`);

                // refetch();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSkip = () => {
        navigate(`/onboarding/kyc-document`);
        window.scrollTo(0, 0);
    };

    const files =
        profileData?.files?.filter(
            ({ action }) => action === "PATIENT_PROFILE_PICTURE"
        ) || [];

    return (
        <div className="px-3 m-auto pb-12 w-100" style={{maxWidth: '70rem'}}>
            <div className="d-flex flex-column">
                <h2 className="text-primary fw-bold mb-6 text-center">
                    Get Your Personalised Medical Cannabis Card
                </h2>
                <p className="fs-5 subtitle text-primary-200 text-start mb-2 mx-2 mx-lg-8">
                    Upload a photograph of yourself as per the UK digital photo guidelines:
                </p>
                <div className="fs-5 subtitle text-primary-200 text-start mb-4 mx-2 mx-lg-8 profile-upload-container">
                    <div className="text-start profile-upload-instructions">
                        <ol>
                            <li>Taken within the last 6 months.</li>
                            <li>Clear and focused, without blur or red-eye.</li>
                            <li>Against a plain white background, no other objects or people.</li>
                            <li>Facing forward, with your face taking up 70-80% of the photo. No hats, sunglasses, or accessories obscuring your face.</li>
                        </ol>
                    </div>
                    <div className="text-center profile-upload-instructions-image">
                        <img src="/images/profile-guidance-uk.png" alt="" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}
                             style={{height: 'auto', maxWidth: '50%', cursor: 'pointer', maxHeight: '200px'}}/>

                        {isHovered && !isMobile && (
                            <div style={{ position: 'absolute', top: '0', left: '0', zIndex: '9999', maxWidth: '50%'}}>
                                <img src="/images/profile-guidance-uk.png" alt="" style={{ height: 'auto', maxWidth: '50%', transform: 'scale(1.2)' }} />
                            </div>
                        )}
                        {isHovered && isMobile && (
                            <div style={{ position: 'absolute', top: '50%', left: '10%', zIndex: '9999', maxWidth: '80%'}}>
                                <img src="/images/profile-guidance-uk.png" alt="" style={{ height: 'auto', maxWidth: '80%', transform: 'scale(1.2)' }} />
                            </div>
                        )}
                        <p style={{fontWeight: 'bold', fontSize: '14px'}}>{isMobile ? "Click to zoom" : "Hover to zoom"}</p>
                    </div>
                </div>
                <p className="fs-5 subtitle text-primary-200 text-start mb-3 mx-2 mx-lg-8"
                   style={{fontWeight: 'bolder'}}>
                *Disclaimer: You will be eligible to receive your medical cannabis card upon successfully being issued a medical cannabis prescription from Treat It.
                </p>
            </div>
            <div className="">
                <Form
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    <UploadProfileForm name="id" onSkip={handleSkip}/>
                </Form>
            </div>
        </div>
    )
}