import React from "react";

const navLinkStates = {
    DISABLED: "disabled",
    COMPLETE: "complete",
};

export default function NavLink({ name, state, isActive }) {
    return (
        <div
            className={`nav-link h-100 ${
                isActive ? "d-block" : "d-none d-md-block"
            }`}
        >
            <label
                className={`text-decoration-none d-flex text-primary fw-bold py-0 px-0 px-sm-4 px-md-3 px-lg-4 fs-6 ${
                    navLinkStates[state]
                } ${isActive && "active"}`}
            >
                {state === "COMPLETE" && (
                    <img
                        className="me-2"
                        src="/images/vectors/tick-primary-light.svg"
                        alt=""
                    />
                )}
                {name}
            </label>
            {isActive && (
                <div className="link-underline w-100 bg-primary mt-3" />
            )}
        </div>
    );
}
