import Collapsible from "../../components/collapsible/Collapsible";

export default function SupportPage() {
    const handleNavigate = (path) => {
        window.location = path;
    };

    return (
        <div className="support-page pt-6 pt-lg-0 pb-5 px-xl-6 px-xxl-12">
            <h2 className="text-primary fw-bold">Support</h2>
            <div className="text-brown-black mt-5">
                <h3 className="fw-bold">Speak to customer service</h3>
                <p>
                    Contact our customer service team with any questions about
                    your order, consultation or treatment.
                </p>
            </div>
            <div className="description-section bg-white rounded mt-5 px-5 py-7">
                <Collapsible
                    title="FAQ"
                    className="border-1 border-bottom px-0 pb-6"
                    textColor="text-primary"
                    logo="stethoscope.svg"
                    onClickArrow={() =>
                        handleNavigate("https://treat-it.clinic/treat-it-faqs/")
                    }
                    link
                />
                <Collapsible
                    title="WhatsApp us"
                    className="border-1 border-bottom px-0 py-6"
                    textColor="text-primary"
                    logo="stethoscope.svg"
                    onClickArrow={() =>
                        handleNavigate("https://treat-it.clinic/contact-us/")
                    }
                    link
                />
                <Collapsible
                    title="Report an issue"
                    className="border-1 border-bottom px-0 py-6"
                    textColor="text-primary"
                    logo="stethoscope.svg"
                    onClickArrow={() =>
                        handleNavigate("https://treat-it.clinic/contact-us/")
                    }
                    link
                />
                <Collapsible
                    title="Call customer support"
                    className="border-0 px-0 pt-6"
                    textColor="text-primary"
                    logo="stethoscope.svg"
                    onClickArrow={() =>
                        handleNavigate("https://treat-it.clinic/contact-us/")
                    }
                    link
                />
            </div>
        </div>
    );
}
