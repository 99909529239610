import React from "react";

import { useModal } from "../../../hooks/modalsHooks";

import Button from "../../button/Button";
import ModalLayout from "../ModalLayout";

import "./consent-modal.scss";

export default function ConsentModal({ onAccept, onDecline }) {
    const { hideModal } = useModal();

    return (
        <ModalLayout width="44rem">
            <ModalLayout.Header closeModal={hideModal}>
                <div className="w-100">
                    <h2 className="position-relative text-center fw-bold">
                        Controlled drug consent form
                        <span className="underline center short" />
                    </h2>
                </div>
            </ModalLayout.Header>
            <ModalLayout.Body className="d-flex d-sm-block p-5">
                <div className="my-auto">
                    <p className="fw-light text-center mb-6">
                        Please read through the below form and provide consent
                        to be eligible for medical cannabis treatments.
                    </p>
                    <div className="consent-modal-description rounded mb-10 mb-sm-0">
                        <div className="bg-secondary py-3 px-4 me-2">
                            <p className="fw-light">
                                Your doctor has recommended that you take a
                                controlled drug. This may include but is not
                                limited to Cannabis, Opioids, Opiates,
                                Benzodiazepines and stimulants such as ADHD
                                medication. The objective of this consent form
                                is to establish that you have been provided with
                                sufficient information to make a value judgement
                                of the risks and benefits of receiving the
                                prescribed medication. By signing and agreeing
                                with the statements on this form you are
                                acknowledging this. Further information
                                regarding controlled drugs is available via the
                                website – provide link
                            </p>
                            <p className="mb-0">
                                I confirm that the following has been discussed
                                and/or sufficient information has been provided
                                with respect to:
                            </p>

                            <ul className="fw-light">
                                <li>
                                    The nature of my symptoms and
                                    any/my/potential underlying medical
                                    condition
                                </li>
                                <li>
                                    The proper use of the medication that is
                                    being prescribed
                                </li>
                                <li>
                                    Alternative means of treating my symptoms
                                    and their cause
                                </li>
                                <li>
                                    The important provisions of my treatment
                                    plan
                                </li>
                                <li>
                                    The potential risks and anticipated benefits
                                    of long-term use of controlled drugs
                                </li>
                                <li>
                                    The side effects, both short-and long-term
                                    of the medication that is being prescribed
                                    or provided
                                </li>
                                <li>
                                    The fact that some medications are known to
                                    have a risk of tolerance, physical
                                    dependency, and addiction over time
                                </li>
                                <li>
                                    The risk of drug interactions, overdose, and
                                    death, especially if the medication is not
                                    taken as prescribed, or combined with
                                    alcohol use or other drugs
                                </li>
                                <li>
                                    The risk of impaired motor skills affecting
                                    driving and other tasks, skilled or
                                    otherwise which can lead to accidents,
                                    injury or death
                                </li>
                                <li>
                                    That I will take my medication exactly as it
                                    is prescribed
                                </li>
                                <li>
                                    That I will keep my medication in a safe
                                    secure space and out of reach of children
                                </li>
                                <li>
                                    That I will not share trade or sell my
                                    medication and I confirm it is for my sole
                                    use
                                </li>
                                <li>
                                    The methods to safely store and legally
                                    dispose of unused medication; and,
                                </li>
                                <li>
                                    How requests for refills of the prescription
                                    will be handled by you as my provider.
                                </li>
                            </ul>

                            <p className="mb-0">
                                I understand that my medication may be stopped
                                if:
                            </p>

                            <ul className="fw-light">
                                <li>There is no change in my symptoms</li>
                                <li>I develop significant side effects</li>
                                <li>
                                    I do not fulfil my responsibilities as
                                    detailed above
                                </li>
                                <li>I do not attend follow up appointments</li>
                                <li>
                                    If the Multi-Disciplinary Team determine for
                                    any reason that the medication is not, or no
                                    longer advisable
                                </li>
                            </ul>

                            <p className="mb-0">I understand that:</p>

                            <ul className="fw-light">
                                <li>
                                    There may be possible unknown side effects
                                    of the prescribed medication(s) that could
                                    harm an unborn child. If you are not
                                    pregnant, you need to use an appropriate
                                    contraception/birth control, during the
                                    course of your treatment. If you become
                                    pregnant or are uncertain,{" "}
                                    <span className="fw-normal">
                                        YOU NEED TO NOTIFY YOUR DOCTOR
                                        IMMEDIATELY
                                    </span>
                                    .
                                </li>
                                <li>
                                    That this treatment may include prolonged or
                                    continuous use of this medication, and that
                                    an appropriate treatment goal may also
                                    include the eventual withdrawal from the use
                                    of this medication.
                                </li>
                                <li>
                                    That my treatment plan will be tailored to
                                    me. I understand that I am expected to
                                    participate in any other treatment plans
                                    that my doctor has recommended or prescribed
                                    to treat or ease my symptoms or condition,
                                    including physical/occupational therapy
                                    and/or psychological counselling.
                                </li>
                                <li>
                                    That my prescriptions and medications are my
                                    responsibility. If they are lost or stolen a
                                    replacement prescription will not be
                                    provided without a police crime reference
                                    number. Any replacement prescriptions will
                                    remain at the clinician’s judgement.
                                </li>
                                <li>
                                    That I would need to inform my health care
                                    provider if I intended to discontinue use of
                                    the prescribed medication since there may be
                                    a medical risk associated with abrupt
                                    termination of the treatment. I understand
                                    that I would be provided with a withdrawal
                                    management plan and medical supervision may
                                    be needed when discontinuing medication use.
                                </li>
                            </ul>

                            <p className="fw-light">
                                I acknowledge I have been given the opportunity
                                to ask questions about my condition and
                                treatment, the risks or not of non-treatment,
                                and the risks and benefits of the specific drug
                                therapy being offered as well as other medical
                                treatments that may be used to treat my
                                condition.
                            </p>

                            <p className="fw-light">
                                I confirm that I have been provided with
                                sufficient information to give informed consent.
                                The Doctor supervising my treatment has
                                satisfactorily answered all my questions about
                                this medication.
                            </p>

                            <p className="fw-light">
                                Please click on{" "}
                                <span className="fw-normal">“I consent”</span>{" "}
                                if you have read, understood, and agree with all
                                statements above.
                            </p>
                        </div>
                    </div>
                </div>
            </ModalLayout.Body>
            <ModalLayout.Footer>
                <div className="d-flex w-100">
                    <Button variant="secondary me-2" onClick={onDecline}>
                        I do not consent
                    </Button>
                    <Button variant="primary ms-2" onClick={onAccept}>
                        I consent
                    </Button>
                </div>
            </ModalLayout.Footer>
        </ModalLayout>
    );
}
