import { SUPPORT_EMAIL, SUPPORT_TELEPHONE } from "../constants/appProperties";

export default function parseError(
    response,
    fallbackErrStr = "Something went wrong"
) {
    const statusCode = Number(response?.data?.status?.toString()[0]);

    if (statusCode && statusCode === 5) {
        return (
            <>
                <p className="mb-1">
                    Something went wrong, Please contact our support team.
                </p>
                <p className="mb-0">
                    Call us on: {SUPPORT_TELEPHONE} or email:
                    {SUPPORT_EMAIL}
                </p>
            </>
        );
    }

    let errorLevelOne = response?.data?.error;

    if (typeof errorLevelOne === "string") {
        return errorLevelOne;
    }

    if (typeof errorLevelOne === "object") {
        errorLevelOne = errorLevelOne[Object.keys(errorLevelOne)[0]];

        if (errorLevelOne?.length) {
            return errorLevelOne[0];
        }

        if (typeof errorLevelOne === "string") {
            return errorLevelOne;
        }
    }
    return fallbackErrStr;
}
