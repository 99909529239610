import React, { useState } from "react";
import { useField } from "formik";

import "./text-field.scss";

/**
 * Native `text` input wrapper with Formik integration.
 * @param {{
 * name: string,
 * label: string,
 * placeholder?: string,
 * type?: string,
 * disabled?: boolean
 * }} props
 * @returns {React.FunctionComponent}
 */
export default function TextField({
    name,
    label,
    type = "text",
    placeholder,
    className,
    labelClass,
    hideNumberArrows = false,
    hideError = false,
    ...rest
}) {
    const [field, meta] = useField({ name, label, type, placeholder, ...rest });
    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className="text-field w-100">
            {label && (
                <label
                    htmlFor={name}
                    className={`form-label fs-5 fw-bold ${
                        rest.disabled ? "disabled" : ""
                    } ${labelClass}`}
                >
                    {label}
                </label>
            )}
            <div className="input-container position-relative">
                <input
                    {...field}
                    {...rest}
                    className={`${className} ${
                        hideNumberArrows ? "hide-arrows" : ""
                    } form-control`}
                    placeholder={placeholder || ""}
                    type={
                        type === "password"
                            ? showPassword
                                ? "text"
                                : "password"
                            : type
                    }
                    value={field.value || ""}
                />
                {type === "password" && !rest.disabled && (
                    <button
                        type="button"
                        className="p-0 eye"
                        onClick={() => setShowPassword(!showPassword)}
                        tabIndex="-1"
                    >
                        <img
                            src={`/images/vectors/eye${
                                showPassword ? "-off" : ""
                            }.svg`}
                            alt=""
                        />
                    </button>
                )}
            </div>

            {!hideError && (
                <div
                    hidden={!(meta.touched && meta.error)}
                    className="invalid-feedback mb-0 mt-0 w-fit"
                    style={{ display: "block" }}
                >
                    {meta.error}
                </div>
            )}
        </div>
    );
}
