import React from "react";
import { Field, ErrorMessage } from "formik";

/**
 * Native `radio` input for boolean selections. Integrated with Formik.
 * @param {{
 * name: string,
 * label: string,
 * horizontal: boolean
 * }} props
 * @returns
 */
export default function YesNoRadio(props) {
    const {
        name,
        label,
        disabled,
        horizontal = true,
        justify = "",
        space = 3,
    } = props;

    return (
        <Field name={name} id={name} label={label}>
            {({ field, form: { setFieldValue } }) => (
                <div>
                    {/* <label>{label}</label> */}
                    <div
                        className={`d-flex flex-${
                            horizontal ? "sm-row flex-column" : "column"
                        } justify-content-${justify}`}
                    >
                        <div
                            className={`custom-control custom-radio ${
                                horizontal ? `me-sm-${space} me-0` : "mb-2"
                            }`}
                        >
                            <input
                                className="custom-control-input"
                                checked={field.value || ""}
                                onChange={() => {
                                    setFieldValue(name, true);
                                }}
                                id={`${name}-yes`}
                                type="radio"
                                disabled={disabled}
                            />
                            <label
                                className="custom-control-label fw-light"
                                htmlFor={`${name}-yes`}
                            >
                                Yes
                            </label>
                        </div>
                        <div
                            className={`custom-control custom-radio ${
                                horizontal ? `ms-sm-${space} ms-0` : "mt-2"
                            }`}
                        >
                            <input
                                className="custom-control-input"
                                checked={field.value === false}
                                onChange={() => {
                                    setFieldValue(name, false);
                                }}
                                id={`${name}-no`}
                                type="radio"
                                disabled={disabled}
                            />
                            <label
                                className="custom-control-label fw-light"
                                htmlFor={`${name}-no`}
                            >
                                No
                            </label>
                        </div>
                    </div>
                    <ErrorMessage
                        name={name}
                        render={(msg) => (
                            <div style={{ color: "red" }}>{msg}</div>
                        )}
                    />
                </div>
            )}
        </Field>
    );
}
