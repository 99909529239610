import React from "react";

import { useModal } from "../../../hooks/modalsHooks";

import Button from "../../button/Button";
import ModalLayout from "../ModalLayout";

import { SUPPORT_EMAIL } from "../../../constants/appProperties";

export default function RejectWarningModal({ message, onAccept, onDecline }) {
    const { hideModal } = useModal();

    const handleAccept = () => {
        if (onAccept) {
            onAccept();
        }
    };

    const handleDecline = () => {
        if (onDecline) {
            onDecline();
        }
    };

    return (
        <ModalLayout width="46rem">
            <ModalLayout.Header closeModal={hideModal}>
                <div className="w-100">
                    <h2 className="position-relative text-center fw-bold">
                        Are you sure?
                        <span className="underline center short" />
                    </h2>
                </div>
            </ModalLayout.Header>
            <ModalLayout.Body className="d-flex d-sm-block p-5">
                <p className="body body-small text-center my-auto">
                    This action is final. Rejecting your order at this stage
                    will result in its cancellation. If you believe there’s been
                    a mistake, please contact our support team at&nbsp;
                    <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
                </p>
            </ModalLayout.Body>
            <ModalLayout.Footer>
                <div className="d-flex w-100">
                    <Button
                        variant="outline-primary me-2"
                        onClick={handleDecline}
                    >
                        TAKE ME BACK
                    </Button>
                    <Button variant="primary ms-2" onClick={handleAccept}>
                        REJECT ORDER
                    </Button>
                </div>
            </ModalLayout.Footer>
        </ModalLayout>
    );
}
