import { createContext, useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useGetAppointmentsQuery } from "../services/orderService";
import { useGetMyProfileQuery } from "../services/userService";
import { RepeatOrderContext } from "./RepeatOrderProvider";
import { useAuth } from "../utils/authUtils";

import { APPOINTMENT_CONTEXT_NAME } from "../constants";

export const AppointmentContext = createContext({
    appointmentData: null,
    setAppointmentData: () => {},
});

export default function AppointmentProvider({ children }) {
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    const {
        isRepeatOrder,
        hasSixMonthsPassed,
        isLoading: repeatOrderDataLoading,
    } = useContext(RepeatOrderContext);
    const auth = useAuth();

    const [appointmentData, setAppointmentData] = useState({});

    const { data } = useGetAppointmentsQuery(
        {
            page: 0,
            pageSize: 320,
            type: hasSixMonthsPassed
                ? "repeat"
                : isRepeatOrder
                ? "repeat"
                : "full",
        },
        { skip: repeatOrderDataLoading }
    );
    const { data: profileData } = useGetMyProfileQuery(
        {},
        { skip: !auth.isLogged }
    );

    let savedAppointmentData = JSON.parse(
        localStorage.getItem(APPOINTMENT_CONTEXT_NAME)
    );

    const handleSaveAppointmentData = (data) => {
        setAppointmentData(data);
        localStorage.setItem(APPOINTMENT_CONTEXT_NAME, JSON.stringify(data));
    };

    useEffect(() => {
        if (data) {
            let selectedAppointment = data.content.find(
                (appointment) => appointment.id === Number(id)
            );

            setAppointmentData((prevState) => ({
                ...selectedAppointment,
                ...prevState,
                name: `Consultation (${dayjs(
                    selectedAppointment?.datetime
                ).format("DD/MM/YYYY")} ${dayjs(
                    selectedAppointment?.datetime
                ).format("hh:mm a")})`,
                patient: profileData,
            }));
        }
    }, [data, id, profileData]);

    useEffect(() => {
        if (savedAppointmentData !== {}) {
            if (
                savedAppointmentData?.pictures?.length > 0 &&
                location.pathname.includes("/payment")
            ) {
                delete savedAppointmentData.pictures;

                handleSaveAppointmentData(savedAppointmentData);

                setAppointmentData({
                    ...appointmentData,
                    ...savedAppointmentData,
                });
                navigate(`/appointment/${id}/uploads`, {
                    replace: true,
                });
            } else {
                setAppointmentData({
                    ...appointmentData,
                    ...savedAppointmentData,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AppointmentContext.Provider
            value={{
                appointmentData,
                setAppointmentData: handleSaveAppointmentData,
            }}
        >
            {children}
        </AppointmentContext.Provider>
    );
}
