import { useFormikContext } from "formik";
import _ from "lodash";

import ModalLayout from "../modals/ModalLayout";
import { Slider, SubmitButton } from "../forms";

import { PEG_FORM } from "../../constants/orderNotesQuestions";

export default function PEGForm({ viewOnly = false }) {
    const { values, errors } = useFormikContext();

    const average =
        Math.round((values.painInPastWeek +
                values.painInterferenceWithEnjoyment +
                values.painInterferenceWithGeneralActivity) /
            3);

    return (
        <>
            <ModalLayout.Body className={`d-flex d-sm-block p-5 ${viewOnly ? "p-0" : ""}`}>
                <div className="my-auto">
                    <div className="fw-light">
                        {PEG_FORM.keys.map((key) => (
                            <div className="row mx-0 mb-8" key={key}>
                                <Slider
                                    label={PEG_FORM.data[key]}
                                    name={key}
                                    min={0}
                                    max={10}
                                    step={0.1}
                                    marks={[0, 10]}
                                    labelClass="fw-semibold"
                                    disabled={viewOnly}
                                />
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px'}}>
                                    {key === 'painInPastWeek' ? (
                                        <>
                                            <span>0 - No pain</span>
                                            <span>10 - Pain as bad as you can imagine</span>
                                        </>
                                    ) : (
                                        <>
                                            <span>0 - Does not interfere</span>
                                            <span>10 - Completely interferes</span>
                                        </>
                                    )}
                                </div>
                            </div>
                            ))}
                    </div>
                    {/*{_.isEmpty(errors) && average > 0 && (*/}
                    {/*    <div className="row mx-0">*/}
                    {/*    <h6>*/}
                    {/*            Final score:&nbsp;&nbsp;*/}
                    {/*            <span className="bg-pink p-3 rounded-2">*/}
                    {/*                {average}*/}
                    {/*            </span>*/}
                    {/*        </h6>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </div>
            </ModalLayout.Body>
            {viewOnly ? (
                <></>
            ) : (
                <ModalLayout.Footer>
                    <div className="d-flex w-100">
                        <SubmitButton
                            variant="primary"
                            disabled={
                                !(
                                    values.painInPastWeek !== undefined &&
                                    values.painInterferenceWithEnjoyment !== undefined &&
                                    values.painInterferenceWithGeneralActivity !== undefined
                                )
                            }
                        >
                            Submit
                        </SubmitButton>
                    </div>
                </ModalLayout.Footer>
            )}
        </>
    );
}
