import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { OrderContext } from "../../../../providers/OrderProvider";
import { getDisplayAddress } from "../../../../utils/dataUtils";

import { Form, SubmitButton, TextField } from "../../../../components/forms";
import Collapsible from "../../../../components/collapsible/Collapsible";
import DeliveryTypeCard from "../../../../components/DeliveryTypeCard";
import PhoneNumberInput from "../../../../components/forms/PhoneNumberInput";
import GoBack from "../../../../components/GoBack";
import PrescriptionSummary from "../../../../components/prescriptionSummary/PrescriptionSummary";

function OrderConfirmPage() {
    const navigate = useNavigate();

    const { order, prescription, deliveryType, setDeliveryType } =
        useContext(OrderContext);

    const [initValues, setInitValues] = useState({});

    useEffect(() => {
        if (order) {
            setInitValues({
                recipient: order?.patient.displayName,
                mobileNo: order?.patient.phone,
                postCode: order?.patient.address.postcode,
                address: getDisplayAddress(order?.patient.address),
            });
        }
    }, [order]);

    const handleSubmit = () => {
        navigate(`/order/${order.id}/payment`);
    };

    return (
        <div className="row mx-0 h-100">
            <div className="col-md-6 pt-5 position-relative bg-light-gray">
                <div className="row mx-0 mb-0 mb-md-8">
                    <div className="col-12 col-lg-11 col-xl-9 col-xxl-8 mx-auto pb-5 pb-md-0 mb-0 mb-md-8">
                        <GoBack />
                        <h3 className="text-center fw-bold text-brown-black mt-4">
                            Delivery details
                        </h3>
                        <div className="d-flex justify-content-center mt-5">
                            <Form
                                onSubmit={handleSubmit}
                                initialValues={initValues}
                                enableReinitialize
                            >
                                <div className="mx-auto">
                                    <div className="row mb-4">
                                        <TextField
                                            name="recipient"
                                            placeholder="Recipient"
                                            disabled
                                        />
                                    </div>
                                    <div className="row mb-4">
                                        <PhoneNumberInput
                                            name="mobileNo"
                                            placeholder="Mobile number"
                                            disabled
                                        />
                                    </div>
                                    <div className="row mb-4">
                                        <TextField
                                            name="postCode"
                                            placeholder="Post code"
                                            disabled
                                        />
                                    </div>
                                    <div className="row mb-4">
                                        <TextField
                                            name="address"
                                            placeholder="Address"
                                            disabled
                                        />
                                    </div>
                                    <div className="row mb-4 justify-content-between">
                                        <p className="mt-3 mb-2 body body-small fw-bold">
                                            {/* Select your delivery type */}
                                            Delivery charge
                                        </p>
                                        {/* <div className="col-12 col-sm-6 col-md-12 col-lg-6 mb-3 mb-sm-0 mb-md-3 mb-lg-0">
                                            <DeliveryTypeCard
                                                onChangeDeliveryType={() =>
                                                    setDeliveryType("EXPRESS")
                                                }
                                                active={
                                                    deliveryType === "EXPRESS"
                                                }
                                                delType="Express (£15)"
                                                estTime="1 - 2 days delivery"
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-12 col-lg-6">
                                            <DeliveryTypeCard
                                                onChangeDeliveryType={() =>
                                                    setDeliveryType("STANDARD")
                                                }
                                                active={
                                                    deliveryType === "STANDARD"
                                                }
                                                delType="Standard (£4)"
                                                estTime="2 - 3 days delivery"
                                            />
                                        </div> */}
                                        <div className="col-12">
                                            <DeliveryTypeCard
                                                onChangeDeliveryType={() =>
                                                    setDeliveryType("STANDARD")
                                                }
                                                active={
                                                    deliveryType === "STANDARD"
                                                }
                                                delType="Shipping (£ 0)"
                                                // delType="Shipping (£9)"
                                                // estTime="2 - 3 days delivery"
                                            />
                                        </div>
                                    </div>
                                    <div className="row mx-0 mt-6 mb-4">
                                        <SubmitButton disabled={!deliveryType}>
                                            CONTINUE
                                        </SubmitButton>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 pt-10 px-2 px-lg-6 mb-5 bg-white">
                <div className="row mx-0 mb-8 mb-md-0 pb-8 pb-md-0">
                    <div className="col-12 col-lg-11 col-xl-9 col-xxl-8 mx-auto pb-5 pb-md-0 mb-0 mb-md-8">
                        <Collapsible title="Order Summary" expanded>
                            <PrescriptionSummary prescription={prescription} />
                        </Collapsible>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderConfirmPage;
