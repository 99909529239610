import React from "react";

import "./surgery-card.scss";

export default function SurgeryCard({
    title,
    phone,
    address,
    onClick,
}) {

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <div className="surgery-card p-4" onClick={handleClick}>
            <p className="text-start fw-bold mb-4 fs-6 text-primary">{title}</p>
            {phone && (
                <div className="d-flex">
                    <img
                        className="my-auto me-2"
                        src="/images/vectors/phone-active.svg"
                        alt=""
                    />
                    <p className="my-auto">{phone}</p>
                </div>
            )}
            {address && (
                <div className="d-flex mt-2">
                    <img
                        className="my-auto me-2"
                        src="/images/vectors/location-active.svg"
                        alt=""
                    />
                    <p className="my-auto text-truncate">{address}</p>
                </div>
            )}
        </div>
    );
}
