/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { useFormikContext } from "formik";

import { AppointmentContext } from "../../providers/AppointmentProvider";
import { RepeatOrderContext } from "../../providers/RepeatOrderProvider";
import { useGetMyProfileQuery } from "../../services/userService";

import Button from "../../components/button/Button";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";

import "./date-time-select.scss";

dayjs.extend(advancedFormat);

export default function DateTimeSelect({
    availableSlots = [],
    isLoading,
    showAvailableDates,
    setShowAvailableDates,
    disabled,
}) {
    const navigate = useNavigate();
    const selectedTimeSlotRef = useRef(null);
    const { values, setFieldValue } = useFormikContext();
    const { setAppointmentData, appointmentData } =
        useContext(AppointmentContext);
    const { isRepeatOrder } = useContext(RepeatOrderContext);

    const [timeSlotThreshold, setTimeSlotThreshold] = useState(5);

    const { data: profileData } = useGetMyProfileQuery();

    const handleSubmit = async (time) => {
        await setFieldValue("timeSlot", time);

        const { id, doctorId, datetime } = time;

        try {
            const appointment = {
                type: "BOOKING",
                id,
                datetime,
                doctorId,
                name: `Consultation (${dayjs(datetime).format(
                    "DD/MM/YYYY"
                )} ${dayjs(datetime).format("hh:mm a")})`,
                patient: profileData,
                deliveryType: "STANDARD",
                paymentType: "ONLINE",
                deviceType: "OTHER",
            };

            await setAppointmentData({ ...appointmentData, ...appointment });

            navigate(`/appointment/${id}/uploads`);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSetSelectedDateSlot = (date) => {
        setFieldValue("date", date);
        setShowAvailableDates(false);
    };

    const handleViewAllSlots = () => {
        if (timeSlots.length) {
            if (timeSlotThreshold === timeSlots.length) {
                setTimeSlotThreshold(3);
            } else {
                setTimeSlotThreshold(timeSlots.length);
            }
        }
    };

    const selectedDate = useMemo(() => {
        if (availableSlots.length > 0) {
            if (values.date) {
                return dayjs(values.date).format("YYYY-MM-DD");
            }

            return dayjs(availableSlots[0].datetime).format("YYYY-MM-DD");
        }

        return null;
    }, [availableSlots, values]);

    const dateSlots = useMemo(() => {
        let arr = [];
        availableSlots.forEach(({ datetime }) => {
            const date = dayjs(datetime);
            const formattedDate = `${date.year()}-${
                date.month() + 1
            }-${date.date()}`;

            arr.push(formattedDate);
        });

        let unique = [...new Set(arr)];

        return unique;
    }, [availableSlots]);

    const timeSlots = useMemo(() => {
        const selectedDateTimeSlots = availableSlots.filter(({ datetime }) => {
            const date = dayjs(datetime);
            const formattedDate = `${date.year()}-${
                date.month() + 1
            }-${date.date()}`;

            return !!dayjs(selectedDate).isSame(dayjs(formattedDate), "day");
        });
        return selectedDateTimeSlots;
    }, [availableSlots, selectedDate]);

    useEffect(() => {
        if (timeSlots.length > 0 && selectedTimeSlotRef.current) {
            selectedTimeSlotRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    }, [timeSlots, selectedTimeSlotRef]);

    useEffect(() => {
        if (availableSlots.length > 0) {
            setFieldValue("date", availableSlots[0].datetime);
        }
    }, [availableSlots]);

    if (isLoading) {
        return (
            <div className="mt-5 text-center">
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <div className="date-time-select d-flex flex-column text-center pb-6">
            {!showAvailableDates && (
                <div className="row mx-0 mt-5">
                    <h4 className="fw-bold">
                        For {dayjs(selectedDate).format("DD MMMM")}
                    </h4>
                </div>
            )}

            <div className="row mx-0 mt-2">
                <p className="fw-light text-dark-4">
                    {showAvailableDates
                        ? "Here are the available dates"
                        : "Pick a time slot that works best for you"}
                </p>
            </div>

            <div className="pt-4 pt-md-0">
                <div className="my-6 available-slots d-flex flex-column">
                    {!showAvailableDates
                        ? timeSlots?.map((timeSlot, idx) => {
                              const formattedStartTime = dayjs(
                                  timeSlot.datetime
                              ).format("hh:mm A");
                              const formattedEndTime = isRepeatOrder
                                  ? dayjs(timeSlot.datetime)
                                        .add(15, "minutes")
                                        .format("hh:mm A")
                                  : dayjs(timeSlot.datetime)
                                        .add(30, "minutes")
                                        .format("hh:mm A");

                              if (idx < timeSlotThreshold) {
                                  return (
                                      <div
                                          className="row mx-auto mb-2"
                                          key={timeSlot.id}
                                          ref={
                                              values.timeSlot.id === timeSlot.id
                                                  ? selectedTimeSlotRef
                                                  : null
                                          }
                                      >
                                          <Button
                                              variant={`${
                                                  values.timeSlot.id ===
                                                  timeSlot.id
                                                      ? "primary text-white"
                                                      : "white"
                                              } w-fit slot fw-bold`}
                                              fullWidth={false}
                                              onClick={() =>
                                                  handleSubmit(timeSlot)
                                              }
                                              disabled={disabled}
                                          >
                                              {formattedStartTime} -{" "}
                                              {formattedEndTime}
                                          </Button>
                                      </div>
                                  );
                              }

                              return null;
                          })
                        : dateSlots?.map((dateSlot, idx) => {
                              const formattedDate =
                                  dayjs(dateSlot).format("Do MMMM");
                              return (
                                  <div
                                      className="row mx-auto mb-2"
                                      key={idx}
                                      //ref={values.timeSlot.id === timeSlot.id ? selectedTimeSlotRef : null}
                                  >
                                      <Button
                                          variant="white w-fit slot fw-bold"
                                          fullWidth={false}
                                          onClick={() =>
                                              handleSetSelectedDateSlot(
                                                  dateSlot
                                              )
                                          }
                                          disabled={disabled}
                                      >
                                          {formattedDate}
                                      </Button>
                                  </div>
                              );
                          })}
                </div>
                {!showAvailableDates && (
                    <div className="row mx-0 justify-content-center">
                        <div className="col-md-9 col-xl-6 col-xxl-5">
                            <div className="row align-items-center justify-content-around">
                                {timeSlots.length > 5 && (
                                    <div className="col-12 col-sm-6 px-1 px-sm-none">
                                        <Button
                                            onClick={handleViewAllSlots}
                                            variant=" border-primary-1 text-primary d-flex justify-content-center align-items-center px-2"
                                        >
                                            <img
                                                src="/images/vectors/clock-primary.svg"
                                                className="me-2"
                                                alt=""
                                            />{" "}
                                            <span className="fw-bold">
                                                {timeSlotThreshold ===
                                                timeSlots.length
                                                    ? "SEE LESS"
                                                    : "VIEW ALL THE TIMES"}
                                            </span>
                                        </Button>
                                    </div>
                                )}

                                <div className="col-12 col-sm-6 mt-2 px-1 px-sm-none mt-sm-0">
                                    <Button
                                        onClick={() =>
                                            setShowAvailableDates(true)
                                        }
                                        variant="primary d-flex justify-content-center align-items-center px-2"
                                    >
                                        <img
                                            src="/images/vectors/calendar-white.svg"
                                            className="me-2"
                                            alt=""
                                        />
                                        <span className="fw-bold">
                                            CHOOSE A DIFFERENT DATE
                                        </span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
