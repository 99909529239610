import React from "react";
import Button from "../button/Button";

import "./timer-window.scss";

export default function TimerWindow({
    success = false,
    showTimer = true,
    title,
    description,
    action,
    actionBtnText,
    titleClass,
    loading,
}) {
    const handleActionButtonClick = () => {
        if (action) {
            action();
        }
    };

    return (
        <div
            className={`timer-window ${
                !showTimer && "short"
            } text-center justify-content-center d-flex flex-column ${
                loading ? "loading-only" : "position-relative"
            }`}
        >
            {success && (
                <div className="d-flex justify-content-center mb-5">
                    <img src="/images/vectors/success.svg" alt="" width={25} />
                </div>
            )}
            {loading && (
                <div className="d-flex justify-content-center mb-5">
                    <img src="/images/loading-heart.gif" alt="" width="25%" />
                </div>
            )}
            <h4 className={`mb-0 fw-bold ${titleClass}`}>{title}</h4>
            <label className="body body-small text-dark-5 mt-3 mb-8">
                {description}
            </label>
            {action && (
                <div className="col-12 col-sm-7 col-lg-5 mb-3 mb-lg-0 mx-auto">
                    <Button variant="primary" onClick={handleActionButtonClick}>
                        {actionBtnText}
                    </Button>
                </div>
            )}
            {showTimer && (
                <div className="circle-timer">
                    <div className="circle">
                        <div className="mask half">
                            <div className="fill"></div>
                        </div>
                        <div className="mask full">
                            <div className="fill"></div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
