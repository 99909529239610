import { useContext } from "react";
import {
    CardNumberElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { NavLink, useNavigate } from "react-router-dom";
import { useFormikContext } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";

import { useModal } from "../../../../hooks/modalsHooks";
import {
    useAddMedicationPaymentMutation,
    useLazyGetPaymentIntentQuery,
} from "../../../../services/paymentService";
import { OrderContext } from "../../../../providers/OrderProvider";
import parseError from "../../../../utils/errorUtils";

import { Checkbox, Form, SubmitButton } from "../../../../components/forms";
import CardInput from "../../../../components/cardInput/CardInput";
import { useLoggerUtils } from "../../../../utils/loggerUtils";

const PaymentFormSchema = yup.object({
    name: yup.string().required("Your name on the card is incomplete."),
});

export function PrescriptionCheckoutForm({ initialValues }) {
    const { order, prescription, deliveryType } = useContext(OrderContext);

    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const { info } = useLoggerUtils();

    const [getPaymentIntent] = useLazyGetPaymentIntentQuery();
    const [payPrescription] = useAddMedicationPaymentMutation();

    const onPaymentDetailsSubmit = async () => {
        try {
            const paymentIntent = await getPaymentIntent();

            const stripeResponse = await stripe.confirmCardSetup(
                paymentIntent.data.client_secret,
                {
                    payment_method: {
                        card: elements.getElement(CardNumberElement),
                    },
                }
            );

            if (stripeResponse?.error) {
                let stripeResponse_Obj = {
                    code: stripeResponse?.error ? stripeResponse.error.code : "",
                    decline_code: stripeResponse?.error ? stripeResponse.error.decline_code : "",
                    doc_url: stripeResponse?.error ? stripeResponse.error.doc_url : "",
                    message: stripeResponse?.error ? stripeResponse.error.message : "",
                }
                info("stripeResponse: " + JSON.stringify(stripeResponse_Obj));
                toast.error(
                    stripeResponse.error.message || "Couldn't complete payment"
                );
            }

            const res = await payPrescription({
                values: {
                    id: prescription.id,
                    deliveryType: deliveryType,
                    paymentType: "ONLINE",
                    paymentMethodID: stripeResponse.setupIntent.payment_method,
                },
            });

            if (res.error) {
                // handle error
                info("payPrescription - error: " + JSON.stringify(res));
                toast.error(parseError(res.error, "Couldn't complete payment"));
            } else {
                //handle success
                // info("payPrescription - success: " + JSON.stringify(res));
                navigate(`/order/${order.id}/success`, { replace: true });
            }
        } catch (e) {
            info("payPrescription - catch: " + JSON.stringify(e));
            console.error(e);
        }
    };

    return (
        <Form
            initialValues={initialValues}
            onSubmit={onPaymentDetailsSubmit}
            validationSchema={PaymentFormSchema}
            enableReinitialize
        >
            <CardInput />
            <PrescriptionCheckoutFooter />
        </Form>
    );
}

function PrescriptionCheckoutFooter() {
    const { showModal, hideModal } = useModal();

    const { values, setFieldValue } = useFormikContext();

    const handleConsent = (val) => {
        setFieldValue("consent", val);

        hideModal({
            modalType: "ConsentModal",
        });
    };

    const handleConsentOrder = () => {
        showModal({
            modalType: "ConsentModal",
            modalProps: {
                onAccept: () => handleConsent(true),
                onDecline: () => handleConsent(false),
            },
        });
    };

    return (
        <>
            <div className="mt-4">
                <Checkbox
                    label={
                        <p className="mb-0 fw fs-6">
                            I have read and understood the
                            <NavLink
                                to="/terms-condition"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="fw-bold text-black text-decoration-underline cursor-pointer mx-1"
                            >
                                terms & conditions
                            </NavLink>
                            prior to booking a consultation
                        </p>
                    }
                    name="terms_and_conditions"
                />
            </div>
            <div className="mt-4">
                <Checkbox
                    label={
                        <p className="mb-0 fw fs-6">
                            I have read and approved the
                            <span
                                className="fw-bold text-black text-decoration-underline cursor-pointer mx-1"
                                onClick={handleConsentOrder}
                            >
                                controlled drug consent form
                            </span>
                            .
                        </p>
                    }
                    name="consent"
                    disabled
                />
            </div>
            <div className="row mx-0 mt-6 mb-4">
                <SubmitButton
                    disabled={!values.consent || !values.terms_and_conditions}
                >
                    CONFIRM PAYMENT
                </SubmitButton>
            </div>
        </>
    );
}
