import React from "react";
import { useFormikContext } from "formik";

import { SelectableRadioCard, TextField } from "../../../components/forms";

export default function ReferredByForm({ options }) {
    const { values, errors, touched } = useFormikContext();

    return (
        <>
            <div className="row">
                {options.map((option, idx) => (
                    <div className="col-12 col-md-6 mb-3" key={idx}>
                        <SelectableRadioCard
                            name="referredBy"
                            id={option.id}
                            label={option.label}
                        />
                    </div>
                ))}
            </div>
            {errors.referredBy && touched.referredBy && (
                <div className="text-danger">Please select an option</div>
            )}
            {values?.referredBy === "other" && (
                <div className="mt-3">
                    <TextField
                        label="Other*"
                        labelClass="text-primary fs-5 fw-semibold"
                        name="other"
                        placeholder="Type your answer here..."
                    />
                </div>
            )}
        </>
    );
}
