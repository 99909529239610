import Collapsible from "../collapsible/Collapsible";

export default function PatientNotes({ note }) {
    return (
        <Collapsible
            title="Patient Notes"
            className="px-0 pb-3 w-100"
            textColor="h6 text-dark fw-semibold"
        >
            <p className="body body-small fw-normal text-start mb-0">{note}</p>
        </Collapsible>
    );
}
