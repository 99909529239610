import React from "react";

import Price from "../price/Price";

import { SUPPORT_EMAIL } from "../../constants/appProperties";

import "./invoice.scss";

export default function Invoice({
    elementId,
    invoiceNumber,
    invoiceDate,
    customerName,
    address,
    customerEmail,
    lineItems,
    subTotal = 0,
    discount = 0,
    prescriptionFee = 0,
    baseDelivery = 0,
    deliveryVat = 0,
    totalAmount = 0,
    paid,
    pharmacy,
    gphcNumber,
}) {
    return (
        <div className="invoice-container overflow-auto" id={elementId}>
            <div className="invoice" id="invoice">
                <div className="header d-flex">
                    <h3 className="me-auto fw-bold">Invoice</h3>
                    <div className="ms-auto">
                        <img
                            className="logo"
                            src="/images/logo.png"
                            alt=""
                            width="150"
                        />
                    </div>
                </div>
                {/* <div className="w-50 row">
                    <div className="col-4">
                        <span className="fw-semibold">VAT number</span>
                    </div>
                    <div className="col-6">
                        <span className="fw-normal">12345</span>
                    </div>
                </div> */}
                <div className="my-5 invoice-details">
                    <div className="row mb-4">
                        <div className="col-6">
                            <div className="row">
                                <div className="col-4">
                                    <span className="fw-semibold">
                                        Invoice number
                                    </span>
                                </div>
                                <div className="col-6">
                                    <span className="fw-semibold">
                                        {invoiceNumber}
                                    </span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <span className="fw-normal">
                                        Invoice date
                                    </span>
                                </div>
                                <div className="col-6">
                                    <span className="fw-normal">
                                        {invoiceDate}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6"></div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <p className="mb-0 fw-semibold">Treat It</p>
                            <p className="mb-0 fw-normal">
                                The GP Service (UK) Limited
                            </p>
                            <p className="mb-0 fw-normal">HDTI Building</p>
                            <p className="mb-0 fw-normal">
                                University Technology Park, Puma Way
                            </p>
                            <p className="mb-0 fw-normal">
                                Coventry CV1 2TT, UK
                            </p>
                            <p className="mb-0 fw-normal">{SUPPORT_EMAIL}</p>
                        </div>
                        <div className="col-6">
                            <p className="mb-0 fw-semibold">Bill to</p>
                            <p className="mb-0 fw-normal">{customerName}</p>
                            <p className="mb-0 fw-normal">{address?.line1}</p>
                            <p className="mb-0 fw-normal">{address?.line2}</p>
                            <p className="mb-0 fw-normal">{address?.city}</p>
                            <p className="mb-0 fw-normal">{address?.country}</p>
                            <p className="mb-0 fw-normal">
                                {address?.postcode}
                            </p>
                            <p className="mb-0 fw-normal">{customerEmail}</p>
                        </div>
                    </div>
                </div>
                <span>Treat It is operated by The GP Service (UK) Limited</span>
                <div className="invoice-table position-relative my-5">
                    <div className="row mx-0">
                        <table className="table table-responsive mb-0">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Qty</th>
                                    <th>Unit price</th>
                                    <th>VAT %</th>
                                    <th className="d-flex">
                                        <span className="ms-auto">Net</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {lineItems?.map((data, idx) => (
                                    <tr key={idx}>
                                        <td>
                                            <span>{data.title}</span>
                                            {data.desc && (
                                                <ul
                                                    className="medication-points m-0 mt-2 ps-5 pe-0"
                                                    style={{
                                                        listStyleType: "square",
                                                    }}
                                                    key={idx}
                                                >
                                                    {data.desc.adminRoute && (
                                                        <li>
                                                            Admin route -{" "}
                                                            {
                                                                data.desc
                                                                    .adminRoute
                                                            }
                                                        </li>
                                                    )}
                                                    {data.desc.quantity && (
                                                        <li>
                                                            Quantity -{" "}
                                                            {data.desc.quantity}
                                                        </li>
                                                    )}
                                                    {data.desc
                                                        .medicineProfile && (
                                                        <li>
                                                            Med. profile -{" "}
                                                            {
                                                                data.desc
                                                                    .medicineProfile
                                                            }
                                                        </li>
                                                    )}
                                                    {data.desc.strain && (
                                                        <li>
                                                            Strain -{" "}
                                                            {data.desc.strain}
                                                        </li>
                                                    )}
                                                </ul>
                                            )}
                                        </td>
                                        <td>{data.qty}</td>
                                        <td>
                                            <Price
                                                amount={data.unitPrice}
                                                currency="GBP"
                                            />
                                        </td>
                                        <td>
                                            <Price amount={data.vat} />
                                        </td>
                                        <td>
                                            <span className="float-right">
                                                <Price
                                                    amount={
                                                        data.net +
                                                        data.net *
                                                            data.qty *
                                                            data.vat
                                                    }
                                                    currency="GBP"
                                                />
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="row mx-0">
                        <div className="offset-6 col-6 px-0">
                            <table className="table table-responsive">
                                <tbody>
                                    <tr>
                                        <td>Subtotal</td>
                                        <td className="d-flex">
                                            <span className="ms-auto">
                                                <Price
                                                    amount={subTotal}
                                                    currency="GBP"
                                                />
                                            </span>
                                        </td>
                                    </tr>
                                    {discount > 0 ? (
                                        <tr>
                                            <td>Discount</td>
                                            <td className="d-flex">
                                                <span className="ms-auto">
                                                    -
                                                    <Price
                                                        amount={discount}
                                                        currency="GBP"
                                                    />
                                                </span>
                                            </td>
                                        </tr>
                                    ) : null}
                                    {baseDelivery > 0 ? (
                                        <tr>
                                            <td>Base delivery</td>
                                            <td className="d-flex">
                                                <span className="ms-auto">
                                                    <Price
                                                        amount={baseDelivery}
                                                        currency="GBP"
                                                    />
                                                </span>
                                            </td>
                                        </tr>
                                    ) : null}
                                    {deliveryVat > 0 ? (
                                        <tr>
                                            <td>Delivery VAT</td>
                                            <td className="d-flex">
                                                <span className="ms-auto">
                                                    <Price
                                                        amount={deliveryVat}
                                                        currency="GBP"
                                                    />
                                                </span>
                                            </td>
                                        </tr>
                                    ) : null}
                                    <tr>
                                        <td className="fw-bold">Total</td>
                                        <td className="d-flex fw-bold">
                                            <span className="ms-auto">
                                                <Price
                                                    amount={totalAmount}
                                                    currency="GBP"
                                                />
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {!paid && <h2 className="watermark fw-bold">UNPAID</h2>}
                {pharmacy && (
                    <div className="mt-8">
                        <h5 className="fw-bold">Notes:</h5>
                        <span>
                            Medications are supplied by {pharmacy}{" "}
                            {gphcNumber && `(GPhC ${gphcNumber})`}
                        </span>
                    </div>
                )}
                <div className="footer d-flex">
                    <div className="me-auto">
                        The GP Service (UK) Limited is registered in England and
                        Wales under company number 09359853
                    </div>
                </div>
            </div>
        </div>
    );
}
