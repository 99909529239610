import React from "react";
import { useField, useFormikContext } from "formik";

import "./selectable-card.scss";

export default function SelectableRadioCard({
    name,
    id,
    onChange,
    label,
    disabled,
    ...rest
}) {
    const { setFieldValue } = useFormikContext();
    const [field] = useField({ name, label, ...rest });

    const handleChange = (name, id) => {
        setFieldValue(name, id);
        if (onChange) {
            onChange(name, id);
        }
    };

    return (
        <div className="selectable-card">
            <input
                name={name}
                className="selectable-radio"
                type="radio"
                checked={field.value === id || field?.value?.choice === id}
                onChange={() => {
                    handleChange(name, id);
                }}
                id={`${name}-${id}`}
                disabled={disabled}
            />
            <label className="selectable-card d-flex fs-5" htmlFor={`${name}-${id}`}>
                {label}
                {(field.value === id || field?.value?.choice === id) && (
                    <img
                        src="/images/vectors/tick-primary.svg"
                        alt=""
                        className="ms-auto"
                    />
                )}
            </label>
        </div>
    );
}
