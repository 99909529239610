import React, { useState } from "react";
import useCollapse from "react-collapsed";

import "./collapsible.scss";

function Collapsible({
    title,
    children,
    expanded,
    className,
    textColor,
    logo,
    onClickArrow,
    link = false,
}) {
    const [isExpanded, setExpanded] = useState(expanded || false);

    const { getCollapseProps, getToggleProps } = useCollapse({
        isExpanded: isExpanded,
    });

    const handleClick = () => {
        if (onClickArrow) {
            onClickArrow();
        }
    };

    return (
        <div className={`collapsible ${className}`}>
            <div aria-label="button" onClick={handleClick}>
                <div
                    {...(onClickArrow
                        ? null
                        : {
                              ...getToggleProps({
                                  onClick: () =>
                                      setExpanded(
                                          (prevExpanded) => !prevExpanded
                                      ),
                              }),
                          })}
                    className="d-flex justify-content-between align-items-center cursor-pointer"
                >
                    <div className="d-flex align-items-center">
                        {logo && (
                            <div className="logo-section me-3 me-lg-4">
                                <img
                                    src={`/images/vectors/${logo}`}
                                    alt="collapsible-logo"
                                    width={34}
                                    height={34}
                                ></img>
                            </div>
                        )}
                        <h3
                            className={`${textColor} fw-bold mb-0 text-truncate ${
                                logo ? "long" : "short"
                            }`}
                        >
                            {title}
                        </h3>
                    </div>
                    <img
                        src="/images/vectors/down-primary.svg"
                        alt=""
                        style={{
                            transform: link
                                ? isExpanded
                                    ? "rotate(360deg)"
                                    : "rotate(270deg)"
                                : isExpanded
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                            transition: "0.3s",
                        }}
                    />
                </div>
                <div className="mt-4" {...getCollapseProps()}>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Collapsible;
