/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useGetAppointmentsQuery } from "../../services/orderService";
import { useGetMyProfileQuery } from "../../services/userService";
import { AppointmentContext } from "../../providers/AppointmentProvider";
import { RepeatOrderContext } from "../../providers/RepeatOrderProvider";

import { Form } from "../../components/forms";
import DateTimeSelect from "./DateTimeSelect";
import GoBack from "../../components/GoBack";
import TaskCard, { TASK_STATUS } from "../../components/taskCard/TaskCard";

export default function AppointmentPage() {
    const navigate = useNavigate();

    const { setAppointmentData } = useContext(AppointmentContext);
    const {
        parentOrderId,
        isRepeatOrder,
        canOrder,
        hasSixMonthsPassed,
        isLoading: repeatOrderDataLoading,
    } = useContext(RepeatOrderContext);

    const [initialValues] = useState({ date: "", timeSlot: "" });
    const [showAvailableDates, setShowAvailableDates] = useState(false);

    const { data: profileData } = useGetMyProfileQuery();
    const { data: appointmentData, isLoading } = useGetAppointmentsQuery(
        {
            page: 0,
            pageSize: 320,
            type: hasSixMonthsPassed
                ? "repeat"
                : isRepeatOrder
                ? "repeat"
                : "full",
        },
        { skip: repeatOrderDataLoading }
    );

    const handleClickBack = () => {
        navigate("/user/dashboard");
    };

    useEffect(() => {
        if (!parentOrderId) {
            setAppointmentData({});
        }
    }, [parentOrderId]);

    useEffect(() => {
        if (!repeatOrderDataLoading && !canOrder) {
            navigate("/user/dashboard", { replace: true });
        }
    }, [profileData, canOrder]);

    return (
        <div className="appointment-page pt-5 mb-10 pb-11 px-3 px-md-8">
            <div className="d-flex flex-column flex-xl-row justify-content-around">
                <GoBack onClickBack={parentOrderId ? null : handleClickBack} />
                <h2 className="title text-center mt-4 mt-xl-0 text-xl-none justify-content-center text-primary fw-bold mb-0">
                    Book your consultation
                </h2>
                <div className="invisible">
                    <GoBack />
                </div>
            </div>
            {appointmentData?.content.length > 0 ? (
                <Form initialValues={initialValues}>
                    <DateTimeSelect
                        availableSlots={appointmentData?.content || []}
                        isLoading={isLoading}
                        showAvailableDates={showAvailableDates}
                        setShowAvailableDates={setShowAvailableDates}
                    />
                </Form>
            ) : (
                <div className="d-flex justify-content-center mt-5 px-2 px-sm-0 w-100">
                    <TaskCard
                        status={TASK_STATUS.ALERT}
                        title="No available appointment dates at the moment"
                        subTitle="Please try again later"
                    />
                </div>
            )}
        </div>
    );
}
