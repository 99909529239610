import React from "react";

import LoadingSpinner from "../loadingSpinner/LoadingSpinner";
import Collapsible from "../collapsible/Collapsible";
import DocumentViewer from "../documentViewer/DocumentViewer";
import TaskCard, { TASK_STATUS } from "../taskCard/TaskCard";

export default function CollapsibleDocumentViewer({
    isLoading,
    files,
    emptyMessage,
}) {
    return isLoading ? (
        <div className="d-flex justify-content-center">
            <LoadingSpinner />
        </div>
    ) : (
        <>
            {files.length > 0 ? (
                files.map((file) => (
                    <div className="mb-3" key={file.id}>
                        <Collapsible
                            title={file?.fileName}
                            className="border-1 border-bottom px-0 pb-3"
                            textColor="text-dark body body-small"
                            logo="document.svg"
                        >
                            <DocumentViewer fileData={file} />
                        </Collapsible>
                    </div>
                ))
            ) : (
                <TaskCard
                    status={TASK_STATUS.ALERT}
                    title="Nothing to show"
                    subTitle={emptyMessage}
                />
            )}
        </>
    );
}
