/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useModal } from "../../../hooks/modalsHooks";

import Button from "../../../components/button/Button";
import ContactCard from "../../../components/contactCard/ContactCard";

import {
    SUPPORT_TELEPHONE,
    SUPPORT_WHATSAPP,
} from "../../../constants/appProperties";

export default function EligibilityConditionExitPage() {
    const navigate = useNavigate();
    const { showModal, hideAllModals } = useModal();

    const handleNavigate = () => {
        navigate("/onboarding/eligibility/condition");
    };

    useEffect(() => {
        showModal({ modalType: "JoinMailingListModal" });

        return () => hideAllModals();
    }, []);

    return (
        <div className="diagnosis-exit-page px-3 m-auto">
            <div className="d-flex flex-column">
                <h2 className="text-primary fw-bold mb-5 text-center">
                    We understand you are struggling with mental health
                    challenges. These issues can be difficult, but know you
                    don't have to cope alone.
                </h2>
                <p className="fs-5 subtitle text-primary-200 text-center mb-0 mx-2 mx-lg-10 px-lg-10">
                    While we don’t currently offer mental health treatment, we
                    hope to soon. We know it's frustrating to wait for care.
                    However, our support team can help guide you toward
                    beneficial alternatives in the meantime.
                </p>
                <p className="fs-5 subtitle text-primary-200 text-center mb-10 mx-2 mx-lg-10 px-lg-10">
                    Don't lose hope. We are committed to assisting you in
                    finding the treatment you need. Please connect with our
                    caring support team via:
                </p>
                <div className="d-flex flex-column shadow-sm justify-content-center mx-auto mb-10 px-2 px-sm-5 py-4 card border-0 rounded-1 w-100 bg-white bg-opacity-50">
                    <h4 className="text-primary text-center fw-bold mb-4">
                        Contact Treat it Support:
                    </h4>
                    <div className="row">
                        <div className="col-12 col-md-6 mb-3">
                            <ContactCard
                                icon="phone-primary"
                                text="Treat it number:"
                                type="tel"
                                contact={SUPPORT_TELEPHONE}
                            />
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                            <ContactCard
                                icon="whatsapp"
                                text="Treat It WhatsApp:"
                                contact={SUPPORT_WHATSAPP}
                                contactText="0738 582 8801"
                            />
                        </div>
                    </div>
                </div>

                <div className="button-container mx-auto">
                    <Button
                        variant="primary text-start mb-4 fw-light py-5 fs-5"
                        onClick={handleNavigate}
                    >
                        <div className="d-flex">
                            <img
                                className="me-2 my-auto"
                                src="/images/vectors/arrow-right.svg"
                                alt=""
                            />
                            Continue if you suffer from other pain conditions we
                            may be able to address
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    );
}
