import React from 'react';
import QRCode from 'react-qr-code';

import "./public.scss";
import * as avatarImage from "./avatarImage";
import * as ukFlagImage from "./ukFlag";

export default function PhysicalCard(props) {

    const profilePicture = props.cannabisCard?.proImageUrl ? props.cannabisCard?.proImageUrl : avatarImage.default;
    const token = props.token;

    var baseURL = window.location.href.split('user/')[0];
    baseURL = baseURL === 'http://localhost:3006/' ? 'https://app.dev.treatit.thegpservice.com/' : baseURL;
    const cannabisCardURL = 'cannabis-card/';
    var qrUrl = baseURL + cannabisCardURL + token;
    
    return (
        <div className="container mb-5 scaled-container">
            <div className="card-display">
                {/* top row with the Flag */}
                <div className="row mx-0">
                    <div className="col-8 highlight round-br title-col pb-5">
                        <h3 className="mt-3 fw-bold">Medical Cannabis Prescription Holder</h3>
                        <p>This patient is a UK registered cannabis patient</p>
                    </div>
                    <div className="col-4 flag-col pr-40">
                        <img className="flag-img mt-2" src={`data:image/png;base64,${ukFlagImage.default}`} alt="" />
                    </div>
                </div>

                {/* information row */}
                <div className="row mx-0 mt-5 mb-5 pl-40">
                    <div className="col-2 px-0">
                        <img className="id-image" src={`data:image/png;base64,${profilePicture}`} alt="Profile Picture" />
                    </div>

                    <div className="col-3 pt-2">
                        <p className="fs-18 mb-0">Given Name</p>
                        <p className="fs-22 fw-bold text-capitalize">{props.cannabisCard?.firstName ? props.cannabisCard.firstName : 'XXXXX'}</p>

                        <p className="fs-18 mb-0">Card Number</p>
                        <p className="fs-22 fw-bold">{props.cannabisCard?.cardNo ? props.cannabisCard.cardNo : 'XXXXX'}</p>
                    </div>

                    <div className="col-4 pt-2">
                        <p className="fs-18 mb-0">Surname</p>
                        <p className="fs-22 fw-bold text-capitalize">{props.cannabisCard?.lastName ? props.cannabisCard.lastName : 'XXXXX'}</p>

                        <p className="fs-18 mb-0">Expiry Date</p>
                        <p className="fs-22 fw-bold">3 months from<br />prescription</p>
                    </div>

                    <div className="col-3 px-0 text-center qr-code-col">
                        <QRCode
                            title="My Cannabis Card"
                            value={qrUrl ? qrUrl : 'https://treat-it.clinic/'}
                            size={150}
                        />
                        <p className='mt-2'>Scan for Prescription</p>
                    </div>
                </div>

                {/* declaration row */}
                <div className="row mx-0 highlight">
                    <p className="fs-18 mt-5 mb-5 text-justify declaration-text">
                        The patient named and pictured above has been lawfully prescribed and supplied 
                        this treatment in accordance with current applicable regulations in the UK. To
                        verify the authenticity of the supply, supervising doctor and regulated pharmacy, 
                        <b> please scan the QR code above for full details</b>. For more information, 
                        call the clinic on 0247 745 0733 or visit our website: treat-it.clinic
                    </p>
                </div>
            </div>
        </div>
    );
}

