import { useFormikContext } from "formik";

import ModalLayout from "../modals/ModalLayout";
import { Checkbox, SubmitButton } from "../forms";

import { CONSENT_FORM } from "../../constants/orderNotesQuestions";

export default function ConsentForm({ viewOnly = false }) {
    const { values } = useFormikContext();

    return (
        <>
            <ModalLayout.Body
                className={`d-flex d-sm-block p-5 ${viewOnly ? "px-2 mx-1" : ""}`}
            >
                <div className="my-auto">
                    <h5 className="fw-light text-start mb-3">
                        I confirm that:
                    </h5>
                    <div className="fw-light">
                        {CONSENT_FORM.keys.map((key) => (
                            <div
                                className="me-0 me-lg-3 mb-2 mb-lg-0"
                                key={key}
                            >
                                <Checkbox
                                    name={key}
                                    label={CONSENT_FORM.data[key]}
                                    disabled={viewOnly}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </ModalLayout.Body>
            {viewOnly ? (
                <></>
            ) : (
                <ModalLayout.Footer>
                    <div className="d-flex w-100">
                        <SubmitButton
                            variant="primary"
                            disabled={
                                !(
                                    values.ageOver25 &&
                                    values.answeredAssessment &&
                                    values.soleUseAcceptance &&
                                    values.agreeToReadInfo &&
                                    values.understandTnc
                                )
                            }
                        >
                            Submit
                        </SubmitButton>
                    </div>
                </ModalLayout.Footer>
            )}
        </>
    );
}
