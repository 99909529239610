/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { GoogleLogin } from "@react-oauth/google";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import {
    useSignUpMutation,
    useSocialSignupMutation,
} from "../../../services/authService";
import { useSubscribeToMailMutation } from "../../../services/dataService";
import { useAuth } from "../../../utils/authUtils";
import parseError from "../../../utils/errorUtils";

import {
    Checkbox,
    SubmitButton,
    Form,
    TextField,
} from "../../../components/forms";
import PasswordConditions from "../../../components/passwordConditions/PasswordConditions";
import Button from "../../../components/button/Button";
import GoBack from "../../../components/GoBack";

import { ACCESS_TOKEN_NAME } from "../../../constants";
import { ONBOARDING_ELIGIBILITY_QUESTIONS } from "../../../constants/onboardingEligibilityQuestions";

import "./create-account-page.scss";

const SignUpSchema = yup.object({
    email: yup
        .string()
        .email("Please enter a valid email")
        .required("Please enter your email"),
    password: yup
        .string()
        .required("Please enter a password")
        .min(8, "Password must be at least 8 characters")
        .matches(
            /^(?=.*[a-z])/,
            "Must contain at least one lowercase character"
        )
        .matches(
            /^(?=.*[A-Z])/,
            "Must contain at least one uppercase character"
        )
        .matches(/^(?=.*[0-9])/, "Must contain at least one number")
        .matches(
            /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~€£¥•]/,
            "Must contain at least one special character"
        ),
    confirmPassword: yup
        .string()
        .required("Required")
        .oneOf([yup.ref("password"), null], "Passwords must match"),
    marketingConsent: yup.boolean().default(false),
});

export default function CreateAccountPage() {
    const navigate = useNavigate();
    const auth = useAuth();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [containsUpperCase, setContainsUpperCase] = useState(false);
    const [containsLowercase, setContainsLowerCase] = useState(false);
    const [containsNumber, setContainsNumber] = useState(false);
    const [containsSpecialChars, setContainsSpecialChars] = useState(false);
    const [containsMinChars, setContainsMinChars] = useState(false);
    const [createAccount, setCreateAccount] = useState(false);

    const [signUp] = useSignUpMutation();
    const [signUpWithSocial] = useSocialSignupMutation();
    const [subscribeToMail] = useSubscribeToMailMutation();

    const handleSubscribeToMail = async (email, name) => {
        try {
            const data = { email, body: name };

            const res = await subscribeToMail(data);

            if (res.error) {
                // handle error
                toast.error(parseError(res.error, "Couldn't subscribe"));
            } else {
                // request succeeded
                toast.success("Successfully subscribed");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmit = async (values) => {
        try {
            const captchaResponseToken = await handleReCaptchaVerify();

            const eligibilityForm = JSON.parse(
                localStorage.getItem("EligibilityForm") || "{}"
            );

            if (values.marketingConsent) {
                await handleSubscribeToMail(values.email, eligibilityForm.name);
            }

            delete eligibilityForm["name"];
            delete eligibilityForm["q7_6"];

            const eligibilityQuestionnaire = Object.keys(eligibilityForm).map(
                (key, idx) => {
                    const prevQuestion = Object.keys(eligibilityForm)[idx - 1];

                    return {
                        question:
                            ONBOARDING_ELIGIBILITY_QUESTIONS.data[key]?.title[
                                eligibilityForm[prevQuestion]
                            ] ||
                            ONBOARDING_ELIGIBILITY_QUESTIONS.data[key]?.title,
                        answer:
                            ONBOARDING_ELIGIBILITY_QUESTIONS.data[
                                key
                            ]?.answers.find(
                                (answer) => answer.id === eligibilityForm[key]
                            )?.label || eligibilityForm[key],
                    };
                }
            );

            if (captchaResponseToken) {
                let data = {
                    ...values,
                    username: values.email,
                    captchaResponseToken,
                    regStatus: "BASIC",
                    eligibilityQuestionnaire,
                };

                delete data["confirmPassword"];

                const res = await signUp({ values: data });

                if (res.error) {
                    // handle error
                    if (res.error.data?.status === 401) {
                        toast.error("Email already exists");
                    } else if (res.error.data?.status === 412) {
                        toast.error(
                            "Your reCAPTCHA verification was failed. Please try again."
                        );
                    } else {
                        toast.error(parseError(res.error, "Couldn't sign up"));
                    }
                } else {
                    // request succeeded
                    if (res.data.value) {
                        localStorage.setItem(ACCESS_TOKEN_NAME, res.data.value);
                        auth.logIn();
                        navigate("/onboarding/auth/success", { replace: true });
                    }
                }
            } else {
                toast.error("reCAPTCHA failed");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleKeyUp = (e) => {
        let value = e.target.value;
        if (/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~€£¥•]/.test(value)) {
            setContainsSpecialChars(true);
        } else {
            setContainsSpecialChars(false);
        }

        if (/^(?=.*[a-z])/.test(value)) {
            setContainsLowerCase(true);
        } else {
            setContainsLowerCase(false);
        }

        if (/^(?=.*[A-Z])/.test(value)) {
            setContainsUpperCase(true);
        } else {
            setContainsUpperCase(false);
        }

        if (/^(?=.*[0-9])/.test(value)) {
            setContainsNumber(true);
        } else {
            setContainsNumber(false);
        }

        if (value.length >= 8) {
            setContainsMinChars(true);
        } else {
            setContainsMinChars(false);
        }
    };

    const handleSocialSignUp = async (values) => {
        try {
            const captchaResponseToken = await handleReCaptchaVerify();

            const eligibilityForm = JSON.parse(
                localStorage.getItem("EligibilityForm") || "{}"
            );

            delete eligibilityForm["name"];
            delete eligibilityForm["q7_6"];

            const eligibilityQuestionnaire = Object.keys(eligibilityForm).map(
                (key, idx) => {
                    const prevQuestion = Object.keys(eligibilityForm)[idx - 1];

                    return {
                        question:
                            ONBOARDING_ELIGIBILITY_QUESTIONS.data[key]?.title[
                                eligibilityForm[prevQuestion]
                            ] ||
                            ONBOARDING_ELIGIBILITY_QUESTIONS.data[key]?.title,
                        answer:
                            ONBOARDING_ELIGIBILITY_QUESTIONS.data[
                                key
                            ]?.answers.find(
                                (answer) => answer.id === eligibilityForm[key]
                            )?.label || eligibilityForm[key],
                    };
                }
            );

            if (captchaResponseToken) {
                let data = {
                    captchaResponseToken,
                    regStatus: "BASIC",
                    marketingConsent: false,
                    eligibilityQuestionnaire,
                };

                const res = await signUpWithSocial({
                    values: data,
                    credential: values.token,
                    provider: values.provider,
                });

                if (res.error) {
                    // handle error
                    if (res.error.data?.status === 412) {
                        toast.error(
                            "Your reCAPTCHA verification was failed. Please try again."
                        );
                    } else if (res.error.data?.status === 403) {
                        toast.error(
                            "An account with this email exists. Please login."
                        );
                    } else {
                        toast.error(parseError(res.error, "Couldn't sign up"));
                    }
                } else {
                    // request succeeded
                    if (res.data.value) {
                        localStorage.setItem(ACCESS_TOKEN_NAME, res.data.value);
                        auth.logIn();
                        navigate("/onboarding/auth/success", { replace: true });
                    }
                }
            } else {
                toast.error("reCAPTCHA failed");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleGoogleSignUp = (values) => {
        const data = {
            token: values.credential,
            provider: "GOOGLE",
        };

        handleSocialSignUp(data);
    };

    // const handleFacebookSignUp = async (values) => {
    //     const data = {
    //         token: values.accessToken,
    //         provider: "FACEBOOK",
    //     };

    //     handleSocialSignUp(data);
    // };

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            return;
        }

        const token = await executeRecaptcha();
        return token;
    }, [executeRecaptcha]);

    useEffect(() => {
        if (auth?.isLogged) {
            navigate("/onboarding/auth/success", { replace: true });
        }
    }, []);

    return (
        <div className="create-account-page px-3 m-auto pb-12">
            {createAccount ? (
                <div className="register-form">
                    <div className="px-0 px-sm-5 text-center">
                        <h2 className="text-primary fw-bold mb-5 text-center">
                            Create an account
                        </h2>
                        {/* google */}
                        <div className="d-flex align-content-center">
                            <div className="mx-auto">
                                <GoogleLogin
                                    onSuccess={handleGoogleSignUp}
                                    onError={() => {
                                        toast.error("Login failed");
                                    }}
                                    text="signup_with"
                                    logo_alignment="center"
                                    width="255"
                                    shape="rectangular"
                                    size="medium"
                                />
                            </div>
                        </div>
                        {/* facebook */}
                        {/* <div className="d-flex align-content-center mt-4">
                            <div className="mx-auto">
                                <FacebookLogin
                                    appId={
                                        process.env.REACT_APP_FACEBOOK_APP_ID
                                    }
                                    callback={handleFacebookSignUp}
                                    render={(renderProps) => (
                                        <button
                                            className="btn-social border-0 rounded-xs bg-fb text-white d-flex justify-content-center"
                                            onClick={renderProps.onClick}
                                            type="button"
                                        >
                                            <img
                                                src="/images/vectors/fb-logo.svg"
                                                alt=""
                                                className="my-auto rounded-circle"
                                                width={16}
                                            />
                                            <span className="ms-2 my-auto">
                                                Sign up with Facebook
                                            </span>
                                        </button>
                                    )}
                                    fields="email"
                                    scope="email"
                                />
                            </div>
                        </div> */}
                        <div className="py-7">
                            <hr className="hr-text" data-content="OR" />
                        </div>
                        <div className="mb-5 create-account-back">
                            <Link to={-1}>
                                <GoBack text="Back"/>
                            </Link>
                        </div>
                        <p className="fs-5 mb-2">Continue with email</p>
                        <p className="fs-6 text-primary mb-6 mx-2 mx-md-12">
                            We'll check if you have an account, and help create
                            one if you don't.
                        </p>
                    </div>
                    <div className="d-flex justify-content-center mt-8 mb-12">
                        <Form
                            onSubmit={handleSubmit}
                            enableReinitialize
                            initialValues={{
                                email: "",
                                password: "",
                                confirmPassword: "",
                                marketingConsent: false,
                            }}
                            validationSchema={SignUpSchema}
                        >
                            <div className="col-12 col-sm-8 mb-3 mb-lg-0 mx-auto">
                                <div className="row mb-5">
                                    <TextField
                                        className="form-control-sm fs-6 px-5 py-4 bg-white"
                                        name="email"
                                        placeholder="Email"
                                        autoComplete="no"
                                    />
                                </div>
                                <div className="row mb-5">
                                    <TextField
                                        className="form-control-sm fs-6 px-5 py-4 bg-white"
                                        name="password"
                                        placeholder="Password"
                                        autoComplete="no"
                                        onKeyUp={handleKeyUp}
                                        type="password"
                                    />
                                </div>
                                <div className="row mb-6">
                                    <TextField
                                        className="form-control-sm fs-6 px-5 py-4 bg-white"
                                        name="confirmPassword"
                                        placeholder="Re-enter Password"
                                        autoComplete="no"
                                        type="password"
                                    />
                                </div>
                                <div className="row mb-5">
                                    <PasswordConditions
                                        containsLowercase={containsLowercase}
                                        containsMinChars={containsMinChars}
                                        containsNumber={containsNumber}
                                        containsSpecialChars={
                                            containsSpecialChars
                                        }
                                        containsUpperCase={containsUpperCase}
                                        title="Password Checklist"
                                        subTitle="Make your password as strong as possible with at least:"
                                    />
                                    <div className="row mx-0 my-3">
                                        <Checkbox
                                            label="I would like to receive the latest offers, promotions, and general medical information from Treat It"
                                            name="marketingConsent"
                                            className="check-lg"
                                            labelClass="ms-3"
                                        />
                                    </div>
                                    <div className="mt-6">
                                        <SubmitButton variant="primary">
                                            Create an Account
                                        </SubmitButton>
                                    </div>
                                    <span className="text-center fw-light mt-6 px-6">
                                        By creating your account you confirm you
                                        have read and understood the{" "}
                                        <span className="text-decoration-underline text-primary fw-bold">
                                            <Link
                                                to="/privacy-policy"
                                                className="fw-semibold"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Privacy Policy
                                            </Link>
                                        </span>{" "}
                                        and agreed to the{" "}
                                        <span className="text-decoration-underline text-primary fw-bold">
                                            <Link
                                                to="/terms-condition"
                                                className="fw-semibold"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Terms and conditions
                                            </Link>
                                        </span>
                                        .
                                    </span>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            ) : (
                <div className="get-started d-flex flex-column">
                    <div className="back-parent mb-5">
                        <Link to={-1}>
                            <GoBack text="Back"/>
                        </Link>
                    </div>
                    <h2 className="text-primary fw-bold mb-5 text-center">
                        Let’s create an account
                    </h2>
                    <p className="fs-5 subtitle text-primary-200 text-center mb-8 mx-2 mx-lg-8 px-lg-8">
                        Creating an account is your gateway to personalised care
                        and expert guidance. Click below to create your account
                        now and take that vital step towards a better future.
                    </p>

                    <div className="mx-auto">
                        <Button
                            variant="primary fs-5"
                            onClick={() => setCreateAccount(true)}
                            fullWidth={false}
                        >
                            <img
                                className="me-2 my-auto"
                                src="/images/vectors/arrow-right.svg"
                                alt=""
                            />
                            Create an Account
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
}
