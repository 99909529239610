/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useField, useFormikContext } from "formik";
import { debounce } from "lodash";

import {
    useLazyGetPostcodesQuery,
    useLazyGetSurgeriesQuery,
} from "../../services/dataService";
import { getDisplayAddress, getGPContact } from "../../utils/dataUtils";
import { useIsInViewport } from "../../utils/domUtils";

import { TextField } from "../forms";
import OutsideAlerter from "../OutsideAlerter";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";
import SurgeryCard from "../surgeryCard/SurgeryCard";

import { DEBOUNCE_DELAY } from "../../constants";

export default function SurgeryDropDown({
    onSelectSurgery,
    onClickSurgeryManualEntry,
    surgeryManualEntry,
    name,
}) {
    const { setFieldValue } = useFormikContext();
    const [, meta] = useField({ name });
    const listRef = useRef();
    const {
        isIntersecting: isVisible,
        observe,
        disconnect,
    } = useIsInViewport(listRef);

    const [showPostcodes, setShowPostcodes] = useState(false);
    const [showSurgeries, setShowSurgeries] = useState(false);
    const [isSurgerySelected, setSurgerySelected] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [postcodeList, setPostcodeList] = useState([]);
    const [surgeriesList, setSurgeriesList] = useState([]);
    const [showNoAddressMessage, setShowNoAddressMessage] = useState(false);

    const [getPostcodes, { isFetching: postcodeLoading }] =
        useLazyGetPostcodesQuery();
    const [getSurgeries, { isFetching: surgeryLoading }] =
        useLazyGetSurgeriesQuery();

    const debouncedChangeHandler = useMemo(
        () => debounce((userInput) => getOptions(userInput), DEBOUNCE_DELAY),
        []
    );

    const getOptions = async (value) => {
        onSelectSurgery(
            {
                gpPractice: "",
                gpAddress: {
                    postcode: "",
                },
            },
            false
        );
        setSurgeriesList([]);

        try {
            if (value) {
                const res = await getPostcodes({ partial: value });

                if (res.data) {
                    const transformedOptions = res.data.result?.map((optn) => ({
                        label: optn,
                        value: optn,
                    }));
                    setShowNoAddressMessage(!transformedOptions);
                    setPostcodeList(transformedOptions);
                } else {
                    setShowNoAddressMessage(true);
                    setPostcodeList([]);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getSurgeriesList = async (value) => {
        try {
            if (value) {
                const res = await getSurgeries({
                    postcode: value.replace(/ /g, ""),
                });

                if (res.data) {
                    const surgeries = res.data.value.map((surg) => {
                        let address = {
                            line1: surg.Address1,
                            line2: surg.Address2,
                            county: surg.County,
                            city: surg.City,
                            postcode: surg.Postcode,
                        };

                        return {
                            name: surg.OrganisationName,
                            displayAddress: getDisplayAddress(address),
                            address: address,
                            mobile: getGPContact(surg.Contacts),
                        };
                    });

                    setSurgeriesList(surgeries);
                    setShowSurgeries(true);
                } else {
                    setPostcodeList([]);
                }
            }
        } catch (error) { }
    };

    const handleClickOption = (value) => {
        setShowPostcodes(false);
        setSearchText(value);
        getSurgeriesList(value);
    };

    const handleClickManualEntry = (input) => {
        if (input) {
            setFieldValue(name, searchText);
        }

        onClickSurgeryManualEntry(input);
    };

    const handleSelectSurgery = (value) => {
        setSurgerySelected(true);
        onSelectSurgery(value, true);
        setSearchText(value.address.postcode);
    };

    const gtePosition = () => {
        let position = {};

        if (isVisible?.left) {
            position = { ...position, left: 0 };
        }
        if (isVisible?.right) {
            position = { ...position, right: 0 };
        }
        if (isVisible?.top) {
            position = { ...position, top: 0 };
        }
        if (isVisible?.bottom) {
            position = { ...position, bottom: 0 };
        }

        return position;
    };

    const displayingPostcodes = useMemo(() => {
        if (searchText) {
            return (
                postcodeList?.filter((optn) =>
                    `${optn.label}`
                        .toLowerCase()
                        .split(" ")
                        .join("")
                        .includes(
                            `${searchText}`.split(" ").join("").toLowerCase()
                        )
                ) || []
            );
        }

        return postcodeList;
    }, [postcodeList, searchText]);

    useEffect(() => {
        observe();

        return () => {
            disconnect();
        };
    }, [showPostcodes]);

    return (
        <>
            <div className="d-flex flex-wrap">
                <label className="form-label fs-5 fw-bold me-auto">
                    GP Post code*
                </label>
                <div className="d-flex form-check form-switch ms-auto my-auto p-0">
                    <label
                        className="form-check-label text-primary-200 fw-light me-auto my-auto"
                        htmlFor="enterDetailsManuallyToggle"
                    >
                        Enter details manually
                    </label>
                    <input
                        className="form-check-input ms-2 my-auto"
                        type="checkbox"
                        role="switch"
                        id="enterDetailsManuallyToggle"
                        onChange={(e) =>
                            handleClickManualEntry(e.target.checked)
                        }
                    />
                </div>
            </div>
            <div className="dropdown w-100 h-100">
                {surgeryManualEntry ? (
                    <TextField
                        className="form-control"
                        placeholder="Type your answer here..."
                        name={name}
                    />
                ) : (
                    <OutsideAlerter
                        className="input-container h-100"
                        onClickOutside={() => {
                            setShowPostcodes(false);
                            setShowSurgeries(false);
                        }}
                        onMouseEnter={() => setShowSurgeries(true)}
                    >
                        <input
                            className="form-control"
                            placeholder="Type your answer here..."
                            type="text"
                            onFocus={() => {
                                setShowPostcodes(true);
                                setShowSurgeries(false);
                            }}
                            onClick={() => {
                                setShowPostcodes(true);
                                setShowSurgeries(false);
                            }}
                            value={searchText || ""}
                            onChange={(e) => {
                                setSurgerySelected(false);
                                setSearchText(e.target.value);
                                debouncedChangeHandler(e.target.value);
                            }}
                            autoComplete="no"
                            autoCorrect="no"
                            tabIndex="0"
                        />

                        {!!searchText && !postcodeLoading && showNoAddressMessage && <span className="invalid-feedback" style={{ display: "block" }}>No address found to match this post code; please recheck/ enter your address details manually</span>}

                        {meta.touched && meta.error && (
                            <div
                                className="position-absolute invalid-feedback mb-0 mt-0 w-fit"
                                style={{ display: "block" }}
                            >
                                {meta.error}
                            </div>
                        )}

                        {showPostcodes && postcodeLoading ? (
                            <div className="option-list text-start ps-2 py-1 w-100">
                                <LoadingSpinner loadingText="Searching for postcodes, please wait..." />
                            </div>
                        ) : null}

                        {showPostcodes &&
                            displayingPostcodes?.length > 0 &&
                            !postcodeLoading && (
                                <div
                                    className="option-list text-start w-100"
                                    ref={listRef}
                                    style={{ ...gtePosition() }}
                                >
                                    {displayingPostcodes?.map((optn) => (
                                        <div
                                            key={optn.value}
                                            className="option fw-bold fs-6 text-truncate"
                                            onClick={() =>
                                                handleClickOption(optn.value)
                                            }
                                            title={optn.label}
                                        >
                                            {optn.label}
                                        </div>
                                    ))}
                                </div>
                            )}

                        {surgeryLoading ? (
                            <div className="option-list text-start ps-2 py-1 w-100">
                                <LoadingSpinner loadingText="Searching for surgeries, please wait..." />
                            </div>
                        ) : null}

                        {showSurgeries &&
                            !isSurgerySelected &&
                            surgeriesList.length > 0 &&
                            !surgeryLoading && (
                                <div className="surgery-card-container position-relative py-2 px-1">
                                    <div className="surgery-card-list">
                                        {surgeriesList.map((surgery, idx) => (
                                            <div
                                                className={`pb-${idx + 1 ===
                                                        surgeriesList.length
                                                        ? "0"
                                                        : "2"
                                                    } pe-1`}
                                                key={idx}
                                            >
                                                <SurgeryCard
                                                    title={surgery.name}
                                                    phone={surgery.mobile}
                                                    address={
                                                        surgery.displayAddress
                                                    }
                                                    onClick={() =>
                                                        handleSelectSurgery(
                                                            surgery
                                                        )
                                                    }
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                    </OutsideAlerter>
                )}
            </div>
        </>
    );
}
