import React from "react";
import { useModal } from "../../../../hooks/modalsHooks";

import Button from "../../../../components/button/Button";
import {
    SubmitButton,
    TextField,
    YesNoRadio,
} from "../../../../components/forms";
import { useFormikContext } from "formik";

export default function QuestionnaireForm({ questionnaire, isMentalHealthQuestionnaire:mhQuestionnaire }) {
    const { setFieldValue, values } = useFormikContext();
    const { showModal, hideModal } = useModal();

    const handleOpenPEGModal = () => {
        showModal({
            modalType: "PEGModal",
            modalProps: {
                onSubmit: handlePEGFormSubmit,
                initialValues: values?.q_7?.questionAnswer
                    ? JSON.parse(values?.q_7?.questionAnswer || "null")
                    : JSON.parse(values?.q_7?.answer?.questionAnswer || "null"),
            },
        });
    };

    const handleOpenGAD2Modal = () => {
        showModal({
            modalType: "GADModal",
            modalProps: {
                onSubmit: handleGADFormSubmit,
                initialValues: values?.q_10?.questionAnswer
                    ? JSON.parse(values?.q_10?.questionAnswer || "null")
                    : JSON.parse(values?.q_10?.answer?.questionAnswer || "null"),
            },
        });
    };

    const handleOpenSideEffectModal = () => {
        showModal({
            modalType: "SideEffectModal",
            modalProps: {
                onSubmit: handleSideEffectFormSubmit,
                initialValues: values?.q_6?.questionAnswer
                    ? JSON.parse(values?.q_6?.questionAnswer || "null")
                    : JSON.parse(values?.q_6?.answer?.questionAnswer || "null"),
            },
        });
    };

    const handlePEGFormSubmit = (values) => {
        setFieldValue("q_7.answer", values);
        hideModal();
    };

    const handleGADFormSubmit = (values) => {
        setFieldValue("q_10.answer", values);
        hideModal();
    };

    const handleSideEffectFormSubmit = (values) => {
        setFieldValue("q_6.answer", values);
        hideModal();
    };

    const isComplete =
        values.q_1?.answer !== "" &&
        values.q_2?.answer !== "" &&
        values.q_3?.answer !== "" &&
        values.q_4?.answer !== "" &&
        values.q_5?.answer !== "" &&
        ((values.q_5?.answer === true && values.q_6?.answer !== "") && (values.q_5?.answer === false && values.q_6?.answer === "")) &&
        values.q_7?.answer !== "";

    const isMentalHealthComplete =
        values.q_1?.answer !== "" &&
        values.q_2?.answer !== "" &&
        values.q_3?.answer !== "" &&
        values.q_4?.answer !== "" &&
        values.q_5?.answer !== "" &&
        ((values.q_5?.answer === true && values.q_6?.answer !== "") || (values.q_5?.answer === false && values.q_6?.answer === "")) &&
        values.q_7?.answer !== "" &&
        values.q_8?.answer !== "" &&
        values.q_9?.answer !== "" &&
        values.q_10?.answer !== "" &&
        values.q_11?.answer !== "";

    if (values.q_5?.answer === false && values.q_6?.answer !== "") {
        setFieldValue("q_6.answer", '');
    }

    return (
        <div className="mx-auto">
            <table className="table table-responsive border mb-0">
                <tbody>
                {questionnaire.map((question, idx) => {
                    const renderQuestion = () => (
                        <React.Fragment key={idx}>
                            <tr>
                                <td className="pt-3 pb-0 ps-2 ps-sm-5 border-0">
                                    <span>{question.text}</span>
                                </td>
                                <td className="pt-3 pb-0 pe-2 pe-sm-5 border-0">
                                    {question.type === "choice" && (
                                        <YesNoRadio
                                            name={`${question.name}.answer`}
                                            justify="end"
                                            space={2}
                                        />
                                    )}
                                    {question.type === "modal" && (
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Button
                                                variant="link p-0 float-end"
                                                size="sm"
                                                fullWidth={false}
                                                onClick={handleOpenPEGModal}
                                            >
                                                PEG Questionnaire
                                            </Button>
                                            {values.q_7?.answer === "" && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                                                <img style={{ width: '20px' }} src="/images/vectors/alert-red.svg" />
                                                <label style={{ color: 'red', textAlign: 'center' }}>Please submit the form</label>
                                            </div>}
                                        </div>
                                    )}
                                    {question.type === "side_effect_modal" && (
                                        <Button
                                            variant="link p-0 float-end"
                                            size="sm"
                                            fullWidth={false}
                                            onClick={handleOpenSideEffectModal}
                                        >
                                            Side Effects Questionnaire
                                        </Button>
                                    )}
                                    {question.type === "gad_modal" && (
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Button
                                                variant="link p-0 float-end"
                                                size="sm"
                                                fullWidth={false}
                                                onClick={handleOpenGAD2Modal}
                                            >
                                                GAD-2 Questionnaire
                                            </Button>
                                            {values?.q_10?.answer === "" && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                                                <img style={{ width: '20px' }} src="/images/vectors/alert-red.svg" />
                                                <label style={{ color: 'red', textAlign: 'center' }}>Please submit the form</label>
                                            </div>}
                                        </div>
                                    )}
                                </td>
                            </tr>
                            <tr className="p-0">
                                <td
                                    colSpan={2}
                                    className={`pt-0 pb-3 px-2 px-sm-5 ${
                                        idx === questionnaire.length - 1 && "border-0"
                                    }`}
                                >
                                    {values.q_1?.answer === false &&
                                        question.name === "q_1" && (
                                            <div className="pt-2">
                                                <TextField
                                                    name={`${question.name}.reason`}
                                                    placeholder="Tell us more"
                                                />
                                            </div>
                                        )}
                                    {((values.q_2?.answer === true &&
                                            question.name === "q_2") ||
                                        (values.q_3?.answer === true &&
                                            question.name === "q_3") ||
                                        (values.q_4?.answer === true &&
                                            question.name === "q_4") ||
                                        (values.q_5?.answer === true &&
                                            question.name === "q_5") ||
                                        (values.q_8?.answer === true &&
                                            question.name === "q_8") ||
                                        (mhQuestionnaire && values.q_9?.answer === true &&
                                            question.name === "q_9") ||
                                        (mhQuestionnaire && values.q_11?.answer === true &&
                                            question.name === "q_11")) && (
                                        <div className="pt-2">
                                            <TextField
                                                name={`${question.name}.reason`}
                                                placeholder="Tell us more"
                                            />
                                        </div>
                                    )}
                                </td>
                            </tr>
                        </React.Fragment>
                    );

                    if (question.name !== "q_6") {
                        return renderQuestion();
                    } else if (question.name === "q_6" && values.q_5?.answer === true) {
                        return renderQuestion();
                    } else {
                        return null;
                    }
                })}


                </tbody>
            </table>
            <div className="row mx-0 mt-6 mb-4">
                <div className="col-12 col-sm-6 mx-auto px-0">
                    <SubmitButton
                        disabled={(!mhQuestionnaire && !isComplete) || (mhQuestionnaire && !isMentalHealthComplete)}>
                        SUBMIT FEEDBACK
                    </SubmitButton>
                </div>
            </div>
        </div>
    );
}
