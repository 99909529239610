import React from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import Form from "../../../components/forms/Form";
import TextField from "../../../components/forms/TextField";
import Checkbox from "../../../components/forms/Checkbox";
import SubmitButton from "../../../components/forms/SubmitButton";

import { useSendUserDataMutation } from "../../../services/authService";

import "./eligibility-page.scss";

const LandingFormSchema = yup.object({
    name: yup.string().required("We need your name to continue"),
    email: yup.string().email('Invalid email').required("We need your email to continue"),
    phone: yup.string().required("We need your phone number to continue").min(7, 'Must be at least 7 digits').max(20, 'Must be below 20 digits'),
    consent: yup.bool()
});

export default function LandingPage() {
    const navigate = useNavigate();

    const savedValues = JSON.parse(
        localStorage.getItem("EligibilityForm") || "null"
    );

    const [sendUserData] = useSendUserDataMutation();

    const handleSubmit = async (values) => {
        try {
            const data = JSON.stringify({ ...savedValues, ...values });
            localStorage.setItem("EligibilityForm", data);
            const res = await sendUserData(values);
            navigate("/onboarding/eligibility/name-confirm");
        } catch (error) {
            const errorObj = JSON.parse(JSON.stringify(error));
        }
    };

    return (
        <div className="landing-page px-3 my-auto">
            <div className="m-auto">
                <div className="d-flex mb-8">
                    <img
                        className="img-fluid mx-auto"
                        src="/images/vectors/welcome-text.svg"
                        alt=""
                    />
                </div>
                <div className="d-flex mb-8">
                    <p className="fs-5 subtitle text-primary-200 text-center mx-auto">
                        Your online pain management service. Our goal is to
                        provide personalised care that addresses your unique
                        needs. Let's begin by getting to know you better.
                    </p>
                </div>

                <Form
                    initialValues={savedValues || { name: "", email: "", phone: "", consent: false }}
                    onSubmit={handleSubmit}
                    enableReinitialize
                    validationSchema={LandingFormSchema}
                >
                    <div className="mb-8 mx-auto name-field">
                        <TextField
                            name="name"
                            placeholder="Type your name here..."
                            autoComplete="name"
                            className="input-field"
                        />
                        <TextField
                            name="email"
                            placeholder="Enter your email address"
                            autoComplete="email"
                            className="input-field"
                        />
                        <TextField
                            name="phone"
                            placeholder="Enter your phone number"
                            autoComplete="phone"
                            className="input-field"
                            type="number"
                        />
                        <Checkbox
                            name='consent'
                            label={'I consent to receiving special offers and general medical information from Treat-It via Call, SMS, WhatsApp and email. (optional)'}
                            disabled={false}
                            labelClass="text-break consent-label"
                            />
                    </div>

                    <p className="fs-5 subtitle text-primary-200 text-center mx-auto">
                        By creating your account you confirm you have read and accepted the 
                        <a href="https://app.treat-it.clinic/privacy-policy" target="_blank" className="a-within-p">Privacy Policy</a> 
                        and the 
                        <a href="https://app.treat-it.clinic/terms-condition" target="_blank" className="a-within-p">Terms and Conditions</a>
                    </p>

                    <div className="d-flex">
                        <SubmitButton
                            fullWidth={false}
                            variant="primary mx-auto fs-5"
                        >
                            <img
                                className="me-2 my-auto"
                                src="/images/vectors/arrow-right.svg"
                                alt=""
                            />
                            Next
                        </SubmitButton>
                    </div>
                </Form>
            </div>
        </div>
    );
}
