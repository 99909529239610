export function getDisplayAddress(address, skipCity = false) {
    if (address) {
        let displayAddress = address.line1;

        if (address.line2 && /\S/.test(address.line2)) {
            displayAddress += ", " + address.line2;
        }

        if (!skipCity && address.city && /\S/.test(address.city)) {
            displayAddress += ", " + address.city;
        }

        if (address.county && /\S/.test(address.county)) {
            displayAddress += ", " + address.county;
        }

        return displayAddress;
    }

    return "";
}

export function getAgeFromDob(birthday) {
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function getGPContact(contactStr) {
    let contacts = JSON.parse(contactStr || "[]");

    if (contacts.length === 0) {
        return null;
    }

    return contacts[0]?.OrganisationContactValue;
}

export function cleanSpaces(string) {
    return string?.trim().replace(/\s+/, " ") || "";
}

export function getBase64(file) {
    return new Promise((resolve) => {
        let baseURL = "";
        let reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => {
            baseURL = reader.result;
            resolve(baseURL);
        };
    });
}

export function getFile(dataURL, fileName = "file") {
    var arr = dataURL.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
}
