export const print = (elementId) => {
    const htmlToPrint = document.getElementById(elementId).innerHTML;

    let stylesHtml = "";
    for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
    ]) {
        stylesHtml += node.outerHTML;
    }

    const WindowPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
    );

    WindowPrint.document.write(`<!DOCTYPE html>
        <html>
            <head>
                ${stylesHtml}
            </head>
            <body>
                ${htmlToPrint}
            </body>
        </html>`);

    WindowPrint.document.close();
    WindowPrint.focus();
    setTimeout(() => {
        WindowPrint.print();
    }, 300);
};
