import { useFormikContext } from "formik";

import DoctorNotesQuestionAnswers from "../../../../components/doctorNotesQuestionAnswers/DoctorNotesQuestionAnswers";

export default function DoctorNotes({ isRepeatOrder }) {
    const { values } = useFormikContext();

    if (!values) {
        return null;
    }

    return (
        <div className="doctor-notes-section">
            <div className="text-primary p1 fw-bold mt-5">Consultation Notes</div>
            <div className="mt-4">
                <div className="basic-table-section table-responsive border border-gray rounded">
                    <table className="table table-responsive mb-0">
                        {isRepeatOrder ? (
                            <thead className="bg-light-gray">
                                <tr>
                                    <th className="ps-5" scope="col">
                                        Doctor comments
                                    </th>
                                </tr>
                            </thead>
                        ) : (
                            <thead className="bg-light-gray">
                                <tr>
                                    <th className="ps-5" scope="col">
                                        Question
                                    </th>
                                    <th className="pe-5" scope="col">
                                        Your answer
                                    </th>
                                </tr>
                            </thead>
                        )}
                        {isRepeatOrder ? (
                            <tr>
                                <td className="py-5 px-5 border-0">
                                    {values["doctorComments"] || "-"}
                                </td>
                            </tr>
                        ) : (
                            values && (
                                <tbody>
                                    <DoctorNotesQuestionAnswers viewOnly />
                                </tbody>
                            )
                        )}
                    </table>
                </div>
            </div>
        </div>
    );
}
