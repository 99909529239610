import {useFormikContext} from "formik";
import ModalLayout from "../modals/ModalLayout";
import {SIDE_EFFECT_FORM} from "../../constants/orderNotesQuestions";
import {Slider, SubmitButton} from "../forms";

export default function SideEffectForm({ viewOnly = false }) {
    const { values, errors } = useFormikContext();

    const average = Math.round((values.sideEffectPain  / 1));

    return (
        <>
            <ModalLayout.Body className={`d-flex d-sm-block p-5 ${viewOnly ? "p-0" : ""}`}>
                <div className="my-auto">
                    <div className="fw-light">
                        {SIDE_EFFECT_FORM.keys.map((key) => (
                            <div className="row mx-0 mb-8" key={key}>
                                <Slider
                                    label={SIDE_EFFECT_FORM.data[key]}
                                    name={key}
                                    min={0}
                                    max={10}
                                    step={0.1}
                                    marks={[0, 10]}
                                    labelClass="fw-semibold"
                                    disabled={viewOnly}
                                />
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px'}}>
                                    {key === 'sideEffectPain' && (
                                        <>
                                            <span>0 - No side effects</span>
                                            <span>10 - Intolerable SE's</span>
                                        </>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </ModalLayout.Body>
            {viewOnly ? (
                <></>
            ) : (
                <ModalLayout.Footer>
                    <div className="d-flex w-100">
                        <SubmitButton
                            variant="primary"
                            disabled={
                                !(
                                    values.sideEffectPain !== undefined
                                )
                            }
                        >
                            Submit
                        </SubmitButton>
                    </div>
                </ModalLayout.Footer>
            )}
        </>
    )
}