import React from "react";
import { Outlet } from "react-router-dom";

function AppointmentLayoutPage() {
    return (
        <div className="mb-10 mb-md-0 position-fixed overflow-auto bg-light-gray w-100 h-100">
            <Outlet />
        </div>
    );
}

export default AppointmentLayoutPage;
