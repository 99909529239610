import ModalLayout from "../ModalLayout";
import {useModal} from "../../../hooks/modalsHooks";
import {navigate} from "@storybook/addon-links";
import { useNavigate } from "react-router-dom";

export function PaymentSuccessInfoModal({title, message, isPaymentSuccess}) {
    const { hideModal } = useModal();
    const navigate = useNavigate();

    const handleNext = () => {
        hideModal()
        navigate(
            `/onboarding/appointment/${message}/documents`,
            {
                replace: true,
            }
        );
    }
    return (
        <ModalLayout className="bg-secondary-100" width={"43rem"}>
            <ModalLayout.Body className="pt-0 px-5 pb-10 h-max bg-secondary-100 mt-5">
                <div className="w-100">
                    <div className="text-center">
                        <img src="/images/vectors/success.svg" alt="" width={70} />
                        <h4 className="fs-5 text-primary fw-semibold my-4">
                            {title}
                        </h4>
                    </div>
                </div>
                <div className="text-center m-auto">
                    <div className="px-0 px-sm-8 px-md-10 d-flex flex-column mx-auto contact-item-container">
                        <div className="mb-4">
                            Payment successful, your appointment booking is confirmed.
                        </div>
                    </div>
                    <button className="btn btn-primary" onClick={handleNext}>
                        Okay
                    </button>
                </div>
            </ModalLayout.Body>
        </ModalLayout>
    )
}
