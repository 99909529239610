import React from "react";

export default function HelpText({ text, onClick, className }) {
    return (
        <button
            onClick={onClick}
            className={`bg-transparent text-primary border-0 btn-link p-0 d-flex w-fit text-start ${className}`}
            type="button"
        >
            <img
                src="/images/vectors/question-icon.svg"
                className="me-2 my-auto"
                alt=""
            />
            <span className="my-auto fw-semibold fs-5">{text}</span>
        </button>
    );
}
