import React from "react";

export default function Prescription(props) {
    return (
        <>
            <div className="col-12">
                <h3 className="fw-semibold">Prescription {props.index + 1}</h3>
            </div>

            {props.prescription.medicationDetails.map((med, ind) => {
                return (
                    <div className="col-12 fs-18" key={ind}>
                        <div className="row">
                            <div className="col-5 col-lg-3">
                                Issued Date
                            </div>
                            <div className="col-7 col-lg-9">
                                {props.prescription.creationDate?.split('T')[0]}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5 col-lg-3">
                                Name
                            </div>
                            <div className="col-7 col-lg-9">
                                {med.productName}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5 col-lg-3">
                                Brand
                            </div>
                            <div className="col-7 col-lg-9">
                                {med.brandName}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5 col-lg-3">
                                Strain
                            </div>
                            <div className="col-7 col-lg-9">
                                {med.strain}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5 col-lg-3">
                                Dosage
                            </div>
                            <div className="col-7 col-lg-9">
                                {med.dosage}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5 col-lg-3">
                                Quantity
                            </div>
                            <div className="col-7 col-lg-9">
                                {med.quantity}
                            </div>
                        </div>
                        <hr />
                    </div>
                )
            })}

            {/* pink prescription section */}
            <div className="col-12 mt-5">
                <h3 className="fw-semibold mb-2">FP10</h3>
            </div>
            <div className="col-12 text-center">
                <img className="fp10-image" src={`data:image/png;base64,${props.prescription.pinkPresUrl}`} alt="Pink Prescription"/>
            </div>

            {/* issued by section */}
            {/*<div className="row fs-18">*/}
            {/*    <div className="col-12 px-0">*/}
            {/*        <p className="text-center mb-0">*/}
            {/*            Issued by <span className="text-capitalize">{props.prescription.doctorName ? props.prescription.doctorName : 'XXXX XXXXX'}</span>*/}
            {/*        </p>*/}
            {/*        <p className="text-center">*/}
            {/*            GMC Number: {props.prescription.gmc ? props.prescription.gmc : 'XX XX XXX XXX XXXX'}*/}
            {/*        </p>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <hr className="mt-2 mb-2" />
        </>
    );
}