import React from "react";
import { useFormikContext } from "formik";

import { SubmitButton } from "../../../components/forms";
import Button from "../../../components/button/Button";
import UploadDocumentForm from "../../../components/uploadDocumentForm/UploadDocumentForm";

export default function UploadDocumentsForm({ name, onSkip }) {
    const { values } = useFormikContext();

    return (
        <>
            <UploadDocumentForm
                name={name}
                options={[
                    {
                        label: "Current prescription copy",
                        value: "current_prescription",
                    },
                    {
                        label: "Previous prescription copy",
                        value: "previous_prescription",
                    },
                    {
                        label: "Other supporting document",
                        value: "other",
                    },
                ]}
                multiple
                fileLimit={5}
                hideSubmit
            />
            <div className="mt-7 d-flex flex-column flex-sm-row justify-content-center">
                <SubmitButton
                    fullWidth={false}
                    variant="primary fs-5 px-12"
                    disabled={
                        !values[name] ||
                        values[name].length === 0
                    }
                >
                    <div className="d-flex justify-content-center px-sm-10">
                        <img
                            className="me-2 my-auto"
                            src="/images/vectors/arrow-right.svg"
                            alt=""
                        />
                        <span>Next</span>
                    </div>
                </SubmitButton>
                <Button
                    variant="link px-7 fw-semibold"
                    fullWidth={false}
                    onClick={onSkip}
                >
                    Skip this step
                </Button>
            </div>
        </>
    );
}
