import React from "react";

import "./password-conditions.scss";

export default function PasswordConditions({
    containsUpperCase,
    containsLowercase,
    containsNumber,
    containsSpecialChars,
    containsMinChars,
    title,
    subTitle,
}) {
    return (
        <div className="password-conditions">
            <p className="mb-0 mt-1 mb-1 fs-5 text-primary fw-bold">{title}</p>
            <p className="fs-6 text-primary-200">{subTitle}</p>
            <div className="password-conditions text-left mt-3 text-primary-200 fs-6">
                <div className="form-check d-flex mb-3">
                    <input
                        type="checkbox"
                        className="form-check-input my-auto me-3"
                        checked={containsUpperCase}
                        disabled={!containsUpperCase}
                    />
                    <label className="small form-check-label fw-light my-auto">
                        One uppercase letter
                    </label>
                </div>
                <div className="form-check d-flex mb-3">
                    <input
                        type="checkbox"
                        className="form-check-input my-auto me-3"
                        checked={containsLowercase}
                        disabled={!containsLowercase}
                    />
                    <label className="small form-check-label fw-light my-auto">
                        One lowercase letter
                    </label>
                </div>
                <div className="form-check d-flex mb-3">
                    <input
                        type="checkbox"
                        className="form-check-input my-auto me-3"
                        checked={containsNumber}
                        disabled={!containsNumber}
                    />
                    <label className="small form-check-label fw-light my-auto">
                        One number
                    </label>
                </div>
                <div className="form-check d-flex mb-3">
                    <input
                        type="checkbox"
                        className="form-check-input my-auto me-3"
                        checked={containsSpecialChars}
                        disabled={!containsSpecialChars}
                    />
                    <label className="small form-check-label fw-light my-auto">
                        One special character (Eg. @#$!%)
                    </label>
                </div>
                <div className="form-check d-flex mb-3">
                    <input
                        type="checkbox"
                        className="form-check-input my-auto me-3"
                        checked={containsMinChars}
                        disabled={!containsMinChars}
                    />
                    <label className="small form-check-label fw-light my-auto">
                        Minimum 8 characters
                    </label>
                </div>
            </div>
        </div>
    );
}
