import { useNavigate } from "react-router-dom";

import Collapsible from "../../../components/collapsible/Collapsible";

export default function AccountPage() {
    const navigate = useNavigate();

    const handleChangeEmail = () => {
        navigate("/user/account/change-email");
    };

    const handleChangePassword = () => {
        navigate("/user/account/change-password");
    };

    const handleNavigateToBasicInfo = () => {
        navigate("/user/account/basic-info");
    };

    const handleNavigateToEditKYC = () => {
        navigate("/user/account/change-kyc");
    };

    const handleNavigateToEditSCR = () => {
        navigate("/user/account/change-scr");
    };

    const handleLogout = () => {
        navigate("/signout");
    };

    return (
        <div className="account-layout pt-6 pt-lg-0 pb-5 px-xl-6 px-xxl-12">
            <h2 className="text-primary fw-bold">Account</h2>
            <div className="description-section bg-white rounded mt-5 px-5 py-7">
                <Collapsible
                    title="Change email address"
                    className="border-1 border-bottom px-0 pb-6"
                    textColor="text-primary"
                    logo="stethoscope.svg"
                    onClickArrow={handleChangeEmail}
                    link
                />
                <Collapsible
                    title="Change password"
                    className="border-1 border-bottom px-0 py-6"
                    textColor="text-primary"
                    logo="stethoscope.svg"
                    onClickArrow={handleChangePassword}
                    link
                />
                <Collapsible
                    title="Basic information"
                    className="border-1 border-bottom px-0 py-6"
                    textColor="text-primary"
                    logo="stethoscope.svg"
                    onClickArrow={handleNavigateToBasicInfo}
                    link
                />
                <Collapsible
                    title="Identifying Documents"
                    className="border-1 border-bottom px-0 py-6"
                    textColor="text-primary"
                    logo="stethoscope.svg"
                    onClickArrow={handleNavigateToEditKYC}
                    link
                />
                <Collapsible
                    title="Medical Documents"
                    className="border-1 border-bottom px-0 py-6"
                    textColor="text-primary"
                    logo="stethoscope.svg"
                    onClickArrow={handleNavigateToEditSCR}
                    link
                />
                <div className="d-flex justify-content-between align-items-center mt-6">
                    <h3 className="text text-primary fw-bold">Logout</h3>
                    <div className="logo" onClick={handleLogout} role="button">
                        <img
                            src="/images/vectors/primary-logout.svg"
                            alt=""
                            width={24}
                            height={24}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
