import React from "react";
import { Outlet } from "react-router-dom";

import { LogoNavbar } from "./navbar";

import "./full-screen-layout.scss";

export default function FullScreenLayout({ className }) {
    return (
        <div className={`full-screen-layout ${className}`}>
            <LogoNavbar />
            <main className="main-container m-auto mt-12 px-0 px-md-4 px-lg-6 pt-4 pt-lg-8 pb-11 pb-sm-8 position-relative">
                <Outlet />
            </main>
        </div>
    );
}
