import React, { useContext } from "react";

import { RepeatOrderContext } from "../../providers/RepeatOrderProvider";

function BookConsultantCard({ onBookConsultant }) {
    const { isRepeatOrder } = useContext(RepeatOrderContext);

    return (
        <div
            className={`bg-white d-flex justify-content-between align-items-sm-center flex-row rounded p-3 p-sm-4`}
        >
            <div className="d-flex align-items-sm-center">
                <img
                    src="/images/vectors/stethoscope.svg"
                    className="mb-auto img-fluid d-none d-sm-block"
                    alt="doctor"
                />
                <div className="ms-2 ms-sm-4">
                    <div className="primary-title text-primary fw-bold fs-5">
                        Book a {isRepeatOrder ? "Repeat " : ""}Consultation
                    </div>
                    <div className="secondary-title text-dark-5 fw-light">
                        Consult with an expert regarding your condition
                    </div>
                </div>
            </div>
            <div className="my-auto">
                <button
                    className="bg-transparent border-0"
                    type="button"
                    onClick={onBookConsultant}
                >
                    <img src="/images/vectors/chevron-right.svg" alt="go" />
                </button>
            </div>
        </div>
    );
}

export default BookConsultantCard;
