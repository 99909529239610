/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState } from "react";
import { debounce } from "lodash";
import { useFormikContext } from "formik";

import {
    useLazyGetAddressesQuery,
    useLazyGetPostcodesQuery,
} from "../../services/dataService";
import { cleanSpaces, getDisplayAddress } from "../../utils/dataUtils";

import { DatePicker, SearchableSelect, TextField } from "../forms";
import DropDown from "../dropDown/DropDown";

import { DEBOUNCE_DELAY } from "../../constants";

const gender = [
    { label: "MALE", value: 0 },
    { label: "FEMALE", value: 1 },
];

export default function BasicInfoForm({ disabled = {} }) {
    const { setFieldValue, values } = useFormikContext();

    const [postCodeOptions, setPostCodeOptions] = useState([]);
    const [addressList, setAddressList] = useState([
        { value: 0, label: "Enter address manually" },
    ]);
    const [selectedPostCode, setSelectedPostCode] = useState("");
    const [manualEntry, setManualEntry] = useState(false);

    const [getPostCodes, { isFetching: postCodeLoading }] =
        useLazyGetPostcodesQuery();
    const [getAddresses, { isFetching: addressLoading }] =
        useLazyGetAddressesQuery();

    const debouncedChangeHandler = useMemo(
        () => debounce((userInput) => getOptions(userInput), DEBOUNCE_DELAY),
        []
    );

    const getOptions = async (value) => {
        setFieldValue("address.line1", "");
        setFieldValue("address.line2", "");
        setFieldValue("address.city", "");
        setFieldValue("address.county", "");

        try {
            if (value) {
                const res = await getPostCodes({ partial: value });

                if (res.data) {
                    const transformedOptions = res.data.result?.map((optn) => ({
                        label: optn,
                        value: optn,
                    }));

                    setPostCodeOptions(transformedOptions);
                } else {
                    setPostCodeOptions([]);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getAddressList = async (value) => {
        setManualEntry(false);
        setSelectedPostCode(value);

        const postCode = value.replace(/ /g, "");

        try {
            if (value) {
                const res = await getAddresses({ postcode: postCode });

                if (res.data) {
                    const addressList = res.data.addresses.map((address, i) => {
                        const addr = address.split(",");
                        const result = {
                            line1: cleanSpaces(addr[0]),
                            line2: cleanSpaces(
                                addr[1] + " " + addr[2] + " " + addr[3]
                            ),
                            city: cleanSpaces(addr[5]),
                            county: cleanSpaces(addr[6]),
                            postcode: value,
                        };

                        return {
                            value: i + 1,
                            label: getDisplayAddress(result),
                            address: result,
                        };
                    });

                    setAddressList([
                        { value: 0, label: "Enter address manually" },
                        ...addressList,
                    ]);
                } else {
                    setPostCodeOptions([]);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSelectAddress = (value) => {
        const selectedAddress = addressList.find(
            ({ label }) => label === value
        );

        if (selectedAddress.value === 0) {
            setManualEntry(true);
            setAddressList([{ value: 0, label: "Enter address manually" }]);
        } else {
            setManualEntry(false);
        }

        setFieldValue("address", selectedAddress.address || {});
        setPostCodeOptions([]);
    };

    return (
        <>
            <div className="row mb-4">
                <TextField
                    name="firstName"
                    placeholder="First name"
                    disabled={disabled.firstName}
                />
            </div>
            <div className="row mb-4">
                <TextField
                    name="lastName"
                    placeholder="Surname"
                    disabled={disabled.lastName}
                />
            </div>
            <div className="row mb-4">
                <TextField
                    name="phone"
                    type="tel"
                    placeholder="Mobile number"
                    disabled={disabled.phone}
                />
            </div>
            <div className="row mb-4">
                <SearchableSelect
                    searchable={false}
                    multiSelect={false}
                    dropDownOnly
                    fullWidth
                    name="gender"
                    defaultSelectPlaceholder="Select your gender"
                    options={gender}
                    disabled={disabled.gender}
                />
            </div>
            <div className="row mb-4">
                <DatePicker
                    name="dateOfBirth"
                    label="Enter your date of birth"
                    disabled={disabled.dateOfBirth}
                />
            </div>
            <div className="row mb-4">
                <SearchableSelect
                    name="address.postcode"
                    placeholder="Post code"
                    options={postCodeOptions}
                    fullWidth
                    multiSelect={false}
                    onChange={debouncedChangeHandler}
                    onSelect={getAddressList}
                    selectedValues={
                        selectedPostCode || values?.address.postcode
                    }
                    loading={postCodeLoading}
                    loadingText="Searching for postcodes, please wait..."
                    hideIcon
                    disabled={disabled.postCode}
                />
            </div>
            <div className="row mb-4">
                {!manualEntry || addressList.length !== 1 ? (
                    <DropDown
                        placeholder="Address"
                        options={addressList}
                        fullWidth
                        multiSelect={false}
                        onSelect={handleSelectAddress}
                        selectedValues={getDisplayAddress(values?.address)}
                        loading={addressLoading}
                        loadingText="Searching for addresses, please wait..."
                        hideIcon
                        disabled={disabled.address}
                    />
                ) : (
                    <TextField
                        name="address.line1"
                        placeholder="Address line 1"
                        disabled={disabled.address}
                    />
                )}
            </div>
            {manualEntry && addressList.length === 1 && (
                <>
                    <div className="row mb-4">
                        <TextField
                            name="address.line2"
                            placeholder="Address line 2"
                            disabled={disabled.address}
                        />
                    </div>
                    <div className="row mb-4">
                        <TextField
                            name="address.city"
                            placeholder="City"
                            disabled={disabled.address}
                        />
                    </div>
                    <div className="row mb-4">
                        <TextField
                            name="address.county"
                            placeholder="County"
                            disabled={disabled.address}
                        />
                    </div>
                </>
            )}
        </>
    );
}
