import React, {useEffect, useState} from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import Button from "../../components/button/Button";
import { Collapse } from 'react-collapse';

import { useGetCannabisCardQuery } from "../../services/dataService";

import PhysicalCard from "./PhysicalCard";
import Prescription from "./Prescription";
import LegalInformationPage from "./LegalInformation";

import "./public.scss";
import { CANNABIS_CARD_LOCAL_STORAGE_ITEM, PUBLIC_CANNABIS_CARD_TIMEOUT } from "../../constants/cannabisCard";

export default function CannabisCardPage(props) {

    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;

    const pin = state && state.pin ? state.pin : props.pin ? props.pin : null;
    const token = state && state.token ? state.token : props.token ? props.token : null;
    const expire = state && state.expire ? state.expire : props.expire ? props.expire : null;

    const { data: cannabisCard, isFetching: cannabisTokenFetching, isError, error } = useGetCannabisCardQuery({pin: pin, token: token});
    const [ collapseAreaOpen, setCollapseAreaOpen ] = useState(false);
    const [ prescriptions, setPrescriptions ] = useState();

    var elapsedTime = null;
    var initialCallTime = null;
    var setTimer = false;

    function backBtnClick() {
        navigate("/cannabis-card/" + token);
    }

    function exipreCannabisCardView() {
        localStorage.removeItem(CANNABIS_CARD_LOCAL_STORAGE_ITEM);
        backBtnClick();
    }
    function setElapsedTime() {
        initialCallTime = Date.parse(localStorage.getItem(CANNABIS_CARD_LOCAL_STORAGE_ITEM));
        elapsedTime = new Date() - initialCallTime;
    }

    function handleShowMoreBtnClick() {
        setCollapseAreaOpen(!collapseAreaOpen);
    }

    useEffect(() => {
        if (cannabisCard?.appointmentPrescriptionDetails) {
            const sortedPrescriptions = [].concat(cannabisCard.appointmentPrescriptionDetails).sort((a, b) => a.creationDate < b.creationDate ? 1 : -1);;
            setPrescriptions(sortedPrescriptions);
        }
    }, [cannabisCard]);

    useEffect(() => {
        if (!localStorage.getItem(CANNABIS_CARD_LOCAL_STORAGE_ITEM)) {
            localStorage.setItem(CANNABIS_CARD_LOCAL_STORAGE_ITEM, new Date());
            setElapsedTime();
            setTimer = true;
        }
    }, [prescriptions]);

    // expiring the public cannabis card after a timeout period
    // redirects to the PIN entry page after the timeout
    useEffect(() => {
        setElapsedTime();

        if (expire) {
            if (elapsedTime < PUBLIC_CANNABIS_CARD_TIMEOUT) {
                setTimeout(() => {
                    exipreCannabisCardView();
                }, PUBLIC_CANNABIS_CARD_TIMEOUT - elapsedTime);
            } else {
                exipreCannabisCardView();
            }
        }
    }, [setTimer]);

    return (
        <div className="container">
            <div className="row card-outer">
                <div className="col-12">
                    {pin ? (
                        <>
                            {cannabisTokenFetching ? (
                                <div className="mt-5 text-center">
                                    <LoadingSpinner />
                                </div>
                            ) : (
                                <div>
                                    {isError ? (
                                        <div className="px-0 px-sm-5 mt-10 mb-5 text-center">
                                            {error.data.error == 'Precondition Failed' ? (
                                                <h2 className="mt-3 fw-bold">Invalid PIN.</h2>
                                            ) : null}
                                            {error.data.error == 'Unauthorized' ? (
                                                <h2 className="mt-3 fw-bold">QR is expired. Please rescan a QR.</h2>
                                            ) : null}
                                            <Button
                                                variant="primary py-2 px-3 border-0"
                                                fullWidth={false}
                                                onClick={backBtnClick}
                                            >
                                                Back
                                            </Button>
                                        </div>
                                    ) : (
                                        <div>
                                            {/* card display section */}
                                            <PhysicalCard cannabisCard={cannabisCard} token={token} />

                                            {/* prescription section */}
                                            <div className="row mb-5 move-row-up">
                                                {prescriptions && prescriptions[0] ? 
                                                    (
                                                        <Prescription prescription={prescriptions[0]} index={0}/>
                                                    ) : null}

                                                {prescriptions?.length > 1 ? 
                                                    (
                                                        <>
                                                            <Button
                                                                variant="primary show-more-btn py-2 px-3 border-0"
                                                                fullWidth={false}
                                                                onClick={handleShowMoreBtnClick}
                                                                className="mb-5"
                                                            >
                                                                {collapseAreaOpen ? 'Hide' : 'Show More'}
                                                            </Button>

                                                            {prescriptions?.map((prescription, index) => {
                                                                return( 
                                                                    <div key={index}>
                                                                        <Collapse isOpened={collapseAreaOpen}>
                                                                            {index != 0 ? <Prescription className="mt-5" prescription={prescription} index={index}/> : null}
                                                                        </Collapse>
                                                                    </div>
                                                                );
                                                            })}
                                                        </>
                                                    ) : null 
                                                }
                                            </div>

                                            {/* clinic info section */}
                                            <div className="row mb-5">
                                                <div className="col-12 px-0">
                                                    <div className="highlight text-center px-2">
                                                        <p className="fs-15 mb-0">This prescription is issued by</p>
                                                        <a className="fs-18 remove-anchor-styles" href="https://thegpservice.co.uk/" target="_blank">The GP Service (UK) Ltd</a>
                                                        <p className="fs-15 mt-5 mb-0">Regulated by</p>
                                                        <p className="fs-18 mb-0">Care Quality Commission (CQC)</p>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* more info section */}
                                            <div className="row">
                                                <div className="col-12 px-0">
                                                    <p className="fs-15 highlight text-center px-2">
                                                        The patient named and pictured above has been lawfully prescribed and supplied 
                                                        this treatment in accordance with current applicable regulations in the UK.<br />
                                                        For more information, call the clinic 
                                                        on <a href="tel:02477450733" className="remove-anchor-styles">0247 745 0733</a>.
                                                    </p>
                                                </div>
                                            </div>

                                            {/* legal information section */}
                                            <div className="row mb-5">
                                                <div className="col-12 px-0">
                                                    <LegalInformationPage/>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="px-0 px-sm-5 mt-10 mb-5 text-center">
                            <h2 className="mt-3 fw-bold">This link is expired. Please rescan a QR.</h2>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
