import React from "react";

import { CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody } from '@coreui/react';
import PoliceInfoPage from "./PoliceInfo";
import DoormenInfoPage from "./DoormenInfo";
import BusinessEstablishmentPage from "./BusinessEstablishment";

export default function LegalInformationPage() {
    return (
        <CAccordion>
            <CAccordionItem itemKey={1}>
                <CAccordionHeader>Police Information</CAccordionHeader>
                <CAccordionBody>
                    <PoliceInfoPage/>
                </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={2}>
                <CAccordionHeader>Doormen Information</CAccordionHeader>
                <CAccordionBody>
                    <DoormenInfoPage/>
                </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={3}>
                <CAccordionHeader>Business Establishment</CAccordionHeader>
                <CAccordionBody>
                    <BusinessEstablishmentPage/>
                </CAccordionBody>
            </CAccordionItem>
        </CAccordion>
    );
}