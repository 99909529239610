import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { toast } from "react-toastify";

import {
    useGetMyProfileQuery,
    useUpdateProfileMutation,
} from "../../../services/userService";
import parseError from "../../../utils/errorUtils";

import { Form, SubmitButton, TextField } from "../../../components/forms";
import GoBack from "../../../components/GoBack";
import TimerWindow from "../../../components/timerWindow/TimerWindow";

const ChangeEmailSchema = yup.object({
    email: yup.string().email().required("Please enter new email address"),
});

export default function ChangeEmailPage() {
    const navigate = useNavigate();

    const [showTimerWindow, setShowTimerWindow] = useState(false);
    const [newEmail, setNewEmail] = useState("");
    const [initialValues] = useState({
        email: "",
    });

    const [updateProfile] = useUpdateProfileMutation();
    const { data: profileData } = useGetMyProfileQuery();

    const handleSubmit = async (values) => {
        try {
            const data = {
                ...profileData,
                ...values,
            };

            const postCode = data.address?.postcode;

            if (postCode.slice(0, 2).toLowerCase().includes("bt")) {
                toast.error("Location not currently supported");
            } else {
                const res = await updateProfile({ values: data });

                if (res.error) {
                    // handle error
                    toast.error(parseError(res.error, "Couldn't update email"));
                } else {
                    // request succeeded
                    setNewEmail(values.email);
                    setShowTimerWindow(true);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleNavigateToAccount = () => {
        navigate("/user/account");
    };

    return (
        <div className="account-layout pt-6 pt-lg-0 mb-4 mb-sm-6 mb-lg-0 px-xl-6 px-xxl-12">
            <GoBack text="Go back to account" />
            <div className="description-section bg-white rounded mt-5 px-5 py-7">
                {showTimerWindow ? (
                    <TimerWindow
                        success
                        showTimer={false}
                        description={
                            <>
                                Your email has been successfully changed to{" "}
                                <span className="text-primary fw-semibold text-decoration-underline">
                                    {newEmail}
                                </span>
                            </>
                        }
                        action={handleNavigateToAccount}
                        actionBtnText="Okay, got it"
                    />
                ) : (
                    <>
                        <h3 className="text-primary text-center fw-bold">
                            Change email address
                        </h3>
                        <Form
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                            validationSchema={ChangeEmailSchema}
                        >
                            <div className="col-12 col-sm-7 col-lg-5 mb-3 mb-lg-0 mx-auto mt-8">
                                <div className="row mb-5">
                                    <TextField
                                        name="email"
                                        placeholder="Email address"
                                        disabled
                                    />
                                </div>

                                <SubmitButton
                                    variant="primary text-uppercase px-2"
                                    disabled
                                >
                                    Change email
                                </SubmitButton>
                            </div>
                        </Form>
                    </>
                )}
            </div>
        </div>
    );
}
