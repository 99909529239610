import React from "react";

import { useModal } from "../../../hooks/modalsHooks";

import ModalLayout from "../ModalLayout";

import {
    SUPPORT_EMAIL,
    SUPPORT_TELEPHONE,
    SUPPORT_WHATSAPP,
} from "../../../constants/appProperties";
import ContactCard from "../../contactCard/ContactCard";

export default function InfoModal({ title, body }) {
    const { hideModal } = useModal();

    return (
        <ModalLayout className="bg-secondary-100" width={"43rem"}>
            <ModalLayout.Header
                className="bg-secondary-100"
                closeModal={hideModal}
            />
            <ModalLayout.Body className="pt-0 px-5 pb-10 h-max bg-secondary-100">
                <div className="w-100">
                    <div className="text-center">
                        <img src="/images/vectors/help.svg" alt="" width={70} />
                        <h4 className="fs-5 text-primary fw-semibold my-4">
                            {title}
                        </h4>
                    </div>
                </div>
                <div className="text-center m-auto">
                    {body && (
                        <label className="text-primary-200 mb-7 fs-6">
                            {body}
                        </label>
                    )}
                    <div className="px-0 px-sm-8 px-md-10 d-flex flex-column mx-auto contact-item-container">
                        <div className="mb-4">
                            <ContactCard
                                icon="phone-primary"
                                text="Call us:"
                                type="tel"
                                contact={SUPPORT_TELEPHONE}
                            />
                        </div>
                        <div className="mb-4">
                            <ContactCard
                                icon="mail"
                                text="Email us:"
                                type="mailto"
                                contact={SUPPORT_EMAIL}
                            />
                        </div>
                        <div className="mb-4">
                            <ContactCard
                                icon="whatsapp"
                                text="WhatsApp us:"
                                contact={SUPPORT_WHATSAPP}
                                contactText="0738 582 8801"
                            />
                        </div>
                    </div>
                </div>
            </ModalLayout.Body>
        </ModalLayout>
    );
}
