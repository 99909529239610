import React from "react";

import "./not-found.scss";

export default function NotFoundPage() {
    return (
        <div className="not-found position-absolute top-50 bottom-50 start-50 end-50 h-100 w-100 d-flex justify-content-center align-items-center text-primary">
            <h1>404. Not Found.</h1>
        </div>
    );
}
