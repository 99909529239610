import React from "react";
import { useFormikContext } from "formik";

import { Checkbox, YesNoRadio } from "../forms";

import {
    PHARMACY_NOTES_QUESTIONS,
    questionStructure,
} from "../../constants/orderNotesQuestions";

export default function PharmacyNotesQuestionAnswers({ viewOnly = false }) {
    const { values } = useFormikContext();

    const valuesArray = values ? Object.keys(values) : [];
    const questionsLength = valuesArray.length;

    const questionAnswersToDisplay = PHARMACY_NOTES_QUESTIONS.keys.filter(
        (key) => PHARMACY_NOTES_QUESTIONS.data[key].active
    );

    return (
        <>
            {questionAnswersToDisplay.map((key, index) => {
                if (
                    PHARMACY_NOTES_QUESTIONS.data[key].type ===
                    questionStructure.text
                ) {
                    return (
                        <tr key={key}>
                            {/* question */}
                            <td
                                className={`py-5 ps-5 ${
                                    index === questionsLength - 1 && "border-0"
                                }`}
                            >
                                <span className="me-2 text-medium-gray fw-bold">
                                    {`${index + 1 < 10 ? "0" : ""}${
                                        index + 1
                                    }.`}
                                </span>
                                <span>
                                    {PHARMACY_NOTES_QUESTIONS.data[key].label ||
                                        "No question preview available"}
                                </span>
                            </td>
                            {/* answer */}
                            <td
                                className={`py-5 pe-5 text-break ${
                                    index === questionsLength - 1 && "border-0"
                                }`}
                            >
                                {values?.[key]?.patientText && (
                                    <span className="row mx-0">
                                        <b className="px-0">Your answer:</b>
                                        {values?.[key]?.patientText}
                                    </span>
                                )}
                                {values?.[key]?.pharmacyText && (
                                    <span className="row mx-0 mt-2">
                                        <b className="px-0">
                                            Pharmacist's comment:
                                        </b>
                                        {values?.[key]?.pharmacyText}
                                    </span>
                                )}
                            </td>
                        </tr>
                    );
                }

                if (
                    PHARMACY_NOTES_QUESTIONS.data[key].type ===
                    questionStructure.choice
                ) {
                    return (
                        <tr key={key}>
                            {/* question */}
                            <td
                                className={`py-5 ps-5 ${
                                    index === questionsLength - 1 && "border-0"
                                }`}
                            >
                                <span className="me-2 text-medium-gray fw-bold">
                                    {`${index + 1 < 10 ? "0" : ""}${
                                        index + 1
                                    }.`}
                                </span>
                                <span>
                                    {PHARMACY_NOTES_QUESTIONS.data[key].label ||
                                        "No question preview available"}
                                </span>
                            </td>
                            {/* answer */}
                            <td
                                className={`py-5 pe-5 text-break ${
                                    index === questionsLength - 1 && "border-0"
                                }`}
                            >
                                <YesNoRadio
                                    name={`${key}.choice`}
                                    disabled={viewOnly}
                                />
                                {values?.[key]?.patientText && (
                                    <span className="row mx-0">
                                        <b className="px-0">Your answer:</b>
                                        {values?.[key]?.patientText}
                                    </span>
                                )}
                                {values?.[key]?.pharmacyText && (
                                    <span className="row mx-0 mt-2">
                                        <b className="px-0">
                                            Pharmacist's comment:
                                        </b>
                                        {values?.[key]?.pharmacyText}
                                    </span>
                                )}
                            </td>
                        </tr>
                    );
                }

                if (
                    PHARMACY_NOTES_QUESTIONS.data[key].type ===
                    questionStructure.checkbox
                ) {
                    return (
                        <tr key={key}>
                            {/* question */}
                            <td
                                className={`py-5 ps-5 ${
                                    index === questionsLength - 1 && "border-0"
                                }`}
                            >
                                <span className="me-2 text-medium-gray fw-bold">
                                    {`${index + 1 < 10 ? "0" : ""}${
                                        index + 1
                                    }.`}
                                </span>
                                <span>
                                    {PHARMACY_NOTES_QUESTIONS.data[key].label ||
                                        "No question preview available"}
                                </span>
                            </td>
                            {/* answer */}
                            <td
                                className={`py-5 pe-5 text-break ${
                                    index === questionsLength - 1 && "border-0"
                                }`}
                            >
                                {PHARMACY_NOTES_QUESTIONS.data[key].choices.map(
                                    (data, idx) => (
                                        <div
                                            className="me-0 me-lg-3 mb-2 mb-lg-0"
                                            key={idx}
                                        >
                                            <Checkbox
                                                name={`${key}.choices[${data.value}]`}
                                                label={data.label}
                                                disabled={viewOnly}
                                                labelClass="text-break"
                                            />
                                        </div>
                                    )
                                )}
                                {values?.[key]?.patientText && (
                                    <span className="row mx-0">
                                        <b className="px-0">Your answer:</b>
                                        {values?.[key]?.patientText}
                                    </span>
                                )}
                                {values?.[key]?.pharmacyText && (
                                    <span className="row mx-0 mt-2">
                                        <b className="px-0">
                                            Pharmacist's comment:
                                        </b>
                                        {values?.[key]?.pharmacyText}
                                    </span>
                                )}
                            </td>
                        </tr>
                    );
                }

                if (
                    PHARMACY_NOTES_QUESTIONS.data[key].type ===
                    questionStructure.checkboxAndText
                ) {
                    if (
                        PHARMACY_NOTES_QUESTIONS.data[key].renderCondition
                            ?.decisionValue ===
                        values[
                            PHARMACY_NOTES_QUESTIONS.data[key].renderCondition
                                ?.parentId
                        ]?.choice
                    ) {
                        return (
                            <tr key={key}>
                                {/* question */}
                                <td
                                    className={`py-5 ps-5 ${
                                        index === questionsLength - 1 &&
                                        "border-0"
                                    }`}
                                >
                                    <span className="me-2 text-medium-gray fw-bold">
                                        {`${index + 1 < 10 ? "0" : ""}${
                                            index + 1
                                        }.`}
                                    </span>
                                    <span>
                                        {PHARMACY_NOTES_QUESTIONS.data[key]
                                            .label ||
                                            "No question preview available"}
                                    </span>
                                </td>
                                {/* answer */}
                                <td
                                    className={`py-5 pe-5 text-break ${
                                        index === questionsLength - 1 &&
                                        "border-0"
                                    }`}
                                >
                                    {PHARMACY_NOTES_QUESTIONS.data[
                                        key
                                    ].choices?.map((data, idx) => (
                                        <div key={idx}>
                                            <div className="me-0 me-lg-3 mb-2 mb-lg-0">
                                                <Checkbox
                                                    name={`${key}.choices[${data.value}]`}
                                                    label={data.label}
                                                    disabled={viewOnly}
                                                    labelClass="text-break"
                                                />
                                            </div>
                                            {values[key]?.choices[data.value]
                                                ?.text?.patientText && (
                                                <span className="row mx-0">
                                                    <b className="px-0">
                                                        Your answer:
                                                    </b>
                                                    {
                                                        values[key]?.choices[
                                                            data.value
                                                        ]?.text.patientText
                                                    }
                                                </span>
                                            )}
                                            {values[key]?.choices[data.value]
                                                ?.text?.pharmacyText && (
                                                <span className="row mx-0 mt-1 mb-2">
                                                    <b className="px-0">
                                                        Pharmacist's comment:
                                                    </b>
                                                    {
                                                        values[key]?.choices[
                                                            data.value
                                                        ]?.text.pharmacyText
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    ))}
                                </td>
                            </tr>
                        );
                    } else {
                        return null;
                    }
                }

                if (
                    PHARMACY_NOTES_QUESTIONS.data[key].type ===
                    questionStructure.choiceAndTextOnCondition
                ) {
                    return (
                        <tr key={key}>
                            {/* question */}
                            <td
                                className={`py-5 ps-5 ${
                                    index === questionsLength - 1 && "border-0"
                                }`}
                            >
                                <span className="me-2 text-medium-gray fw-bold">
                                    {`${index + 1 < 10 ? "0" : ""}${
                                        index + 1
                                    }.`}
                                </span>
                                <span>
                                    {PHARMACY_NOTES_QUESTIONS.data[key].label ||
                                        "No question preview available"}
                                </span>
                            </td>
                            {/* answer */}
                            <td
                                className={`py-5 pe-5 text-break ${
                                    index === questionsLength - 1 && "border-0"
                                }`}
                            >
                                <div className="me-0 me-lg-3 mb-2 mb-lg-0">
                                    <YesNoRadio
                                        name={`${key}.choice`}
                                        disabled={viewOnly}
                                    />
                                </div>
                                {(values[key]?.choice === true ||
                                    values[key]?.choice === false) && (
                                    <>
                                        {values?.[key]?.patientText && (
                                            <span className="row mx-0">
                                                <b className="px-0">
                                                    Your answer:
                                                </b>
                                                {values?.[key]?.patientText}
                                            </span>
                                        )}
                                        {values?.[key]?.pharmacyText && (
                                            <span className="row mx-0 mt-2">
                                                <b className="px-0">
                                                    Pharmacist's comment:
                                                </b>
                                                {values?.[key]?.pharmacyText}
                                            </span>
                                        )}
                                    </>
                                )}
                                {typeof values[key]?.choice === "string" &&
                                    PHARMACY_NOTES_QUESTIONS.data[key]
                                        ?.inputRenderCondition[
                                        values[key]?.choice
                                    ] && (
                                        <>
                                            {PHARMACY_NOTES_QUESTIONS.data[
                                                key
                                            ].choices.map((data, idx) => (
                                                <div
                                                    className="me-0 me-lg-3 mb-2 mb-lg-0"
                                                    key={idx}
                                                >
                                                    <>
                                                        <Checkbox
                                                            name={`${key}.choices[${data.value}]`}
                                                            label={data.label}
                                                            disabled={viewOnly}
                                                            labelClass="text-break"
                                                        />
                                                        {PHARMACY_NOTES_QUESTIONS
                                                            .data[key]
                                                            ?.inputRenderCondition[
                                                            values[key]?.choice
                                                        ] === data.value && (
                                                            <>
                                                                {values?.[key]
                                                                    ?.patientText && (
                                                                    <span className="row mx-0">
                                                                        <b className="px-0">
                                                                            Your
                                                                            answer:
                                                                        </b>
                                                                        {
                                                                            values?.[
                                                                                key
                                                                            ]
                                                                                ?.patientText
                                                                        }
                                                                    </span>
                                                                )}
                                                                {values?.[key]
                                                                    ?.pharmacyText && (
                                                                    <span className="row mx-0 mt-1 mb-2">
                                                                        <b className="px-0">
                                                                            Pharmacist's
                                                                            comment:
                                                                        </b>
                                                                        {
                                                                            values?.[
                                                                                key
                                                                            ]
                                                                                ?.pharmacyText
                                                                        }
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                </div>
                                            ))}
                                        </>
                                    )}
                            </td>
                        </tr>
                    );
                }

                if (
                    PHARMACY_NOTES_QUESTIONS.data[key].type ===
                    questionStructure.checkboxQuestion
                ) {
                    return (
                        <tr key={key}>
                            {/* question */}
                            <td
                                className={`py-5 ps-5 ${
                                    index === questionsLength - 1 && "border-0"
                                }`}
                            >
                                <span className="me-2 text-medium-gray fw-bold">
                                    {`${index + 1 < 10 ? "0" : ""}${
                                        index + 1
                                    }.`}
                                </span>
                                <span>
                                    {PHARMACY_NOTES_QUESTIONS.data[key].label ||
                                        "No question preview available"}
                                </span>
                            </td>
                            {/* answer */}
                            <td
                                className={`py-5 pe-5 text-break ${
                                    index === questionsLength - 1 && "border-0"
                                }`}
                            >
                                {PHARMACY_NOTES_QUESTIONS.data[key].choices.map(
                                    (data, idx) => (
                                        <div key={idx}>
                                            <div className="d-flex">
                                                <Checkbox
                                                    name={`${key}.choices[${data.value}]`}
                                                    disabled={viewOnly}
                                                    labelClass="text-break"
                                                />
                                                {data.label}
                                            </div>
                                            <b className="text-break">
                                                {values[key]?.choices[
                                                    data.value
                                                ]?.choice
                                                    ? `Note: Advised`
                                                    : `Note: Not advised`}
                                            </b>
                                        </div>
                                    )
                                )}
                            </td>
                        </tr>
                    );
                }

                return (
                    <tr key={key}>
                        {/* question */}
                        <td
                            className={`py-5 ps-5 ${
                                index === questionsLength - 1 && "border-0"
                            }`}
                        >
                            <span className="me-2 text-medium-gray fw-bold">
                                {`${index + 1 < 10 ? "0" : ""}${index + 1}.`}
                            </span>
                            <span>
                                {PHARMACY_NOTES_QUESTIONS.data[key].label ||
                                    "No question preview available"}
                            </span>
                        </td>
                        {/* answer */}
                        <td
                            className={`py-5 pe-5 text-break ${
                                index === questionsLength - 1 && "border-0"
                            }`}
                        >
                            {values[key]?.text || "No answer preview available"}
                            {values?.[key]?.patientText && (
                                <span className="row mx-0">
                                    <b className="px-0">Your answer:</b>
                                    {values?.[key]?.patientText}
                                </span>
                            )}
                            {values?.[key]?.pharmacyText && (
                                <span className="row mx-0 mt-2">
                                    <b className="px-0">
                                        Pharmacist's comment:
                                    </b>
                                    {values?.[key]?.pharmacyText}
                                </span>
                            )}
                        </td>
                    </tr>
                );
            })}
        </>
    );
}
