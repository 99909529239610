import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import { AppointmentContext } from "../../../providers/AppointmentProvider";
import { useGetDoctorQuery } from "../../../services/dataService";

import Button from "../../../components/button/Button";
import ContactCard from "../../../components/contactCard/ContactCard";

import {
    SUPPORT_TELEPHONE,
    SUPPORT_WHATSAPP,
} from "../../../constants/appProperties";

export default function AppointmentSuccessPage() {
    const navigate = useNavigate();
    const { appointmentData } = useContext(AppointmentContext);

    const { data: doctorData } = useGetDoctorQuery(
        {
            doctorId: appointmentData.doctorId,
        },
        { skip: !appointmentData?.doctorId }
    );

    const handleNavigate = () => {
        localStorage.removeItem("EligibilityForm");
        localStorage.removeItem("SubscribedToMail");
        navigate("/user/dashboard", { replace: true });
    };

    return (
        <div className="appointment-success-page px-3 m-auto pb-12 w-100">
            <div className="d-flex flex-column">
                <h2 className="text-primary fw-bold mb-6 text-center">
                    Thank you for booking your appointment with us!
                </h2>
                <p className="fs-5 subtitle text-primary-200 text-center mb-8 mx-2 mx-lg-8">
                    Thank you for booking an appointment with us! If you have
                    any questions, feel free to reach out.
                    <br />
                    Here's to your better health!
                </p>
                <h3 className="fw-semibold subtitle text-primary text-center mb-4 mx-2 mx-lg-8">
                    Consultation details
                </h3>
            </div>
            <div className="px-1 px-md-7 px-lg-8 px-xl-10 mx-0 mx-md-7 mx-lg-8 mx-xl-10">
                <div className="bg-white bg-opacity-25 py-6 px-4 mx-1 mx-md-2 mb-2 row">
                    <div className="col-12 col-md-6 d-flex justify-content-start mb-4 mb-md-0">
                        <img src="/images/vectors/stethoscope.svg" alt="" />
                        <div className="d-flex flex-column justify-content-center ms-3">
                            <label className="mb-1 small">Clinician name</label>
                            <label className="text-start fs-6 fw-bold">
                                {doctorData?.displayName}
                            </label>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 d-flex justify-content-start">
                        <img
                            src="/images/vectors/calendar-bordered.svg"
                            alt=""
                        />
                        <div className="d-flex flex-column justify-content-center ms-3">
                            <label className="mb-1 small">
                                Consultation date & time
                            </label>
                            <label className="text-start fs-6 fw-bold">
                                {dayjs(appointmentData.datetime).format(
                                    "Do MMMM, YYYY"
                                )}
                                &nbsp;|&nbsp;
                                {dayjs(appointmentData.datetime).format(
                                    "hh:mma"
                                )}
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <p className="fs-5 subtitle text-primary-200 text-center mt-8 mx-2 mx-lg-8">
                Please contact the Treat it support team to learn more about
                your appointment
            </p>

            <div className="row px-2 px-md-6 px-lg-12 mx-0 mx-lg-5">
                <div className="col-12 col-md-6 mb-3">
                    <ContactCard
                        icon="phone-primary"
                        text="Treat it number:"
                        type="tel"
                        contact={SUPPORT_TELEPHONE}
                    />
                </div>
                <div className="col-12 col-md-6 mb-3">
                    <ContactCard
                        icon="whatsapp"
                        text="Treat It WhatsApp:"
                        contact={SUPPORT_WHATSAPP}
                        contactText="0738 582 8801"
                    />
                </div>
            </div>
            <div className="row px-2 px-md-6 px-lg-12 mx-0 mx-lg-5">
                <div className="col-12">
                    <div className="card shadow-sm rounded-1 border-0 px-3 py-4 d-flex flex-row">
                        <img
                            src="/images/vectors/faq-primary-rounded.svg"
                            alt=""
                            width={50}
                            className="my-auto"
                        />
                        <span className="ms-3 text-primary fs-6 fw-semibold my-auto">
                            Frequently asked Questions and Answers
                        </span>
                        <button
                            className="bg-transparent p-0 ms-auto border-0"
                            onClick={() =>
                                (window.location =
                                    "https://treat-it.clinic/treat-it-faqs/")
                            }
                        >
                            <img
                                src="/images/vectors/chevron-right.svg"
                                alt=""
                                width={25}
                            />
                        </button>
                    </div>
                </div>
            </div>

            <div className="d-flex">
                <Button
                    fullWidth={false}
                    variant="primary mt-7 fs-5 mx-auto px-6 px-sm-12"
                    onClick={handleNavigate}
                >
                    <img
                        className="me-2 my-auto"
                        src="/images/vectors/arrow-right.svg"
                        alt=""
                    />
                    Go to your Profile
                </Button>
            </div>
        </div>
    );
}
