import React, {useContext, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { AppointmentContext } from "../../../providers/AppointmentProvider";
import { useGetConsultationDetailsQuery } from "../../../services/userService";
import { useGetMyProfileQuery } from "../../../services/userService";
import Button from "../../../components/button/Button";
import { useAuth } from "../../../utils/authUtils";
import { useLoggerUtils } from "../../../utils/loggerUtils";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

export default function ConsultationDetailsPage() {
  const { setAppointmentData, appointmentData } =
    useContext(AppointmentContext);

  const navigate = useNavigate();
  const auth = useAuth();
  const { info } = useLoggerUtils();

  const { data: consultationDetails } = useGetConsultationDetailsQuery();
  const { data: profileData } = useGetMyProfileQuery();

  if (auth.isLogged && profileData) {
    datadogRum.setUser({
        id: profileData?.id,
        email: profileData?.email,
        name: profileData?.displayName,
    });
    info("User added to RUM!");

    datadogLogs.setUser({
        id: profileData?.id,
        email: profileData?.email,
        name: profileData?.displayName,
    });
    info("User added to logger!");
  }

  const navigateToCheckoutPage = async () => {
    try {
      const appointment = {
        type: "BOOKING",
        id: consultationDetails?.appointmentId,
        datetime: consultationDetails?.dateTime,
        doctorId: consultationDetails?.doctorId,
        name: `Consultation (${dayjs(consultationDetails?.dateTime).format(
          "DD/MM/YYYY"
        )} ${dayjs(consultationDetails?.dateTime).format("hh:mm a")})`,
        patient: profileData,
        deliveryType: "STANDARD",
        paymentType: "ONLINE",
        deviceType: "OTHER",
      };
      await setAppointmentData({ ...appointment });
      navigate("/onboarding/appointment/checkout");
    } catch (error) {
      console.error(error);
    }
  };

  const navigateToSelectSlot = () => {
    navigate("/onboarding/appointment/select-slot", { replace: true });
  };

  const formatDate = (dateString) => {
    if (!dateString) return ''; // Handle case when dateString is empty or undefined
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div className="confirm-details-page px-3 m-auto pb-12 w-100" style={{maxWidth: '50rem'}}>
      <div className="d-flex flex-column">
        <h2 className="text-primary fw-bold mb-6 text-center">
          Confirm Appointment
        </h2>
        <p className="fs-5 subtitle text-primary-200 text-center mb-8 mx-2 mx-lg-8">
          Review your appointment date and time.
        </p>
        {consultationDetails && consultationDetails.code !== "200" && (
          <div className="alert alert-danger mx-md-2 mb-5 mx-1" role="alert">
            <h5 className="fw-semibold subtitle text-center alert-text">
              <img
                className="me-2 my-auto"
                src="/images/vectors/warning-exclamation-red.svg"
                alt=""
              />
              {consultationDetails.message}
            </h5>
          </div>
        )}
      </div>
      <div className="bg-white bg-opacity-25 py-6 px-4 mx-1 mx-md-2 mb-2">
        <div className="row mt-4">
          <div className="col-12 col-md-6 d-flex justify-content-start mb-4 mb-md-0">
            <img src="/images/vectors/profile-bordered.svg" alt=""/>
            <div className="d-flex flex-column justify-content-center ms-3">
              <label className="mb-1 small">Patient Name</label>
              <label className="text-start fs-6 fw-bold">
                {profileData &&
                    `${profileData.displayName}`}
              </label>
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-start">
            <img src="/images/vectors/email-bordered.svg" alt=""/>
            <div className="d-flex flex-column justify-content-center ms-3">
              <label className="mb-1 small">Email</label>
              <label className="text-start fs-6 fw-bold overflow-wrap">
                {profileData &&
                    `${profileData.email}`}
              </label>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 col-md-6 d-flex justify-content-start mb-4 mb-md-0">
            <img src="/images/vectors/phone-bordered.svg" alt=""/>
            <div className="d-flex flex-column justify-content-center ms-3">
              <label className="mb-1 small">Telephone</label>
              <label className="text-start fs-6 fw-bold">
                {profileData &&
                    `${profileData.phone}`}
              </label>
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-start mb-4 mb-md-0">
            <img src="/images/vectors/calendar-bordered.svg" alt=""/>
            <div className="d-flex flex-column justify-content-center ms-3">
              <label className="mb-1 small">Date of Birth</label>
              <label className="text-start fs-6 fw-bold">
                {profileData &&
                    `${formatDate(profileData.dateOfBirth)}`}
              </label>
            </div>
          </div>
        </div>
        <div>
          <hr/>
        </div>
        <div className="row mt-4">
          <div className="col-12 col-md-6 d-flex justify-content-start mb-4 mb-md-0">
            <img src="/images/vectors/stethoscope.svg" alt=""/>
            <div className="d-flex flex-column justify-content-center ms-3">
              <label className="mb-1 small">Clinician name</label>
              <label className="text-start fs-6 fw-bold ">
                {consultationDetails &&
                    `${consultationDetails.docFirstName} ${consultationDetails.docLastName}`}
              </label>
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-start">
            <img src="/images/vectors/calendar-bordered.svg" alt=""/>
            <div className="d-flex flex-column justify-content-center ms-3">
              <label className="mb-1 small">Consultation date & time</label>
              <label className="text-start fs-6 fw-bold">
                {consultationDetails &&
                    `${dayjs(consultationDetails.dateTime).format(
                        "Do MMMM, YYYY"
                    )} | ${dayjs(consultationDetails.dateTime).format("hh:mma")}`}
              </label>
            </div>
          </div>
        </div>


      </div>
      <div className="d-flex">
      {consultationDetails && consultationDetails.code === "200" && (
            <Button
                fullWidth={false}
                variant="primary mt-7 fs-5 mx-auto"
                onClick={navigateToCheckoutPage}
            >
              <img
                  className="me-2 my-auto"
                  src="/images/vectors/arrow-right.svg"
                  alt=""
              />
              Confirm and Continue
            </Button>
        )}
        {consultationDetails && consultationDetails.code !== "200" && (
            <Button
                fullWidth={false}
                variant="primary mt-7 fs-5 mx-auto px-6 px-sm-12"
                onClick={navigateToSelectSlot}
            >
              <img
                  className="me-2 my-auto"
                  src="/images/vectors/arrow-right.svg"
                  alt=""
              />
              Select new time slot
            </Button>
        )}
      </div>
    </div>
  );
}
