import { useState, useContext, createContext, useEffect } from "react";

import { ACCESS_TOKEN_NAME } from "../constants";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isLogged, setIsLogged] = useState(
    !!localStorage.getItem(ACCESS_TOKEN_NAME)
  );

  const logIn = () => {
    setIsLogged(true);
  };

  const logOut = () => {
    setIsLogged(false);
  };

  useEffect(() => {
    if (localStorage.getItem(ACCESS_TOKEN_NAME)) {
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isLogged, logIn, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
