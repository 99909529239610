import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useAddOrderNotesMutation } from "../../../services/orderService";
import parseError from "../../../utils/errorUtils";

import { Form, SubmitButton, TextArea } from "../../../components/forms";
import Button from "../../../components/button/Button";

export default function AdditionalNotesPage() {
    const navigate = useNavigate();
    const { id: orderId } = useParams();

    const [addOrderNote] = useAddOrderNotesMutation();

    const handleSubmit = async (values) => {
        try {
            const data = {
                type: "PRESENTING_COMPLAINT",
                description: values.complain,
            };

            const res = await addOrderNote({ orderId, values: data });

            if (res.error) {
                // handle error
                toast.error(parseError(res.error, "Couldn't add note"));
            } else {
                //handle success
                // navigate(`/onboarding/appointment/referred-by`, {
                //     replace: true,
                // });
                navigate(`/onboarding/update-profile-picture`);
                window.scrollTo(0, 0);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleSkip = () => {
        navigate(`/onboarding/update-profile-picture`);
        window.scrollTo(0, 0);
    };

    return (
        <div className="additional-notes-page px-3 m-auto pb-12 w-100">
            <div className="d-flex flex-column">
                <h2 className="text-primary fw-bold mb-6 text-center">
                    Additional details
                </h2>
                <p className="fs-5 subtitle text-primary-200 text-center mb-8 mx-2 mx-lg-8">
                    Is there anything else you'd like to share or let us know
                    about? The more information the better and helps us provide
                    a seamless experience for you.
                </p>
            </div>
            <Form initialValues={{ complain: "" }} onSubmit={handleSubmit}>
                <div className="px-1 px-md-12 mx-0 mx-md-12">
                    <TextArea
                        name="complain"
                        placeholder="Type your notes here..."
                    />
                </div>
                <div className="mt-7 d-flex flex-column flex-sm-row justify-content-center">
                    <SubmitButton
                        fullWidth={false}
                        variant="primary fs-5 px-12"
                        type="submit"
                    >
                        <div className="d-flex justify-content-center px-sm-10">
                            <img
                                className="me-2 my-auto"
                                src="/images/vectors/arrow-right.svg"
                                alt=""
                            />
                            <span>Next</span>
                        </div>
                    </SubmitButton>
                    <Button
                        variant="link px-7 fw-semibold"
                        fullWidth={false}
                        onClick={handleSkip}
                    >
                        Skip this step
                    </Button>
                </div>
            </Form>
        </div>
    );
}
