/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useRef } from "react";

import { useIsInViewport } from "../../utils/domUtils";
import OutsideAlerter from "../OutsideAlerter";

import LoadingSpinner from "../loadingSpinner/LoadingSpinner";

import "./drop-down.scss";

/**
 * Search enabled dropdown
 * @param {{
 * options: { value: string, label: string }[],
 * placeholder: string,
 * onChange: function,
 * onSelect: function,
 * multiSelect: boolean,
 * disabled?: boolean,
 * }} props
 * @returns {React.FunctionComponent}
 *
 */
export default function DropDown({
    options = [],
    label,
    placeholder,
    onChange,
    onSelect,
    onBlur,
    selectedValues,
    multiSelect = true,
    hideIcon = false,
    showSearchIcon = false,
    showDownArrow = true,
    children,
    searchable = true,
    fullWidth = false,
    dropDownOnly = false,
    showOnMouseEnter = false,
    defaultSelectPlaceholder,
    containerClass,
    loading = false,
    loadingText = "",
    disabled,
    ...rest
}) {
    const listRef = useRef();
    const {
        isIntersecting: isVisible,
        observe,
        disconnect,
    } = useIsInViewport(listRef);

    const [showOptions, setShowOptions] = useState(false);
    const [searchText, setSearchText] = useState(
        !multiSelect ? selectedValues : ""
    );

    const handleAddOption = (value) => {
        setShowOptions(false);

        if (multiSelect) {
            setSearchText("");
            const values = [...(selectedValues || [])];
            if (onSelect) {
                onSelect([...values, value]);
            }
        } else {
            const selectedLabel = options.find(
                (optn) => optn.value === value
            ).label;
            if (!dropDownOnly) {
                setSearchText(selectedLabel);
            }
            if (onSelect) {
                onSelect(selectedLabel);
            }
        }
    };

    const handleOnChange = (value) => {
        setSearchText(value);
        if (onChange) {
            onChange(value);
        }
    };

    const gtePosition = () => {
        let position = {};

        if (isVisible?.left) {
            position = { ...position, left: 0 };
        }
        if (isVisible?.right) {
            position = { ...position, right: 0 };
        }
        if (isVisible?.top) {
            position = { ...position, top: 0 };
        }
        if (isVisible?.bottom) {
            position = { ...position, bottom: 0 };
        }

        return position;
    };

    useEffect(() => {
        observe();

        return () => {
            disconnect();
        };
    }, [showOptions]);

    useEffect(() => {
        if (!multiSelect) {
            setSearchText(selectedValues);
        }
    }, [selectedValues]);

    const displayingOptions = useMemo(() => {
        if (searchText) {
            if (multiSelect && selectedValues?.length > 0) {
                const availableOptions = options.filter(
                    (optn) => !selectedValues.includes(optn.value)
                );

                return availableOptions.filter((optn) =>
                    optn.label.includes(searchText)
                );
            }

            if (searchable) {
                return options.filter((optn) =>
                    `${optn.label}`
                        .toLowerCase()
                        .split(" ")
                        .join("")
                        .includes(
                            `${searchText}`.split(" ").join("").toLowerCase()
                        )
                );
            }
        }

        return options;
    }, [options, searchText, selectedValues, multiSelect]);

    return (
        <>
            {label && (
                <label className="form-label fs-5 fw-bold">{label}</label>
            )}
            <div
                className={`dropdown ${
                    fullWidth ? "w-100 h-100" : "w-fit"
                } ${containerClass}`}
            >
                <OutsideAlerter
                    className="input-container h-100"
                    onClickOutside={() => setShowOptions(false)}
                    onMouseEnter={() =>
                        showOnMouseEnter ? setShowOptions(true) : {}
                    }
                    onMouseLeave={() => setShowOptions(false)}
                >
                    {searchable ? (
                        <>
                            <input
                                {...rest}
                                className="form-control"
                                placeholder={
                                    placeholder ||
                                    defaultSelectPlaceholder ||
                                    ""
                                }
                                type="text"
                                onFocus={() => setShowOptions(true)}
                                onClick={() => setShowOptions(true)}
                                value={searchText || ""}
                                onBlur={onBlur}
                                onChange={(e) => {
                                    handleOnChange(e.target.value);
                                    setShowOptions(true);
                                }}
                                autoComplete="no"
                                autoCorrect="no"
                                tabIndex="0"
                                disabled={disabled}
                            />
                            {!hideIcon && (
                                <img
                                    className="search-icon"
                                    src="/images/vectors/search.svg"
                                    alt=""
                                />
                            )}
                            {showSearchIcon && hideIcon && (
                                <div
                                    className={`p-0 border-0 w-100 ${
                                        disabled && "disabled cursor-default"
                                    }`}
                                    aria-label="button"
                                    type="button"
                                    tabIndex="0"
                                    onClick={() =>
                                        !disabled && setShowOptions(true)
                                    }
                                    onFocus={() =>
                                        !disabled && setShowOptions(true)
                                    }
                                >
                                    <img
                                        className="search-icon"
                                        src="/images/vectors/down-primary.svg"
                                        alt=""
                                    />
                                </div>
                            )}
                        </>
                    ) : (
                        <div
                            className={`p-0 border-0 w-100 h-100 ${
                                disabled && "disabled cursor-default"
                            }`}
                            aria-label="button"
                            type="button"
                            tabIndex="0"
                            onClick={() => !disabled && setShowOptions(true)}
                            onFocus={() => !disabled && setShowOptions(true)}
                        >
                            {children || (
                                <label
                                    className={`default-select d-flex h-100 ${
                                        fullWidth ? "w-100" : ""
                                    } ${selectedValues ? "active" : ""} ${
                                        disabled && "disabled cursor-default"
                                    } ${showOptions ? "focus" : ""}`}
                                >
                                    <span className="me-2 my-auto text-truncate">
                                        {selectedValues ||
                                            defaultSelectPlaceholder ||
                                            "Select"}
                                    </span>
                                    {showDownArrow && (
                                        <img
                                            src="/images/vectors/down-primary.svg"
                                            alt=""
                                            className="ms-auto my-auto"
                                        />
                                    )}
                                </label>
                            )}
                        </div>
                    )}

                    {showOptions && loading ? (
                        <div
                            className={`option-list text-start ps-2 py-1 ${
                                searchable ? "w-100" : "custom-position"
                            }`}
                        >
                            <LoadingSpinner loadingText={loadingText} />
                        </div>
                    ) : null}

                    {showOptions && displayingOptions.length > 0 && !loading && (
                        <div
                            className={`option-list text-start ${
                                searchable ? "w-100" : "custom-position p-1"
                            }`}
                            ref={listRef}
                            style={{ ...gtePosition() }}
                        >
                            {displayingOptions.map((optn) => (
                                <div
                                    key={optn.value}
                                    className={`option fw-bold fs-6 text-truncate ${
                                        searchable ? "" : "p-2"
                                    }`}
                                    onClick={() => handleAddOption(optn.value)}
                                    title={optn.label}
                                >
                                    {optn.label}
                                </div>
                            ))}
                        </div>
                    )}
                </OutsideAlerter>
            </div>
        </>
    );
}
