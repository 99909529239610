import React, { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import { useGetMyProfileQuery } from "../../../services/userService";
import { useAuth } from "../../../utils/authUtils";
import { useGetOrdersQuery } from "../../../services/orderService";
import { RepeatOrderContext } from "../../../providers/RepeatOrderProvider";

import BookConsultantCard from "../../../components/consultationCard/BookConsultantCard";
import TaskCard, { TASK_STATUS } from "../../../components/taskCard/TaskCard";
import ConsultantCard from "../../../components/consultationCard/ConsultantCard";
import LoadingSpinner from "../../../components/loadingSpinner/LoadingSpinner";
import OrderCard from "../../../components/orderCard/OrderCard";

import {
    ORDER_STATUS,
    PATIENT_ANSWER_WAITING,
    PATIENT_APPROVAL_WAITING,
} from "../../../constants/orderStatus";

function DashboardPage() {
    const navigate = useNavigate();
    const auth = useAuth();
    const {
        isRepeatOrder,
        parentOrderId,
        availableToReorder,
        canOrder,
        hasSixMonthsPassed,
        isLoading: isRepeatContextLoading,
    } = useContext(RepeatOrderContext);

    const [orderItemsThreshold, setOrderItemsThreshold] = useState(3);

    const { data: profileData } = useGetMyProfileQuery(
        {},
        {
            skip: !auth.isLogged,
            refetchOnMountOrArgChange: true,
        }
    );
    const { data: ordersData, isLoading: ordersLoading } = useGetOrdersQuery(
        {},
        {
            skip: !auth.isLogged,
            refetchOnMountOrArgChange: true,
        }
    );

    const handleViewAllOrders = () => {
        if (ordersData.content) {
            if (orderItemsThreshold === consultations.length) {
                setOrderItemsThreshold(3);
            } else {
                setOrderItemsThreshold(consultations.length);
            }
        }
    };

    const handleOrderAction = (id, customRoute = "", isReorder = false) => {
        if (isReorder) {
            navigate(`/reorder/confirm/?type=REPEAT`);
        } else {
            navigate(`/user/order/${id}${customRoute}`);
        }
    };

    const onBookConsultant = () => {
        if (isRepeatOrder) {
            navigate(`/reorder/confirm`);
        } else {
            navigate("/appointment");
        }
    };

    const getGreeting = () => {
        var currentHour = new Date().getHours();

        if (currentHour < 12) {
            return "morning";
        } else if (currentHour < 18) {
            return "afternoon";
        } else {
            return "evening";
        }
    };

    const getAvailability = (dateTime) => {
        return dayjs(dateTime).diff(new Date()) > -3600000;
    };

    const pendingApprovals = useMemo(() => {
        if (ordersData?.content.length > 0) {
            let tempOrders = [...ordersData?.content];

            const eligibleOrderToReorder =
                availableToReorder && !hasSixMonthsPassed && canOrder
                    ? [tempOrders?.find((order) => order.id === parentOrderId)]
                    : [];

            const approvalPendingOrders =
                ordersData?.content.filter((order) =>
                    PATIENT_APPROVAL_WAITING.includes(order.status)
                ) || [];

            return [...approvalPendingOrders, ...eligibleOrderToReorder];
        }

        return [];
    }, [
        availableToReorder,
        canOrder,
        hasSixMonthsPassed,
        ordersData,
        parentOrderId,
    ]);

    const pendingMessages =
        ordersData?.content
            .find((order) => PATIENT_ANSWER_WAITING.includes(order.status))
            ?.orderNotes.filter(
                (note) =>
                    note.type === "SPECIALIST_PATIENT_MESSAGE" &&
                    note.patient === null
            )
            ?.sort((a, b) => a.id - b.id) || [];

    const consultations =
        ordersData?.content
            .filter(
                (order) =>
                    order.status === ORDER_STATUS.BOOKED &&
                    getAvailability(order.appointment.datetime)
            )
            .sort((a, b) => b.id - a.id) || [];

    return (
        <div className="pt-6 pt-lg-0 pb-5 px-xl-6 px-xxl-12">
            <h2 className="fw-bold text-primary">{`Good ${getGreeting()} ${
                profileData?.firstName || ""
            }!`}</h2>

            <h3 className="fw-bold mt-7">Notices</h3>
            {ordersLoading ? (
                <div className="mt-5 text-center">
                    <LoadingSpinner />
                </div>
            ) : (
                <div className="mt-4">
                    {(pendingMessages.length > 0 ||
                        pendingApprovals.length > 0) &&
                    !isRepeatContextLoading ? (
                        pendingApprovals.map((content) => {
                            return (
                                <div className="mt-4" key={content?.id}>
                                    <OrderCard
                                        onClickAction={handleOrderAction}
                                        orderData={content}
                                        isReorder={content?.eligibleToReorder}
                                    />
                                </div>
                            );
                        })
                    ) : (
                        <TaskCard
                            status={TASK_STATUS.COMPLETE}
                            title="Nothing pending, you're all set!"
                            subTitle="No tasks at the moment"
                        />
                    )}
                    {pendingMessages.length > 0 && (
                        <div className="mt-4">
                            <TaskCard
                                status={TASK_STATUS.MESSAGE}
                                // title={`${pendingMessages.length} message${
                                //     pendingMessages.length > 1 ? "s" : ""
                                // } from your specialist ${
                                //     pendingMessages[0].doctor?.displayName
                                // }`} // Comment due to nurse introduction
                                title={`You have a new message`}
                                subTitle={`Received on ${dayjs(
                                    pendingMessages[0].creationDate
                                ).format("MMMM Do, YYYY")} at ${dayjs(
                                    pendingMessages[0].creationDate
                                ).format("hh:mm a")}`}
                                actionBtnText="REPLY"
                                onClickActionBtn={() =>
                                    handleOrderAction(
                                        pendingMessages[0].orderItem?.id,
                                        `?message=${pendingMessages[0].id}`
                                    )
                                }
                            />
                        </div>
                    )}
                </div>
            )}

            {consultations.length > 0 && (
                <>
                    <div className="d-flex justify-content-between flex-wrap mt-7 align-items-center">
                        <h3 className="fw-bold">Consultations</h3>
                        {consultations.length > 3 &&
                            consultations.length >= orderItemsThreshold && (
                                <h5
                                    className="fw-bold text-decoration-underline fs-6 text-brown-black cursor-pointer"
                                    onClick={handleViewAllOrders}
                                >
                                    {orderItemsThreshold ===
                                    consultations.length
                                        ? "See less"
                                        : "View all Consultations"}
                                </h5>
                            )}
                    </div>

                    {ordersLoading ? (
                        <div className="mt-5 text-center">
                            <LoadingSpinner />
                        </div>
                    ) : (
                        consultations.map((data, idx) => {
                            if (idx < orderItemsThreshold) {
                                return (
                                    <div className="mt-4" key={data.id}>
                                        <ConsultantCard consultData={data} />
                                    </div>
                                );
                            }

                            return null;
                        })
                    )}
                </>
            )}

            {profileData?.regStatus === "COMPLETE" &&
                profileData?.externallyIdentified &&
                canOrder && (
                    <>
                        <h3 className="fw-bold mt-7">
                            Book a {isRepeatOrder ? "Repeat " : ""}Consultation
                        </h3>
                        <div className="my-4">
                            <BookConsultantCard
                                onBookConsultant={onBookConsultant}
                            />
                        </div>
                    </>
                )}
        </div>
    );
}

export default DashboardPage;
