import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

import Button from "../../../components/button/Button";
import GoBack from "../../../components/GoBack";

import { SUPPORT_WHATSAPP } from "../../../constants/appProperties";

export default function EligibilitySuccessPage() {
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate("/onboarding/auth/register");
    };

    useEffect(() => {
        window.history.scrollRestoration = 'manual';
        window.scrollTo(0, 0);

        return () => {
            // Reset scroll restoration behavior when the component unmounts
            window.history.scrollRestoration = 'auto';
        };
        
    }, []);

    return (
        <div className="eligibility-success-page px-3 m-auto">
            <div className="row mb-5 back-parent-level1">
                <div className="col mx-25">
                    <Link to={-1}>
                        <GoBack text="Back"/>
                    </Link>
                </div>
                <div className="col">
                    <img
                        src="/images/vectors/success-primary.svg"
                        alt=""
                        className="eligibility-success-icon"
                    />
                </div>
            </div>
            <div className="d-flex flex-column">
                <h2 className="text-primary fw-bold mb-5 text-center">
                    Congratulations, you are eligible for our services.
                </h2>
                <p className="fs-5 subtitle text-primary-200 text-center mb-0 mx-2 mx-lg-10 px-lg-10">
                    The opportunity to improve your wellbeing is here.
                </p>
                <p className="fs-5 subtitle text-primary-200 text-center mb-8 mx-2 mx-lg-10 px-lg-10">
                    Schedule a consultation with our specialist doctor - the
                    first step towards renewed vitality.
                </p>
                <div className="info-card d-flex flex-column p-6 mx-auto mb-8">
                    <h4 className="text-primary fw-bold mb-4">
                        This Consultation provides:
                    </h4>
                    <div className="text-start fs-5">
                        <ul
                            className="ms-2 mb-0"
                            style={{ listStyle: "square" }}
                        >
                            <li>
                                A comprehensive assessment tailored to your
                                unique needs
                            </li>
                            <li>
                                A prescription plan designed within your
                                budgetary requirements
                            </li>
                        </ul>
                    </div>
                </div>
                <p className="fs-5 subtext text-primary text-center mb-8 mx-auto px-2 px-lg-6">
                    Click below to book your appointment with our specialist
                    doctor today. We eagerly await assisting you on your journey
                    to an enhanced quality of life.
                </p>

                <div className="button-container mx-auto">
                    <Button
                        variant="primary text-start mb-4 fw-light py-5 fs-5"
                        onClick={handleNavigate}
                    >
                        <div className="d-flex">
                            <img
                                className="me-2 my-auto"
                                src="/images/vectors/arrow-right.svg"
                                alt=""
                            />
                            Yes, I'd like to proceed to book an appointment
                        </div>
                    </Button>
                    <a
                        href={SUPPORT_WHATSAPP}
                        className="btn btn-white bg-white text-primary text-start fw-light py-5 fs-5 w-100"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <div className="d-flex">
                            <img
                                className="me-2 my-auto"
                                src="/images/vectors/arrow-right-primary.svg"
                                alt=""
                            />
                            I'd like to speak to someone to learn more
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}
