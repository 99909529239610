import React, { useEffect, useState } from "react";

import { SearchableSelect } from "../forms";
import ProgressBar from "../progressBar/ProgressBar";
import { useFormikContext } from "formik";

export default function Document({
    fileName,
    fieldName,
    fileSize,
    options,
    idx,
    onRemoveDocument,
}) {
    const { values } = useFormikContext();
    const [percentage, setPercentage] = useState(0);
    const [uploading, setUploading] = useState(true);
    const [categoryState, setcategoryState] = useState(true);

    useEffect(() => {

        const url = new URL(window.location.href);
        const lastSegment = url.pathname.split('/').pop();
        
        if(lastSegment == "documents" || lastSegment == "update-profile-picture") {
            setcategoryState(false);
        } else {
            setcategoryState(true);
        }

        let intervalTime = Math.round(fileSize / 120000);

        const interval = setInterval(() => {
            setPercentage((prevSate) => prevSate + 1);
        }, intervalTime);

        if (percentage >= 150) {
            clearInterval(interval);
            setUploading(false);
        }

        return () => clearInterval(interval);
    }, [fileSize, percentage]);

    return (
        <div className="bg-white bg-opacity-25 rounded-1 d-flex flex-column justify-content-between px-5 py-4">
            <div className="d-flex justify-content-between">
                <div className="my-auto text-primary fs-6 fw-semibold file-name text-truncate">
                    {fileName}
                </div>
                {uploading && (
                    <div className="progress-container my-auto d-none d-sm-flex">
                        <ProgressBar progressPercentage={percentage} />
                        <span className="ms-2">Uploading...</span>
                    </div>
                )}
                { categoryState && <div className="my-auto d-none d-sm-block">
                    <SearchableSelect
                        options={options}
                        defaultSelectPlaceholder="Select type"
                        name={`${fieldName}[${idx}].type`}
                        multiSelect={false}
                        searchable={false}
                        dropDownOnly
                    >
                        <button
                            className="bg-transparent d-flex p-0 border-0 w-max"
                            type="button"
                        >
                            <p className="text-primary me-2 mb-0 fw-bold fs-6">
                                {values[fieldName][idx].type || "Select type"}
                            </p>
                            <img
                                src="/images/vectors/down-arrow-primary-filled.svg"
                                alt=""
                            />
                        </button>
                    </SearchableSelect>
                </div> }
                <button
                    className="bg-transparent p-0 border-0"
                    onClick={() => onRemoveDocument(idx)}
                    type="button"
                >
                    <img src="/images/vectors/close-primary.svg" alt="" />
                </button>
            </div>
            <div className="d-flex d-sm-none justify-content-evenly">
                {uploading && (
                    <div className="progress-container my-auto">
                        <ProgressBar progressPercentage={percentage} />
                    </div>
                )}
                <div className="my-auto">
                    <SearchableSelect
                        options={options}
                        defaultSelectPlaceholder="Select type"
                        name={`${fieldName}[${idx}].type`}
                        multiSelect={false}
                        searchable={false}
                        dropDownOnly
                        containerClass="ms-auto"
                    >
                        <button
                            className="bg-transparent d-flex p-0 border-0 w-max"
                            type="button"
                        >
                            <p className="text-primary me-2 mb-0 fw-bold fs-6">
                                {values[fieldName][idx].type || "Select type"}
                            </p>
                            <img
                                src="/images/vectors/down-arrow-primary-filled.svg"
                                alt=""
                            />
                        </button>
                    </SearchableSelect>
                </div>
            </div>
        </div>
    );
}
