import {Form} from "../../../components/forms";
import UploadDocumentForm from "../../../components/uploadDocumentForm/UploadDocumentForm";
import React, {useState} from "react";
import {useGetMyProfileQuery} from "../../../services/userService";
import {uploadFile} from "../../../services/fileService";
import {toast} from "react-toastify";
import parseError from "../../../utils/errorUtils";
import Button from "../../../components/button/Button";
import {useNavigate} from "react-router-dom";
import {UploadProfileForm} from "./UploadProfileForm";
import {UploadKYCForm} from "./UploadKYCForm";

export default function UpdateKYCDocsOnBoarding() {
    const [initialValues] = useState({ id: [] });
    const navigate = useNavigate();
    const { data: profileData, refetch, isLoading } = useGetMyProfileQuery();
    const [errorText, setErrorText] = useState('');

    const handleSubmit = async (values, { resetForm }) => {
        try {
            let res;
            var formData = new FormData();
            formData.append("action", "PATIENT_KYC_DOCUMENTS");
            let undefinedTypeCount = values.id.filter(file => file.type == undefined).length;

            if (undefinedTypeCount == 0) {
                setErrorText('');
                for (const fileData of values.id) {
                    formData.append(
                        "files",
                        fileData.file,
                        `${fileData.type}.${fileData.file.name.split(".")[1]}`
                    );
                    formData.append("category", fileData.type);
    
                    res = await uploadFile(formData);
                    formData.delete("files");
                    formData.delete("category");
                }
    
                if (res.error) {
                    // handle error
                    toast.error(parseError(res.error, "Couldn't upload file"));
                } else {
                    // request succeeded
                    navigate(`/onboarding/appointment/referred-by`);
                    window.scrollTo(0, 0);
                }
            } else {
                setErrorText('Please select the type for each image');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSkip = () => {
        navigate(`/onboarding/appointment/referred-by`);
        window.scrollTo(0, 0);
    };

    const files =
        profileData?.files?.filter(
            ({ action }) => action === "PATIENT_KYC_DOCUMENTS"
        ) || [];

    return (
        <div className="additional-notes-page px-3 m-auto pb-12 w-100">
            <div className="d-flex flex-column">
                <h2 className="text-primary fw-bold mb-6 text-center">
                    Upload Your KYC Identification Document
                </h2>
                <div className="fs-5 subtitle text-primary-200 text-start mb-8 mx-2 mx-lg-8">
                    To comply with regulatory requirements and ensure a secure and trustworthy environment for all our
                    users, we kindly request that you upload the following KYC (Know Your Customer) document to verify
                    your identity.
                    <ul>
                        <li>An Accepted form of Identification (Appropriate ID includes a valid passport, photo driving
                            license, or proof of age card, such as a PASS card from the national Proof of Age Standards
                            Scheme.
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <Form
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    <UploadKYCForm name="id" errorText={errorText} onSkip={handleSkip}/>
                </Form>

            </div>
        </div>
    )
}