import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./baseQueries";

export const userAPI = createApi({
    reducerPath: "userAPI",
    baseQuery: baseQueryWithReauth,
    tagTypes: ["profile"],
    endpoints: (build) => ({
        getMyProfile: build.query({
            query: () => ({
                url: `/api/patient/me/`,
            }),
            providesTags: ["profile"],
        }),
        updateProfile: build.mutation({
            query: ({ values }) => ({
                url: `/api/patient/me/`,
                method: "PUT",
                body: { ...values },
            }),
            invalidatesTags: ["profile"],
        }),
        checkEligibility: build.mutation({
            query: ({ values }) => ({
                url: `/api/patient/me/eligibility-check`,
                method: "POST",
                body: [...values],
            }),
            invalidatesTags: ["profile"],
        }),
        changePassword: build.mutation({
            query: ({ values }) => ({
                url: `/api/patient/password`,
                method: "PUT",
                body: { ...values },
            }),
        }),
        getCannabisCardToken: build.query({
            query: () => ({
                url: `/api/patient/cannabis/cannabis-token`,
                method: "GET",
                responseHandler: "text"
            })
        }),
        getConsultationDetails: build.query({
            query: () => ({
                url: `/api/patient/booking-availability`,
                method: "GET",
            }),
        }),
    }),
});

export const {
    useGetMyProfileQuery,
    useUpdateProfileMutation,
    useCheckEligibilityMutation,
    useChangePasswordMutation,
    useGetCannabisCardTokenQuery,
    useGetConsultationDetailsQuery
} = userAPI;
