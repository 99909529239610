import { useContext, useMemo, useState } from "react";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";

import {
    useGetOrderPrescriptionQuery,
    useGetOrderQuery,
} from "../../../services/orderService";
import useQuery from "../../../hooks/useQuery";
import { RepeatOrderContext } from "../../../providers/RepeatOrderProvider";
import { getStatus } from "../../../utils/orderStatusUtils";

import OrderDescription from "./orderDetails/OrderDescription";
import OrderActionCard from "./orderDetails/OrderActionCard";
import OrderErrorCard from "./orderDetails/OrderErrorCard";
import GoBack from "../../../components/GoBack";
import VerticalProgressBar from "../../../components/progressBar/VerticalProgressBar";
import TreatmentMedications from "./orderDetails/TreatmentMedications";
import ShippingCard from "./orderDetails/ShippingCard";
import ConsultationCard from "./orderDetails/ConsultationCard";
import MessageThread from "./orderReview/MessageThread";
import PastQuestions from "./orderReview/PastQuestions";
import PatientNotes from "../../../components/patientNotes/PatientNotes";
import SupportingDocuments from "../../../components/supportingDocuments/SupportingDocuments";

import {
    PATIENT_ANSWER_WAITING,
    PATIENT_APPROVAL_WAITING,
    PAYED_FOR_PRESCRIPTION_STATUS,
} from "../../../constants/orderStatus";
import { TIMER_TIMEOUT } from "../../../constants";

export default function OrderDetailsPage() {
    const query = useQuery();
    const message = query.get("message");
    const { id } = useParams();
    const { canOrder } = useContext(RepeatOrderContext);

    const [submittedMessageId, setSubmittedMessageId] = useState(null);

    const { data: orderData } = useGetOrderQuery(
        { appointmentId: id },
        { refetchOnMountOrArgChange: true }
    );
    const { data: prescriptionData, isError: prescriptionDataError } =
        useGetOrderPrescriptionQuery(
            { orderId: id },
            { skip: !id, refetchOnMountOrArgChange: true }
        );

    const handleMessageSuccess = (id) => {
        setSubmittedMessageId(id);
        setTimeout(() => {
            setSubmittedMessageId(null);
        }, TIMER_TIMEOUT);
    };

    const rejectedReason = useMemo(() => {
        if (getStatus(orderData?.status).status === "REJECTED") {
            if (Array.isArray(getStatus(orderData?.status).rejectedReason)) {
                let reason = orderData?.orderNotes
                    .filter((note) =>
                        getStatus(
                            orderData?.status
                        ).rejectedReason.includes(note.type)
                    )
                
                if(reason[0]?.description?.indexOf('-') > -1){
                    let splitReason = reason[0].description.split(" - ");
                    return splitReason[0] + " - ( " + splitReason[1] + " )";
                }
                else {
                    return reason[0].description ? reason[0].description : "Not provided";
                }
            } else {
                return (
                    getStatus(orderData?.status).rejectedReason ||
                    "Not provided"
                );
            }
        }
    }, [orderData]);

    const isApprovalWaiting = PATIENT_APPROVAL_WAITING.includes(
        orderData?.status
    );
    const isQuestionAsked = PATIENT_ANSWER_WAITING.includes(orderData?.status);
    const newQuestionData = orderData?.orderNotes
        ?.filter(
            (note) =>
                note.type === "SPECIALIST_PATIENT_MESSAGE" &&
                note.patient === null
        )
        ?.sort((a, b) => a.id - b.id);
    const pastQuestionData = orderData?.orderNotes
        ?.filter((note) => note.type === "SPECIALIST_PATIENT_MESSAGE")
        ?.sort((a, b) => a.id - b.id);
    const deliveryType = PAYED_FOR_PRESCRIPTION_STATUS.includes(
        orderData?.status
    )
        ? orderData?.deliveryType
        : null;
    const eligibleToReorder = orderData?.eligibleToReorder;

    const formatDateTime = (dateTimeString) => {
        let [date, time] = dateTimeString.split(', ');
        let [month, day, year] = date.split('/');
        let [hours, minutes] = time.split(':');

        if (hours == '24') {
            hours = '00';
        }

        let dateObject = new Date(`${year}-${month}-${day}T${hours}:${minutes}`);
        let options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };

        let formattedDateTime = dateObject.toLocaleString('en-GB', options).replace(',', '');
        return formattedDateTime;
    };

    const extractDateTime = (fullString) => {
        const dateTimePart = fullString.split(' - ')[1];
        return dateTimePart.trim();
    };

    return (
        <div className="order-details-section pt-6 pt-lg-0 pb-7 px-xl-6 px-xxl-12">
            <GoBack />
            <div className="bg-white rounded mt-5 py-8">
                {/* All the sets of order description components are presented here */}
                {orderData && (
                    <OrderDescription
                        orderName={
                            orderData?.consultationDuration === 'MINUTES_00'
                                ? `Repeat prescription - Order ${orderData?.id}`
                                : orderData?.consultationDuration === 'MINUTES_30'
                                    ? `Consultation (30 minutes) - Order ${orderData.id} ${orderData?.name.slice(orderData?.name.indexOf('('))}`
                                    : orderData?.consultationDuration === 'MINUTES_15'
                                        ? `Repeat consultation (15 minutes) - Order ${orderData.id} ${orderData?.name.slice(orderData?.name.indexOf('('))}`
                                        : orderData?.consultationDuration === 'IN_PERSON_MINUTE_00'
                                            ? `Pharmacy Consultation - Order ${orderData.id} (${formatDateTime(extractDateTime(orderData?.name))})`
                                            : orderData.name
                        }
                        orderNumber={id}
                        orderDate={
                            orderData?.isRepeatOrder
                                ? null
                                : dayjs(orderData.appointment.datetime).format(
                                      "MMMM Do, YYYY @ hh:mm a"
                                  )
                        }
                        prescriptionNumber={prescriptionData?.id}
                        status={orderData.status}
                    />
                )}

                <div className="px-5 px-md-8 px-xxl-10">
                    {/* All the sets of error cards are presented here */}
                    {getStatus(orderData?.status).status === "REJECTED" && (
                        <div className="mb-6">
                            <OrderErrorCard
                                rejected={true}
                                reasonForError={{
                                    header: rejectedReason,
                                }}
                            />
                        </div>
                    )}

                    {((eligibleToReorder && canOrder) || isApprovalWaiting) && (
                        <div className="mb-6">
                            <OrderActionCard order={orderData} />
                        </div>
                    )}

                    {prescriptionData && (
                        <div className="mb-6">
                            <TreatmentMedications
                                prescription={prescriptionData}
                                deliveryType={deliveryType}
                                orderId={id}
                            />
                        </div>
                    )}

                    {submittedMessageId !== null && (
                        <div className="mb-6">
                            <div className="border border-primary d-flex flex-column bg-primary-light rounded px-4 py-5">
                                <img
                                    src="/images/vectors/tick-primary-rounded.svg"
                                    className="mx-auto mb-4"
                                    width={25}
                                    alt=""
                                />

                                <p className="body fw-semibold text-center mb-0">
                                    Your response was successfully submitted to
                                    our specialist.
                                </p>
                            </div>
                        </div>
                    )}

                    {isQuestionAsked && newQuestionData.length > 0 && (
                        <div className="message-thread-container overflow-auto mb-6">
                            {newQuestionData.map((questionData, idx) => {
                                if (submittedMessageId !== questionData.id) {
                                    return (
                                        <div
                                            className={
                                                idx + 1 <
                                                    newQuestionData.length &&
                                                "mb-3"
                                            }
                                        >
                                            <MessageThread
                                                orderNoteId={questionData.id}
                                                question={
                                                    questionData.questionAnswer
                                                }
                                                onMessageSuccess={
                                                    handleMessageSuccess
                                                }
                                                show={
                                                    questionData.id ===
                                                    Number(message)
                                                }
                                            />
                                        </div>
                                    );
                                }

                                return null;
                            })}
                        </div>
                    )}

                    {((isQuestionAsked && pastQuestionData?.length > 1) ||
                        (!isQuestionAsked && pastQuestionData?.length > 0)) && (
                        <div className="mb-6">
                            <PastQuestions questionData={pastQuestionData} />
                        </div>
                    )}

                    {prescriptionDataError ? (
                        (orderData?.doctor ||
                            orderData?.prescribingPharmacist) && (
                            <div className="mb-6">
                                <ConsultationCard
                                    orderId={id}
                                    doctorData={orderData?.doctor}
                                    appointmentDateTime={
                                        orderData?.appointment?.datetime
                                    }
                                    status={orderData?.status}
                                    pharmacyConsultation={
                                        orderData?.consultationDuration ===
                                        "IN_PERSON_MINUTE_00"
                                    }
                                    pharmacyData={
                                        orderData?.prescribingPharmacist
                                    }
                                />
                            </div>
                        )
                    ) : (
                        <div className="row mb-6">
                            {orderData?.patient && (
                                <div
                                    className={`col-12 ${
                                        (orderData?.doctor ||
                                            orderData?.prescribingPharmacist) &&
                                        "col-sm-6 col-md-12 col-lg-6"
                                    }`}
                                >
                                    <div className="text-primary p1 fw-bold mb-3">
                                        Shipping Address
                                    </div>
                                    <ShippingCard
                                        patientData={orderData?.patient}
                                    />
                                </div>
                            )}
                            {(orderData?.doctor ||
                                orderData?.prescribingPharmacist) && (
                                <div
                                    className={`col-12 ${
                                        orderData?.patient &&
                                        "col-sm-6 col-md-12 col-lg-6"
                                    }`}
                                >
                                    <div className="text-primary p1 fw-bold mb-3 mt-3 mt-sm-0 mt-md-3 mt-lg-0">
                                        Consultation
                                    </div>
                                    <ConsultationCard
                                        orderId={id}
                                        small
                                        doctorData={orderData?.doctor}
                                        appointmentDateTime={
                                            orderData?.appointment?.datetime
                                        }
                                        status={orderData?.status}
                                        pharmacyConsultation={
                                            orderData?.consultationDuration ===
                                            "IN_PERSON_MINUTE_00"
                                        }
                                        pharmacyData={
                                            orderData?.prescribingPharmacist
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    )}

                    {orderData?.workflow?.PRESENTING_COMPLAINT ||
                    orderData?.pictures?.length ? (
                        <>
                            <p className="text-primary p1 fw-bold mb-3">
                                Supporting Information
                            </p>
                            <div className="d-flex flex-column align-items-start bg-light-gray border border-gray rounded p-4 mb-6">
                                {orderData?.orderNotes?.find(
                                    (note) =>
                                        note.type === "PRESENTING_COMPLAINT"
                                ) && (
                                    <PatientNotes
                                        note={
                                            orderData?.orderNotes.find(
                                                (note) =>
                                                    note.type ===
                                                    "PRESENTING_COMPLAINT"
                                            )?.description
                                        }
                                    />
                                )}

                                {orderData?.pictures?.length > 0 && (
                                    <SupportingDocuments
                                        files={orderData?.pictures}
                                    />
                                )}
                            </div>
                        </>
                    ) : null}

                    <VerticalProgressBar orderData={orderData} />
                </div>
            </div>
        </div>
    );
}
