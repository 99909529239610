import { useContext } from "react";
import {
    CardNumberElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormikContext } from "formik";
import * as yup from "yup";

import { AppointmentContext } from "../../../providers/AppointmentProvider";
import { useLazyGetPaymentIntentQuery } from "../../../services/paymentService";
import { useAddOrderMutation } from "../../../services/orderService";
import parseError from "../../../utils/errorUtils";

import { Checkbox, Form, SubmitButton } from "../../../components/forms";
import CardInput from "../../../components/cardInput/CardInput";
import {useModal} from "../../../hooks/modalsHooks";
import { useLoggerUtils } from "../../../utils/loggerUtils";

const PaymentFormSchema = yup.object({
    name: yup.string().required("Your name on the card is incomplete."),
});

export function CheckoutForm({ initialValues, couponId, fullVoucher }) {
    const { appointmentData } = useContext(AppointmentContext);

    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const { showModal, hideModal } = useModal();
    const { info } = useLoggerUtils();

    const [getPaymentIntent] = useLazyGetPaymentIntentQuery();
    const [addOrder] = useAddOrderMutation();

    const handleAddOrder = async () => {
        const paymentIntent = await getPaymentIntent();

        const stripeResponse = await stripe.confirmCardSetup(
            paymentIntent?.data?.client_secret,
            {
                payment_method: {
                    card: elements.getElement(CardNumberElement),
                },
            }
        );

        if (stripeResponse?.error) {
            let stripeResponse_Obj = {
                code: stripeResponse?.error ? stripeResponse.error.code : "",
                decline_code: stripeResponse?.error ? stripeResponse.error.decline_code : "",
                doc_url: stripeResponse?.error ? stripeResponse.error.doc_url : "",
                message: stripeResponse?.error ? stripeResponse.error.message : "",
            }
            info("stripeResponse: " + JSON.stringify(stripeResponse_Obj));
            toast.error(stripeResponse.error.message || "Couldn't place order");
        }

        const orderData = {
            paymentMethodID: stripeResponse.setupIntent.payment_method,
            deliveryType: "STANDARD",
            paymentType: "ONLINE",
            deviceType: "OTHER",
            orderNotes: [],
            info: {
                ...(couponId
                    ? {
                          COUPON: couponId,
                      }
                    : {}),
            },
            name: appointmentData.name,
            type: appointmentData.type,
            appointment: {
                id: Number(appointmentData?.id),
            },
            patient: appointmentData.patient,
            isRepeatOrder: false,
        };

        try {
            let payload = orderData;
            if (orderData?.patient?.preferences?.TEMPORARY_APPOINTMENT) {
                payload = { ...orderData, source: 'CALL_BOOKING' };
            }
            const res = await addOrder({ type: "BOOKING", values: payload });

            if (res.error) {
                // handle error
                info("addOrder: " + JSON.stringify(res));
                toast.error(parseError(res.error, "Couldn't place order"));
            } else {
                //handle success
                if (appointmentData.patient.preferences?.TEMPORARY_APPOINTMENT) {
                    showModal({
                        modalType: "PaymentSuccessInfoModal",
                        modalProps: {
                            title: "Payment Successful",
                            message: res.data.message,
                            isPaymentSuccess: true
                        },
                    });
                } else {
                    navigate(
                        `/onboarding/appointment/${res.data.message}/documents`,
                        {
                            replace: true,
                        }
                    );
                }
            }
        } catch (e) {
            info("addOrder - catch: " + JSON.stringify(e));
            console.error(e);
        }
    };

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleAddOrder}
            validationSchema={PaymentFormSchema}
            enableReinitialize
        >
            <CardInput disabled={fullVoucher} />
            {!fullVoucher && <CheckoutFormFooter />}
        </Form>
    );
}

export function CheckoutFormFooter() {
    const { values } = useFormikContext();

    return (
        <>
            <div className="mt-4">
                <Checkbox
                    label="I am making this appointment for myself and not on behalf of another person, including children under the age of 18."
                    name="identity_consent"
                    className="check-lg"
                    labelClass="ms-3 text-primary"
                />
            </div>
            <div className="mt-4">
                <Checkbox
                    label={
                        <p className="mb-0 fw text-primary">
                            I have read and understood the&nbsp;
                            <NavLink
                                className="fw-bold text-primary text-decoration-underline"
                                to="/terms-condition"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Terms and Conditions
                            </NavLink>
                            &nbsp;prior to booking a consultation
                        </p>
                    }
                    tooltip="More Details"
                    name="terms_and_conditions"
                    className="check-lg"
                    labelClass="ms-3 text-primary"
                />
            </div>
            <div className="row mx-0 mt-6 mb-4">
                <SubmitButton
                    disabled={
                        !values.terms_and_conditions || !values.identity_consent
                    }
                >
                    Confirm Payment
                </SubmitButton>
            </div>
        </>
    );
}

export default CheckoutForm;
