import React, { useState, useEffect } from "react";
import QRCode from 'react-qr-code';

import { useGetCannabisCardTokenQuery } from "../../../services/userService";
import { useGetMyProfileQuery } from "../../../services/userService";
import { useGetOrdersQuery } from "../../../services/orderService";

import LoadingSpinner from "../../../components/loadingSpinner/LoadingSpinner";
import CannabisCardPage from "../../public/CannabisCardPage";

import "./my-cannabis-card-page.scss";
import * as orderStatus from "./orderStatuses";
import * as orderStatusMessage from "./orderStatusMessages";

function MyCannabisCardPage() {

    var baseURL = window.location.href.split('user/')[0];
    baseURL = baseURL === 'http://localhost:3006/' ? 'https://app.treat-it.clinic/' : baseURL;
    const cannabisCardURL = 'cannabis-card/';

    const { data: profileData, isFetching: profileFetching } = useGetMyProfileQuery();
    const { data: cannabisToken, isFetching: cannabisTokenFetching } = useGetCannabisCardTokenQuery();
    const { data: ordersData, isLoading: ordersLoading } = useGetOrdersQuery();

    const [qrUrl, setQrUrl] = useState();
    const [lastOrderStatus, setLastOrderStatus] = useState();
    const [noticeText, setNoticeText] = useState("");
    const [showGetStartedBtn, setShowGetStartedBtn] = useState(false);
 
    useEffect(() => {
        setQrUrl(baseURL + cannabisCardURL + cannabisToken);
    }, [cannabisToken]);

    useEffect(() => {
        if (profileData?.settings?.INITIAL_CONSULTATION_DONE == 'true') {
            setLastOrderStatus('DELIVERED');
        } else {
            if (ordersData && ordersData.content && ordersData.content.length > 0) {
                const max = ordersData.content.reduce(function(prev, current) {
                    return (prev && prev.id > current.id) ? prev : current
                });
                setLastOrderStatus(max.status);
            } else if (ordersData && ordersData.content && ordersData.content.length == 0) {
                setLastOrderStatus('NO_ORDERS');
            }
        }
    }, [profileData, ordersData]);

    useEffect(() => {
        setShowGetStartedBtn(false);
        if (orderStatus.deliveredStatus.includes(lastOrderStatus)) {
            setNoticeText('');
        } else if (orderStatus.bookedStatus.includes(lastOrderStatus)) {
            setNoticeText(orderStatusMessage.bookedStatus);
        } else if (orderStatus.noOrdersStatus.includes(lastOrderStatus)) {
            setNoticeText(orderStatusMessage.noOrdersStatus);
            setShowGetStartedBtn(true);
        } else if (orderStatus.rejectedStatus.includes(lastOrderStatus)) {
            setNoticeText(orderStatusMessage.rejectedStatus);
            setShowGetStartedBtn(true);
        } else {
            setNoticeText(orderStatusMessage.reviewStatus);
        }
    }, [lastOrderStatus]);

    const navigateToFormLink = () => {
        window.open('https://share-eu1.hsforms.com/1V-9FiH-8TCuqsSHsFjfxLQ2b23l4', '_blank');
    };

    return (
        <div className="account-layout pt-6 pt-lg-0 pb-5 px-xl-6 px-xxl-12">
            <h6 className="fw-bold">Do you want a printed patient ID card?</h6>

            <button className="btn btn-primary btn-sm mb-3"
                    onClick={navigateToFormLink}
                    type="button">Request Physical Card
            </button>
            <h2 className="text-primary fw-bold">My Cannabis Card</h2>

            {profileFetching || ordersLoading ? (
                <div className="mt-5 text-center">
                    <LoadingSpinner/>
                </div>
            ) : (
                <>
                    {noticeText.length == 0 ? (
                        <div>
                            {profileData && profileData.cannaCardPin ?
                                <>
                                    <h3 className="fw-bold mt-7">Prescription Verification
                                        PIN: {profileData.cannaCardPin}</h3>
                                    <h3 className="fw-bold mt-7">Below is a preview of your Cannabis Card</h3>

                                    <hr/>
                                    {profileData && cannabisToken ?
                                        <CannabisCardPage pin={profileData.cannaCardPin} token={cannabisToken}/> : (
                                            <div className="mt-5 text-center">
                                                <LoadingSpinner/>
                                            </div>
                                        )}
                                    <hr/>
                                    <div className="text-center">
                                        <h2 className="mt-5 mb-5 fw-bold">
                                            Need to verify this to a law enforcement officer?<br/>
                                            Ask them to scan this QR.
                                        </h2>
                                        <div>
                                            {cannabisTokenFetching ? (
                                                <div className="mt-5">
                                                    <LoadingSpinner/>
                                                </div>
                                            ) : (
                                                <QRCode
                                                    title="My Cannabis Card"
                                                    value={qrUrl ? qrUrl : 'https://treat-it.clinic/'}
                                                    size={250}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </> : <>
                                    <h3 className="fw-bold mt-7">Cannabis Card is unavailable at the moment</h3>
                                </>
                            }
                        </div>
                    ) : (
                        <div>
                            <h3 className="fw-bold mt-7 text-center">
                                {noticeText}
                            </h3>
                            {showGetStartedBtn ? (
                                <div className="row">
                                    <div className="col-12 mt-7 text-center">
                                        <a className="btn btn-primary btn-sm book-appointment-btn fw-bold px-2 "
                                           href="https://treat-it.clinic/">
                                            <h5 className="px-3">Get Started</h5>
                                        </a>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    )
                    }
                </>
            )}
        </div>
    );
}

export default MyCannabisCardPage;
