import React from "react";

import Button from "../button/Button";

import {
    SUPPORT_EMAIL,
    SUPPORT_TELEPHONE,
} from "../../constants/appProperties";

import "./contact-support-form.scss";

export default function ContactSupportForm({
    title,
    description,
    onClickLater,
    onClickAction,
    actionButtonText,
}) {
    return (
        <div className="text-center">
            <h2 className="fw-semibold mb-0">{title}</h2>
            {description && (
                <label className="body body text-dark-5 mt-5">
                    {description}
                </label>
            )}

            <div className="mt-8 px-0 px-sm-8 px-md-10 d-flex flex-column mx-auto contact-item-container">
                <div className="d-flex justify-content-center mx-auto mb-7 px-2 px-sm-5 py-4 context-item w-100">
                    <img
                        src="/images/vectors/phone-primary.svg"
                        alt=""
                        className="my-auto"
                    />
                    <h6 className="text-primary text-start my-auto ms-3">
                        Call us:{" "}
                        <a
                            className="text-decoration-underline fw-bold"
                            href={`tel:${SUPPORT_TELEPHONE}`}
                        >
                            {SUPPORT_TELEPHONE}
                        </a>
                    </h6>
                </div>
                <div className="d-flex justify-content-center mx-auto mb-4 px-2 px-sm-5 py-4 context-item w-100">
                    <img
                        src="/images/vectors/mail.svg"
                        alt=""
                        className="my-auto"
                    />
                    <h6 className="text-primary text-start my-auto ms-3">
                        Email us:{" "}
                        <a
                            className="text-decoration-underline fw-bold"
                            href={`mailto:${SUPPORT_EMAIL}`}
                        >
                            {SUPPORT_EMAIL}
                        </a>
                    </h6>
                </div>
            </div>
            {onClickAction && (
                <div className="col-12 col-sm-6 mx-auto my-6">
                    <Button
                        variant="btn btn-primary text-uppercase"
                        onClick={onClickAction}
                    >
                        {actionButtonText || "Continue"}
                    </Button>
                </div>
            )}
            <div className="mt-4 px-0 px-sm-8 px-md-10 pb-12 mx-0 mx-sm-8 mx-md-10 mb-12">
                <Button
                    variant="text-dark text-decoration-underline fw-bold p-0 w-fit border-0"
                    onClick={onClickLater}
                >
                    I’ll do this later
                </Button>
            </div>
        </div>
    );
}
