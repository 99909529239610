import { createApi } from "@reduxjs/toolkit/query/react";
import { publicBaseQuery } from "./baseQueries";

export const authAPI = createApi({
    reducerPath: "authAPI",
    baseQuery: publicBaseQuery,
    endpoints: (build) => ({
        signIn: build.mutation({
            query: ({ username, password, captchaResponseToken }) => ({
                url: `/login/`,
                method: "POST",
                body: {
                    username,
                    password,
                    captchaResponseToken,
                    type: "patient",
                },
            }),
            invalidatesTags: ["profile"],
        }),
        eligibility: build.mutation({
            query: ({ values }) => ({
                url: `/api/public/eligibility/`,
                method: "POST",
                body: [...values],
            }),
        }),
        signUp: build.mutation({
            query: ({ values }) => ({
                url: `/register/`,
                method: "POST",
                body: { ...values },
            }),
            invalidatesTags: ["profile"],
        }),
        socialSignup: build.mutation({
            query: ({ values, credential, provider }) => ({
                url: `/social/signup?social-provider=${provider}`,
                method: "POST",
                headers: {
                    authorization: `Bearer ${credential}`,
                },
                body: { ...values },
            }),
            invalidatesTags: ["profile"],
        }),
        socialSignIn: build.mutation({
            query: ({ values, credential, provider }) => ({
                url: `/social/signin?social-provider=${provider}`,
                method: "POST",
                headers: {
                    authorization: `Bearer ${credential}`,
                },
                body: { ...values },
            }),
            invalidatesTags: ["profile"],
        }),
        forgetPassword: build.mutation({
            query: ({ values }) => ({
                url: `/forget-password/`,
                method: "POST",
                body: { ...values },
            }),
        }),
        resetPassword: build.mutation({
            query: ({ values }) => ({
                url: `/reset-password/`,
                method: "POST",
                body: { ...values },
            }),
        }),
        sendUserData: build.mutation({
            query: (values) => ({
                url: `/api/public/sendUserData`,
                method: "POST",
                body: { 
                    consent: values.consent,
                    name: values.name,
                    email: values.email,
                    phone: values.phone,
                 },
            }),
        }),
    }),
});

export const {
    useSignInMutation,
    useEligibilityMutation,
    useSignUpMutation,
    useSocialSignupMutation,
    useSocialSignInMutation,
    useForgetPasswordMutation,
    useResetPasswordMutation,
    useSendUserDataMutation
} = authAPI;
