import { useFormikContext } from "formik";

import ModalLayout from "../modals/ModalLayout";
import { Radio, SubmitButton } from "../forms";

import { GAD_FORM } from "../../constants/orderNotesQuestions";

export default function GADForm({ viewOnly = false }) {
    const { values } = useFormikContext();

    const score = values.feeling + values.worrying;
    const isNull = values.feeling === null || values.worrying === null;

    return (
        <>
            <ModalLayout.Body className={`d-flex d-sm-block p-5 ${viewOnly ? "p-0" : ""}`}>
                <div className="my-auto">
                    <div className="fw-normal">
                        {GAD_FORM.keys.map((key) => (
                            <div className="row mx-0 mb-5" key={key}>
                                <label className="form-label">
                                    {GAD_FORM.data[key].label}
                                </label>
                                <div className="d-flex flex-column flex-lg-row">
                                    {GAD_FORM.data[key].values.map(
                                        (data, idx) => (
                                            <div
                                                className="me-0 me-lg-3 mb-2 mb-lg-0"
                                                key={idx}
                                            >
                                                <Radio
                                                    name={key}
                                                    id={idx}
                                                    label={data}
                                                    disabled={viewOnly}
                                                />
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* {!isNull && (
                        <div className="row mx-0">
                            <h6>Score: {score.toFixed(2)}</h6>
                        </div>
                    )} */}
                </div>
            </ModalLayout.Body>
            {viewOnly ? (
                <></>
            ) : (
                <ModalLayout.Footer>
                    <div className="d-flex w-100">
                        <SubmitButton variant="primary" disabled={isNull}>
                            Submit
                        </SubmitButton>
                    </div>
                </ModalLayout.Footer>
            )}
        </>
    );
}
