import { useFormikContext } from "formik";
import ReactSlider from "react-slider";

import "./slider.scss";
import {useState, useEffect} from "react";

export default function Slider({
    label,
    name,
    min,
    max,
    step,
    marks,
    className,
    labelClass,
    thumbClassName,
    trackClassName,
    disabled,
}) {
    const { values, setFieldValue } = useFormikContext();
    const [buttons, setButtons] = useState([]);
    const [numbers, setNumbers] = useState([]);
    const [clickedNumberButton, setClickedNumberButton] = useState(null);

    useEffect(() => {
        const initializeData = () => {
            const newButtons = [];
            const newNumbers = [];
            for (let i = min; i <= max; i++) {
                newButtons.push(i);
                newNumbers.push(i);
            }
            setButtons(newButtons);
            setNumbers(newNumbers);
        };

        initializeData();
        if (values[name]) {
            setClickedNumberButton(values[name]);
        }
    }, [min, max, values, name]);

    const numberButtonClicked = (index, event) => {
        event.stopPropagation();
        setClickedNumberButton(index);
        setFieldValue(name, index);
    };

    const getNumberButtonClass = (index) => {
        let className = 'level-' + index;
        if (index === clickedNumberButton) {
            className += ' selected';
        }
        return className;
    };

    const getButtonBackgroundColor = (index) => {
        const r = 248;
        const g = 214 - index * 10;
        const b = 352 - index * 10;
        return `rgb(${r}, ${g}, ${b})`;
    };

    return (
        <div className="w-100">
            {label && (
                <label className={`${labelClass} mb-4`} htmlFor={name}>
                    {label}
                </label>
            )}
             {/*Older Silder UI*/}
            {/*<ReactSlider*/}
            {/*    min={min}*/}
            {/*    max={max}*/}
            {/*    step={step}*/}
            {/*    className={`slider ${className}`}*/}
            {/*    thumbClassName={thumbClassName}*/}
            {/*    trackClassName={trackClassName}*/}
            {/*    renderThumb={(props, state) => (*/}
            {/*        <div {...props}>*/}
            {/*            /!*<span>{state.valueNow}</span>*!/*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*    value={values[name]}*/}
            {/*    disabled={disabled}*/}
            {/*    onChange={(e) => setFieldValue(name, e)}*/}
            {/*/>*/}

            <div>
                <div onClick={(e) => e.stopPropagation()}>
                {buttons.map((levelVal, index) => (
                    <button
                        type="button"
                        key={index}
                        className={`round-button ${getNumberButtonClass(levelVal)}`}
                        style={{backgroundColor: getButtonBackgroundColor(levelVal)}}
                        onClick={(e) => numberButtonClicked(levelVal, e)}
                    >
                        <span className="number-text">{numbers[levelVal]}</span>
                    </button>
                ))}
                </div>
            </div>

            {/*This shows the boundary values in the PEG scale*/}
            {/*<div className="marks-container d-flex justify-content-between">*/}
            {/*{marks &&*/}
            {/*        marks.map((mark) => (*/}
            {/*            <span*/}
            {/*                className={`step-mark mark-${mark} text-dark fw-semibold z-10 my-auto mx-1`}*/}
            {/*            >*/}
            {/*                {mark}*/}
            {/*            </span>*/}
            {/*        ))}*/}
            {/*</div>*/}
        </div>
    );
}
