import React from "react";

import "./pill.scss";

/**
 * Pill component
 * @param {{
 * children: JSX.Element | string,
 * variant: "completed" | "active" | "rejected" | "pending" | "cancelled",
 * }} props
 * @returns {React.FunctionComponent}
 */

export default function Pill({ children = "", variant = "completed" }) {
    return (
        <div
            className={`badge pill text-truncate text-center fw-bold px-3 py-2 ${variant}`}
        >
            {children}
        </div>
    );
}
