import React from "react";

import { useModal } from "../../../hooks/modalsHooks";

import { Form } from "../../forms";
import { ConsentForm } from "../../orderNoteForms";
import ModalLayout from "../ModalLayout";

export default function ConsultationConsentModal({ onSubmit }) {
    const { hideModal } = useModal();

    const handleSubmit = (values) => {
        const data = {
            type: "PATIENT_DETAIL_CONFIRMATION",
            questionAnswer: JSON.stringify(values),
        };

        onSubmit(data);
    };

    return (
        <ModalLayout width="44rem">
            <ModalLayout.Header closeModal={hideModal}>
                <div className="w-100">
                    <h2 className="position-relative text-center fw-bold">
                        Patient consent form
                        <span className="underline center short" />
                    </h2>
                </div>
            </ModalLayout.Header>
            <Form
                initialValues={{
                    ageOver25: "",
                    answeredAssessment: "",
                    soleUseAcceptance: "",
                    agreeToReadInfo: "",
                    understandTnc: "",
                }}
                onSubmit={handleSubmit}
                className="h-100"
            >
                <ConsentForm />
            </Form>
        </ModalLayout>
    );
}
