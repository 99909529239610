import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { useGetOrderQuery } from "../../../services/orderService";
import { getDisplayAddress } from "../../../utils/dataUtils";
import { RepeatOrderContext } from "../../../providers/RepeatOrderProvider";
import useQuery from "../../../hooks/useQuery";

import {
    DatePicker,
    Form,
    PhoneNumberInput,
    SubmitButton,
    TextArea,
    TextField,
} from "../../../components/forms";

export default function ReOrderConfirmPage() {
    const navigate = useNavigate();
    const query = useQuery();
    const type = query.get("type");
    const { parentOrderId, isLoading } = useContext(RepeatOrderContext);

    const { data: orderData, isLoading: orderLoading } = useGetOrderQuery(
        { appointmentId: parentOrderId },
        { skip: !parentOrderId || isLoading, refetchOnMountOrArgChange: true }
    );

    let queryParams = "";

    if (type) {
        queryParams = "/?type=REPEAT";
    }

    const handleSubmit = () => {
        navigate(`/appointment/questionnaire${queryParams}`);
    };

    const patientData = orderData?.patient;

    return (
        <div className="d-flex justify-content-center">
            <div className="col-sm-6 mb-6">
                <div className="row mx-0">
                    <h2 className="text-center fw-bold text-brown-black">
                        Confirm details
                    </h2>
                    <p className="body body-small text-dark-5 mt-4 fs-6 text-center">
                        Please ensure this information is accurate.
                    </p>
                    <div className="d-flex justify-content-center mt-5">
                        <Form
                            onSubmit={handleSubmit}
                            initialValues={{
                                ...patientData,
                                address: getDisplayAddress(
                                    patientData?.address
                                ),
                            }}
                            enableReinitialize
                        >
                            <div className="mx-auto">
                                <div className="row mb-4">
                                    <TextField
                                        name="firstName"
                                        placeholder="First Name"
                                        disabled
                                    />
                                </div>
                                <div className="row mb-4">
                                    <TextField
                                        name="lastName"
                                        placeholder="Last Name"
                                        disabled
                                    />
                                </div>
                                <div className="row mb-4">
                                    <PhoneNumberInput
                                        name="phone"
                                        placeholder="Mobile number"
                                        disabled
                                    />
                                </div>
                                <div className="row mb-4">
                                    <TextField
                                        name="email"
                                        placeholder="Email"
                                        disabled
                                    />
                                </div>
                                <div className="row mb-4">
                                    <DatePicker
                                        name="dateOfBirth"
                                        label="Date of birth"
                                        disabled
                                    />
                                </div>
                                <div className="row mb-4">
                                    <TextArea
                                        name="address"
                                        placeholder="Address"
                                        rows="2"
                                        disabled
                                    />
                                </div>
                                <div className="row mx-0 mt-6 mb-12">
                                    <SubmitButton
                                        disabled={orderLoading || isLoading}
                                    >
                                        CONTINUE
                                    </SubmitButton>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}
