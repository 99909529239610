/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";

import { AppointmentContext } from "../../providers/AppointmentProvider";
import { useLazyGetDoctorQuery } from "../../services/dataService";

import { Form } from "../../components/forms";
import Avatar from "../../components/avatar/Avatar";
import Button from "../../components/button/Button";
import Collapsible from "../../components/collapsible/Collapsible";
import GoBack from "../../components/GoBack";
import UploadDocumentForm from "../../components/uploadDocumentForm/UploadDocumentForm";

function ConsultationUploadsPage() {
    const navigate = useNavigate();
    const { appointmentData, setAppointmentData } =
        useContext(AppointmentContext);
    const { id: orderId } = useParams();

    const [doctorData, setDoctorData] = useState({});
    const [initialValues, setInitValues] = useState({
        documents: [],
    });

    const [getDoctor] = useLazyGetDoctorQuery();

    const handleGetDoctor = async () => {
        if (appointmentData?.doctorId) {
            try {
                const doctorRes = await getDoctor({
                    doctorId: appointmentData.doctorId,
                });
                if (doctorRes.error) {
                    // handle error
                } else {
                    setDoctorData(doctorRes.data);
                }
            } catch (error) {}
        }
    };

    const handleSubmit = async ({ documents }) => {
        if (documents?.length > 0) {
            const appointment = {
                ...appointmentData,
                pictures: documents,
            };

            await setAppointmentData(appointment);

            navigate(`/appointment/${orderId}/payment`);
        } else {
            navigate(`/appointment/${orderId}/payment`);
        }
    };

    const handleSkip = () => {
        navigate(`/appointment/${orderId}/payment`);
    };

    const handleClickAppointmentChange = () => {
        navigate("/appointment");
    };

    const handleClickNoteChange = () => {
        navigate(`/appointment/${orderId}/notes`);
    };

    useEffect(() => {
        if (appointmentData?.pictures) {
            setInitValues({
                documents: appointmentData?.pictures,
            });
        }

        handleGetDoctor();
    }, [appointmentData]);

    return (
        <div className="row mx-0 h-100">
            <div className="col-md-6 pt-5 position-relative bg-light-gray">
                <div className="row mx-0 mb-0 mb-md-8">
                    <div className="col-12 col-lg-11 col-xl-9 col-xxl-8 mx-auto pb-5 pb-md-0 mb-0 mb-md-8">
                        <GoBack />

                        <h3 className="text-center fw-bold text-brown-black mt-4">
                            Upload supporting documents
                        </h3>
                        <p className="mt-4 text-medium-gray fs-6 text-center">
                            Upload relevant documents or pictures to share with your 
                            consultant. You can also do this later by logging into your 
                            Treat It account.
                        </p>

                        <div className="d-flex justify-content-center mt-3">
                            <Form
                                initialValues={initialValues}
                                onSubmit={handleSubmit}
                                enableReinitialize
                            >
                                <div className="mt-0">
                                    <UploadDocumentForm
                                        name="documents"
                                        options={[
                                            {
                                                label: "Current prescription copy",
                                                value: "current_prescription",
                                            },
                                            {
                                                label: "Previous prescription copy",
                                                value: "previous_prescription",
                                            },
                                            {
                                                label: "Other supporting document",
                                                value: "other",
                                            },
                                        ]}
                                        multiple
                                        fileLimit={5}
                                        submitText="CONTINUE"
                                    />
                                </div>
                                <div
                                    className="mt-4 text-decoration-underline text-center text-brown-black cursor-pointer fs-6"
                                    onClick={handleSkip}
                                >
                                    Skip this step
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 pt-10 px-2 px-lg-6 mb-5 bg-white">
                <div className="row mx-0 mb-8 mb-md-0 pb-8 pb-md-0">
                    <div className="col-12 col-lg-11 col-xl-9 col-xxl-8 mx-auto pb-5 pb-md-0 mb-0 mb-md-8">
                        <Collapsible
                            title="Consultation Summary"
                            className="mb-10 mb-md-0 py-6 px-5 custom-shadow"
                            expanded
                        >
                            <div className="text-primary fs-6 fw-bold">
                                Consultation details
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-4">
                                <div className="d-flex align-items-sm-center">
                                    <div
                                        className="d-none d-sm-block"
                                        style={{ width: 56, height: 56 }}
                                    >
                                        <Avatar
                                            src={
                                                doctorData?.settings
                                                    ?.DOCTOR_AVATAR ||
                                                "/images/samples/doctor-thumb.png"
                                            }
                                        />
                                    </div>

                                    <div className="ms-0 ms-sm-4">
                                        <div className="primary-title fw-bold fs-5">
                                            {doctorData.displayName}
                                        </div>
                                        {appointmentData?.datetime && (
                                            <div className="secondary-title text-dark-3 fw-light">
                                                {dayjs(
                                                    appointmentData.datetime
                                                ).format("DD/MM/YYYY")}{" "}
                                                at{" "}
                                                {dayjs(
                                                    appointmentData.datetime
                                                ).format("hh:mma")}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <Button
                                    variant=" text-dark-4 text-decoration-underline border-0 text-right p-0 ms-4"
                                    fullWidth={false}
                                    onClick={handleClickAppointmentChange}
                                >
                                    Change
                                </Button>
                            </div>
                            {appointmentData?.notes && (
                                <>
                                    <hr />
                                    <div className="text-primary fs-6 fw-bold">
                                        Consultation notes
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mt-2">
                                        <div className="text-dark-4 pe-2">
                                            {appointmentData.notes}
                                        </div>
                                        <Button
                                            variant=" text-dark-4 text-decoration-underline border-0 text-right p-0 ms-4"
                                            fullWidth={false}
                                            onClick={handleClickNoteChange}
                                        >
                                            Change
                                        </Button>
                                    </div>
                                </>
                            )}
                        </Collapsible>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConsultationUploadsPage;
