import React, { useState } from "react";
import {
    useStripe,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
} from "@stripe/react-stripe-js";

import { TextField } from "../forms";

import "./card-input.scss";

const defaultOptions = {
    style: {
        base: {
            letterSpacing: "0.025rem",
            fontWeight: 400,
            fontFamily: "Titillium Web",
            fontSize: "16px",
            "::placeholder": {
                color: "#8e7f98",
                fontWeight: 400,
            },
            ":focus": {
                backgroundColor: "255, 255, 255, 0.75",
            },
        },
        invalid: {
            color: "#9e2146",
        },
    },
};

const cardNumberOptions = {
    placeholder: "Card number",
};

const cvcOptions = {
    placeholder: "CVC / CVV",
};

const dateOptions = {
    placeholder: "Expiry date",
};

export default function CardInput({ disabled }) {
    const stripe = useStripe();

    const [cardNumberError, setCardNumberError] = useState("");
    const [cardExpiryError, setCardExpiryError] = useState("");
    const [cardCvcError, setCardCvcError] = useState("");
    const [focusedInput, setFocusedInput] = useState("");

    return (
        <div className={`card-input-container d-${stripe ? "block" : "none"}`}>
            <div className="row mb-5">
                <div className="col-12">
                    <div className="card-number-input">
                        <CardNumberElement
                            options={{
                                ...defaultOptions,
                                ...cardNumberOptions,
                                disabled,
                            }}
                            className={`stripe form-control form-control-sm px-5 py-4 bg-white bg-opacity-75 ${
                                focusedInput === "card" ? "focus" : ""
                            } ${disabled && "disabled"}`}
                            onChange={(e) => {
                                setCardNumberError(e.error?.message || "");
                            }}
                            onFocus={() => setFocusedInput("card")}
                            onBlur={() => setFocusedInput("")}
                        />
                        <div className="card-logo">
                            <img src="/images/vectors/card-icon.svg" alt="" />
                        </div>
                    </div>
                    {cardNumberError && (
                        <small className="form-message form-error text-danger">
                            {cardNumberError}
                        </small>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-6 col-md-12 col-lg-6 mb-5">
                    <CardExpiryElement
                        options={{
                            ...defaultOptions,
                            ...dateOptions,
                            disabled,
                        }}
                        className={`stripe form-control form-control-sm px-5 py-4 bg-white bg-opacity-75 ${
                            focusedInput === "date" ? "focus" : ""
                        } ${disabled && "disabled"}`}
                        onChange={(e) => {
                            setCardExpiryError(e.error?.message || "");
                        }}
                        onFocus={() => setFocusedInput("date")}
                        onBlur={() => setFocusedInput("")}
                    />
                    {cardExpiryError && (
                        <small className="form-message form-error text-danger">
                            {cardExpiryError}
                        </small>
                    )}
                </div>
                <div className="col-12 col-sm-6 col-md-12 col-lg-6 mb-5">
                    <CardCvcElement
                        options={{
                            ...defaultOptions,
                            ...cvcOptions,
                            disabled,
                        }}
                        className={`stripe form-control form-control-sm px-5 py-4 bg-white bg-opacity-75 ${
                            focusedInput === "cvc" ? "focus" : ""
                        } ${disabled && "disabled"}`}
                        onChange={(e) => {
                            setCardCvcError(e.error?.message || "");
                        }}
                        onFocus={() => setFocusedInput("cvc")}
                        onBlur={() => setFocusedInput("")}
                    />
                    {cardCvcError && (
                        <small className="form-message form-error text-danger">
                            {cardCvcError}
                        </small>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col-12 mb-5 mb-md-0">
                    <TextField
                        name="name"
                        placeholder="Enter the name on the card"
                        className="stripe form-control form-control-sm fs-6 px-5 py-3 bg-white bg-opacity-75"
                    />
                </div>
            </div>
        </div>
    );
}
