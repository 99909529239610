import { useFormikContext } from "formik";

import PharmacyNotesQuestionAnswers from "../../../../components/pharmacyNotesQuestionAnswers/PharmacyNotesQuestionAnswers";

export default function PharmacyNotes() {
    const { values } = useFormikContext();

    if (!values) {
        return null;
    }

    return (
        <div className="doctor-notes-section">
            <div className="text-primary p1 fw-bold mt-5">Pharmacy Notes</div>
            <div className="mt-4">
                <div className="basic-table-section table-responsive border border-gray rounded">
                    <table className="table table-responsive mb-0">
                        <thead className="bg-light-gray">
                            <tr>
                                <th className="ps-5" scope="col">
                                    Question
                                </th>
                                <th className="pe-5" scope="col">
                                    Answer
                                </th>
                            </tr>
                        </thead>
                        {values && (
                            <tbody>
                                <PharmacyNotesQuestionAnswers viewOnly />
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
        </div>
    );
}
