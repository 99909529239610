import Price from "../price/Price";

export default function OrderContent({
    medicationAmount,
    deliveryAmount,
    colCount,
    totalAmount,
    deliveryType,
    textColor,
    textTotalAmount = "",
}) {
    return (
        <>
            {medicationAmount && (
                <tr className="p1 fw-bold">
                    <td
                        colSpan={colCount}
                        className={`border border-0 ${
                            deliveryAmount ? "pt-3 pb-2" : "py-5"
                        } ps-3 ps-md-5`}
                    >
                        Total Amount For Medication
                    </td>
                    <td
                        className={`border border-0 ${
                            deliveryAmount ? "pt-3 pb-2" : "py-5"
                        } pe-3 pe-md-5 d-flex justify-content-end`}
                    >
                        <Price amount={medicationAmount} currency="GBP" />
                    </td>
                </tr>
            )}
            {deliveryType && (
                <tr className="p1 fw-bold">
                    <td colSpan={colCount} className="pt-1 pb-3 ps-3 ps-md-5">
                        {deliveryType === "EXPRESS"
                            ? "Express delivery"
                            : "Shipping"}
                    </td>
                    <td className="pt-1 pb-3 pe-3 pe-md-5 d-flex justify-content-end">
                        <Price amount={deliveryAmount || 0} currency="GBP" />
                    </td>
                </tr>
            )}
            {totalAmount && (
                <tr
                    className={`${
                        textColor ? `text-${textColor}` : "text-primary"
                    } p1 fw-bold border-top border-gray`}
                >
                    <td
                        colSpan={colCount}
                        className="border border-0 py-5 ps-3 ps-md-5"
                    >
                        Total amount {textTotalAmount}
                    </td>
                    <td className="border border-0 py-5 pe-3 pe-md-5 d-flex justify-content-end">
                        <Price amount={totalAmount} currency="GBP" />
                    </td>
                </tr>
            )}
        </>
    );
}
