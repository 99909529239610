import React from "react";
import { useFormikContext } from "formik";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./calendar.scss";

export default function Calendar({ name, minimumDate, maximumDate, disabled }) {
    const { setFieldValue, values } = useFormikContext();

    const handleChangeDate = (value) => {
        setFieldValue(name, value);
    };

    return (
        <DatePicker
            name={name}
            selected={values[name]}
            onChange={handleChangeDate}
            minDate={minimumDate}
            maxDate={maximumDate}
            inline
            showDisabledMonthNavigation
            calendarClassName="custom-calendar"
            disabled={disabled}
        />
    );
}
