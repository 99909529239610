import { useFormikContext } from "formik";
import { Link } from "react-router-dom";

import { SubmitButton, TextField } from "../../../components/forms";

export default function ForgotPasswordForm({ linkSent, onRetry }) {
    const { values } = useFormikContext();

    const handleRetry = () => {
        if (onRetry) {
            onRetry({ username: values.username });
        }
    };

    if (linkSent) {
        return (
            <div className="mb-12">
                <p className="body body-small text-dark-5 text-center mb-8">
                    We have emailed your instructions to reset your password.
                </p>
                <div className="body body-small text-dark-5 row mx-0 mb-5 justify-content-center">
                    <p className="my-auto w-fit pe-1">
                        Didn’t receive an email?
                    </p>
                    <button
                        className="bg-transparent border-0 text-primary text-decoration-underline link p-0 my-auto fw-semibold w-fit fs-6"
                        onClick={handleRetry}
                        type="button"
                    >
                        Resend instructions
                    </button>
                </div>
                <Link to="/auth/signin" className="btn btn-primary mt-3 w-100">
                    Return to login
                </Link>
            </div>
        );
    }

    return (
        <div className="mb-12">
            <p className="body body-small text-dark-5 text-center mb-8">
                Don’t worry! We will send you instructions to reset your
                password.
            </p>
            <div className="row mb-5">
                <TextField
                    name="username"
                    placeholder="Email"
                    className="form-control-sm fs-6 px-5 py-4 bg-white"
                />
            </div>
            <SubmitButton variant="primary">Reset Password</SubmitButton>
            <Link
                to="/auth/signin"
                className="link p-0 mt-3 fw-semibold d-flex justify-content-center"
            >
                Return to login
            </Link>
        </div>
    );
}
