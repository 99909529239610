import React from "react";

import "./cookies-policy.scss";

function CookiesPolicyPage() {
    return (
        <div className="policy-container container my-6 pb-6">
            <h1 className="fw-bold fs-3">Cookies</h1>
            <h2 className="fs-3 mb-4 my-6">Cookies Policy</h2>
            <p>
                Our website uses Cookies for various purposes. Cookies are small
                text parcels sent to and from a website, which is then stored on
                your computer by your browser. Without a Cookie a website cannot
                distinguish between different users, which can lead to the
                essential functionality of a site not working as expected. This
                policy explains how Cookies are used on our website and how you
                can control them.
            </p>
            <p>
                In this policy, we/us/website refers to&nbsp;
                <a
                    href="https://treat-it.clinic/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    https://treat-it.clinic
                </a>
            </p>
            <h2 className="fs-3 mb-4 mt-6">How we use Cookies</h2>
            <p>
                We use Cookies to define sessions, provide essential site
                features and track visitors anonymously for analytical purposes.
                We regularly carry out an audit of the cookies used on our
                website and have categorized them into the following:
                <ul className="mt-4 fs-6">
                    <li>Essential Cookies</li>
                    <li>Functional Cookies</li>
                    <li>Third Party Cookies</li>
                </ul>
            </p>
            <h2 className="fs-3 mb-4 mt-6">Essential Cookies</h2>
            <p>
                These Cookies are required by the website in order to work as
                expected. Without them, you may be unable to browse parts of the
                website or view it at all.
            </p>

            <div className="policy-table-container overflow-auto mt-6">
                <table className="table table-responsive table-bordered fs-6">
                    <thead>
                        <tr className="table-primary">
                            <th className="px-3 py-4" scope="col">Cookie</th>
                            <th className="px-3 py-4" scope="col">Name(s)</th>
                            <th className="px-3 py-4" scope="col">Duration</th>
                            <th className="px-3 py-4" scope="col">Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="table-light">
                            <td className="py-3 px-2">Session</td>
                            <td className="py-3 px-2">ASP.NET_SessionId</td>
                            <td className="py-3 px-2">Session</td>
                            <td className="py-3 px-2">Used to provide the User with a Session</td>
                        </tr>
                        <tr className="table-light">
                            <td className="py-3 px-2">Authorisation</td>
                            <td className="py-3 px-2">ASPXAUTH</td>
                            <td className="py-3 px-2">Session</td>
                            <td className="py-3 px-2">
                                Authorises the User to have access to the
                                member’s area
                            </td>
                        </tr>
                        <tr className="table-light">
                            <td className="py-3 px-2">Member</td>
                            <td className="py-3 px-2">Member</td>
                            <td className="py-3 px-2">2 months</td>
                            <td className="py-3 px-2">
                                Stores the User’s unique ID for login and
                                checkout purposes
                            </td>
                        </tr>
                        <tr className="table-light">
                            <td className="py-3 px-2">User</td>
                            <td className="py-3 px-2">User</td>
                            <td className="py-3 px-2">30 days</td>
                            <td className="py-3 px-2">
                                Stores the User’s basket for adding items to
                                their basket and remembering it when returning
                                to the site
                            </td>
                        </tr>
                        <tr className="table-light">
                            <td className="py-3 px-2">SelectedOption</td>
                            <td className="py-3 px-2">SelectedOption</td>
                            <td className="py-3 px-2">1 hour</td>
                            <td className="py-3 px-2">
                                Stores the User’s chosen delivery option on the
                                Checkout
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <h2 className="fs-3 mb-4 mt-6">Functional Cookies</h2>
            <p>
                These Cookies are used by features of our website to identify
                you and your preferences as you use the site. It is possible to
                use the website without these, though you may see a degraded
                experience.
            </p>

            <div className="policy-table-container overflow-auto mt-6">
                <table className="table table-responsive table-bordered fs-6">
                    <thead>
                        <tr className="table-primary">
                            <th className="px-3 py-4" scope="col">Cookie</th>
                            <th className="px-3 py-4" scope="col">Name(s)</th>
                            <th className="px-3 py-4" scope="col">Duration</th>
                            <th className="px-3 py-4" scope="col">Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="table-light">
                            <td className="py-3 px-2">Viewed</td>
                            <td className="py-3 px-2">ViewedHomeSlide20120229a</td>
                            <td className="py-3 px-2">2 days</td>
                            <td className="py-3 px-2">
                                Name varies, but is used to determine if the
                                homepage offer has been viewed to avoid showing
                                it to the user multiple times.
                            </td>
                        </tr>
                        <tr className="table-light">
                            <td className="py-3 px-2">Name</td>
                            <td className="py-3 px-2">Name</td>
                            <td className="py-3 px-2">2 days</td>
                            <td className="py-3 px-2">
                                Stores the User’s name when entered on the
                                various forms to save having to re-enter their
                                name at a later date.
                            </td>
                        </tr>
                        <tr className="table-light">
                            <td className="py-3 px-2">Email</td>
                            <td className="py-3 px-2">Email</td>
                            <td className="py-3 px-2">2 days</td>
                            <td className="py-3 px-2">
                                Stores the User’s email when entered on the
                                various forms to save having to re-enter their
                                email at a later date.
                            </td>
                        </tr>
                        <tr className="table-light">
                            <td className="py-3 px-2">Referrer</td>
                            <td className="py-3 px-2">Referrer</td>
                            <td className="py-3 px-2">2 days</td>
                            <td className="py-3 px-2">
                                Stores the affiliate that referred the User to
                                the website for tracking purposes
                            </td>
                        </tr>
                        <tr className="table-light">
                            <td className="py-3 px-2">Legacy Cookies</td>
                            <td className="py-3 px-2">URLS, Query</td>
                            <td className="py-3 px-2">2 days</td>
                            <td className="py-3 px-2">
                                Old legacy cookies that may still be stored on a
                                User’s PC if they have visited the website
                                previously
                            </td>
                        </tr>
                        <tr className="table-light">
                            <td className="py-3 px-2">Purchased</td>
                            <td className="py-3 px-2">Purchased</td>
                            <td className="py-3 px-2">2 months</td>
                            <td className="py-3 px-2">
                                Tracks whether the User has previously purchased
                                from the website in the last 2 months
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <h2 className="fs-3 mb-4 mt-6">Third Party Cookies</h2>
            <p>
                These Cookies are used by selected third parties we have chosen
                to integrate with. They are used mainly for tracking purposes so
                that we can further improve the site for the future. They may
                also be used for integrating with social sites to further
                enhance your website experience.
            </p>

            <div className="policy-table-container overflow-auto mt-6">
                <table className="table table-responsive table-bordered fs-6">
                    <thead>
                        <tr className="table-primary">
                            <th className="px-3 py-4" scope="col">Cookie</th>
                            <th className="px-3 py-4" scope="col">Name(s)</th>
                            <th className="px-3 py-4" scope="col">Duration</th>
                            <th className="px-3 py-4" scope="col">Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="table-light">
                            <td className="py-3 px-2">Google Analytics</td>
                            <td className="py-3 px-2">_utmz, _utmc, _utmb, _utma</td>
                            <td className="py-3 px-2">Varies</td>
                            <td className="py-3 px-2">
                                Used by Google Analytics for tracking purposes.
                                You can opt out of these using the Google
                                Analytics opt-out browser add-on.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <h2 className="fs-3 mb-4 mt-6">Controlling the Cookies</h2>
            <p>
                By default, all of the above Cookies are in use on our website.
                However, if you wish to take control of these cookies you can do
                so by following the instructions below for your browser:
                <ul className="mt-4 fs-6">
                    <li>Internet Explorer (mobile)</li>
                    <li>Chrome</li>
                    <li>Firefox</li>
                    <li>Safari</li>
                    <li>Blackberry</li>
                    <li>Android</li>
                    <li>Opera</li>
                </ul>
            </p>

            <p>
                This will allow you to remove Cookies or prevent using them
                altogether. Remember, turning off Cookies in your browser will
                affect all websites, not just this one. If you later decide
                you’d like to allow Cookies, then simply adjust your browser
                settings and continue using the website; Cookies will then be
                sent to and from our website.
            </p>
        </div>
    );
}

export default CookiesPolicyPage;
