import React from "react";
import { Link } from "react-router-dom";

import EligibilityExitPageLayout from "./EligibilityExitPageLayout";
import GoBack from "../../../components/GoBack";

export default function EligibilityPregnantExitPage() {

    return (
        <div>
            <div className="mb-5 eligibility-diagnosis-back">
                <Link to={-1}>
                    <GoBack text="Back"/>
                </Link>
            </div>
            <EligibilityExitPageLayout title="We regret to inform you that we are unable to prescribe medical cannabis if you are pregnant, intending to become pregnant, or breastfeeding.">
                <p className="fs-5 subtitle text-primary-200 text-center mb-5 mx-2 mx-lg-10 px-lg-10">
                    We appreciate your understanding. If you require any additional
                    information or believe that you are still eligible, please don't
                    hesitate to reach out to us. Our team is always available to
                    assist you.
                </p>
                <p className="fs-5 subtitle text-primary-200 text-center mb-5 mx-2 mx-lg-10 px-lg-10">
                    Why not take a moment to discover more of the services and
                    benefits we provide at Treat It clinic?
                </p>
            </EligibilityExitPageLayout>
        </div>
    );
}
