import * as Yup from "yup";
import {useModal} from "../../../hooks/modalsHooks";
import React, {useState} from "react";
import ModalLayout from "../ModalLayout";
import {Form} from "../../forms";
import SideEffectForm from "../../orderNoteForms/SideEffectForm";


const SideEffectFormSchema = Yup.object().shape({
    sideEffect: Yup.number()
        .min(0, "Must be greater than or equal to 0")
        .max(10, "Must be less than or equal to 10")
});

export default function SideEffectModal({ onSubmit, initialValues }) {
    const { hideModal } = useModal();

    const [initValues] = useState(
        initialValues || {
            sideEffectPain: undefined,
        }
    );

    const handleSubmit = (values) => {
        const average =
            (values.sideEffectPain / 1);

        const data = {
            type: "SIDE_EFFECT_FORM",
            questionAnswer: JSON.stringify({ ...values, average }),
        };

        onSubmit(data);
    };

    return (
        <ModalLayout width="44rem">
            <ModalLayout.Header closeModal={hideModal}>
                <div className="w-100">
                    <h2 className="position-relative text-center fw-bold">
                        Side Effect Form
                        <span className="underline center short" />
                    </h2>
                </div>
            </ModalLayout.Header>
            <Form
                initialValues={initValues}
                validationSchema={SideEffectFormSchema}
                onSubmit={handleSubmit}
                className="h-100"
            >
                <SideEffectForm />
            </Form>
        </ModalLayout>
    )
}