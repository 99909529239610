import React from "react";
import { useNavigate } from "react-router-dom";

import Button from "../../components/button/Button";

function ConsultationCompletePage() {
    const navigate = useNavigate();

    const handleRedirectToDashboard = () => {
        navigate(`/user/dashboard`, { replace: true });
    };

    return (
        <div
            className="row mx-0 align-items-center justify-content-center bg-light-gray"
            style={{
                minHeight: `calc(100vh - 80px)`,
            }}
        >
            <div className="col-12 col-md-10 col-xl-4 col-xxl-3 mx-auto pb-12">
                <img
                    src="/images/vectors/success.svg"
                    className="img-fluid mx-auto d-block"
                    alt=""
                />
                <div className="fw-bold fs-4 text-brown-black text-center mt-3">
                    Consultation ended.
                </div>

                <div className="mt-3 text-dark-4 fs-6 text-center px-xl-2">
                    Your case is now under review. We will provide you with an update 
                    within the next few days. Thank you for choosing Treat It!
                </div>

                <div className="row">
                    <div className="col-12 col-sm-10 col-md-8 mx-auto">
                        <Button
                            variant="primary mt-5"
                            onClick={handleRedirectToDashboard}
                        >
                            Go back to Dashboard
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConsultationCompletePage;
