import { createApi } from "@reduxjs/toolkit/query/react";

import { publicBaseQuery } from "./baseQueries";

import { NHS_SUBSCRIPTION_KEY } from "../constants";

export const dataAPI = createApi({
    reducerPath: "dataAPI",
    baseQuery: publicBaseQuery,
    endpoints: (build) => ({
        getPostcodes: build.query({
            query: ({ partial }) => ({
                url: `https://api.postcodes.io/postcodes/${partial}/autocomplete`,
                method: "GET",
            }),
        }),
        getSurgeries: build.query({
            query: ({ postcode }) => ({
                url: `https://api.nhs.uk/service-search/search-postcode-or-place?api-version=1&search=${postcode}`,
                headers: {
                    "subscription-key": NHS_SUBSCRIPTION_KEY,
                    "Content-Type": "application/json",
                },
                body: {
                    filter: "OrganisationTypeID eq 'GPB'",
                    select: "OrganisationName,Address1,Address2,Address3,City,County,Postcode,Contacts",
                    top: 10,
                    skip: 0,
                    count: true,
                },
                method: "POST",
            }),
        }),
        getAddresses: build.query({
            query: ({ postcode }) => ({
                url: `/api/public/get-address?postcode=${postcode}`,
                method: "GET",
            }),
        }),
        getDoctor: build.query({
            query: ({ doctorId }) => ({
                url: `/api/public/doctor/${doctorId}`,
                method: "GET",
            }),
        }),
        subscribeToMail: build.mutation({
            query: ({ body, email }) => ({
                url: `api/public/marketing-subscription?email=${email}`,
                body,
                method: "POST",
            }),
        }),
        getCannabisCard: build.query({
            query: ({ pin, token }) => ({
                url: `/api/public/cannabis-details?pin=${pin}&token=${token}`,
                method: "GET"
            }),
        }),
    }),
});

export const {
    useLazyGetPostcodesQuery,
    useLazyGetSurgeriesQuery,
    useLazyGetAddressesQuery,
    useLazyGetDoctorQuery,
    useGetDoctorQuery,
    useSubscribeToMailMutation,
    useGetCannabisCardQuery
} = dataAPI;
