import React from "react";

import { useModal } from "../../../hooks/modalsHooks";
import ModalLayout from "../ModalLayout";

import "./camera-modal.scss";

export default function CameraModal() {
    const { hideModal } = useModal();

    return (
        <ModalLayout className="bg-white h-auto" width={"725px"}>
            <ModalLayout.Header
                className="bg-white header-text"
                closeModal={hideModal}
            >
                <span className="text-center text-primary fs-4 fw-bold">Treat it Clinic</span>
            </ModalLayout.Header>
            <ModalLayout.Body className="pt-0 px-5 h-max bg-white header-text">
                <div className="parent parent-mobile">
                    <div className="image-parent">
                        <img className="image" alt="Illustration of browser showing where to fix blocked permissions." src="/images/vectors/camera_permission.svg" />
                    </div>
                    <div className="parent-text">
                        <h1 className="heading">Consultation is blocked from using your microphone and camera</h1>
                        <ol className="child">
                            <li className="child-text">Click the <img className="lock" src="/images/vectors/lock.svg" alt="" /> lock icon in your browser's address bar</li>
                            <li className="child-text">Turn on microphone and camera</li>
                        </ol>
                    </div>
                </div>
            </ModalLayout.Body>
        </ModalLayout>
    );
}

