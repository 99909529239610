import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AppointmentContext } from "../../../providers/AppointmentProvider";
import { RepeatOrderContext } from "../../../providers/RepeatOrderProvider";

import GoBack from "../../../components/GoBack";
import TextArea from "../../../components/forms/TextArea";
import {
    Form,
    SearchableSelect,
    SubmitButton,
} from "../../../components/forms";
import PrevPrescription from "../../../components/prevPrescription/PrevPrescription";

const CHANGE_REASONS = [
    {
        label: "I want to try a different strain or brand",
        value: "I want to try a different strain or brand",
    },
    {
        label: "I want to try a different administration route (vape/flower/oil/etc)",
        value: "I want to try a different administration route (vape/flower/oil/etc)",
    },
    {
        label: "I want to add or remove an item from my prescription",
        value: "I want to add or remove an item from my prescription",
    },
    {
        label: "I’m experiencing significant side effects",
        value: "I’m experiencing significant side effects",
    },
    {
        label: "My needs aren’t sufficiently met",
        value: "My needs aren’t sufficiently met",
    },
    {
        label: "It’s something else",
        value: "It’s something else",
    },
    {
        label: "Other - Book a Repeat Consultation",
        value: "Other - Book a Repeat Consultation",
    },
];

export default function ReOrderFeedBackPage() {
    const navigate = useNavigate();
    const { setAppointmentData, appointmentData } =
        useContext(AppointmentContext);
    const { parentOrderId } = useContext(RepeatOrderContext);

    const [selectedReason, setSelectedReason] = useState();
    const [initValues, setInitValues] = useState({
        reason: "",
        tellUsMore: "",
    });

    const handleSubmit = (values) => {
        const prescriptionRejectReason = {
            ...values,
        };

        let tempAppointmentData = { ...appointmentData };

        if (tempAppointmentData.pictures) {
            delete tempAppointmentData?.pictures;
        }

        const data = {
            ...tempAppointmentData,
            prescriptionRejectReason,
        };

        setAppointmentData(data);

        if (values.reason === "Other - Book a Repeat Consultation") {
            navigate(`/appointment`);
        } else {
            navigate(`/appointment/${parentOrderId}/payment?type=REPEAT`);
        }
    };

    useEffect(() => {
        if (appointmentData?.prescriptionRejectReason) {
            setInitValues({
                ...appointmentData?.prescriptionRejectReason,
            });
        }
    }, [appointmentData]);

    return (
        <div className="row mx-0 pt-5 mb-12">
            <div className="col-12 col-lg-8 col-xl-6 mx-auto mb-12">
                <GoBack center="justify-content-center" />
                <h2 className="text-center fw-bold mt-5">
                    REQUEST A PRESCRIPTION CHANGE
                </h2>
                <div className="my-8 mx-auto col-12">
                    <PrevPrescription orderId={parentOrderId} />
                </div>
                <div className="px-6 mb-8">
                    <p className="body body-small text-dark-2 mb-3">
                        To request a change, please select one of the menu items
                        below. Use the text field to provide as much detail as
                        possible.
                        {/*You may receive a few follow-up questions from*/}
                        {/*your doctor. * Please be aware the doctor may not be*/}
                        {/*able to approve the requested change, and may request*/}
                        {/*that you book another online consultation.*/}
                    </p>
                </div>
                <div className="d-flex justify-content-center mt-5">
                    <div className="col-12 col-sm-6 mx-auto">
                        <Form
                            onSubmit={handleSubmit}
                            initialValues={initValues}
                            enableReinitialize
                        >
                            <div className="mx-auto">
                                <div className="mb-4">
                                    <SearchableSelect
                                        name="reason"
                                        defaultSelectPlaceholder="Please select a reason"
                                        containerClass="w-100 mb-4"
                                        options={CHANGE_REASONS}
                                        onSelect={setSelectedReason}
                                        selectedValues={selectedReason}
                                        searchable={false}
                                        multiSelect={false}
                                        fullWidth
                                        hideIcon
                                    />
                                </div>
                                <div className="mb-4">
                                    <TextArea
                                        name="tellUsMore"
                                        placeholder="Tell us more..."
                                    />
                                </div>
                                <div className="row mx-0 mt-6 mb-4">
                                    <SubmitButton>SUBMIT FEEDBACK</SubmitButton>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}
