import React from "react";
import { useField, useFormikContext } from "formik";

import { SelectableRadioCard, TextField } from "../../../components/forms";

export default function EligibilityFormElements({
    answers,
    field = "",
    condition = [],
    conditionalField = {},
    name,
}) {
    const { values } = useFormikContext();
    const [, meta] = useField({ name });

    return (
        <>
            {answers.map((answer) => (
                <div key={answer.id}>
                    <div className="col-12 col-sm-6 mb-4">
                        {answer.type === "checkbox" && (
                            <SelectableRadioCard
                                name={answer.name}
                                id={answer.id}
                                label={answer.label}
                            />
                        )}
                        {answer.type === "text" && (
                            <TextField
                                name={answer.name}
                                label={answer.label}
                                placeholder={answer.placeholder}
                                hideError
                            />
                        )}
                    </div>
                </div>
            ))}
            {condition?.includes(values[field]) && (
                <div className="col-12 col-sm-6 mb-4 mt-8">
                    {conditionalField.type === "checkbox" && (
                        <SelectableRadioCard
                            name={conditionalField.name}
                            id={conditionalField.id}
                            label={conditionalField.label}
                        />
                    )}
                    {conditionalField.type === "text" && (
                        <TextField
                            name={conditionalField.name}
                            label={conditionalField.label}
                            placeholder={conditionalField.placeholder}
                            hideError
                        />
                    )}
                </div>
            )}
            <div
                hidden={!meta.error}
                className="invalid-feedback mb-0 mt-0 w-fit border-1 border-transparent"
                style={{ display: "block" }}
            >
                {meta.error}
            </div>
        </>
    );
}
