import Collapsible from "../collapsible/Collapsible";
import DocumentViewer from "../documentViewer/DocumentViewer";

import "./supporting-documents.scss";

export default function SupportingDocuments({ files }) {
    return (
        <Collapsible
            title="Documents"
            className="px-0 w-100"
            textColor="h6 text-dark fw-semibold"
        >
            <div
                className={`supporting-documents-container d-flex files-${files.length}`}
            >
                {files?.map((file, idx) => (
                    <div
                        className={`${
                            idx < files.length - 1 && "pe-0 pe-sm-2"
                        } supporting-document mb-2`}
                        key={idx}
                    >
                        <DocumentViewer fileData={file} fullHeight />
                    </div>
                ))}
            </div>
        </Collapsible>
    );
}
