import {useFormikContext} from "formik";
import UploadDocumentForm from "../../../components/uploadDocumentForm/UploadDocumentForm";
import {SubmitButton} from "../../../components/forms";
import Button from "../../../components/button/Button";
import React from "react";

export function UploadProfileForm ({ name, onSkip }) {
    const { values } = useFormikContext();

    return (
        <>
            <UploadDocumentForm
                name={name}
                options={[
                    {
                        label: "Profile Picture",
                        value: "profile_picture",
                    }
                ]}
                hideSubmit
                fileLimit={1}
            />
            <div className="mt-7 d-flex flex-column flex-sm-row justify-content-center">
                <SubmitButton
                    fullWidth={false}
                    variant="primary fs-5 px-12"
                    disabled={
                        !values[name] ||
                        values[name].length === 0
                    }
                >
                    <div className="d-flex justify-content-center px-sm-10">
                        <img
                            className="me-2 my-auto"
                            src="/images/vectors/arrow-right.svg"
                            alt=""
                        />
                        <span>Next</span>
                    </div>
                </SubmitButton>
                <Button
                    variant="link px-7 fw-semibold"
                    fullWidth={false}
                    onClick={onSkip}
                >
                    Skip this step
                </Button>
            </div>
        </>
    );
}