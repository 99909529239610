import { Navigate } from "react-router-dom";
import { useAuth } from "../utils/authUtils";

export default function RequireAuth({ children }) {
  const auth = useAuth();

  if (!auth.isLogged) {
    return <Navigate to="/auth/signin" />;
  }

  return children;
}
