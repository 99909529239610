import React, {useCallback, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { useResetPasswordMutation } from "../../../services/authService";
import useQuery from "../../../hooks/useQuery";
import parseError from "../../../utils/errorUtils";

import { Form, SubmitButton, TextField } from "../../../components/forms";
import PasswordConditions from "../../../components/passwordConditions/PasswordConditions";
import TimerWindow from "../../../components/timerWindow/TimerWindow";

import {ACCESS_TOKEN_NAME, TIMER_TIMEOUT} from "../../../constants";
import {useGetMyProfileQuery} from "../../../services/userService";
import {useAuth} from "../../../utils/authUtils";

const ChangePasswordSchema = yup.object({
    newPassword: yup
        .string()
        .required("Please enter a password")
        .min(8, "New password must be at least 8 characters")
        .matches(
            /^(?=.*[a-z])/,
            "Must contain at least one lowercase character"
        )
        .matches(
            /^(?=.*[A-Z])/,
            "Must contain at least one uppercase character"
        )
        .matches(/^(?=.*[0-9])/, "Must contain at least one number")
        .matches(
            /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~€£¥•]/,
            "Must contain at least one special character"
        ),
    confirmNewPassword: yup
        .string()
        .required("Please re-enter the password")
        .oneOf([yup.ref("newPassword")], "Passwords must match"),
});

export default function ResetPasswordPage() {
    const navigate = useNavigate();
    const query = useQuery();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const auth = useAuth();

    const token = query.get("token");
    const username = query.get("username");

    const [containsUpperCase, setContainsUpperCase] = useState(false);
    const [containsLowercase, setContainsLowerCase] = useState(false);
    const [containsNumber, setContainsNumber] = useState(false);
    const [containsSpecialChars, setContainsSpecialChars] = useState(false);
    const [containsMinChars, setContainsMinChars] = useState(false);
    const [showTimerWindow, setShowTimerWindow] = useState(false);
    const [initValues] = useState({
        password: "",
        confirmNewPassword: "",
    });

    const [resetPassword] = useResetPasswordMutation();
    const { data: profileData, refetch, isUninitialized } = useGetMyProfileQuery(
        {enabled: false},
        {
            skip: !auth.isLogged,
            refetchOnMountOrArgChange: true,
        }
    );

    const refetchProfile = useCallback(() => {
        if (!isUninitialized) {
            refetch();
        }
    }, [isUninitialized, refetch]);

    useEffect(() => {
        if (profileData) {
            if (profileData?.preferences?.TEMPORARY_APPOINTMENT) {
                navigate("../../onboarding/consultation/confirm-details");
            } else {
                navigate("/user/dashboard", { replace: true });
            }
        }
    }, [profileData]);

    const handleKeyUp = (e) => {
        let value = e.target.value;
        if (/^(?=.*[@#_$!%])/.test(value)) {
            setContainsSpecialChars(true);
        } else {
            setContainsSpecialChars(false);
        }

        if (/^(?=.*[a-z])/.test(value)) {
            setContainsLowerCase(true);
        } else {
            setContainsLowerCase(false);
        }

        if (/^(?=.*[A-Z])/.test(value)) {
            setContainsUpperCase(true);
        } else {
            setContainsUpperCase(false);
        }

        if (/^(?=.*[0-9])/.test(value)) {
            setContainsNumber(true);
        } else {
            setContainsNumber(false);
        }

        if (value.length >= 8) {
            setContainsMinChars(true);
        } else {
            setContainsMinChars(false);
        }
    };

    const handleNavigateToLogin = () => {
        navigate("/auth/signin");
    };

    const handleSignInSubmit = async (values) => {
        const captchaResponseToken = await handleReCaptchaVerify();

        const data = {
            username,
            token,
            ...values,
            type: "patient",
            captchaResponseToken,
        };

        try {
            const res = await resetPassword({ values: data });
            if (res.error) {
                // handle error
                toast.error(parseError(res.error, "Couldn't reset password"));
            } else {
                // request succeeded
                await refetchProfile();
                setShowTimerWindow(true);
                if (res.data.value) {
                    localStorage.setItem(ACCESS_TOKEN_NAME, res.data.value);
                    auth.logIn();
                    // setTimeout(() => {
                    //     if (profileData) {
                    //         if (profileData?.preferences?.TEMPORARY_APPOINTMENT) {
                    //             navigate("../../onboarding/consultation/confirm-details");
                    //         } else {
                    //             navigate("/user/dashboard", { replace: true });
                    //         }
                    //     }
                    // }, TIMER_TIMEOUT);
                } else {
                    setTimeout(() => {
                        navigate("/auth/signin");
                    }, TIMER_TIMEOUT);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            return;
        }

        const token = await executeRecaptcha();
        return token;
    }, [executeRecaptcha]);

    return (
        <div className="verify-email-page row mx-0">
            {showTimerWindow ? (
                <TimerWindow
                    success
                    title="Password changed successfully."
                    description="You have successfully updated your password."
                    actionBtnText="Return to Login"
                />
            ) : (
                <>
                    <div className="px-0 px-sm-5 text-center">
                        <h2 className="mt-5 fw-bold">
                            Please reset your password
                        </h2>
                        <p className="mt-5 body body-small text-dark-5">
                            Please type in your new password
                        </p>
                    </div>
                    <div className="auth-container mx-auto d-flex justify-content-center mt-8 mb-12">
                        <Form
                            onSubmit={handleSignInSubmit}
                            validationSchema={ChangePasswordSchema}
                            initialValues={initValues}
                        >
                            <div className="col-12 col-sm-7 col-lg-5 mb-3 mb-lg-0 mx-auto">
                                <div className="row mb-4">
                                    <TextField
                                        name="newPassword"
                                        placeholder="New password"
                                        onKeyUp={handleKeyUp}
                                        type="password"
                                        className="form-control-sm fs-6 px-5 py-4 bg-white"
                                    />
                                </div>
                                <div className="row mb-4">
                                    <TextField
                                        name="confirmNewPassword"
                                        placeholder="Re-enter password"
                                        type="password"
                                        className="form-control-sm fs-6 px-5 py-4 bg-white"
                                    />
                                </div>
                                <div className="row mb-8">
                                    <PasswordConditions
                                        containsLowercase={containsLowercase}
                                        containsMinChars={containsMinChars}
                                        containsNumber={containsNumber}
                                        containsSpecialChars={
                                            containsSpecialChars
                                        }
                                        containsUpperCase={containsUpperCase}
                                        title="Password Conditions:"
                                    />
                                </div>
                                <SubmitButton variant="primary">
                                    Reset My Password
                                </SubmitButton>
                            </div>
                        </Form>
                    </div>
                </>
            )}
        </div>
    );
}
