import "./order-details.scss";

export default function OrderErrorCard({
  rejected = true,
  reasonForError = { header: "", description: "", suggestions: [] },
}) {
  return (
    <div className="order-error-section rounded p-3">
      <div className="title-section text-danger fw-bold p1">
        {rejected
          ? `Reason of rejection - ${reasonForError.header}`
          : "Medication out of stock"}
      </div>
      {reasonForError.description &&
        <div className="description-section mt-2">
          {reasonForError.description}
        </div>
      }
      {!rejected && (
        <div className="bullet-point-section mt-3">
          <ul className="suggested-alternative-medication ps-3 pe-0">
            {reasonForError?.suggestions?.map((element) => (
              <li>{element}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
