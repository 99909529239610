import React from "react";
import { useFormikContext } from "formik";

export default function EligibilityForm({
    children,
    condition = [],
    field = "",
    infoText = "",
}) {
    const { values } = useFormikContext();

    return (
        <>
            {children}
            {condition?.includes(values[field]) && (
                <div className="info-card d-flex p-5">
                    <img
                        className="mb-auto me-1 me-md-3"
                        src="/images/vectors/info.svg"
                        alt=""
                    />
                    <div className="text-start text-primary-200 fs-5">
                        {infoText[values[field]]}
                    </div>
                </div>
            )}
        </>
    );
}
