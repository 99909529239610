import React from "react";
import { Outlet, useParams } from "react-router-dom";
import OrderProvider from "../../providers/OrderProvider";

function UserOrderLayoutPage() {
    const { id } = useParams();

    return (
        <OrderProvider orderId={id}>
            <div className="app-layout mb-10 mb-md-0 position-relative">
                <Outlet />
            </div>
        </OrderProvider>
    );
}

export default UserOrderLayoutPage;
