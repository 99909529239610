import { datadogLogs } from "@datadog/browser-logs";

export function useLoggerUtils() {
    const info = (message) => {
        datadogLogs.logger.info(message);
    };

    const debug = (message) => {
        datadogLogs.logger.debug(message);
    };

    const error = (message) => {
        datadogLogs.logger.error(message);
    };

    const warn = (message) => {
        datadogLogs.logger.warn(message);
    };

    return { info, debug, error, warn };
}
