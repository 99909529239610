import { useParams } from "react-router-dom";

import {
    useGetOrderQuery,
    useGetVideoTokenQuery,
} from "../../services/orderService";

import VideoStreamWithChat from "../../components/videoStreamWithChat/VideoStreamWithChat";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import TaskCard, { TASK_STATUS } from "../../components/taskCard/TaskCard";

import "./consultation-page.scss";

export default function ConsultationPage() {
    const { id } = useParams();

    const { data: orderData } = useGetOrderQuery({ appointmentId: id });
    const { data: videoTokenData, isLoading } = useGetVideoTokenQuery(
        {
            appointmentId: orderData?.appointment?.id,
        },
        { skip: !orderData }
    );

    return (
        <div className="consultation-page">
            {isLoading ? (
                <div className="mt-5">
                    <LoadingSpinner />
                </div>
            ) : videoTokenData ? (
                <>
                    <div className="row mx-0 mt-0 mt-lg-3">
                        <VideoStreamWithChat
                            sessionId={videoTokenData?.sessionId}
                            sessionToken={videoTokenData?.token}
                            orderData={orderData}
                        />
                    </div>
                </>
            ) : (
                <div className="row mx-0">
                    <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4 mt-5 mx-auto">
                        <TaskCard
                            status={TASK_STATUS.ALERT}
                            title="Consultation is not available at the moment"
                            subTitle="Please try again later"
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
