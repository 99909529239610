import { useMemo } from "react";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

import { APPROVED_STATUS, REJECTED_STATUS } from "../../constants/orderStatus";

import "./progress-bar.scss";

dayjs.extend(advancedFormat);

const includedStatusTypes = [
    "GP_APPROVAL_DECISION",
    "PRESCRIBING_PHARMACIST_APPROVAL_DECISION",
    "MDT_DECISION",
    "SPECIALIST_DECISION",
    "PHARMACY_DECISION",
    "PATIENT_PHARMACY_DECISION",
];

const statusMap = {
    GP_APPROVAL_DECISION: {
        completedText: "Patient approved by the consultant",
        awaitingText: "Awaiting patient approval from the GP",
        rejectedText: "Rejected by GP",
    },
    PRESCRIBING_PHARMACIST_APPROVAL_DECISION: {
        completedText: "Pharmacist completed the consultation",
        awaitingText: "Awaiting MDT Session",
        rejectedText: "Pharmacist completed the consultation",
    },
    MDT_DECISION: {
        completedText: "Patient approved by MDT",
        awaitingText: "Awaiting patient approval from the MDT",
        rejectedText: "Rejected by MDT",
    },
    SPECIALIST_DECISION: {
        // completedText: "Order approved by the Specialist",
        completedText: "Prescription Issued",
        awaitingText: "Awaiting order approval from the Specialist",
        rejectedText: "Rejected by Specialist",
    },
    // PATIENT_SPECIALIST_RECOMMENDATION_DECISION: {
    //    completedText: "Approved by the patient",
    //    awaitingText: "Awaiting approval from the patient",
    // },
    PHARMACY_DECISION: {
        completedText: "Order approved by Pharmacy",
        awaitingText: "Awaiting order approval from the Pharmacy",
        rejectedText: "Rejected by Pharmacy",
    },
    PATIENT_PHARMACY_DECISION: {
        completedText: "You approved the prescription",
        awaitingText: "Waiting on your approval",
        rejectedText: "Rejected by you",
    },
};

function VerticalProgressBar({ orderData }) {
    const appointmentData = orderData?.appointment;
    const isPharmacyConsultation =
        orderData?.consultationDuration === "IN_PERSON_MINUTE_00";

    const getStatusClass = (statusData, status) => {
        if (
            statusData?.approved === null ||
            !statusData?.approved === undefined
        ) {
            if (status === activeStatus) {
                return "progress-item-active";
            }
            return "";
        } else if (statusData?.approved === true) {
            return "progress-item-completed";
        } else if (statusData?.approved === false) {
            return "progress-item-rejected";
        }
    };

    const getOrderStatusText = (approved, statusMap) => {
        if (approved === null || approved === undefined) {
            return statusMap.awaitingText;
        } else if (approved === true) {
            return statusMap.completedText;
        } else if (approved === false) {
            return statusMap.rejectedText;
        }
    };

    const getAppointmentStatus = (status) => {
        switch (status) {
            case "BOOKED":
                return {
                    title: "Awaiting consultation",
                    class: "progress-item-active",
                };
            case "APPOINTMENT_COMPLETED":
                return {
                    title: "Consultation completed",
                    class: "progress-item-completed",
                };
            case "CANCELED_BY_PATIENT":
                return {
                    title: "Canceled by you",
                    class: "progress-item-rejected",
                };
            case "CANCELED_BY_DOCTOR":
                return {
                    title: "Canceled by Doctor",
                    class: "progress-item-rejected",
                };
            case "CANCELED_BY_ADMIN":
                return {
                    title: "Canceled by Admin",
                    class: "progress-item-rejected",
                };
            default:
                return { title: "", class: "progress-item-active" };
        }
    };

    const statusTypesEnum = useMemo(() => {
        let statusTypes = [];

        const orderNotes = orderData?.orderNotes || [];

        if (orderNotes?.length > 0) {
            statusTypes = orderNotes
                .filter((note) => includedStatusTypes.includes(note.type))
                .sort((a, b) => a.id - b.id)
                .map((note) => note.type);

            const lastItem = orderNotes.find(
                (note) => note.type === statusTypes[statusTypes.length - 1]
            );
            let isLastItemCompleted = true;

            if (lastItem?.type !== "GP_APPROVAL_DECISION") {
                isLastItemCompleted = lastItem?.approved;
            }

            if (
                !(
                    REJECTED_STATUS.includes(orderData.status) &&
                    APPROVED_STATUS.includes(orderData.status)
                ) &&
                isLastItemCompleted
            ) {
                const lastItemIdx = includedStatusTypes.indexOf(
                    statusTypes[statusTypes.length - 1]
                );

                if (includedStatusTypes.length > lastItemIdx + 1) {
                    statusTypes.push(includedStatusTypes[lastItemIdx + 1]);
                }
            }
        }

        return statusTypes;
    }, [orderData]);

    const activeStatus = useMemo(() => {
        statusTypesEnum?.find((obj2) => {
            const obj1 = orderData?.orderNotes?.find(
                (item) => item.type === obj2
            );

            return obj1?.approved === undefined;
        });
    }, [orderData, statusTypesEnum]);

    return (
        <div className="vertical-progress-container">
            <h3 className="text-primary fw-semibold">Order Status</h3>
            <ul className="vertical-progress">
                {!isPharmacyConsultation && (
                    <>
                        {orderData?.isRepeatOrder ? (
                            <li
                                className={`progress-item ${
                                    orderData?.status !==
                                    "AWAITING_SPECIALIST_CALL_PATIENT"
                                        ? "progress-item-completed"
                                        : "progress-item-active"
                                }`}
                            >
                                <p className="text-medium-gray mb-0 lh-1">
                                    {dayjs(orderData?.creationDate).format(
                                        "MMMM Do, YYYY"
                                    )}
                                </p>
                                <p className="progress-title">
                                    Repeat order initiated
                                </p>
                            </li>
                        ) : (
                            <li
                                className={`progress-item ${
                                    getAppointmentStatus(
                                        appointmentData?.status
                                    ).class
                                } `}
                            >
                                <p className="text-medium-gray mb-0 lh-1">
                                    {dayjs(appointmentData?.datetime).format(
                                        "MMMM Do, YYYY"
                                    )}
                                </p>
                                <p className="progress-title">
                                    {
                                        getAppointmentStatus(
                                            appointmentData?.status
                                        ).title
                                    }
                                </p>
                            </li>
                        )}
                    </>
                )}
                {statusTypesEnum?.map((status, index) => {
                    const statusData = orderData?.orderNotes.find(
                        (note) => note.type === status
                    );

                    return (
                        <li
                            className={`progress-item ${getStatusClass(
                                statusData,
                                status
                            )}`}
                            key={index}
                        >
                            <p className="text-medium-gray mb-0 lh-1">
                                {dayjs(statusData?.creationDate).format(
                                    "MMMM Do, YYYY"
                                ) || ""}
                            </p>
                            <p className="progress-title">
                                {getOrderStatusText(
                                    statusData?.approved,
                                    statusMap[status]
                                )}
                            </p>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

export default VerticalProgressBar;
