import React from "react";

import "./progress-bar.scss";

export default function ProgressBar({ progressPercentage }) {
    return (
        <div className="progress my-auto">
            <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${Math.ceil(progressPercentage)}%` }}
                aria-valuenow={progressPercentage}
                aria-valuemin="0"
                aria-valuemax="100"
            ></div>
        </div>
    );
}
