import Button from "../button/Button";

import "./task-card.scss";

export const TASK_STATUS = {
    COMPLETE: "COMPLETE",
    ALERT: "ALERT",
    PENDING: "PENDING",
    MESSAGE: "MESSAGE",
};

export default function TaskCard({
    status,
    title,
    subTitle,
    onClickActionBtn,
    actionBtnText,
}) {
    const handleOnClickAction = () => {
        if (onClickActionBtn) {
            onClickActionBtn();
        }
    };

    return (
        <div className="task-card">
            <div
                className={`d-flex justify-content-between align-items-start flex-column flex-md-row ${
                    status === TASK_STATUS.COMPLETE
                        ? "completed-task-background"
                        : "pending-task-border bg-white"
                } rounded p-4`}
            >
                <div className="logo-section d-flex align-items-start">
                    {status === TASK_STATUS.MESSAGE && (
                        <img src="/images/vectors/message-alert.svg" alt="" />
                    )}
                    {status === TASK_STATUS.PENDING && (
                        <img src="/images/vectors/task-pending.svg" alt="" />
                    )}
                    {status === TASK_STATUS.COMPLETE && (
                        <img src="/images/vectors/completed.svg" alt="" />
                    )}
                    {status === TASK_STATUS.ALERT && (
                        <img src="/images/vectors/task-alert.svg" alt="" />
                    )}
                    <div className="completed-description-section ms-0 ms-sm-4">
                        <div className="primary-title fs-5 text-primary fw-bold d-flex">
                            <span className="ms-2 ms-sm-0 my-auto">
                                {title}
                            </span>
                        </div>
                        <div className="secondary-title ms-2 ms-sm-0 text-dark-5 fw-light">
                            {subTitle}
                        </div>
                    </div>
                </div>
                {onClickActionBtn ? (
                    <div className="action-section  mt-4 mt-md-0">
                        <Button
                            variant="primary text-uppercase"
                            size="sm"
                            onClick={handleOnClickAction}
                        >
                            {actionBtnText}
                        </Button>
                    </div>
                ) : null}
            </div>
        </div>
    );
}
