import { useMemo } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import {
    useGetOrderInvoiceQuery,
    useGetOrderQuery,
} from "../../../services/orderService";

import { Form } from "../../../components/forms";
import GoBack from "../../../components/GoBack";
import ConsultationInvoice from "./orderConsultationDetails/ConsultationInvoice";
import DoctorNotes from "./orderConsultationDetails/DoctorNotes";
import PharmacyNotes from "./orderConsultationDetails/PharmacyNotes";
import OrderDescription from "./orderDetails/OrderDescription";
import LoadingSpinner from "../../../components/loadingSpinner/LoadingSpinner";

import {
    DOCTOR_NOTES_QUESTIONS,
    PHARMACY_NOTES_QUESTIONS,
    questionStructure,
} from "../../../constants/orderNotesQuestions";

export default function OrderInvoicePage() {
    const { id } = useParams();

    const { data: orderData, isLoading: orderLoading } = useGetOrderQuery(
        { appointmentId: id },
        { skip: !id, refetchOnMountOrArgChange: true }
    );
    const { data: invoice, isLoading: invoiceLoading } =
        useGetOrderInvoiceQuery(
            {
                orderId: id,
            },
            { skip: !id, refetchOnMountOrArgChange: true }
        );

    const activeDoctorQuestionAnswers = DOCTOR_NOTES_QUESTIONS.keys.filter(
        (key) => DOCTOR_NOTES_QUESTIONS.data[key].active
    );

    const activePharmacistQuestionAnswers =
        PHARMACY_NOTES_QUESTIONS.keys.filter(
            (key) => PHARMACY_NOTES_QUESTIONS.data[key]?.active
        );

    const invoiceAmount = invoice?.find(
        (inv) => inv.phase === "CONSULTATION"
    )?.price;
    const doctorQuestionData = useMemo(() => {
        let parsedObj = JSON.parse(
            orderData?.orderNotes?.find(
                (note) => note.type === "GP_CONSULTATION_NOTES"
            )?.questionAnswer || "{}"
        );

        activeDoctorQuestionAnswers.forEach((key) => {
            if (
                DOCTOR_NOTES_QUESTIONS.data[key].type.includes(
                    questionStructure.checkbox
                )
            ) {
                if (parsedObj?.[key]?.choices?.length > 0) {
                    let choicesObj = {};
                    parsedObj?.[key].choices
                        .filter((choice) => choice.checked)
                        .forEach(
                            (choice) =>
                                (choicesObj[choice.id] = {
                                    choice: choice.checked,
                                    text: choice.text,
                                })
                        );

                    parsedObj[key].choices = choicesObj;
                }
            }
        });

        return parsedObj;
    }, [activeDoctorQuestionAnswers, orderData]);

    const pharmacistQuestionData = useMemo(() => {
        let parsedObj = JSON.parse(
            orderData?.orderNotes?.find(
                (note) => note.type === "GP_CONSULTATION_NOTES"
            )?.questionAnswer || "{}"
        );

        activePharmacistQuestionAnswers.forEach((key) => {
            if (
                PHARMACY_NOTES_QUESTIONS.data[key].type.includes(
                    questionStructure.checkbox
                )
            ) {
                if (parsedObj?.[key]?.choices?.length > 0) {
                    let choicesObj = {};
                    parsedObj?.[key].choices
                        .filter((choice) => choice.checked)
                        .forEach(
                            (choice) =>
                                (choicesObj[choice.id] = {
                                    choice: choice.checked,
                                    text: {
                                        pharmacyText: choice.pharmacyText,
                                        patientText: choice.patientText,
                                    },
                                })
                        );

                    parsedObj[key].choices = choicesObj;
                }
            }

            if (
                PHARMACY_NOTES_QUESTIONS.data[key].type ===
                questionStructure.choiceAndTextOnCondition
            ) {
                if (
                    !!parsedObj?.[key]?.choice &&
                    !!PHARMACY_NOTES_QUESTIONS.data[key].inputRenderCondition
                ) {
                    parsedObj[key].choice = !!PHARMACY_NOTES_QUESTIONS.data[key]
                        .inputRenderCondition?.[parsedObj?.[key].choice]
                        ? parsedObj?.[key].choice
                        : false;

                    parsedObj[key].choices = {
                        [PHARMACY_NOTES_QUESTIONS.data[key]
                            .inputRenderCondition?.[parsedObj?.[key].choice]]: {
                            choice: !!PHARMACY_NOTES_QUESTIONS.data[key]
                                .inputRenderCondition?.[
                                parsedObj?.[key].choice
                            ],
                            text: "",
                        },
                    };
                }
            }
        });

        return parsedObj;
    }, [activePharmacistQuestionAnswers, orderData]);

    const isPharmacyConsultation =
        orderData?.consultationDuration === "IN_PERSON_MINUTE_00";

    const orderName = isPharmacyConsultation
        ? orderData?.prescribingPharmacist?.displayName
        : orderData?.doctor?.displayName;
    const registration = {
        type: isPharmacyConsultation ? "GPHC" : "GMC",
        number: isPharmacyConsultation
            ? orderData?.prescribingPharmacist?.gphc
            : orderData?.doctor?.gmc,
    };
    const orderDescription = `${
        isPharmacyConsultation ? "Consultation" : "Online consultation"
    } with ${
        isPharmacyConsultation
            ? orderData?.prescribingPharmacist?.displayName
            : orderData?.doctor?.displayName
    }`;

    return (
        <div className="order-details-section pt-6 pt-lg-0 pb-7 px-xl-6 px-xxl-12">
            <GoBack />
            <div className="bg-white rounded mt-5 py-8">
                {orderData && (
                    <OrderDescription
                        orderName={`Consultation with ${orderName}`}
                        orderNumber={id}
                        orderDate={
                            orderData?.isRepeatOrder
                                ? null
                                : dayjs(orderData.appointment.datetime).format(
                                      "MMMM Do, YYYY @ hh:mm a"
                                  )
                        }
                        registration={registration}
                        doctorAvatar={
                            orderData?.doctor?.settings.DOCTOR_AVATAR ||
                            "/images/samples/doctor-thumb.png"
                        }
                        status={orderData.status}
                    />
                )}

                <div className="px-5 px-md-8 px-xxl-10">
                    {orderLoading || invoiceLoading ? (
                        <LoadingSpinner />
                    ) : (
                        orderData && (
                            <div className="mb-6">
                                <ConsultationInvoice
                                    amount={invoiceAmount}
                                    topic={orderData?.name}
                                    dateTime={orderData?.appointment.datetime}
                                    orderId={id}
                                    orderDescription={orderDescription}
                                    registration={registration}
                                />
                            </div>
                        )
                    )}
                    <div className="mb-6">
                        <Form
                            initialValues={
                                isPharmacyConsultation
                                    ? pharmacistQuestionData
                                    : doctorQuestionData
                            }
                            enableReinitialize
                        >
                            {isPharmacyConsultation ? (
                                <PharmacyNotes />
                            ) : (
                                <DoctorNotes
                                    isRepeatOrder={orderData?.isRepeatOrder}
                                />
                            )}
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}
