/*
Modals can be stackable.
When hiding stacked modals the top-most one will go out first.(LIFO)
Show/hide functionality will be handled via a custom hook. (check modalHooks)
Modals will blur the backgrounds. Clicking on the background won't close the modal.

Edit below component to change these behaviors. Make sure to consult an SSE or above rank before making changes.
*/

import { useContext } from "react";
import { ModalContext } from "../../providers/ModalProvider";

import RejectWarningModal from "./rejectWarningModal/RejectWarningModal";
import SuccessModal from "./successModal/SuccessModal";
import ConsentModal from "./consentModal/ConsentModal";
import ConsultationConsentModal from "./consultationConsentModal/ConsultationConsentModal";
import PEGModal from "./pegModal/PEGModal";
import GADModal from "./gadModal/GADModal";
import SideEffectModal from "./sideEffectModal/SideEffectModal";
import AUDITModal from "./auditModal/AUDITModal";
import InfoModal from "./infoModal/InfoModal";
import JoinMailingListModal from "./joinMailingListModal/JoinMailingListModal";
import CameraModal from "./cameraModal/CameraModal";

import "./modal-container.scss";
import {PaymentSuccessInfoModal} from "./paymentSuccessModal/PaymentSuccessInfoModal";

// register modals here.
const modalContent = {
    RejectWarningModal,
    SuccessModal,
    ConsentModal,
    ConsultationConsentModal,
    PEGModal,
    SideEffectModal,
    GADModal,
    AUDITModal,
    InfoModal,
    JoinMailingListModal,
    CameraModal,
    PaymentSuccessInfoModal
};

export default function ModalContainer() {
    const { modalProps, modalTypes, hideModal } = useContext(ModalContext);

    const handleClose = async () => {
        // hide current modal
        await hideModal();
    };

    return modalTypes.map((modalType) => {
        const CurrModalContent = modalContent[modalType];
        const currModalProps = { ...modalProps[modalType] };
        if (currModalProps && !currModalProps.closeModal) {
            currModalProps.closeModal = handleClose;
        }

        return (
            <div
                className="modal-container"
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div
                    role="presentation"
                    className="modal-overlay"
                    onClick={() => currModalProps?.isPaymentSuccess ? undefined : hideModal()}
                />
                <CurrModalContent {...currModalProps} />
            </div>
        );
    });
}
