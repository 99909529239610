import { useField, useFormikContext } from "formik";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/style.css";
import "./phone-number-input.scss";

/**
 * Custom phone number input. Integrated with Formik
 * @param {{
 * name: string,
 * label: string,
 * disabled?: boolean,
 * }} props
 * @returns {React.FunctionComponent}
 *
 */

export default function PhoneNumberInput(props) {
    const { name, label, ...rest } = props;

    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(props);

    return (
        <div className="phone-input w-100">
            {label && (
                <label htmlFor={name} className="form-label">
                    {label}
                </label>
            )}
            <div className="input-container position-relative">
                <PhoneInput
                    {...field}
                    {...rest}
                    country={"gb"}
                    disableDropdown
                    disableCountryCode
                    inputClass="w-100"
                    containerClass="input-container"
                    value={field.value}
                    onChange={(val) => setFieldValue(field.name, val)}
                />
                <span className="prefix position-absolute d-flex px-2">
                    <img src="/images/vectors/gb-flag.svg" alt="" />
                    <span className="ms-1 my-auto fs-p2 code">+44</span>
                </span>
            </div>
            {meta.touched && meta.error ? (
                <div
                    className="invalid-feedback mb-0 mt-0 w-fit"
                    style={{ display: "block" }}
                >
                    {meta.error}
                </div>
            ) : null}
        </div>
    );
}
