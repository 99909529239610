/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from "react";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";

import { AppointmentContext } from "../../providers/AppointmentProvider";
import { useLazyGetDoctorQuery } from "../../services/dataService";

import { Form, SubmitButton, TextArea } from "../../components/forms";
import Avatar from "../../components/avatar/Avatar";
import Button from "../../components/button/Button";
import Collapsible from "../../components/collapsible/Collapsible";
import GoBack from "../../components/GoBack";

function ConsultationNotesPage() {
    const navigate = useNavigate();
    const { appointmentData, setAppointmentData } =
        useContext(AppointmentContext);
    const { id: orderId } = useParams();

    const [doctorData, setDoctorData] = useState({});
    const [initialValues, setInitValues] = useState({
        notes: "",
    });

    const [getDoctor] = useLazyGetDoctorQuery();

    const handleGetDoctor = async () => {
        if (appointmentData?.doctorId) {
            try {
                const doctorRes = await getDoctor({
                    doctorId: appointmentData.doctorId,
                });
                if (doctorRes.error) {
                    // handle error
                } else {
                    setDoctorData(doctorRes.data);
                }
            } catch (error) {}
        }
    };

    const handleSubmit = async ({ notes }) => {
        const appointment = {
            ...appointmentData,
            notes,
        };

        setAppointmentData(appointment);

        navigate(`/appointment/${orderId}/uploads`);
    };

    const handleSkip = () => {
        navigate(`/appointment/${orderId}/uploads`);
    };

    const handleClickAppointmentChange = () => {
        navigate(`/appointment`);
    };

    useEffect(() => {
        if (appointmentData?.notes) {
            setInitValues({
                notes: appointmentData?.notes,
            });
        }

        handleGetDoctor();
    }, [appointmentData]);

    return (
        <div className="row mx-0 h-100">
            <div className="col-md-6 pt-5 position-relative bg-light-gray">
                <div className="row mx-0">
                    <div className="col-12 col-lg-11 col-xl-9 col-xxl-8 mx-auto pb-5 pb-md-0 mb-0 mb-md-8">
                        <GoBack />

                        <h3 className="text-center fw-bold text-brown-black mt-4">
                            Anything you think your doctor should know before
                            your appointment?
                        </h3>
                        <p className="mt-4 text-medium-gray fs-6 text-center">
                            To help our Doctors understand more about the
                            symptoms/problems you are having, please provide a
                            brief description below
                        </p>

                        <div className="d-flex justify-content-center mt-5">
                            <Form
                                initialValues={initialValues}
                                onSubmit={handleSubmit}
                            >
                                <div className="mt-3">
                                    <TextArea name="notes" />
                                </div>
                                <div className="mt-6 text-center">
                                    <SubmitButton
                                        variant="primary px-8"
                                        fullWidth={false}
                                    >
                                        CONTINUE
                                    </SubmitButton>
                                </div>
                                <div
                                    className="mt-4 text-decoration-underline text-center text-brown-black cursor-pointer fs-6"
                                    onClick={handleSkip}
                                >
                                    Skip this step
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 pt-10 px-2 px-lg-6 mb-5 bg-white">
                <div className="row mx-0 mb-12 mb-md-0 pb-12 pb-md-0">
                    <div className="col-12 col-lg-11 col-xl-9 col-xxl-8 mx-auto pb-5 pb-md-0 mb-0 mb-md-8">
                        <Collapsible
                            title="Consultation Summary"
                            expanded
                            className="py-6 px-5 custom-shadow"
                        >
                            <div className="text-primary fs-6 fw-bold">
                                Consultation details
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-4">
                                <div className="d-flex align-items-sm-center">
                                    <div
                                        className="d-none d-sm-block"
                                        style={{ width: 56, height: 56 }}
                                    >
                                        <Avatar
                                            src={
                                                doctorData?.settings
                                                    ?.DOCTOR_AVATAR ||
                                                "/images/samples/doctor-thumb.png"
                                            }
                                        />
                                    </div>

                                    <div className="ms-0 ms-sm-4">
                                        <div className="primary-title fw-bold fs-5">
                                            {doctorData.displayName}
                                        </div>
                                        {appointmentData?.datetime && (
                                            <div className="secondary-title text-dark-3 fw-light">
                                                {dayjs(
                                                    appointmentData.datetime
                                                ).format("DD/MM/YYYY")}{" "}
                                                at{" "}
                                                {dayjs(
                                                    appointmentData.datetime
                                                ).format("hh:mma")}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <Button
                                    variant=" text-dark-4 text-decoration-underline border-0 text-right p-0 ms-4"
                                    fullWidth={false}
                                    onClick={handleClickAppointmentChange}
                                >
                                    Change
                                </Button>
                            </div>
                        </Collapsible>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConsultationNotesPage;
