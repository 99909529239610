import { useField } from "formik";

export default function TextArea({ name, label, placeholder, rows, ...rest }) {
    const [field, meta] = useField({ name, label, placeholder, rows, ...rest });

    return (
        <div className="text-area w-100">
            {label && (
                <label htmlFor={name} className="form-label">
                    {label}
                </label>
            )}
            <textarea
                className="form-control"
                placeholder={placeholder || ""}
                rows={rows || 5}
                {...field}
                {...rest}
            />

            <div
                hidden={!(meta.touched && meta.error)}
                className="invalid-feedback mb-0 mt-0 w-fit"
                style={{ display: "block" }}
            >
                {meta.error}
            </div>
        </div>
    );
}
