import React from "react";

import "./service-card.scss";

export default function ServiceCard({ title, body, logo, buttonText, link }) {
    return (
        <div className="service-card card px-6 py-5 border-0 shadow-sm rounded-1 bg-white bg-opacity-50 d-flex flex-column">
            <span className="text-center text-primary fs-4 fw-bold">
                {title}
            </span>
            <span className="text-primary-200 text-center fs-6 mt-3">
                {body}
            </span>
            <div className="mx-auto my-auto">
                <img
                    src={`/images/vectors/${logo}.svg`}
                    alt=""
                    width={220}
                    className="img-fluid"
                />
            </div>
            <a
                href={link}
                className="btn btn-link p-0 fs-6 mt-auto fw-semibold"
                target="_blank"
                rel="noreferrer"
            >
                {buttonText}
                <img
                    className="ms-2 my-auto"
                    src="/images/vectors/arrow-right-primary.svg"
                    alt=""
                />
            </a>
        </div>
    );
}
