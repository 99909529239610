import React, { useState } from "react";
import * as Yup from "yup";

import { useModal } from "../../../hooks/modalsHooks";

import { Form } from "../../forms";
import { PEGForm } from "../../orderNoteForms";
import ModalLayout from "../ModalLayout";

const PEGFormSchema = Yup.object().shape({
    painInPastWeek: Yup.number()
        .min(0, "Must be greater than or equal to 0")
        .max(10, "Must be less than or equal to 10"),
    painInterferenceWithEnjoyment: Yup.number()
        .min(0, "Must be greater than or equal to 0")
        .max(10, "Must be less than or equal to 10"),
    painInterferenceWithGeneralActivity: Yup.number()
        .min(0, "Must be greater than or equal to 0")
        .max(10, "Must be less than or equal to 10"),
});

export default function PEGModal({ onSubmit, initialValues }) {
    const { hideModal } = useModal();

    const [initValues] = useState(
        initialValues || {
            painInPastWeek: undefined,
            painInterferenceWithEnjoyment: undefined,
            painInterferenceWithGeneralActivity: undefined,
        }
    );

    const handleSubmit = (values) => {
        const average =
            (values.painInPastWeek +
                values.painInterferenceWithEnjoyment +
                values.painInterferenceWithGeneralActivity) /
            3;

        const data = {
            type: "PEG_FORM",
            questionAnswer: JSON.stringify({ ...values, average }),
        };

        onSubmit(data);
    };

    return (
        <ModalLayout width="44rem">
            <ModalLayout.Header closeModal={hideModal}>
                <div className="w-100">
                    <h2 className="position-relative text-center fw-bold">
                        PEG form
                        <span className="underline center short" />
                    </h2>
                </div>
            </ModalLayout.Header>
            <Form
                initialValues={initValues}
                validationSchema={PEGFormSchema}
                onSubmit={handleSubmit}
                className="h-100"
            >
                <PEGForm />
            </Form>
        </ModalLayout>
    );
}
