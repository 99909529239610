// Current modal layout logic is as follows.

// Modal Layout has three sections; Header, Body, Footer
// Make sure to include them within the ModalLayout Component in the correct order. (check one of the example modals)
// You can render anything within those sections.
// Modal layout takes the full screen height available while leaving padding on the top and bottom.
// Within the Layout, Modal Header and Footer has fixed heights.
// Modal Body takes up the full height available regardless of the content height.
// If the content within the Body component is too long, body component will be internally scrollable to view everything. (check ExampleModalOne)

// Alternatively you can use just the Modal Layout without those three components if you need flexibility.

// You can change these behaviors according the UI/UX design of the project. Plan these changes behaviors beforehand.
// Make sure that the changes are consistent across project and works well in any screen.
// Do not change these components without consulting senior frontend engineers (someone equal or above SSE)

import Button from "../button/Button";

import "./modal-layout.scss";

function ModalLayout({ className, children, width = 600 }) {
    return (
        <div
            className={`modal-dialog modal-dialog-scrollable m-0 ${className}`}
            style={{ width: width }}
        >
            <div className="modal-content h-100">{children}</div>
        </div>
    );
}

ModalLayout.Header = ({
    className,
    children,
    disableClose = false,
    closeModal,
}) => (
    <div className={`modal-header pt-6 pb-5 px-6 ${className}`}>
        {!disableClose && closeModal && (
            <Button
                variant=" p-0 position-absolute close-button"
                fullWidth={false}
                onClick={closeModal}
            >
                <img src="/images/vectors/close-grey.svg" alt="" />
            </Button>
        )}
        {children}
    </div>
);

ModalLayout.Body = ({ children, className }) => (
    <div className={`modal-body ${className || "p-5"}`}>{children}</div>
);

ModalLayout.Footer = ({ children }) => (
    <div className="modal-footer py-3 px-4">{children}</div>
);

export default ModalLayout;
