import React, { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import dayjs from "dayjs";

import { useGetAppointmentsQuery } from "../../../services/orderService";
import { useGetMyProfileQuery } from "../../../services/userService";
import { AppointmentContext } from "../../../providers/AppointmentProvider";

import { Form } from "../../../components/forms";
import SelectSlotForm from "./SelectSlotForm";

const AppointmentSchema = yup.object({
    id: yup.string().required("Please select a time slot"),
});

export default function SelectSlotPage() {
    const navigate = useNavigate();
    const { setAppointmentData, appointmentData } =
        useContext(AppointmentContext);

    const { data: profileData } = useGetMyProfileQuery();
    const { data: appointmentList, isFetching } = useGetAppointmentsQuery({
        page: 0,
        pageSize: 320,
        type: "full",
    });

    const handleSubmit = async (values) => {
        const timeSlot = appointmentList.content.find(
            (slot) => slot.id === values.id
        );
        const { id, doctorId, datetime } = timeSlot;

        try {
            const appointment = {
                type: "BOOKING",
                id,
                datetime,
                doctorId,
                name: `Consultation (${dayjs(datetime).format(
                    "DD/MM/YYYY"
                )} ${dayjs(datetime).format("hh:mm a")})`,
                patient: profileData,
                deliveryType: "STANDARD",
                paymentType: "ONLINE",
                deviceType: "OTHER",
            };

            await setAppointmentData({ ...appointmentData, ...appointment });

            navigate("/onboarding/appointment/confirm-details");
        } catch (error) {
            console.error(error);
        }
    };

    const dateSlots = useMemo(() => {
        let arr = [];
        appointmentList?.content.forEach(({ datetime }) => {
            const date = dayjs(datetime);
            const formattedDate = `${date.year()}-${
                date.month() + 1
            }-${date.date()}`;

            arr.push(formattedDate);
        });

        let unique = [...new Set(arr)];

        return unique;
    }, [appointmentList?.content]);

    return (
        <div className="select-slot-page px-3 m-auto pb-12">
            <div className="d-flex flex-column">
                <Form
                    initialValues={{
                        // default first date always should be today
                        date: new Date(),
                    }}
                    enableReinitialize
                    onSubmit={handleSubmit}
                    validationSchema={AppointmentSchema}
                >
                    <SelectSlotForm
                        availableSlots={appointmentList?.content || []}
                        dateSlots={dateSlots}
                        isFetching={isFetching}
                        name="id"
                    />
                </Form>
            </div>
        </div>
    );
}
