import React, { useEffect, useMemo, useState } from "react";
import { useField, useFormikContext } from "formik";

import DropDown from "../dropDown/DropDown";

const dayOptions = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
    { label: 10, value: 10 },
    { label: 11, value: 11 },
    { label: 12, value: 12 },
    { label: 13, value: 13 },
    { label: 14, value: 14 },
    { label: 15, value: 15 },
    { label: 16, value: 16 },
    { label: 17, value: 17 },
    { label: 18, value: 18 },
    { label: 19, value: 19 },
    { label: 20, value: 20 },
    { label: 21, value: 21 },
    { label: 22, value: 22 },
    { label: 23, value: 23 },
    { label: 24, value: 24 },
    { label: 25, value: 25 },
    { label: 26, value: 26 },
    { label: 27, value: 27 },
    { label: 28, value: 28 },
    { label: 29, value: 29 },
    { label: 30, value: 30 },
    { label: 31, value: 31 },
];

const monthOptions = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
];

export default function DatePicker(props) {
    const { name, label, disabled } = props;
    const { setFieldValue, values, setFieldTouched } = useFormikContext();
    const [, meta] = useField(props);

    const [selectedDay, setSelectedDay] = useState("");
    const [selectedMonth, setSelectedMonth] = useState("");
    const [selectedYear, setSelectedYear] = useState("");

    const getValue = (val) => {
        const newVal = new Date(val);
        let fullDate = {};

        if (val) {
            const day = newVal.getDate();
            const month = newVal.getMonth() + 1;
            const year = newVal.getFullYear();

            fullDate = {
                day,
                month,
                year,
            };
        }

        return fullDate;
    };

    const setDay = (value) => {
        setSelectedDay(value);
    };

    const setMonth = (value) => {
        if (monthOptions.find((month) => month.label === value)) {
            setSelectedMonth(value);
        } else {
            setFieldValue(name, "");
        }
    };

    const setYear = (value) => {
        if (value.length === 4) {
            setSelectedYear(value);
        } else {
            setFieldValue(name, "");
        }
    };

    let yearOptions = useMemo(() => {
        let years = [];
        for (var i = 1900; i <= new Date().getFullYear(); i++) {
            years.push({ label: `${i}`, value: `${i}` });
        }

        return years;
    }, []);

    useEffect(() => {
        if (selectedDay && selectedMonth && selectedYear) {
            const date = new Date(
                `${selectedDay}-${selectedMonth}-${selectedYear}`
            );

            setFieldTouched(name, true);
            setFieldValue(name, date);
        }
    }, [
        name,
        selectedDay,
        selectedMonth,
        selectedYear,
        setFieldValue,
        setFieldTouched,
    ]);

    useEffect(() => {
        if (values[name]) {
            const fullDate = getValue(values[name]);
            const month = monthOptions.find(
                (month) => Number(month.value) === fullDate.month
            ).label;

            setSelectedDay(`${fullDate.day}`);
            setSelectedMonth(month);
            setSelectedYear(`${fullDate.year}`);
        }
    }, [name, values]);

    return (
        <div className="position-relative d-flex flex-column w-100">
            {label && (
                <label htmlFor={name} className="form-label fs-5 fw-bold">
                    {label}
                </label>
            )}
            <div className="d-flex w-100">
                <div className="col-3 p-0 pe-3">
                    <DropDown
                        options={dayOptions}
                        defaultSelectPlaceholder="Day"
                        multiSelect={false}
                        dropDownOnly
                        fullWidth
                        hideIcon
                        showSearchIcon={false}
                        onSelect={setDay}
                        onChange={setDay}
                        selectedValues={selectedDay}
                        disabled={disabled}
                    />
                </div>
                <div className="col-5 p-0 pe-3">
                    <DropDown
                        options={monthOptions}
                        defaultSelectPlaceholder="Month"
                        multiSelect={false}
                        dropDownOnly
                        fullWidth
                        hideIcon
                        showSearchIcon={false}
                        onSelect={setMonth}
                        onChange={setMonth}
                        selectedValues={selectedMonth}
                        disabled={disabled}
                    />
                </div>
                <div className="col-4 p-0">
                    <DropDown
                        options={yearOptions}
                        defaultSelectPlaceholder="Year"
                        multiSelect={false}
                        dropDownOnly
                        fullWidth
                        hideIcon
                        showSearchIcon={false}
                        onSelect={setYear}
                        onChange={setYear}
                        selectedValues={selectedYear}
                        disabled={disabled}
                    />
                </div>
            </div>
            <div
                hidden={!(meta.touched && meta.error)}
                className="invalid-feedback mb-0 mt-0 w-fit"
                style={{ display: "block", bottom: "-1.125rem" }}
            >
                {meta.error}
            </div>
        </div>
    );
}
