import { getDisplayAddress } from "../../../../utils/dataUtils";

import "./order-details.scss";

export default function ShippingCard({ patientData, handleTracking }) {
    const address = patientData?.address;

    return (
        <div className="shipping-card d-flex justify-content-between flex-column flex-md-row bg-light-gray border border-gray rounded p-4">
            <div className="d-flex justify-content-start w-100">
                <div className="image-section me-3 mb-auto">
                    <img
                        src="/images/samples/shipping.png"
                        alt=""
                        style={{ width: 64, height: 64, borderRadius: "50%" }}
                    />
                </div>
                <div className="description-section text-brown-black">
                    <div className="title fw-bold">
                        {patientData?.displayName}
                    </div>
                    <div className="date mt-1">{patientData?.phone || ""}</div>
                    <div className="date mt-1">
                        {getDisplayAddress(address)}
                    </div>
                    {/* <div className="track-section text-primary text-decoration-underline fw-bold mt-3">
                        <div
                            className="track"
                            type="button"
                            onClick={handleTracking}
                        >
                            Track your order
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}
