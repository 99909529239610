import { createContext, useEffect, useState } from "react";
import {
    useGetOrderInvoiceQuery,
    useGetOrderPrescriptionQuery,
    useGetOrderQuery,
} from "../services/orderService";

export const OrderContext = createContext({
    order: null,
    prescription: null,
    deliveryType: "STANDARD",
    setShippingMethod: () => {},
    onPayPrescription: () => {},
});

export default function OrderProvider({ orderId, children }) {
    const [deliveryType, setDeliveryType] = useState("STANDARD");

    const { data: order } = useGetOrderQuery({ appointmentId: orderId });
    const { data: prescription } = useGetOrderPrescriptionQuery({
        orderId: orderId,
    });
    const { data: invoice } = useGetOrderInvoiceQuery({
        orderId: orderId,
    });

    useEffect(() => {
        if (order?.deliveryType) {
            setDeliveryType(order.deliveryType);
        }
    }, [order]);

    return (
        <OrderContext.Provider
            value={{
                order,
                prescription,
                deliveryType,
                invoice,
                setDeliveryType,
            }}
        >
            {children}
        </OrderContext.Provider>
    );
}
