import React from "react";
import { useLocation } from "react-router-dom";

import { useAuth } from "../../utils/authUtils";

import NavLink from "./NavLink";

import { TREAT_IT_URL } from "../../constants";
import { ONBOARDING_ELIGIBILITY_QUESTIONS } from "../../constants/onboardingEligibilityQuestions";

import "./nav-bar.scss";

const navLinks = [
    {
        id: 1,
        name: "Eligibility Test",
        baseURL: "/onboarding/eligibility",
    },
    {
        id: 2,
        name: "Create Account",
        baseURL: "/onboarding/auth",
    },
    {
        id: 3,
        name: "Book an Appointment",
        baseURL: "/onboarding/appointment",
    },
];

export default function OnboardingNavbar() {
    const location = useLocation();
    const auth = useAuth();

    const handleRouteToWebsite = () => {
        window.location.href = TREAT_IT_URL;
    };

    const getStatus = (pathId) => {
        const eligibilityForm = JSON.parse(
            localStorage.getItem("EligibilityForm") || "{}"
        );
        const currentLocationData = navLinks.find((link) =>
            location.pathname.includes(link.baseURL)
        );

        switch (pathId) {
            case 1:
                if (
                    (Object.keys(eligibilityForm).length >=
                        ONBOARDING_ELIGIBILITY_QUESTIONS.keys.length &&
                        ONBOARDING_ELIGIBILITY_QUESTIONS.keys.find(
                            (key) => eligibilityForm[key]
                        )) ||
                    auth.isLogged
                ) {
                    return "COMPLETE";
                }

                return "DISABLED";
            case 2:
                if (auth.isLogged) {
                    return "COMPLETE";
                }

                return "DISABLED";
            case 3:
                if (
                    currentLocationData?.id === pathId &&
                    location.pathname.includes("success")
                ) {
                    return "COMPLETE";
                }

                return "DISABLED";
            default:
                return "DISABLED";
        }
    };

    return (
        <nav className="logo-nav d-flex justify-content-sm-between justify-content-end align-items-center pt-4 px-3 px-lg-5 position-fixed w-100">
            <div className="me-auto">
                <button
                    className="bg-transparent p-0 border-0"
                    onClick={handleRouteToWebsite}
                >
                    <img
                        className="logo p-4 pb-5"
                        src="/images/logo.png"
                        alt=""
                    />
                </button>
            </div>
            <div className="mx-sm-auto mt-auto d-flex">
                {navLinks.map((link, idx) => (
                    <NavLink
                        name={link.name}
                        state={getStatus(link.id)}
                        isActive={location.pathname.includes(link.baseURL)}
                        key={idx}
                    />
                ))}
            </div>
            <div className="ms-auto invisible d-none d-sm-block">
                <button className=" p-0 border-0">
                    <img
                        className="logo p-4 pb-5"
                        src="/images/logo.png"
                        alt=""
                    />
                </button>
            </div>
        </nav>
    );
}
