import React from "react";
import { useNavigate } from "react-router-dom";

import { useGetOrdersQuery } from "../../../services/orderService";

import OrderCard from "../../../components/orderCard/OrderCard";
import LoadingSpinner from "../../../components/loadingSpinner/LoadingSpinner";
import TaskCard, { TASK_STATUS } from "../../../components/taskCard/TaskCard";

import {
    APPROVED_STATUS,
    FAILED_STATUS,
    REJECTED_STATUS,
} from "../../../constants/orderStatus";

function OrdersPage() {
    const navigate = useNavigate();

    const { data: ordersData, isLoading: ordersLoading } = useGetOrdersQuery();

    const handleOrderAction = (id) => {
        navigate(`/user/order/${id}`);
    };

    const activeOrders =
        ordersData?.content
            .filter(
                (order) =>
                    !(
                        REJECTED_STATUS.includes(order.status) ||
                        APPROVED_STATUS.includes(order.status) ||
                        FAILED_STATUS.includes(order.status)
                    )
            )
            .sort((a, b) => b.id - a.id) || [];

    const pastOrders =
        ordersData?.content
            .filter(
                (order) =>
                    REJECTED_STATUS.includes(order.status) ||
                    FAILED_STATUS.includes(order.status) ||
                    APPROVED_STATUS.includes(order.status)
            )
            .sort((a, b) => b.id - a.id) || [];

    if (ordersLoading) {
        return (
            <div className="mt-5 text-center">
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <div className="pt-6 pt-lg-0 pb-5 px-xl-6 px-xxl-12">
            <h2 className="fw-bold text-primary">Orders</h2>

            <h3 className="fw-bold mt-7">Active</h3>
            {activeOrders.length > 0 ? (
                activeOrders.map((content, idx) => (
                    <div className="mt-4" key={idx}>
                        <OrderCard
                            onClickAction={handleOrderAction}
                            orderData={content}
                        />
                    </div>
                ))
            ) : (
                <TaskCard
                    status={TASK_STATUS.ALERT}
                    title="No active orders"
                    subTitle="No active orders at the moment"
                />
            )}
            <h3 className="fw-bold mt-7">Past orders</h3>
            {pastOrders.length > 0 ? (
                pastOrders.map((content, idx) => (
                    <div className="mt-4" key={idx}>
                        <OrderCard
                            onClickAction={handleOrderAction}
                            orderData={content}
                        />
                    </div>
                ))
            ) : (
                <TaskCard
                    status={TASK_STATUS.ALERT}
                    title="No past orders"
                    subTitle="No past orders at the moment"
                />
            )}
        </div>
    );
}

export default OrdersPage;
