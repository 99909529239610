import React from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import {
    useGetOrderInvoiceQuery,
    useGetOrderQuery,
} from "../../../services/orderService";
import { print } from "../../../utils/printUtils";

import Button from "../../../components/button/Button";
import Invoice from "../../../components/invoice/Invoice";
import GoBack from "../../../components/GoBack";
import LoadingSpinner from "../../../components/loadingSpinner/LoadingSpinner";

export default function ConsultationInvoicePage() {
    const { id } = useParams();

    const { data: orderData } = useGetOrderQuery(
        { appointmentId: id },
        { skip: !id, refetchOnMountOrArgChange: true }
    );
    const { data: invoice, isLoading } = useGetOrderInvoiceQuery(
        {
            orderId: id,
        },
        { skip: !id, refetchOnMountOrArgChange: true }
    );

    const printPage = () => {
        print("consultation-invoice");
    };

    const subTotal =
        invoice?.find(
            (data) =>
                data.phase === "CONSULTATION" &&
                (orderData?.parentOrderItemId && !orderData?.doctor
                    ? data.type === "SPECIALIST_SERVICE_CHARGE"
                    : data.type === "APPOINTMENT")
        )?.price || 0;
    const discount =
        invoice?.find(
            (data) => data.phase === "CONSULTATION" && data.type === "COUPON"
        )?.price * subTotal || 0;
    const total =
        invoice?.find(
            (data) => data.phase === "CONSULTATION" && data.type === "TOTAL"
        )?.price || 0;

    const invoiceDate = invoice?.find(
        (data) => data.phase === "CONSULTATION" && data.type === "TOTAL"
    )?.paymentDate;

    const lineItems = [
        {
            title:
                orderData?.parentOrderItemId && orderData.doctor
                    ? "Repeat consultation fee"
                    : orderData?.parentOrderItemId &&
                      orderData.isRepeatOrder &&
                      !orderData.doctor
                    ? "Repeat prescription fee"
                    : "Consultation fee",
            desc: null,
            qty: 1,
            unitPrice: subTotal,
            vat: 0,
            net: subTotal,
        },
    ];

    return (
        <div className="pt-6 pt-lg-0 pb-7 px-xl-6 px-xxl-12 position-relative">
            <GoBack />
            <h4 className="mt-8 d-flex justify-content-center fw-bold">
                Consultation Invoice
            </h4>
            <div className="d-flex">
                <Button
                    onClick={printPage}
                    fullWidth={false}
                    variant="primary ms-auto mt-2 z-100"
                    disabled={isLoading}
                >
                    Print
                </Button>
            </div>
            {isLoading ? (
                <div className="mt-3 d-flex justify-content-center">
                    <LoadingSpinner />
                </div>
            ) : (
                <Invoice
                    elementId="consultation-invoice"
                    invoiceNumber={id}
                    invoiceDate={dayjs(invoiceDate).format("MMMM Do, YYYY")}
                    customerName={orderData?.patient.displayName}
                    address={{
                        line1: orderData?.patient.address.line1,
                        line2: orderData?.patient.address.line2,
                        city: orderData?.patient.address.city,
                        country: orderData?.patient.address.country,
                        postcode: orderData?.patient.address.postcode,
                    }}
                    customerEmail={orderData?.patient.email}
                    lineItems={lineItems}
                    subTotal={subTotal}
                    discount={discount}
                    totalAmount={total}
                    paid={!!invoiceDate}
                />
            )}
        </div>
    );
}
