import React from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import {
    useGetOrderInvoiceQuery,
    useGetOrderPrescriptionQuery,
    useGetOrderQuery,
} from "../../../services/orderService";
import { print } from "../../../utils/printUtils";

import Button from "../../../components/button/Button";
import Invoice from "../../../components/invoice/Invoice";
import GoBack from "../../../components/GoBack";
import LoadingSpinner from "../../../components/loadingSpinner/LoadingSpinner";

export default function PrescriptionInvoicePage() {
    const { id } = useParams();

    const { data: orderData } = useGetOrderQuery(
        { appointmentId: id },
        { skip: !id, refetchOnMountOrArgChange: true }
    );
    const { data: prescriptionData } = useGetOrderPrescriptionQuery({
        orderId: id,
    });
    const { data: invoice, isLoading } = useGetOrderInvoiceQuery(
        {
            orderId: id,
        },
        { skip: !id, refetchOnMountOrArgChange: true }
    );

    const printPage = () => {
        print("prescription-invoice");
    };

    const subTotal =
        invoice?.find(
            (data) =>
                data.phase === "PRESCRIPTION" &&
                data.type === "PRESCRIPTION_DRUG"
        )?.price || 0;
    const baseDelivery =
        invoice?.find(
            (data) =>
                data.phase === "PRESCRIPTION" && data.type === "BASE_DELIVERY"
        )?.price || 0;
    const deliveryVat =
        invoice?.find(
            (data) =>
                data.phase === "PRESCRIPTION" && data.type === "DELIVERY_VAT"
        )?.price || 0;
    const total =
        invoice?.find(
            (data) => data.phase === "PRESCRIPTION" && data.type === "TOTAL"
        )?.price || 0;
    const prescriptionFee =
        invoice?.find(
            (data) =>
                data.phase === "PRESCRIPTION" && data.type === "PRESCRIPTION"
        )?.price || 0;

    const invoiceDate = invoice?.find(
        (data) => data.phase === "PRESCRIPTION" && data.type === "TOTAL"
    )?.paymentDate;

    const lineItems = prescriptionData?.medication.map((data) => ({
        title: `${data.formulary.productName} - ${data.formulary.quantity}`,
        desc: {
            medication: data.formulary.productName,
            adminRoute: data.formulary.administrationRoute,
            quantity: data.quantity,
            medicineProfile: data.formulary.medicineProfile,
            strain: data.formulary.strain,
        },
        qty: data.quantity,
        unitPrice: data.formulary.salePrice,
        vat: 0,
        net: data.charge,
    }));

    return (
        <div className="pt-6 pt-lg-0 pb-7 px-xl-6 px-xxl-12 position-relative">
            <GoBack />
            <h4 className="mt-8 d-flex justify-content-center fw-bold">
                Prescription Invoice
            </h4>
            <div className="d-flex">
                <Button
                    onClick={printPage}
                    fullWidth={false}
                    variant="primary ms-auto mt-2 z-100"
                    disabled={isLoading}
                >
                    Print
                </Button>
            </div>
            {isLoading ? (
                <div className="mt-3 d-flex justify-content-center">
                    <LoadingSpinner />
                </div>
            ) : (
                <Invoice
                    elementId="prescription-invoice"
                    invoiceNumber={`${id}-001`}
                    invoiceDate={dayjs(invoiceDate).format("MMMM Do, YYYY")}
                    customerName={orderData?.patient.displayName}
                    address={{
                        line1: orderData?.patient.address.line1,
                        line2: orderData?.patient.address.line2,
                        city: orderData?.patient.address.city,
                        country: orderData?.patient.address.country,
                        postcode: orderData?.patient.address.postcode,
                    }}
                    customerEmail={orderData?.patient.email}
                    lineItems={lineItems}
                    subTotal={subTotal}
                    prescriptionFee={prescriptionFee}
                    baseDelivery={baseDelivery}
                    deliveryVat={deliveryVat}
                    totalAmount={total}
                    paid={!!invoiceDate}
                    pharmacy={prescriptionData?.pharmacy.name}
                    gphcNumber={prescriptionData?.pharmacy.gphc}
                />
            )}
        </div>
    );
}
