/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { useField, useFormikContext } from "formik";

import { Calendar, SubmitButton } from "../../../components/forms";
import LoadingSpinner from "../../../components/loadingSpinner/LoadingSpinner";

dayjs.extend(advancedFormat);

const SHOWING_TIME_SLOTS = 5;

export default function SelectSlotForm({
    availableSlots,
    dateSlots,
    isFetching,
    name,
}) {
    const { values, setFieldValue } = useFormikContext();
    const [, meta] = useField({ name });

    const [root, setRoot] = useState(0);

    const handleClickNext = () => {
        if (timeSlots.length / (root + 1) > SHOWING_TIME_SLOTS) {
            setRoot((prevData) => prevData + 1);
        }
    };

    const handleClickPrev = () => {
        if (root > 0) {
            setRoot((prevData) => prevData - 1);
        }
    };

    const handleSelectTime = (id) => {
        setFieldValue(name, id);
    };

    const selectedDate = useMemo(() => {
        setRoot(0);

        if (availableSlots.length > 0) {
            if (values.date) {
                return dayjs(values.date).format("YYYY-MM-DD");
            }

            return dayjs(availableSlots[0].datetime).format("YYYY-MM-DD");
        }

        return null;
    }, [availableSlots, values?.date]);

    const timeSlots = useMemo(() => {
        const selectedDateTimeSlots = availableSlots.filter(({ datetime }) => {
            const date = dayjs(datetime);
            const formattedDate = `${date.year()}-${
                date.month() + 1
            }-${date.date()}`;

            return !!dayjs(selectedDate).isSame(dayjs(formattedDate), "day");
        });

        return selectedDateTimeSlots;
    }, [availableSlots, selectedDate]);

    // first date always should be today
    const minimumDate = new Date();
    const maximumDate = new Date(dateSlots[dateSlots.length - 1]);

    return (
        <>
            <div className="row">
                <div className="col-12 col-sm-6 py-4 px-1 pe-sm-6 divider text-center">
                    <h3 className="text-primary fw-bold mb-3 text-center text-sm-start">
                        Appointment Date*
                    </h3>
                    <p className="fs-6 subtitle text-primary-200 text-center text-sm-start mb-5">
                        Select a date for your appointment.
                    </p>

                    <Calendar
                        minimumDate={minimumDate}
                        maximumDate={maximumDate}
                        name="date"
                        disabled={isFetching}
                    />
                </div>
                <div className="col-12 col-sm-6 py-4 px-1 ps-sm-6 text-center">
                    <h3 className="text-primary fw-bold mb-3 text-center text-sm-start">
                        Appointment Time*
                    </h3>
                    <p className="fs-6 subtitle text-primary-200 text-center text-sm-start mb-5">
                        Select a time for your appointment.
                    </p>

                    <div className="time-slot-card card py-4 px-3 mx-auto">
                        <div className="d-flex justify-content-evenly pt-2 mb-4">
                            <button
                                className="bg-transparent p-0 my-auto d-flex border-0"
                                onClick={handleClickPrev}
                                disabled={isFetching}
                                type="button"
                            >
                                <img
                                    className="my-auto"
                                    src="/images/vectors/arrow-left-filled.svg"
                                    alt=""
                                />
                            </button>
                            <div className="text-primary fw-semibold my-auto">
                                {isFetching ? (
                                    <LoadingSpinner />
                                ) : (
                                    dayjs(values?.date).format(
                                        "dddd DD, MMMM YYYY"
                                    )
                                )}
                            </div>
                            <button
                                className="bg-transparent p-0 my-auto d-flex border-0"
                                onClick={handleClickNext}
                                disabled={isFetching}
                                type="button"
                            >
                                <img
                                    className="my-auto"
                                    src="/images/vectors/arrow-right-filled.svg"
                                    alt=""
                                />
                            </button>
                        </div>
                        <div className="d-flex flex-wrap justify-content-center h-100">
                            {timeSlots.length > 0 ? (
                                timeSlots
                                    ?.slice(
                                        root * SHOWING_TIME_SLOTS,
                                        root * SHOWING_TIME_SLOTS +
                                            SHOWING_TIME_SLOTS
                                    )
                                    .map((slot, idx) => {
                                        const formattedTime = dayjs(
                                            slot.datetime
                                        ).format("hh:mma");

                                        return (
                                            <button
                                                className={`rounded-1 bg-white text-primary text-start fw-light fs-6 p-3 mb-2 ${
                                                    idx % 2 === 0
                                                        ? "me-1"
                                                        : "ms-1"
                                                } ${
                                                    slot.id === values.id
                                                        ? "border-primary-1"
                                                        : "border-light"
                                                }`}
                                                key={idx}
                                                disabled={isFetching}
                                                onClick={() =>
                                                    handleSelectTime(slot.id)
                                                }
                                                type="button"
                                            >
                                                {formattedTime}
                                            </button>
                                        );
                                    })
                            ) : (
                                <p className="fs-6 subtitle text-primary text-center m-auto mb-12">
                                    No available time slots for today. Please
                                    select another day
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div
                hidden={meta.touched && meta.error}
                className="invalid-feedback text-center mt-7"
                style={{ display: "block" }}
            >
                {meta.error}
            </div>

            <div className="d-flex justify-content-center">
                <SubmitButton
                    fullWidth={false}
                    variant="primary mt-7 fs-5"
                    type="submit"
                    disabled={isFetching}
                >
                    <img
                        className="me-2 my-auto"
                        src="/images/vectors/arrow-right.svg"
                        alt=""
                    />
                    Confirm Date & Time
                </SubmitButton>
            </div>
        </>
    );
}
