export const ORDER_STATUS = {
    DELIVERED: "DELIVERED",
    DISPENSED_BY_PHARMACY: "DISPENSED_BY_PHARMACY",
    BOOKED: "BOOKED",
    IN_MDT_SESSION: "IN_MDT_SESSION",
    AWAITING_MDT_SESSION_INITIATION: "AWAITING_MDT_SESSION_INITIATION",
    AWAITING_MDT_SESSION_COMPLETION: "AWAITING_MDT_SESSION_COMPLETION",
    AWAITING_SPECIALIST_CALL_PATIENT: "AWAITING_SPECIALIST_CALL_PATIENT",
    AWAITING_SPECIALIST_PRESCRIPTION_CREATION:
        "AWAITING_SPECIALIST_PRESCRIPTION_CREATION",
    REJECTED_BY_PHARMACY_AWAITING_SPECIALIST_ALTERATION:
        "REJECTED_BY_PHARMACY_AWAITING_SPECIALIST_ALTERATION",
    WAITING_FOR_TRACKING_CODE: "WAITING_FOR_TRACKING_CODE",
    WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_NO_CHANGES:
        "WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_NO_CHANGES",
    WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_PATIENT_APPROVAL:
        "WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_PATIENT_APPROVAL",
    AWAITING_PATIENT_PRESCRIPTION_PAYMENT:
        "AWAITING_PATIENT_PRESCRIPTION_PAYMENT",
    AWAITING_PATIENT_SPECIALIST_PRESCRIPTION_CONFIRMATION:
        "AWAITING_PATIENT_SPECIALIST_PRESCRIPTION_CONFIRMATION",
    AWAITING_PATIENT_SPECIALIST_ALERT_REPLY:
        "AWAITING_PATIENT_SPECIALIST_ALERT_REPLY",
    AWAITING_PATIENT_PHARMACY_PRESCRIPTION_CONFIRMATION:
        "AWAITING_PATIENT_PHARMACY_PRESCRIPTION_CONFIRMATION",
    WAITING_FOR_PHARMACY_APPROVAL: "WAITING_FOR_PHARMACY_APPROVAL",
    AWAITING_PHARMACY_DISPENSE: "AWAITING_PHARMACY_DISPENSE",
    AWAITING_PHARMACY_DISPATCH: "AWAITING_PHARMACY_DISPATCH",
    AWAITING_PHARMACY_DELIVER: "AWAITING_PHARMACY_DELIVER",
    REJECTED_BY_SPECIALIST: "REJECTED_BY_SPECIALIST",
    REJECTED_BY_PHARMACY: "REJECTED_BY_PHARMACY",
    REJECTED_BY_MDT: "REJECTED_BY_MDT",
    REJECTED_BY_PATIENT: "REJECTED_BY_PATIENT",
    REJECTED_BY_DOCTOR: "REJECTED_BY_DOCTOR",
    CANCELED_BY_DOCTOR: "CANCELED_BY_DOCTOR",
    CANCELED_BY_ADMIN: "CANCELED_BY_ADMIN",
    REJECTED_AFTER_28_DAYS: "REJECTED_AFTER_28_DAYS",
    PAYMENT_FAILED: "PAYMENT_FAILED",
};

export const CONSULTATION_WAITING = [ORDER_STATUS.BOOKED];

export const PATIENT_APPROVAL_WAITING = [
    ORDER_STATUS.AWAITING_PATIENT_SPECIALIST_PRESCRIPTION_CONFIRMATION,
    ORDER_STATUS.AWAITING_PATIENT_PHARMACY_PRESCRIPTION_CONFIRMATION,
    ORDER_STATUS.AWAITING_PATIENT_PRESCRIPTION_PAYMENT,
];

export const PATIENT_ANSWER_WAITING = [
    ORDER_STATUS.AWAITING_PATIENT_SPECIALIST_ALERT_REPLY,
];

export const MDT_APPROVAL_WAITING = [
    ORDER_STATUS.AWAITING_MDT_SESSION_INITIATION,
    ORDER_STATUS.AWAITING_MDT_SESSION_COMPLETION,
];

export const SPECIALIST_APPROVAL_WAITING = [
    ORDER_STATUS.AWAITING_SPECIALIST_CALL_PATIENT,
    ORDER_STATUS.AWAITING_SPECIALIST_PRESCRIPTION_CREATION,
    ORDER_STATUS.WAITING_FOR_TRACKING_CODE,
    ORDER_STATUS.WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_NO_CHANGES,
    ORDER_STATUS.WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_PATIENT_APPROVAL,
    ORDER_STATUS.REJECTED_BY_PHARMACY_AWAITING_SPECIALIST_ALTERATION,
];

export const PHARMACY_APPROVAL_WAITING = [
    ORDER_STATUS.WAITING_FOR_PHARMACY_APPROVAL,
    ORDER_STATUS.AWAITING_PHARMACY_DISPATCH,
    ORDER_STATUS.AWAITING_PHARMACY_DISPENSE,
    ORDER_STATUS.AWAITING_PHARMACY_DELIVER,
];

export const PAYED_FOR_PRESCRIPTION_STATUS = [
    ORDER_STATUS.AWAITING_PHARMACY_DISPENSE,
    ORDER_STATUS.DISPENSED_BY_PHARMACY,
    ORDER_STATUS.AWAITING_PHARMACY_DISPATCH,
    ORDER_STATUS.AWAITING_PHARMACY_DELIVER,
    ORDER_STATUS.DELIVERED,
];

export const PENDING_STATUS = [
    ...PATIENT_APPROVAL_WAITING,
    ...PATIENT_ANSWER_WAITING,
    ...SPECIALIST_APPROVAL_WAITING,
    ...PHARMACY_APPROVAL_WAITING,
    ...MDT_APPROVAL_WAITING,
];

export const APPROVED_STATUS = [
    ORDER_STATUS.DELIVERED,
    ORDER_STATUS.DISPENSED_BY_PHARMACY,
];

export const PATIENT_REJECTED = [ORDER_STATUS.REJECTED_BY_PATIENT];

export const MDT_REJECTED = [ORDER_STATUS.REJECTED_BY_MDT];

export const SPECIALIST_REJECTED = [ORDER_STATUS.REJECTED_BY_SPECIALIST];

export const PHARMACY_REJECTED = [ORDER_STATUS.REJECTED_BY_PHARMACY];

export const DOCTOR_REJECTED = [
    ORDER_STATUS.REJECTED_BY_DOCTOR,
    ORDER_STATUS.CANCELED_BY_DOCTOR,
];

export const ADMIN_REJECTED = [ORDER_STATUS.CANCELED_BY_ADMIN];

export const REJECTED_AFTER_28_DAYS = [ORDER_STATUS.REJECTED_AFTER_28_DAYS];

export const PAYMENT_FAILED = [ORDER_STATUS.PAYMENT_FAILED];

export const REJECTED_STATUS = [
    ...DOCTOR_REJECTED,
    ...PATIENT_REJECTED,
    ...MDT_REJECTED,
    ...SPECIALIST_REJECTED,
    ...PHARMACY_REJECTED,
    ...ADMIN_REJECTED,
];

export const FAILED_STATUS = [...PAYMENT_FAILED];
