import React from "react";

import "./avatar.scss";

const DEFAULT_AVATAR = "/images/default-avatar.png";

export default function Avatar({ src }) {
    return (
        <div className="avatar">
            <div className="avatar-inner rounded-circle">
                <img
                    src={src || DEFAULT_AVATAR}
                    className="avatar-img"
                    alt=""
                />
            </div>
        </div>
    );
}
