import React, { useState } from "react";
import * as yup from "yup";
import { toast } from "react-toastify";

import { useForgetPasswordMutation } from "../../../services/authService";
import parseError from "../../../utils/errorUtils";

import { Form } from "../../../components/forms";
import ForgotPasswordForm from "./ForgotPasswordForm";

const ForgotPasswordSchema = yup.object({
    username: yup.string().email().required("Please enter your email"),
});

export default function ForgotPasswordPage() {
    const [linkSent, setLinkSent] = useState(false);
    const [initValues] = useState({
        username: "",
    });

    const [forgetPassword] = useForgetPasswordMutation();

    const handleForgotPasswordSubmit = async ({ username }) => {
        const values = { username, type: "patient" };

        try {
            const res = await forgetPassword({ values });
            if (res.error) {
                // handle error
                toast.error(
                    parseError(res.error, "Couldn't send the reset link")
                );
            } else {
                // request succeeded
                toast.success("Reset link sent");
                setLinkSent(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="forgot-password-page row mx-0">
            <div className="px-0 px-sm-5 text-center">
                <h2 className="mt-3 fw-bold">
                    {linkSent ? "Check your inbox" : "Forgot Password"}
                </h2>
            </div>
            <div className="auth-container mx-auto d-flex flex-column justify-content-center mt-5">
                <Form
                    onSubmit={handleForgotPasswordSubmit}
                    validationSchema={ForgotPasswordSchema}
                    initialValues={initValues}
                >
                    <div className="col-12 col-sm-7 col-lg-5 mb-3 mb-lg-0 mx-auto">
                        <ForgotPasswordForm
                            linkSent={linkSent}
                            onRetry={handleForgotPasswordSubmit}
                        />
                    </div>
                </Form>
            </div>
        </div>
    );
}
